import React, { useEffect, useMemo, useState } from 'react';
import { SvgIcon } from '../../components/common';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Divider, Form, Input } from 'antd';
import './index.scss';

import logoImage from '../../assets/images/logo-light.svg';
import logoImage1 from '../../assets/images/logo.svg';
import AwesomeImg from '../../assets/images/tp-left.png';
import VideoOne from '../../assets/video/bg_auth.mp4';
import { logEvent } from '../../utility/analytics';
import { useLoaderContext } from '../../components/common/Loader';
import { useDispatch } from 'react-redux';
import { apiClient } from '../../apiConfig';
import { Alert } from '../../common';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { auth } from '../../utility/firebase';
import { setAccessToken, setPriveCredential, setUser } from '../../slice/userSlice';

const Login = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setShowLoader } = useLoaderContext();
  const { redirect } = location.state || {};

  const [email, setEmail] = useState('');
  const isEmailValid = useMemo(() => {
    return email && /\S+@\S+\.\S+/.test(email);
  }, [email]);

  const handleCheckEmail = async () => {
    try {
      logEvent('login_check_email_success');
      setShowLoader(true);
      const response = await apiClient.post('/admin/checkEmailLogin', { vEmail: email });
      if (response.data) {
        if (response.data.status === 1) {
          logEvent('email_login_initiated');
          Alert.success(response.data.MESSAGE);
          history.replace(`/verifyOtp`, {
            from: location,
            redirect: redirect,
            formData: {
              email: email,
            },
          });
        } else if (response.data.status === 0) {
          logEvent('login_check_email_error', { message: response.data.MESSAGE });
          Alert.error(response.data.MESSAGE);
        } else {
          logEvent('login_check_email_error');
          console.error('Unknown status:', response.data.status);
        }
      }
    } catch (error) {
      console.error('Error checking email:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const authUser = async (token) => {
    try {
      setShowLoader(true);
      const response = await apiClient.post('/admin/doLoginWithIdToken', {
        idToken: token,
      });
      if (response.data) {
        if (response.data.status === 1) {
          dispatch(setPriveCredential(undefined));
          dispatch(setAccessToken(response.data.DATA.accesstoken));
          dispatch(setUser(response.data.DATA));

          let redirectUrl = window.location.origin;
          if (redirect) {
            if (redirect.pathname.startsWith('/')) {
              redirectUrl += redirect.pathname + redirect.search;
            } else {
              redirectUrl += '/' + redirect.pathname + redirect.search;
            }
          }
          window.location.href = redirectUrl;
        } else {
          if (response.data?.MESSAGE) {
            Alert.error(response.data.MESSAGE);
          }
          console.error('Unknown status:', response.data.status);
        }
      }
    } catch (error) {
      console.error('Error login with idToken:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleGoogle = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            //console.log(token);
            authUser(token);
          })
          .catch((error) => {});
        // ...
        //console.log(token);
      })
      .catch((error) => {
        console.error(error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          Alert.error('You have used another provider before to login/signup please use the same.');
        }
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleFacebook = () => {
    signInWithPopup(auth, new FacebookAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            //console.log(token);
            authUser(token);
          })
          .catch((error) => {});
        // ...
        //console.log(token);
      })
      .catch((error) => {
        console.error(error);
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          Alert.error('You have used another provider before to login/signup please use the same.');
        }
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleApple = () => {
    signInWithPopup(auth, new OAuthProvider('apple.com'))
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = OAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            console.log(token);
            authUser(token);
          })
          .catch((error) => {
            console.error(error);
          });
        // ...
        //console.log(token);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          Alert.error('You have used another provider before to login/signup please use the same.');
        }
        const errorMessage = error.message;
        console.log(errorMessage);
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = OAuthProvider.credentialFromError(error);
        // ...
      });
  };

  useEffect(() => {
    logEvent('login', { name: 'Login Screen' });
  }, []);

  return (
    <div className="auth-wrapper">
      <div className="auth-left">
        <div className="authleft-inner">
          <div>
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>
              Discover <br />
              Your City
            </h1>
          </div>
          <div>
            <div className="awse-img">
              <img src={AwesomeImg} alt="Awesome" />
            </div>
            <h3>Awesome!</h3>
            <p>
              Awesome! It’s easy to use and very handy. It helps a lot to save more. Worth every
              penny
            </p>
            <div className="left-name">Nikol</div>
          </div>
        </div>
        <video playsInline autoPlay muted loop className="banner-video">
          <source src={VideoOne} />
        </video>
      </div>
      <div className="auth-right">
        <div className="authright-inner">
          <div className="right-upper">
            <div className="mobile-logo">
              <Link to="/">
                <img src={logoImage1} alt={logoImage1} />
              </Link>
            </div>
            <div className="upper-inner">
              <h2>Login</h2>
              <div className="right-head">
                <span>Don't have an account?</span>
                <Link to="/signup" className="auth-upper-btn ant-btn" replace>
                  Sign Up
                </Link>
              </div>
            </div>
          </div>
          <div className="right-form">
            <Form name="basic" autoComplete="off" layout="vertical">
              <Form.Item label="Email">
                <Input
                  prefix={<SvgIcon name="email-icon" viewbox="0 0 30 30" />}
                  placeholder="Enter"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item className="m-0 py-3">
                <Button
                  type="secondary"
                  htmlType="submit"
                  block
                  onClick={handleCheckEmail}
                  disabled={!isEmailValid}
                >
                  Log In
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="auth-right-footer">
            <Divider>Or Log In with Email</Divider>
            <ul>
              <li>
                <Button onClick={handleApple}>
                  <SvgIcon name="apple" viewbox="0 0 22.982 28.234" />
                </Button>
              </li>
              <li>
                <Button onClick={handleGoogle}>
                  <SvgIcon name="google" viewbox="0 0 28.688 29.243" />
                </Button>
              </li>
              <li>
                <Button onClick={handleFacebook}>
                  <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
