import React from 'react';
import { Button, Modal } from 'antd';
import { Col, Row, SvgIcon } from '../../common';
import Lottie from 'react-lottie';
import './index.scss';

import AnimationOne from '../../../assets/json/no_location.json';

const NotAtSelectedVenueModal = ({ handleConfirm, handleCancel }) => {
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  return (
    <>
      <Modal
        className="not-at-selected-venue-modal"
        centered
        maskClosable={false}
        footer={null}
        title={null}
        width={550}
        open={true}
        onOk={handleCancel}
        onCancel={handleCancel}
        closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
      >
        <div>
          <Row>
            <Col md="12">
              <Lottie options={animationLottie} height={200} width={200} />
            </Col>
            <Col md="12">
              <h1 data-aos="fade-up" data-aos-duration="800">
                Oops!
              </h1>
              <p data-aos="fade-up" data-aos-duration="900">
                Looks like you are not at the selected partner venue <br />
                <br />
                Would you like us to redirect you to the correct venue on the App
                <br />
              </p>
              <div className="pt-3 sub-btns" data-aos="fade-up" data-aos-duration="1000">
                <Button type="secondary" className="px-5" onClick={handleConfirm}>
                  Confirm
                </Button>
                <Button type="link" className="px-5 cancel-text" onClick={handleCancel}>
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default NotAtSelectedVenueModal;
