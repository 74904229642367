import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const RPTermsandConditions = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Rewards Plus
                  </h1>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Terms and Conditions
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/rewards-privacy-policy">
                    Privacy Policy{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  The Rewards Plus mobile application provides discount offers and ‘2 for 1’ offers
                  at participating retailers. The offers are managed by a third party, TravellerPass
                  LLC. Rewards Plus can only be used for offer redemption by bank cardholders. Bank
                  card customers must use the Rewards Plus app and a valid bank card to avail of the
                  offers. The bank assumes no liability or responsibility for the products or
                  services provided by any partners in the program.
                  <br />
                  <br />
                  Rewards Plus lists a catalogue of preferred partners and their offers, available
                  to card customers. Rewards Plus terms and conditions apply to all users. Customers
                  will register by entering their name, mobile number, email ID, and membership
                  number. Once the registration process is completed with Rewards Plus, the customer
                  will have access to all offers. Rewards Plus privileges may be revoked at any time
                  at the sole discretion of the bank, with or without notification.
                </p>
              </div>
              <div className="content-row">
                <h3>Preferred Partners</h3>
                <p>
                  Preferred partners are those retailers or partners in the UAE where the offers can
                  be redeemed using an applicable bank card. The preferred partners and their
                  respective offers are listed in Rewards Plus. Offers and the countries where they
                  are allowed are subject to change from time to time. Offers may be modified or
                  discontinued at any time without notice.
                  <br />
                  For the 2 for 1 offer, the partner merchant may apply either as a deduction of the
                  cost of one least expensive main course or main menu item (when two or more main
                  courses or main menu items are ordered) or as a discount on the bill for the
                  offers.
                </p>
              </div>

              <div className="content-row">
                <h3>Offer Redemption</h3>
                <p>
                  Offers can be redeemed at preferred partners only when the purchase is made using
                  an applicable bank card. To avail of the offer, the card customer will have to:
                </p>
                <ol>
                  <li>
                    Present the Rewards Plus mobile voucher to the preferred partner before
                    requesting the bill.
                  </li>
                  <li>Pay the remaining balance for the service/meal using bank cards.</li>
                </ol>
                <p>All bank cards terms and conditions apply.</p>
                <ol>
                  <li>A valid bank card must be presented for payment of the offer.</li>
                  <li>
                    Card customers will receive the offers at preferred partners for the total
                    amount of the bill minus any tax or service charges.
                  </li>
                  <li>
                    Offers can only be claimed against purchases made at preferred partners and
                    cannot be paid in cash, using any other payment or loyalty card, by combining
                    with any other promotion or offer.
                  </li>
                  <li>
                    The Rewards Plus mobile app must be presented at the point of purchase to redeem
                    the offer. A preferred partner has the right to deny the discount if the mobile
                    app is not presented while requesting the bill.
                  </li>
                  <li>
                    The use of offers within the mobile app in conjunction with any other officially
                    announced promotions or reductions is at the discretion of preferred partners.
                    Offers will not be valid on festival days or as per the terms and conditions
                    offered by the preferred partner. The terms and conditions of each offer are
                    updated on the mobile app for each preferred partner. Card customers need to
                    take note of the offer validity dates and special offer terms and conditions
                    that will be mentioned along with the offer.
                  </li>
                </ol>
              </div>
              <div className="content-row">
                <h3>Rewards Plus validity</h3>
                <p>
                  The bank has the right to terminate Rewards Plus at any time without reason or
                  notice and will not be liable for any loss or damage that may arise from any
                  suspension or termination. The bank reserves the right to withdraw the privileges
                  of Rewards Plus if any card customer is in breach of the terms and conditions of
                  Rewards Plus.
                </p>
              </div>
              <div className="content-row">
                <h3>Disputes on Discount</h3>
                <p>
                  If a Preferred Partner does not honor the valid Offer, the instance can be
                  reported within two working days by connecting with TravellerPass through the
                  Mobile App in the “Need Help” section during office hours (from 12PM – 10PM, on
                  all days) or via email to{' '}
                  <a href="mailto:concierge@travellerpass.com">concierge@travellerpass.com</a>.
                  <br />
                  The Customer must provide complete details to TravellerPass about the merchant’s
                  name and location, date of purchase, discount denied and purchase information. If
                  the Customer has a dispute on the discount, TravellerPass will take reasonable
                  efforts to resolve the dispute.
                </p>
              </div>
              <div className="content-row">
                <h3>Additional Terms and Conditions</h3>
                <ol type="a">
                  <li>
                    Offers are subject to the prescribed bank terms and conditions and those of
                    TravellerPass. The bank reserves the right to amend these terms and conditions
                    at any time or withdraw this offer without prior notice. By accepting the offer,
                    the customer accepts and agrees to be bound by the terms and conditions.
                  </li>
                  <li>
                    The bank assumes no responsibility for the services provided by the service
                    partner (TravellerPass)
                  </li>
                  <li>
                    The customer understands and acknowledges that Rewards Plus is a service offered
                    by the bank and performed by TravellerPass. TravellerPass and the bank are
                    independent contractors to each other, and the bank will have no liability to
                    the customer for any claims that the customer may have against TravellerPass for
                    services rendered.
                  </li>
                  <li>
                    The bank will not be liable for any errors, omissions, delays, or disruptions in
                    the operation of Rewards Plus.
                  </li>
                  <li>
                    The bank or TravellerPass may amend the offers or add or remove preferred
                    partners from the program at any time.
                  </li>
                  <li>
                    Preferred partners may exclude products or services from being eligible for the
                    offers without prior consent or notice. At certain preferred partners, offers
                    may be subject to availability and may be altered or withdrawn without notice.
                  </li>
                  <li>
                    The bank is not responsible if a preferred partner changes its discount due to
                    new ownership or acquisition.
                  </li>
                  <li>
                    The bank will not be liable if any participating preferred partner fails to
                    provide the offer on any product. The bank does not accept any liability in
                    connection with the failure or refusal of any participating preferred partner to
                    provide products. The bank does not accept any liability or responsibility for
                    the quality or suitability of products offered by preferred partners.
                  </li>
                  <li>
                    No preferred partner has any authority, express or implied, to make a
                    representation, warranty, or statement on behalf of the bank or in relation to
                    Rewards Plus, and the bank accepts no liability in respect of any
                    representation, warranty, or statement made by any preferred partner.
                  </li>
                  <li>
                    The bank does not give any guarantee, undertaking, or warranty concerning the
                    products or services supplied or the performance of any participating preferred
                    partner. All conditions and warranties, whether express or implied and whether
                    arising under statute or otherwise, as to the condition, suitability, quality,
                    fitness, or safety of any goods or services supplied by any preferred partner
                    are expressly excluded.
                  </li>
                  <li>
                    Rewards Plus may be amended, withdrawn, or replaced at any time without notice
                  </li>
                  <li>
                    The bank is not liable for any dissatisfaction with the delivery, service, or
                    quality of Rewards Plus.
                  </li>
                  <li>
                    The bank and TravellerPass’s interpretation of these terms and conditions is
                    final and binding.
                  </li>
                </ol>
              </div>
              <div className="content-row">
                <h3>Rewards Plus Mobile App Terms and Conditions</h3>
                <ol>
                  <li>
                    The mobile app will only work on compatible devices and operating systems, and
                    certain functionalities will only work if your device is connected to the
                    internet. Some features may also only be available with certain operating
                    systems.
                  </li>
                  <li>
                    You may be charged by your service provider for downloading and/or accessing the
                    mobile app on your mobile phone (the device). These may include data roaming
                    charges if you do this outside your home territory. All these charges are solely
                    your responsibility.
                  </li>
                  <li>TravellerPass reserves the right to make changes to the Mobile App.</li>
                  <li>
                    Certain functionalities of the Mobile App may need to access your device and
                    information stored on it so that it works properly. By using the Mobile App, you
                    consent to allow the Mobile App access to your information requested to proving
                    this Service.
                  </li>
                  <li>
                    You must not use the Mobile App for any of the following purposes:(a) anything
                    unlawful or illegal or which is fraudulent or malicious or which promotes any
                    criminal activity or provides information about the same;(b) anything which is
                    defamatory, harassing or threatening or which otherwise infringes or violates
                    the rights of others. This includes any information that you may add or upload
                    to the Mobile App;(c) interfering in any way with any other user of the Mobile
                    App; and/or(d) knowingly introducing viruses or other malicious or harmful
                    material or using it in connection with unsolicited communications. The Mobile
                    App and all copyright, database rights, trademarks and other intellectual
                    property rights related to it belong to us or our licensors.
                  </li>
                  <li>
                    Access to the Mobile App may be suspended temporarily from time to time and
                    without notice in the case of system failure, maintenance, or repair or for any
                    reason beyond our control or if we deem it necessary. For the avoidance of
                    doubt, we shall not be liable for any loss or liability which may be suffered or
                    incurred by you because of any suspension of, or interruption to, the operation
                    of the Mobile App.
                  </li>
                  <li>
                    The Mobile App, including all content on or available through the Mobile App, is
                    provided on an ‘as is’ basis and we do not make any representation or give any
                    warranty in respect of the Mobile App or any of its contents. But without
                    limitation, we do not give any warranty as to the accuracy, suitability,
                    reliability, completeness, performance, fitness, freedom from viruses or
                    timeliness of the content contained on the App.
                  </li>
                  <li>
                    The Mobile App will only work on compatible devices and operating systems and
                    certain functionalities will only work if your device is connected to the
                    internet. Some features may also only be available with certain operating
                    systems.
                  </li>
                </ol>
              </div>
              <div className="content-row">
                <h3>Bank/TravellerPass does not accept any responsibility for:</h3>
                <ol type="a">
                  <li>
                    malfunctions in communication facilities which cannot reasonably be considered
                    to be under our control and that may affect the accuracy or timeliness of
                    messages you send or the material you access via the Mobile App
                  </li>
                  <li>
                    any losses or delays in transmission of messages or material you access arising
                    out of the use of any internet access service provider or mobile network service
                    provider or caused by any browser or other software which is not under our
                    control.
                  </li>
                  <li>
                    Viruses that may infect your computer equipment or other property on account of
                    your access to or use of the Mobile App or your accessing any materials on the
                    Mobile App
                  </li>
                  <li>
                    any unauthorized use or interception of any message or information before it
                    reaches the Mobile App or our servers from the Mobile App.
                  </li>
                  <li>
                    any unauthorized use of or access to data relating to you or your transactions
                    which is held by us (unless such use or access is caused by our negligence,
                    fraud or failure to comply with laws relating to the protection of your data),
                    to the extent permitted by local law
                  </li>
                  <li>any content provided by third parties</li>
                </ol>
              </div>
              <div className="content-row">
                <p>
                  n addition, Bank/TravellerPass and its officers, directors, employees,
                  shareholders, or agents exclude all liability and responsibility for any amount or
                  kind of loss or damage that may result to you or a third party (including without
                  limitation, any direct, indirect, punitive, or consequential loss or damages, or
                  any loss of income, profits, goodwill, data, contracts, use of money, or loss or
                  damages arising from or connected in any way to business interruption, and whether
                  in tort (including without limitation negligence), contract, or otherwise) in any
                  way or in connection with:
                </p>
                <ol type="a">
                  <li>
                    the Mobile App and your use, delay in use or inability to use the App or if it
                    does not work as you expect or would like or if it contains any defects or
                    errors and whether or not we correct any of those defects or errors
                  </li>
                  <li>
                    your access to and use of any material on the Mobile App or material linked to
                    or referred to on the Mobile App; and/or
                  </li>
                  <li>
                    any loss or damage to your device or any other hardware or software you use in
                    connection with the Mobile App, including in connection with any viruses that
                    may affect the same on account of your downloading and use of the Mobile App.
                  </li>
                </ol>
                <p>
                  You acknowledge that use of the mobile app is dependent on third parties,
                  including your own network providers, and that we are not liable for any acts or
                  omissions of those third parties. These mobile app terms and conditions and any
                  dispute or claim arising out of or in connection with them or their subject matter
                  (including any non-contractual disputes or claims) will be governed by the law of
                  the United Arab Emirates (“UAE”), and the parties agree that the courts of the UAE
                  will have exclusive jurisdiction to settle any such disputes or claims.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default RPTermsandConditions;
