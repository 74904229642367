import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { priveApiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getPriveListingPageData = createAsyncThunk(
  'fetch/prive/offerList',
  async (arg, thunkAPI) => {
    const { countryCity: countryCityReducer, user: userReducer } = thunkAPI.getState();
    const { latLng } = userReducer;
    const { selectedCity } = countryCityReducer;

    const { cacheKey, categoryId } = arg;
    if (!selectedCity) {
      throw Error('City not selected.');
    }
    if (!cacheKey) {
      throw Error('arg cacheKey not found.');
    }

    console.log('LatLng', latLng);

    thunkAPI.dispatch(setCacheKey(cacheKey));

    const payload = {
      dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
      dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
      iCountryID: selectedCity.iCountryID,
      start: 0,
      limit: 20,
      iCategoryID: categoryId,
    };

    const response = await priveApiClient.post('offer/offerList', payload);

    return response.data.DATA || [];
  },
);

export const loadMorePriveListingPageData = createAsyncThunk(
  'fetch/offerList/loadMore',
  async (arg, thunkAPI) => {
    const { countryCity: countryCityReducer, user: userReducer, listing } = thunkAPI.getState();

    if (!listing.initialArg) {
      throw Error('Initial load arg not found.');
    }

    const { categoryId, subCategoryId } = listing.initialArg;

    const { latLng } = userReducer;
    const { selectedCity } = countryCityReducer;

    if (!selectedCity) {
      throw Error('City not selected.');
    }

    const payload = {
      dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
      dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
      iCountryID: selectedCity.iCountryID,
      start: listing.deals.length,
      limit: 20,
      iCategoryID: categoryId,
    };

    const response = await priveApiClient.post('offer/offerList', payload);

    return response.data.DATA || [];
  },
);

const initialState = {
  state: LoadState.IDLE,
  loadMoreState: LoadState.IDLE,
  cacheKey: undefined,
  initialArg: {},
  deals: [],
  hasMore: true,
};
export const priveListingSlice = createSlice({
  name: 'priveListing',
  initialState,
  reducers: {
    toggleFav: (state, action) => {
      const { iBrandID, iOutletID, iDealID, isPrive, isFavouriteOffer } = action.payload;

      if (state.deals) {
        for (let i = 0; i < state.deals.length; i++) {
          if (
            state.deals[i].iBrandID === iBrandID &&
            state.deals[i].iDealID === iDealID &&
            state.deals[i].iOutletID === iOutletID
          ) {
            state.deals[i] = {
              ...state.deals[i],
              isFavouriteOffer: isFavouriteOffer,
            };
          }
        }
      }
    },
    setCacheKey: (state, action) => {
      state.cacheKey = action.payload;
    },
    resetPriveListing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getPriveListingPageData.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getPriveListingPageData.fulfilled, (state, action) => {
      state.initialArg = action.meta.arg;
      state.hasMore = action.payload.length >= 20;
      console.log('Deals Length 1', action.payload.length);
      state.deals = action.payload.map((item) => ({
        ...item,
        isPrive: 'Yes',
      }));
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getPriveListingPageData.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });
    builder.addCase(loadMorePriveListingPageData.pending, (state, action) => {
      state.loadMoreState = LoadState.LOADING;
    });
    builder.addCase(loadMorePriveListingPageData.fulfilled, (state, action) => {
      state.hasMore = action.payload.length >= 20;
      state.deals = [...state.deals, ...action.payload].map((item) => ({
        ...item,
        isPrive: 'Yes',
      }));
      state.loadMoreState = LoadState.SUCCESS;
    });
    builder.addCase(loadMorePriveListingPageData.rejected, (state, action) => {
      console.error('loadMorePriveListingPageData error', action.error);
      state.loadMoreState = LoadState.ERROR;
    });
  },
});

export const { toggleFav, resetPriveListing, setCacheKey } = priveListingSlice.actions;
export default priveListingSlice.reducer;
