import React, { useEffect, useMemo, useState } from 'react';
import { SvgIcon } from '../../components/common';
import { Button } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import './index.scss';
import { useMembershipSelector } from '../../store/selectors';
import { useLoaderContext } from '../../components/common/Loader';
import { getMembershipPlans } from '../../slice/membershipSlice';
import { LoadState } from '../../constants';
import { useDispatch } from 'react-redux';
import MembershipPlanList from '../../components/common/MembershipPlanList';
import CancelSubscriptionModal from './CancelSubscriptionModal';
import { openChatSupport } from '../../utility/chat';

const MyMembershipTab = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { state, plans, selectedPlanId } = useMembershipSelector();
  const { setShowLoader } = useLoaderContext();
  const [value, setValue] = useState(1);

  const activePlan = useMemo(() => plans.find((item) => item.isActive), [plans]);
  const onChange = (e) => {
    setValue(e.target.value);
  };
  useEffect(() => {
    dispatch(getMembershipPlans());
  }, []);

  useEffect(() => {
    if (state === LoadState.SUCCESS) {
      setShowLoader(false);
    } else {
      setShowLoader(true);
    }
  }, [state]);
  return (
    <div className="right-tab-inner">
      <h1 className="tab-heading">My Membership</h1>
      <div className="tab-content mymembership-tab">
        <div className="subscriptions-card" data-aos="fade-in" data-aos-duration="800">
          <div className="subscriptions-card-inner">
            <div className="upper-header">
              {/*              <Radio.Group defaultValue="1" buttonStyle="solid" onChange={onChange} value={value}>
                <Radio.Button value={1}>UAE</Radio.Button>
                <Radio.Button value={2}>OMAN</Radio.Button>
              </Radio.Group>*/}
              <div className="header">Subscriptions</div>
              <Link
                to="#"
                className="need-help-icon"
                onClick={(e) => {
                  e.preventDefault();
                  openChatSupport();
                }}
              >
                <SvgIcon name="mice" viewbox="0 0 13.753 13.407" />
                Need Help?
              </Link>
            </div>
            {/*<div className="header">Subscriptions</div>*/}
            <MembershipPlanList />
            <Button
              type="secondary"
              block
              disabled={!selectedPlanId}
              onClick={() => {
                history.push('/membership/checkout', {
                  from: location,
                });
              }}
            >
              {activePlan ? 'Upgrade' : 'Purchase'}
            </Button>
            {/*<div>
              <Button type="link" className="mx-auto">
                <SvgIcon name="promo-code" viewbox="0 0 14.611 10.163" /> Do you have Promo code?
              </Button>
            </div>*/}

            <div className="text-center">
              <CancelSubscriptionModal />
            </div>

            {/*<div className="text-center">
              <CancelSubscriptionModal />
              <CancelMembershipDoneModal />
            </div>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyMembershipTab;
