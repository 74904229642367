// store.js
import { configureStore, isAnyOf } from '@reduxjs/toolkit';
import userReducer, {
  removeUser,
  setAccessToken,
  setLatLng,
  setPriveCredential,
  setUser,
} from '../slice/userSlice';
import currencyReducer, {
  setInitialSelectedCurrency,
  setSelectedCurrency,
} from '../slice/currencySlice';
import cityReducer, { setInitialSelectedCity, setSelectedCity } from '../slice/countryCitySlice';
import categoryReducer from '../slice/categorySlice';
import priveCategoryReducer from '../slice/priveCategorySlice';
import subCategoryReducer from '../slice/subCategorySlice';
import homeReducer, { toggleFav as toggleFavHome } from '../slice/homeSlice';
import priveHomeReducer, { toggleFav as toggleFavHomePrive } from '../slice/priveHomeSlice';
import listingReducer, { toggleFav as toggleFavListing } from '../slice/listingSlice';
import mapListingReducer, { toggleFav as toggleFavMapListing } from '../slice/mapListingSlice';
import searchListingReducer, {
  toggleFav as toggleFavSearchListing,
} from '../slice/searchListingSlice';
import priveListingReducer, {
  toggleFav as toggleFavListingPrive,
} from '../slice/priveListingSlice';
import groupListingReducer from '../slice/groupListingSlice';
import membershipReducer from '../slice/membershipSlice';
import topBrandsReducer from '../slice/topBrandsSlice';
import { toggleFavourite } from '../slice/globalActions';
import { setAnalyticsUserId } from '../utility/analytics';

const localStorageMiddleware = ({ dispatch, getState }) => {
  return (next) => (action) => {
    const result = next(action);
    if (isAnyOf(setUser, removeUser)(action)) {
      if (action.payload) {
        localStorage.setItem('user', JSON.stringify({ DATA: getState().user.user }));
        setAnalyticsUserId(getState().user.user.iUserID);
      } else if (isAnyOf(removeUser)(action)) {
        localStorage.removeItem('userLatLng');
        localStorage.removeItem('user');
        localStorage.removeItem('accesstoken');
        localStorage.removeItem('priveCredential');
      }
    } else if (isAnyOf(setAccessToken)(action)) {
      if (action.payload) {
        localStorage.setItem('accesstoken', JSON.stringify(getState().user.accessToken));
      }
    } else if (isAnyOf(setPriveCredential)(action)) {
      if (action.payload) {
        localStorage.setItem('priveCredential', JSON.stringify(getState().user.priveCredential));
      } else {
        localStorage.removeItem('priveCredential');
      }
    } else if (isAnyOf(setLatLng)(action)) {
      if (action.payload) {
        localStorage.setItem('userLatLng', JSON.stringify(getState().user.latLng));
      }
    } else if (isAnyOf(setSelectedCurrency, setInitialSelectedCurrency)(action)) {
      if (action.payload) {
        localStorage.setItem(
          'selectedCurrency',
          JSON.stringify(getState().currency.selectedCurrency),
        );
        if (isAnyOf(setSelectedCurrency)(action)) {
          window.location.reload();
        }
      }
    } else if (isAnyOf(setSelectedCity, setInitialSelectedCity)(action)) {
      if (action.payload) {
        localStorage.setItem('selectedCity', JSON.stringify(getState().countryCity.selectedCity));
        if (isAnyOf(setSelectedCity)(action)) {
          window.location.href = '/';
        }
      }
    }

    if (toggleFavourite.match(action)) {
      dispatch(toggleFavHome(action.payload));
      dispatch(toggleFavHomePrive(action.payload));
      dispatch(toggleFavListing(action.payload));
      dispatch(toggleFavMapListing(action.payload));
      dispatch(toggleFavListingPrive(action.payload));
      dispatch(toggleFavSearchListing(action.payload));
      /*dispatch(toggleFavByIdHome(action.payload));
      dispatch(toggleFavByIdListing(action.payload));
      dispatch(toggleFavByIdMapListing(action.payload));
      dispatch(toggleFavByIdGroupListing(action.payload));*/
    }
    return result;
  };
};
const store = configureStore({
  reducer: {
    currency: currencyReducer,
    countryCity: cityReducer,
    category: categoryReducer,
    priveCategory: priveCategoryReducer,
    subCategory: subCategoryReducer,
    user: userReducer,
    home: homeReducer,
    priveHome: priveHomeReducer,
    listing: listingReducer,
    mapListing: mapListingReducer,
    searchListing: searchListingReducer,
    priveListing: priveListingReducer,
    groupListing: groupListingReducer,
    membership: membershipReducer,
    topBrands: topBrandsReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(localStorageMiddleware),
});

export default store;
