import React, { useEffect, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import MediaQuery from 'react-responsive';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import FilterDrawer from '../FilterDrawer';
import { useHistory, useLocation } from 'react-router-dom';
import {
  useCategorySelector,
  useCityCountrySelector,
  useListingSelector,
  usePriveCategorySelector,
  usePriveListingSelector,
  useSubCategorySelector,
} from '../../store/selectors';
import { useDispatch } from 'react-redux';
import {
  getListingPageData,
  loadMoreListingPageData,
  resetListing,
} from '../../slice/listingSlice';
import hash from 'object-hash';
import { getSubCategories, resetSubCategories } from '../../slice/subCategorySlice';
import { LoadState } from '../../constants';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lottie from 'react-lottie';
import DotLoader from '../../assets/json/dot_loader.json';
import DotLoaderWhite from '../../assets/json/dot_loader_white.json';
import { useLoaderContext } from '../../components/common/Loader';
import {
  getPriveListingPageData,
  loadMorePriveListingPageData,
  resetPriveListing,
} from '../../slice/priveListingSlice';
import { getPriveCategories } from '../../slice/priveCategorySlice';
import { setSelectedCategory } from '../../slice/categorySlice';
import DealList from '../../components/common/DealList';
import { Button } from 'antd';
import CategorySlider from '../../components/common/CategorySlider';
import { logEvent } from '../../utility/analytics';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const ListingPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const { setShowLoader } = useLoaderContext();
  const searchParams = new URLSearchParams(search);
  const categoryId = searchParams.get('categoryId');
  const subCategoryId = searchParams.get('subCategoryId');
  const isPrive = searchParams.has('isPrive');
  const isPriveSubCat = searchParams.has('priveSubCat');

  const { selectedCity } = useCityCountrySelector();

  const { categories } = useCategorySelector();
  const { subCategories, cacheKey: subCatCacheKey, state: subCatState } = useSubCategorySelector();
  const { categories: priveCategories, state: priveCategoryState } = usePriveCategorySelector();

  const { deals, cacheKey: listCacheKey, state: listState, hasMore } = useListingSelector();

  const {
    deals: priveDeals,
    cacheKey: priveListCacheKey,
    state: priveListState,
    hasMore: priveHasMode,
  } = usePriveListingSelector();

  const [isSetOnScroll, setOnScroll] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setOnScroll(window.scrollY > 0);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleSubCategoryClick = (subCatId) => {
    /* if (+categoryId === 1 && subCatId && +subCatId === 155) {
      const params = new URLSearchParams({
        categoryId: 1,
        isPrive: 'Yes',
      });
      history.replace('/reload', {
        from: {
          pathname: location.pathname,
          search: params.toString(),
        },
      });
      return;
    }*/
    const searchParams = new URLSearchParams(search);
    if (!subCatId) {
      searchParams.delete('subCategoryId');
    } else {
      searchParams.set('subCategoryId', subCatId);
      const sc = subCategories.find((sc) => +sc.iSubCategoryID === +subCatId);
      if (sc && sc.isPrive === 'Yes') {
        searchParams.set('priveSubCat', '');
      } else {
        searchParams.delete('priveSubCat');
      }
    }

    history.replace({ search: searchParams.toString() });
  };

  const handlePriveCategoryClick = (catId) => {
    const searchParams = new URLSearchParams(search);
    if (!catId) {
      searchParams.delete('categoryId');
    } else {
      searchParams.set('categoryId', catId);
    }

    history.replace({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (categoryId && categories && categories.length > 0) {
      const category = categories.find((cat) => +cat.iCategoryID === +categoryId);
      if (category) {
        logEvent('view_item_list', { name: `${category.vCategoryName} Category Homepage` });
      }
    }
  }, [categories, categoryId]);

  useEffect(() => {
    if (!isPrive) {
      const categoryId = searchParams.get('categoryId');

      if (categoryId) {
        const cacheKey = hash({ categoryId });
        if (subCatCacheKey !== cacheKey) {
          dispatch(resetSubCategories());
          console.log(`[${Date.now()}] dispatch getSubCategories`);
          dispatch(setSelectedCategory(categoryId));
          dispatch(getSubCategories({ cacheKey, categoryId }));
        }
      } else {
        history.replace('/');
      }
    }
  }, [isPrive, searchParams, subCatCacheKey]);

  useEffect(() => {
    if (isPrive) {
      dispatch(setSelectedCategory(categories.find((cat) => cat.isPrive === 'Yes')?.iCategoryID));
      if (priveCategoryState === LoadState.IDLE) {
        dispatch(getPriveCategories());
      }
    }
  }, [isPrive, priveCategoryState]);

  useEffect(() => {
    if (!isPrive) {
      if (subCatState === LoadState.SUCCESS && subCategories.length > 0) {
        const categoryId = searchParams.get('categoryId');
        const subCategoryId = searchParams.get('subCategoryId');
        const discountType = searchParams.get('type');
        const distance = searchParams.get('dist');
        const priveSubCat = searchParams.has('priveSubCat');

        const cacheKey = hash({ categoryId, subCategoryId, discountType, distance });
        if (listCacheKey !== cacheKey) {
          console.log(`[${Date.now()}] dispatch getListingPageData`);
          dispatch(resetListing());
          dispatch(
            getListingPageData({
              cacheKey,
              categoryId,
              subCategoryId,
              priveSubCat,
              discountType,
              distance,
            }),
          );
        }
      }
    } else {
      if (priveCategoryState === LoadState.SUCCESS && priveCategories.length > 0) {
        const categoryId = searchParams.get('categoryId');
        const discountType = searchParams.get('type');
        const distance = searchParams.get('dist');

        const cacheKey = hash({ categoryId, discountType, distance });
        if (priveListCacheKey !== cacheKey) {
          console.log(`[${Date.now()}] dispatch getPriveListingPageData`);
          dispatch(resetPriveListing());
          dispatch(getPriveListingPageData({ cacheKey, categoryId, discountType, distance }));
        }
      }
    }
  }, [isPrive, subCatState, subCategories, searchParams]);

  useEffect(() => {
    if (!isPrive) {
      if (subCatState === LoadState.SUCCESS) {
        setShowLoader(false);
      } else {
        setShowLoader(true);
      }
    } else {
      if (priveCategoryState === LoadState.SUCCESS) {
        setShowLoader(false);
      } else {
        setShowLoader(true);
      }
    }
  }, [isPrive, subCatState, priveCategoryState]);

  return (
    <div className="twl-listing-wrapper">
      <section className="category-section-main">
        <Container className="listing-container">
          <div className={isSetOnScroll ? 'category-section fixedCategory' : 'category-section'}>
            {!isPrive && (
              <CategorySlider
                subCategories={subCategories}
                subCategoryId={subCategoryId}
                handleSubCategoryClick={handleSubCategoryClick}
              />
            )}
            {isPrive && priveCategories.length > 0 && (
              <CategorySlider
                priveCategories={priveCategories}
                priveCategoryId={categoryId}
                handlePriveCategoryClick={handlePriveCategoryClick}
              />
            )}
            <MediaQuery minWidth={768}>
              <FilterDrawer />
            </MediaQuery>
          </div>
        </Container>
      </section>
      {((!isPrive && subCatState === LoadState.SUCCESS && subCategories.length > 0) ||
        (isPrive && priveCategoryState === LoadState.SUCCESS && priveCategories.length > 0)) && (
        <section className="listing-bottom">
          <Container className="listing-container">
            <Row>
              <Col>
                <InfiniteScroll
                  next={() => {
                    if (isPrive) {
                      dispatch(loadMorePriveListingPageData());
                    } else {
                      dispatch(loadMoreListingPageData());
                    }
                  }}
                  hasMore={!isPrive ? hasMore : priveHasMode}
                  loader={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        padding: 32,
                      }}
                    >
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: isPrive ? DotLoaderWhite : DotLoader,
                          renderer: 'svg',
                        }}
                        height={160}
                        width={160}
                      />
                    </div>
                  }
                  dataLength={deals.length}
                >
                  <DealList items={isPrive ? priveDeals : deals} />
                </InfiniteScroll>
              </Col>
            </Row>
            {!isPrive && (
              <Row>
                <Col className="text-center">
                  <Button
                    type="primary"
                    className="map-btn"
                    onClick={() =>
                      history.replace({ pathname: '/listing-map', search: searchParams.toString() })
                    }
                  >
                    Map <SvgIcon name="map-icon" viewbox="0 0 14.855 14.695" />
                  </Button>
                </Col>
              </Row>
            )}
          </Container>
        </section>
      )}
    </div>
  );
};

export default ListingPage;
