import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { priveApiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getPriveHomePageData = createAsyncThunk('fetch/priveHome', async (arg, thunkAPI) => {
  const state = thunkAPI.getState();
  const { selectedCity } = state.countryCity;
  if (!selectedCity) {
    throw Error('City not selected.');
  }

  const payload = {
    iCountryID: selectedCity.iCountryID,
    dCurrentLat: selectedCity.vCityLatitude,
    dCurrentLong: selectedCity.vCityLongitude,
    isPrivedeal: 'Yes',
    Language: 'en',
  };
  const response = await priveApiClient.post('/offer/categoryFeaturedOfferList', payload);

  if (response.data.status !== 1) {
    throw Error(`prive categoryFeaturedOfferList received non zero status.`);
  }

  return response.data.DATA;
});
export const homeSlice = createSlice({
  name: 'priveHome',
  initialState: {
    collections: [],
    state: LoadState.IDLE,
  },
  reducers: {
    toggleFav: (state, action) => {
      const { iBrandID, iOutletID, iDealID, isPrive, isFavouriteOffer } = action.payload;

      if (state.collections) {
        for (let idx = 0; idx < state.collections.length; idx++) {
          if (state.collections[idx].dealList) {
            for (let i = 0; i < state.collections[idx].dealList.length; i++) {
              if (
                state.collections[idx].dealList[i].iBrandID === iBrandID &&
                state.collections[idx].dealList[i].iDealID === iDealID &&
                state.collections[idx].dealList[i].iOutletID === iOutletID
              ) {
                state.collections[idx].dealList[i] = {
                  ...state.collections[idx].dealList[i],
                  isFavouriteOffer: isFavouriteOffer,
                };
              }
            }
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPriveHomePageData.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getPriveHomePageData.fulfilled, (state, action) => {
      state.collections = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getPriveHomePageData.rejected, (state, action) => {
      console.error('getPriveHomePageData failed', action.error);
      state.state = LoadState.ERROR;
    });
  },
});

export const { toggleFav } = homeSlice.actions;
export default homeSlice.reducer;
