import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button, Form } from 'antd';
import { Link } from 'react-router-dom';
import './index.scss';

import PayImage from '../../../assets/images/checkout-img.png';
import MaterCard from '../../../assets/images/mater-card.png';
import qrcode from '../../../assets/images/qr-code.png';
import CancelTicketModal from '../CancelTicketModal';

const MyBookingDetails = () => {
  return (
    <div className="mybookingdetails-wrapper">
      <div className="mybookingdetails-inner">
        <Container>
          <Row>
            <Col>
              <Form name="basic" autoComplete="off" layout="vertical">
                <div className="mybookingdetails-row">
                  <div className="mybookingdetails-left">
                    <div className="twl-header" data-aos="fade-in" data-aos-duration="800">
                      <Link to="/user">
                        <SvgIcon name="arrow-left" viewbox="0 0 12.251 8.653" />
                      </Link>
                      <h2>Your Ticket</h2>
                    </div>
                    <div className="twl-content">
                      <div className="twlcontent-inner">
                        <div className="confirm-details">
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Booking Date and time</p>
                            </div>
                            <div className="dtl-right">25th Jun 2022</div>
                          </div>
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Payment method</p>
                            </div>
                            <div className="dtl-right">
                              <div className="d-flex align-items-center">
                                ****637{' '}
                                <div className="card-icon">
                                  <img src={MaterCard} alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Email</p>
                            </div>
                            <div className="dtl-right">akp.arvindpatel@gmail.com</div>
                          </div>
                          <div className="dtl-row">
                            <div className="dtl-left">
                              <p>Mobile</p>
                            </div>
                            <div className="dtl-right">+97153647876</div>
                          </div>
                        </div>
                        <Row>
                          <Col className="cancelpolicy-info">
                            <h3>Terms and Condition</h3>
                            <ul>
                              <li>Guests should book in advance to secure a slot as Where Can I</li>
                              <li>Check the weather forecast beforehand to ensure that</li>
                              <li>Your Views won’t be obstructed</li>
                              <li>When can I start my membership with Traveller</li>
                            </ul>
                            <div className="text-block col-md-6">
                              <h3>How to Use</h3>
                              <p>
                                Get Ready to enjoy At The Top Level 124th Floor & Sky View
                                Observatory tickets at the sky view Address Towers
                              </p>
                            </div>
                            <div className="text-block col-md-6">
                              <h3>Cancellation Policy</h3>
                              <p>
                                Get Ready to enjoy At The Top Level 124th Floor & Sky View
                                Observatory tickets at the sky view Address Towers
                              </p>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <div className="mybookingdetails-right">
                    <div className="twl-content">
                      <div className="twlcontent-inner">
                        <ul className="summary-list" data-aos="fade-left" data-aos-duration="800">
                          <li>
                            <div className="image-col">
                              <img src={PayImage} alt="Explore Magic Imagica" />
                            </div>
                            <div className="list-left">
                              <h3>Explore Magic Imagica</h3>
                              <p>
                                <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> Royal residence in
                                London, United Kingdom
                              </p>
                            </div>
                          </li>
                        </ul>
                        <div
                          className="payment-details"
                          data-aos="fade-left"
                          data-aos-duration="1200"
                        >
                          <Row className="discount-row">
                            <Col>
                              <b>Naveen Sajeev</b>
                              <label>Lead Participant</label>
                            </Col>
                            <Col className="text-right">
                              <b>02</b>
                              <label>Person</label>
                            </Col>
                          </Row>
                          <Row className="discount-row">
                            <Col>
                              <b>25th Jun 22</b>
                              <label>Date</label>
                            </Col>
                            <Col className="text-right">
                              <b>01:20 PM</b>
                              <label>Time</label>
                            </Col>
                          </Row>
                          <Row className="discount-row">
                            <Col>
                              <b>AED 340</b>
                              <label>Paid</label>
                            </Col>
                            <Col className="text-right">
                              <b>67463846</b>
                              <label>Confirmation Code</label>
                            </Col>
                          </Row>
                          <Row className="discount-row">
                            <Col>
                              <b>Magic Imagica</b>
                              <label>Option</label>
                            </Col>
                          </Row>
                          <div className="bottom-details">
                            <div className="barcode-row">
                              <img src={qrcode} alt="barcode" />
                              <h3>Scan the code</h3>
                              <p>Scan the QR code at the venue</p>
                            </div>
                          </div>
                        </div>
                        <div className="download-row" data-aos="fade-left" data-aos-duration="1250">
                          <Button className="pay-btn" type="primary" block>
                            Ticket
                            <SvgIcon name="download" viewbox="0 0 15.624 15.996" />
                          </Button>
                          <CancelTicketModal />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default MyBookingDetails;
