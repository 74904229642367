import { useSelector } from 'react-redux';

export const useUserSelector = () => useSelector((state) => state.user);
export const useCityCountrySelector = () => useSelector((state) => state.countryCity);
export const useHomeSelector = () => useSelector((state) => state.home);
export const usePriveHomeSelector = () => useSelector((state) => state.priveHome);
export const useCategorySelector = () => useSelector((state) => state.category);
export const usePriveCategorySelector = () => useSelector((state) => state.priveCategory);
export const useSubCategorySelector = () => useSelector((state) => state.subCategory);
export const useListingSelector = () => useSelector((state) => state.listing);
export const useMapListingSelector = () => useSelector((state) => state.mapListing);
export const useSearchListingSelector = () => useSelector((state) => state.searchListing);
export const usePriveListingSelector = () => useSelector((state) => state.priveListing);
export const useGroupListingSelector = () => useSelector((state) => state.groupListing);
export const useMembershipSelector = () => useSelector((state) => state.membership);
export const useTopBrandsSelector = () => useSelector((state) => state.topBrands);
