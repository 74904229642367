import store from '../store';
import { logEvent } from './analytics';

const generateRandomID = () => {
  // Generate a random number between 100000 and 999999 (inclusive)
  return Math.floor(Math.random() * 900000) + 100000;
};
export const openChatSupport = () => {
  const { user } = store.getState().user;
  const searchParams = new URLSearchParams();
  if (user) {
    searchParams.set('vEmail', user.vEmail);
    searchParams.set('vUserName', user.vUserName);
    searchParams.set('vMobileCountryCode', user.vMobileCountryCode);
    searchParams.set('vMobileNo', user.vMobileNo);
  } else {
    searchParams.set(
      'vEmail',
      `guest_tp_web_${new Date().getTime()}${generateRandomID()}@chatsupport.com`,
    );
  }
  const params = searchParams.toString();

  logEvent('contact', { name: 'Chat Support Section' });
  window.open(`https://chat.travellerpass.com/login${params ? `?${params}` : ''}`, '_blank');
};
