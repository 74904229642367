import React, { useEffect, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button } from 'antd';
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';
import BlogImg2 from '../../assets/images/blog-2.jpg';
import BlogImg3 from '../../assets/images/blog-3.jpg';
import BlogImg4 from '../../assets/images/blog-4.jpg';
import LabelledSolutionImg from '../../assets/images/labelled-solution.png';
import LabelledSolutionBg from '../../assets/images/labelled-solution-bg.svg';
import EIBBankLogo from '../../assets/images/partner-eib.png';
import BeyondRewards from '../../assets/images/Beyond-Rewards.png';
import CommercialBank from '../../assets/images/Commercial-Bank.png';
import NBFRewards from '../../assets/images/NBF-Rewards.png';
import Ahlirewards from '../../assets/images/ahlirewards.png';
import MyOffers from '../../assets/images/My-Offers.png';
import TravellerPass from '../../assets/images/TravellerPass.png';
import DubaiIslamicBank from '../../assets/images/Dubai-Islamic-Bank.png';
import PartnersBgImage from '../../assets/images/partner-bg.png';
import TpIcon from '../../assets/images/tp-icon.png';
import AboutBannerBg from '../../assets/images/about-bg.jpg';
import DiscoverImageSmall from '../../assets/images/discover-small-img.jpg';
import HowItWorks from '../../assets/video/how_it_works.mp4';
import ModalVideo from 'react-modal-video';
import TopBrandLogo from '../../components/common/TopBrandLogo';
import { logEvent } from '../../utility/analytics';

const AboutUsPage = () => {
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const exporeList = [
    {
      id: 1,
      image: <SvgIcon name="dining-icon" viewbox="0 0 71.125 48.658" />,
      name: 'Customize Your way',
      details: 'TravellerPass powers reward systems for your firm.',
      details2:
        'We can build you a customized app based on specific branding needs tailor-made for your company.',
      speed: 600,
    },
    {
      id: 2,
      image: <SvgIcon name="leisure-icon" viewbox="0 0 61.201 44.936" />,
      name: 'Api Flexiblity',
      details: 'We adapt to your needs.',
      details2:
        'We are flexible enough to provide you with external or internal API’s. Use flexible extensions based on your business. We are there every step of the way.',
      speed: 700,
    },
    {
      id: 3,
      image: <SvgIcon name="shopping-icon" viewbox="0 0 50.18 47.098" />,
      name: 'Digital Cost effective',
      details: 'Digital marketing is a key factor to standout from the masses.',
      details2:
        ' Signing up with us helps your brand grow digitally and enhance marketing plans with our social campaigns.',
      speed: 800,
    },
    {
      id: 4,
      image: <SvgIcon name="wellness-icon" viewbox="0 0 53.338 53.341" />,
      name: 'Dashboard Real insights',
      details:
        'Signing up with us provides access to a real-time dashboard with business insights.',
      details2: 'Track customer usage, generate heat maps, access data on usage metrics and more.',
      speed: 900,
    },
    {
      id: 5,
      image: <SvgIcon name="prive-icon" viewbox="0 0 57.805 48.664" />,
      name: 'Shoutout Email marketing',
      details: 'Our in-house team will create tailor-made email marketing plans for your business.',
      details2:
        'Drive usage, increase awareness and engagement for your loyalty program with your branding.',
      speed: 1000,
    },
    {
      id: 6,
      image: <SvgIcon name="prive-icon" viewbox="0 0 57.805 48.664" />,
      name: 'Support 24/7 helpline',
      details:
        'Our customer service team is always available to help out with customer grievances.',
      details2: 'Providing your loyalty program with 24/7 live help and structured B2B support.',
      speed: 1100,
    },
  ];

  const Blogdata = [
    {
      title: 'The Ultimate Children’s Playground',
      avatar: BlogImg2,
      date: 'NOV 29 - 5 MIN READ',
    },
    {
      title: 'Time to head to The Dubai Mall',
      avatar: BlogImg3,
      date: 'DEC 02 - 9 MIN READ',
    },
    {
      title: 'Time for something new',
      avatar: BlogImg4,
      date: 'JAN 08 - 10 MIN READ',
    },
  ];

  useEffect(() => {
    logEvent('view_item', { name: 'About Us Page' });
  }, []);

  return (
    <>
      <div className="twl-about-wrapper">
        <section className="landing-banner">
          <Container>
            <div className="banner-row">
              <h1 data-aos="fade-right" data-aos-duration="900">
                Know more <br /> About TravellerPass
              </h1>
              <p data-aos="fade-right" data-aos-duration="1000">
                Get to know the story and the <br />
                operation behind the brand
              </p>
            </div>
          </Container>
        </section>
        <section className="about-banner">
          <Container>
            <Row>
              <Col>
                <div className="about-card" data-aos="zoom-in" data-aos-duration="900">
                  <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
                  <p>About</p> <p>Brands</p> <p>Partners</p>
                </div>
              </Col>
            </Row>
          </Container>
          <img
            data-aos="fade-in"
            data-aos-duration="900"
            className="banner-img"
            src={AboutBannerBg}
            alt={AboutBannerBg}
          />
        </section>
        <section className="about-section">
          <Container>
            <Row className="align-items-center">
              <Col md="8" className="left-section">
                <label data-aos="fade-right" data-aos-duration="800">
                  ABOUT US
                </label>
                <h1 data-aos="fade-right" data-aos-duration="900">
                  <b>TravellerPass LLC</b> is a part of <b>IMC Middle East</b> leveraging over{' '}
                  <b>20 years</b> of expertise in crafting cutting-edge loyalty solutions
                </h1>
                <p data-aos="fade-right" data-aos-duration="1000">
                  Our team comprises of a diverse set of experts, ranging from consultants,
                  engineers, social media experts and loyalty experts. We are committed to helping
                  businesses deploy strategic marketing campaigns and building loyalty programs.
                </p>
              </Col>
              <Col md="4" className="right-section">
                <div className="brand-mark" data-aos="fade-left" data-aos-duration="1000">
                  <img src={TpIcon} alt={TpIcon} />
                  <p>TravellerPass</p>
                  <h1>Brand Mark</h1>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <TopBrandLogo />

        <section className="buildwith-section">
          <Container>
            <Row>
              <Col md="6" className="buildwith-left">
                <h1 data-aos="fade-in" data-aos-duration="800">
                  Build a white labelled solution <br />
                  or integrate with our API
                </h1>
                <Button
                  onClick={() => {
                    window.open('mailto:concierge@travellerpass.com');
                  }}
                >
                  Get in Touch
                </Button>
              </Col>
              <Col md="6" className="buildwith-right">
                <div className="buildwith-right-inner">
                  <img
                    className="buildwith-img"
                    src={LabelledSolutionImg}
                    alt={LabelledSolutionImg}
                  />
                  <img className="buildwith-bg" src={LabelledSolutionBg} alt={LabelledSolutionBg} />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="explore-section">
          <Container>
            <Row className="mb-5">
              <Col sm="6" className="heading-left">
                <label>PARTNER</label>
                <h2 className="heading text-left" data-aos="fade-in" data-aos-duration="1000">
                  Work on your <br /> loyalty solution with us
                </h2>
              </Col>
              {/*  <Col sm="6">
              <div className="heading-right">
                <div className="heading-right-inner">
                  <div>
                    <div>
                      <Button>
                        <SvgIcon name="play" viewbox="0 0 41.93 41.965" />
                      </Button>
                      <SvgIcon className="arrow" name="arrow2" viewbox="0 0 51.113 32.973" />
                    </div>
                    <p>
                      Here's an <br />
                      app demo
                    </p>
                  </div>
                  <div className="right-col">
                    <SvgIcon name="dots" viewbox="0 0 48.628 42.496" />
                  </div>
                </div>
                <img className="bg-image" src={videoBg} alt={videoBg} />
              </div>
            </Col>*/}
            </Row>
            <Row>
              <Col sm="12">
                <div>
                  <ul className="explore-list">
                    {exporeList.map((item) => (
                      <li key={item.id} data-aos="fade-up" data-aos-duration={item.speed}>
                        <div className="explore-list-card">
                          <div className="explore-list-card-image">{item.image}</div>
                          <div className="explore-caption">
                            <h3>{item.name}</h3>
                            <p>{item.details}</p>
                            <p>{item.details2}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="partner-section" style={{ backgroundImage: `url(${PartnersBgImage})` }}>
          <Container>
            <Row>
              <Col xs="12" className="mb-5">
                <h1 data-aos="fade-iright" data-aos-duration="900" className="heading">
                  Some of Our Partners
                </h1>
                <p className="heading-text" data-aos="fade-right" data-aos-duration="1000">
                  Building loyalty programs with initiatives that focus on retaining customers,
                  driving incremental profits and to create stronger engagement between your
                  customers and your brands.
                </p>
              </Col>
              <Col xs="12">
                <div className="text-right mb-4">
                  <a
                    target="_blank"
                    href="mailto:concierge@travellerpass.com"
                    className="getin-link"
                  >
                    Get in Touch <SvgIcon name="arrow-right" viewbox="0 0 24.603 7.838" />
                  </a>
                </div>
                <div className="partner-card">
                  <ul>
                    <li>
                      <div className="img-box">
                        <img src={EIBBankLogo} alt="Rewards Plus" />
                      </div>
                      <p>Rewards Plus</p>
                    </li>
                    <li>
                      <div className="img-box">
                        <img src={BeyondRewards} alt={BeyondRewards} />
                      </div>
                      <p>Beyond Rewards</p>
                    </li>
                    <li>
                      <div className="img-box">
                        <img src={CommercialBank} alt={CommercialBank} />
                      </div>
                      <p>Commercial Bank of Dubai</p>
                    </li>
                    <li>
                      <div className="img-box">
                        <img src={NBFRewards} alt={NBFRewards} />
                      </div>
                      <p>NBF Rewards</p>
                    </li>
                    <li>
                      <div className="img-box">
                        <img src={Ahlirewards} alt={Ahlirewards} />
                      </div>
                      <p>ahlirewards</p>
                    </li>
                    <li>
                      <div className="img-box">
                        <img src={MyOffers} alt={MyOffers} />
                      </div>
                      <p>My Offers</p>
                    </li>
                    {/* <li>
                      <div className="img-box">
                        <img src={TravellerPass} alt={TravellerPass} />
                      </div>
                      <p>TravellerPass</p>
                    </li>*/}
                    <li>
                      <div className="img-box">
                        <img src={DubaiIslamicBank} alt={DubaiIslamicBank} />
                      </div>
                      <p>Dubai Islamic Bank</p>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/*  <section className="blog-section">
        <Container>
          <Row>
            <Col>
              <div className="blog-upper">
                <Row>
                  <Col lg="6" data-aos="fade-right" data-aos-duration="800">
                    <img className="blog-img" src={BlogImg1} alt="Blog" />
                  </Col>
                  <Col lg="6" data-aos="fade-left" data-aos-duration="800">
                    <label>Blogs</label>
                    <h3>Your perfect plan for an unforgettable holidays</h3>
                    <p>
                      People of the UAE, Eid Holidays are approaching. So, planning the ultimate Eid
                      weekend is what is on most of our minds as we all want to make the most out of
                      this Eid weekend.
                    </p>
                    <Button
                      type="primary"
                      className="with-icon"
                      icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                    >
                      Read Full
                    </Button>
                    <div className="bottom-text">Nov 29 - 3 min read</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <List
                data-aos="fade-up"
                data-aos-duration="800"
                itemLayout="horizontal"
                dataSource={Blogdata}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button>
                        {' '}
                        <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<img src={item.avatar} alt="" />}
                      title={item.title}
                      description={item.date}
                    />
                  </List.Item>
                )}
              />
              <div className="more-blog">
                <ul>
                  <li>
                    <img src={Tpimg1} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg2} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg3} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg4} alt="Blog More" />
                  </li>
                </ul>
                <Button type="link">+ 23 Blogs</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>*/}
        <section className="social-section">
          <div className="travellerPass-icon" data-aos="fade-right" data-aos-duration="900">
            <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
          </div>
          <Container>
            <Row>
              <Col>
                <img
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className="logo-icon"
                  src={LogoIcon}
                  alt="TravellerPass"
                />
                <h1 data-aos="fade-up" data-aos-duration="1100">
                  Discover the world like never before <br /> with TravellerPass
                </h1>
                <div className="small-card" data-aos="fade-up" data-aos-duration="1200">
                  <div className="left-img" onClick={() => setShowHowItWorks(true)}>
                    <div className="overlay-play">
                      <SvgIcon name="play-alt" viewbox="0 0 30.001 30" />
                    </div>
                    <img src={DiscoverImageSmall} alt="TravellerPass" />
                  </div>
                  <div>
                    <h3>Welcome</h3>
                    <p>A whole new experience to TravellerPass</p>
                  </div>
                </div>
                <ul data-aos="fade-up" data-aos-duration="1400">
                  {/*<li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="dribbble" viewbox="0 0 17.206 17.206" />
                  </Button>
                </li>
                  <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                  </Button>
                </li>*/}
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://www.tiktok.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://medium.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <ModalVideo
        isOpen={showHowItWorks}
        channel="custom"
        url={HowItWorks}
        onClose={() => setShowHowItWorks(false)}
      />
    </>
  );
};

export default AboutUsPage;
