import { htmlToText } from 'html-to-text';

export const htmlToList = (html) => {
  const lines = [];
  if (/.*<[^>]+>.*/.test(html)) {
    const options = {
      selectors: [
        {
          selector: 'ul',
          format: 'unorderedList',
          options: { itemPrefix: ' | ', leadingLineBreaks: 2, trailingLineBreaks: 2 },
        },
      ],
    };
    lines.push(
      ...htmlToText(html, options)
        .split(/\s\|\s/)
        .map((s) => s.trim() /*.replace(/\s*\n\s*!/, ' ')*/)
        .filter((s) => s.length > 0),
    );
  } else {
    lines.push(
      ...html
        .split(/\n/)
        .map((s) => s.trim())
        .filter((s) => s.length > 0),
    );
  }
  return lines;
};
