import React, { useEffect, useState } from 'react';
import { Col, Row, SvgIcon } from '../../components/common';
import { Input, Select, Table } from 'antd';
import './index.scss';
import NoRedemptions from '../../assets/images/no-redemptions.png';
import { useLoaderContext } from '../../components/common/Loader';
import { useCityCountrySelector, useUserSelector } from '../../store/selectors';
import { apiClient, priveApiClient } from '../../apiConfig';

const RedemptionsTab = () => {
  const { setShowLoader } = useLoaderContext();
  const { selectedCity } = useCityCountrySelector();
  const { latLng } = useUserSelector();
  const [type, setType] = useState('all');
  const [results, setResults] = useState([]);

  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-IN', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(+price);
  };

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      setResults([]);
      const payload = {
        iCountryID: selectedCity.iCountryID,
        historyType: type,
      };
      try {
        const [response, priveResponse] = await Promise.all([
          apiClient.post('offer/redemtionHistory', payload),
          priveApiClient.post('offer/redemtionHistory', payload),
        ]);

        console.log(priveResponse);

        let results = [];
        if (response.data.status === 1) {
          results = response.data.DATA;
        }
        if (priveResponse.data.status === 1) {
          results = [
            ...results,
            ...priveResponse.data.DATA.map((item) => ({ ...item, isPrive: 'Yes' })),
          ];
        }
        setResults(results);
      } catch (e) {
      } finally {
        setShowLoader(false);
      }
    })();
  }, [type]);

  const columns = [
    {
      title: 'Venues',
      dataIndex: 'tOffer',
      key: 'tOffer',
      render: (text, record) => (
        <div className="name-with-icon">
          <div className="table-image-icon">
            <img src={record.vBrandImage} alt={record.tOffer} />
          </div>
          {record.tOffer}{' '}
        </div>
      ),
      sorter: (a, b) => a.tOffer - b.tOffer,
      width: 180,
    },
    {
      title: 'Transaction ID',
      dataIndex: 'iTransactionID',
      key: 'iTransactionID',
      sorter: (a, b) => a.iTransactionID - b.iTransactionID,
      width: 130,
    },
    {
      title: 'Date',
      dataIndex: 'tCreatedAt',
      key: 'tCreatedAt',
      sorter: (a, b) => a.tCreatedAt - b.tCreatedAt,
      width: 120,
    },
    {
      title: 'Spent',
      dataIndex: 'vTotalAmount',
      key: 'vTotalAmount',
      sorter: (a, b) => a.vTotalAmount - b.vTotalAmount,
      render: (text) => <div>AED {formatPrice(text)}</div>,
      width: 60,
    },
    {
      title: 'Saved',
      dataIndex: 'vDiscountAmount',
      key: 'vDiscountAmount',
      render: (text) => (
        <div className="spent-col text-right">
          <small>AED</small>
          <div className="amount-col">{formatPrice(text)}</div>
        </div>
      ),
      sorter: (a, b) => a.vDiscountAmount - b.vDiscountAmount,
      align: 'right',
      className: 'text-right',
      width: 100,
    },
    /*{
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: () => (
        <Button type="secondary" className="download-btn">
          <SvgIcon name="download" viewbox="0 0 15.624 15.996" />
        </Button>
      ),
      align: 'right',
      width: 60,
    },*/
  ];
  return (
    <div className="right-tab-inner">
      {results.length > 0 ? (
        <>
          <h1 className="tab-heading" data-aos="fade-in" data-aos-duration="800">
            Redemptions
          </h1>
          <div className="tab-content redemptions-tab" data-aos="fade-in" data-aos-duration="800">
            <Row>
              <Col>
                <ul className="statcis-row">
                  <li className="statcis-item">
                    <div className="statcis-item-inner">
                      <div className="top-icon">
                        <SvgIcon name="wallet" viewbox="0 0 15.446 15.727" />
                      </div>
                      <div className="item-bottom">
                        <h3>
                          {results.reduce(
                            (partialSum, item) => partialSum + +item.vDiscountAmount,
                            0,
                          )}
                        </h3>
                        <p>Savings (AED)</p>
                      </div>
                    </div>
                  </li>
                  <li className="statcis-item">
                    <div className="statcis-item-inner">
                      <div className="top-icon">
                        <SvgIcon name="shopping-bag" viewbox="0 0 17.422 19.107" />
                      </div>
                      <div className="item-bottom">
                        <h3>{results.length}</h3>
                        <p>Redemptions</p>
                      </div>
                    </div>
                  </li>
                  <li className="statcis-item">
                    <div className="statcis-item-inner">
                      <div className="top-icon">
                        <SvgIcon name="cash" viewbox="0 0 19.153 18.981" />
                      </div>
                      <div className="item-bottom">
                        <h3>
                          {results.reduce((partialSum, item) => partialSum + +item.vTotalAmount, 0)}
                        </h3>
                        <p>Spends (AED)</p>
                      </div>
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="filter-row">
              <Col md="6">
                <div className="filter-left">
                  <h3>History</h3>
                  <p>Your savings from each redemption</p>
                </div>
              </Col>
              <Col md="6" className="filter-right">
                {/*<Input
                  placeholder="Search"
                  suffix={<SvgIcon name="search" viewbox="0 0 15.13 15.134" />}
                />*/}
                <Select
                  value={type}
                  style={{ width: 120 }}
                  suffixIcon={<SvgIcon viewbox="0 0 15.839 11.88" name="filter" />}
                  onSelect={(value) => {
                    setType(value);
                  }}
                  options={[
                    { value: 'all', label: 'All' },
                    { value: 'weekly', label: 'Weekly' },
                    { value: 'monthly', label: 'Monthly' },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col sm="12">
                <Table
                  className="custom-table redemption-table"
                  pagination={false}
                  scroll={{ x: '100%', y: true }}
                  dataSource={results}
                  columns={columns}
                  showSorterTooltip={false}
                  sortUpIcon={<SvgIcon name="heart" viewbox="0 0 14.297 12.626" />}
                />
              </Col>
            </Row>
          </div>
        </>
      ) : (
        <div
          className="empty-data-card empty-redemption"
          data-aos="fade-in"
          data-aos-duration="800"
        >
          <img src={NoRedemptions} alt="No Redemptions yet!" />
          <h2>Oops, its empty!</h2>
          <p>
            Looks like you need to start your savings journey and avail our offers to fill up this
            space!
          </p>
        </div>
      )}
    </div>
  );
};

export default RedemptionsTab;
