import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button, Pagination } from 'antd';
import './index.scss';

import communityBanner from '../../assets/images/community-banner.png';
import CupImage from '../../assets/images/cup.png';
import topCreator1 from '../../assets/images/top-creator1.png';
import topCreator2 from '../../assets/images/top-creator2.png';
import topCreator3 from '../../assets/images/top-creator3.png';
import topCreator4 from '../../assets/images/top-creator4.png';
import topCreator5 from '../../assets/images/top-creator5.png';
import topCreator6 from '../../assets/images/top-creator6.png';
import topCreator7 from '../../assets/images/top-creator7.png';
import topCreator8 from '../../assets/images/top-creator8.png';
import communityImg1 from '../../assets/images/community-img1.png';
import communityImg2 from '../../assets/images/community-img2.png';
import communityImg3 from '../../assets/images/community-img3.png';
import communityImg4 from '../../assets/images/community-img4.png';
import communityImg5 from '../../assets/images/community-img5.png';
import communityImg6 from '../../assets/images/community-img6.png';
import communityImg7 from '../../assets/images/community-img7.png';
import communityImg8 from '../../assets/images/community-img8.png';

const topCreatorData = [
  { key: 1, image: topCreator1 },
  { key: 2, image: topCreator2 },
  { key: 3, image: topCreator3 },
  { key: 4, image: topCreator4 },
  { key: 5, image: topCreator5 },
  { key: 6, image: topCreator6 },
  { key: 7, image: topCreator7 },
  { key: 8, image: topCreator8 },
];

const CommunityData = [
  {
    key: 1,
    image: communityImg1,
    user: topCreator1,
    name: 'Experience Luxury Desert Safari in Dubai',
    views: '2345',
  },
  {
    key: 2,
    image: communityImg2,
    user: topCreator2,
    name: 'Experience Luxury Desert Safari in Dubai',
    views: '2345',
  },
  {
    key: 3,
    image: communityImg3,
    user: topCreator3,
    name: 'Experience Luxury Desert Safari in Dubai',
    views: '2345',
  },
  {
    key: 4,
    image: communityImg4,
    user: topCreator4,
    name: 'Experience Luxury Desert Safari in Dubai',
    views: '2345',
  },
  {
    key: 5,
    image: communityImg5,
    user: topCreator5,
    name: 'Experience Luxury Desert Safari in Dubai',
    views: '2345',
  },
  {
    key: 6,
    image: communityImg6,
    user: topCreator6,
    name: 'Time to try something new',
    views: '2345',
  },
  {
    key: 7,
    image: communityImg7,
    user: topCreator7,
    name: 'Your perfect plan for an unforgettable holidays',
    views: '2345',
  },
  {
    key: 8,
    image: communityImg8,
    user: topCreator8,
    name: 'Exploring the unknown Abu Dhabi edition',
    views: '2345',
  },
];

const CommunityPage = () => {
  return (
    <div className="communitypage-wrapper">
      <section className="communitypage-banner">
        <Container>
          <Row>
            <Col md="6" className="col-left">
              <h1 data-aos="fade-right" data-aos-duration="800">
                Explore our Amazing content
              </h1>
              <ul data-aos="fade-right" data-aos-duration="900">
                <li>
                  <h3>234</h3>
                  <p>Videos</p>
                </li>
                <li>
                  <h3>103</h3>
                  <p>Creator</p>
                </li>
              </ul>
              <Button data-aos="fade-right" data-aos-duration="980" type="secondary">
                Upload Content
              </Button>
            </Col>
            <Col md="6" className="col-right">
              <div className="colright-inner">
                <div className="circle-right" data-aos="zoom-in" data-aos-duration="900">
                  <img src={CupImage} alt={CupImage} />
                  <h4>Reel</h4>
                  <p>Of the day</p>
                </div>
                <img
                  data-aos="fade-left"
                  data-aos-duration="900"
                  className="banner-img"
                  src={communityBanner}
                  alt="Banner"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="topcreater-section">
        <Container>
          <Row>
            <Col data-aos="fade-in" data-aos-duration="900">
              <div className="heading">Top Creator</div>
              <ul className="topcreator-list">
                {topCreatorData.map((item) => (
                  <li key={item.key}>
                    <img src={item.image} alt="Top Creator" />
                  </li>
                ))}
              </ul>
              <ul className="topcreator-filter">
                <li>All</li>
                <li>Restaurant</li>
                <li>Water Sport</li>
                <li>Theme Park</li>
                <li>Food Cafe</li>
                <li>Beach</li>
                <li>Restaurant</li>
                <li>Water Sport</li>
                <li>Theme Park</li>
                <li>Food Cafe</li>
                <li>Beach</li>
                <li>Theme Park</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="communitylist-section">
        <Container>
          <Row>
            <Col data-aos="fade-in" data-aos-duration="900">
              <ul className="community-list">
                {CommunityData.map((item) => (
                  <li key={item.key}>
                    <div className="image-upper">
                      <img src={item.image} alt={item.name} />
                    </div>
                    <div className="bottom-content">
                      <div className="creator-img">
                        <img src={item.user} alt="user" />
                      </div>
                      <div>
                        <h3>{item.name}</h3>
                      </div>
                      <div className="view-row">
                        <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        {item.views} Views
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="text-center pt-4 pb-5">
                <Pagination defaultCurrent={1} total={50} />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default CommunityPage;
