import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, DealCard, Row, SvgIcon } from '../../components/common';
import { Button, Collapse, List } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';
import DiscountOfferLogo from '../../assets/images/offer_type_discount.png';
import SpecialOfferLogo from '../../assets/images/offertype_special.png';
import B1G1OfferLogo from '../../assets/images/offertype_b1g1.png';
import { useCityCountrySelector, useUserSelector } from '../../store/selectors';
import { apiClient, priveApiClient } from '../../apiConfig';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import { htmlToList } from '../../utility/parser';
import { useLoaderContext } from '../../components/common/Loader';
import { toggleFavourite } from '../../slice/globalActions';
import { useDispatch } from 'react-redux';
import { Alert } from '../../common';
import LocationModal from '../../components/modal/LocationModal';
import haversine from 'haversine-distance';
import store from '../../store';
import { useIsVisible } from '../../hooks/useIsVisible';
import TravellerLogoVertical from '../../svg-icons/TravellerLogoVertical';
import PriveLogo from '../../svg-icons/PriveLogo';
import NotAtVenueModal from '../../components/modal/NotAtVenueModal';
import NotAtSelectedVenueModal from '../../components/modal/NotAtSelectedVenueModal';
import HowToRedeem from '../../assets/video/tp_redeem_process.mp4';
import HowToRedeemPrive from '../../assets/video/prive_redeem_process.mp4';
import ModalVideo from 'react-modal-video';
import { logEvent } from '../../utility/analytics';

const { Panel } = Collapse;

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const DetailsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;

  const searchParams = new URLSearchParams(search);
  const brandId = searchParams.get('iBrandID');
  const outletId = searchParams.get('iOutletID');
  const dealId = searchParams.get('iDealID');
  const prive = searchParams.has('isPrive');

  const { setShowLoader } = useLoaderContext();

  const { selectedCity } = useCityCountrySelector();
  const { latLng, locationPermission } = useUserSelector();
  const [details, setDetails] = useState();
  const [details2, setDetails2] = useState();
  const [outlet, setOutlet] = useState();
  const [otherOutlets, setOtherOutlets] = useState([]);
  const [desc, setDesc] = useState();
  const [images, setImages] = useState([]);
  const [offerInfos, setOfferInfos] = useState([]);
  const [isFavourite, setIsFavourite] = useState(false);
  const [locationModalType, setLocationModalType] = useState(undefined);
  const [redeemEnable, setRedeemEnable] = useState(false);
  const [inRangeOutlet, setInRangeOutlet] = useState(false);
  const [isNoOutletInRange, setNoOutletInRange] = useState(false);
  const [isNotInSelectedOutlet, setNotInSelectedOutlet] = useState(false);
  const [showHowToRedeem, setShowHowToRedeem] = useState(false);

  const offerInfosRef = useRef();
  const faqRef = useRef();
  const offerInfosVisible = useIsVisible(offerInfosRef);
  const [showDirectionButton, setShowDirectionButton] = useState(false);

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const settingsOtherVanues = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.6,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settingsSimilerVanues = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const detailSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const selectedOfferInfo = useMemo(() => {
    return offerInfos.find((item) => item.selected);
  }, [offerInfos]);

  const getOfferInfoIcon = (item) => {
    let discount = item.iPercentageDiscount;
    if (discount) {
      discount = discount.replace(/\s+/g, '');
    }

    if (discount === 'SpecialOffer') {
      return SpecialOfferLogo;
    } else if (discount === '2for1') {
      return B1G1OfferLogo;
    } else if (isFinite(+discount)) {
      return DiscountOfferLogo;
    }
    return '';
  };
  const getOfferInfoTitle = (item) => {
    let discount = item.iPercentageDiscount;
    if (discount) {
      discount = discount.replace(/\s+/g, '');
    }

    if (discount === 'SpecialOffer') {
      return 'Special Offer';
    } else if (discount === '2for1') {
      return '2 For 1';
    } else if (isFinite(+discount)) {
      return `${discount}% Off`;
    }
    return '';
  };

  const getOfferInfoDesc = (item) => {
    let discount = item.iPercentageDiscount;
    if (discount) {
      discount = discount.replace(/\s+/g, '');
    }

    if (discount === 'SpecialOffer') {
      if (item.vDealTitle.toLowerCase().includes('special offer')) {
        return <>{item.sTearmsConditions}</>;
      } else {
        return (
          <>
            {item.vDealTitle} <br />
            {item.sTearmsConditions}
          </>
        );
      }
    } else if (discount === '2for1') {
      return <>{item.sTearmsConditions} </>;
    } else if (isFinite(+discount)) {
      return (
        <>
          {item.vDealTitle} <br />
          {item.sTearmsConditions}{' '}
        </>
      );
    }
    return <>{item.vDealTitle}</>;
  };

  const handleOfferInfoClick = (offerInfo) => {
    if (isMobile) {
      redeem(offerInfo);
      return;
    }

    setOfferInfos((items) =>
      items.map((item) => {
        if (offerInfo.iDealID === item.iDealID) {
          return { ...item, selected: true };
        } else {
          return { ...item, selected: false };
        }
      }),
    );
  };

  const redeem = (offerInfo) => {
    if (!offerInfo || !details || !outlet) return;

    if (offerInfo.isLocationMatch === 'Yes') {
      const { lat, lng } = latLng || {};
      if (!lat || !lng || locationPermission !== 'granted') {
        setLocationModalType('permission_denied');
        return;
      }

      const outletInRange = [outlet, ...otherOutlets].find((o) => {
        const coordinates1 = { lat: +o.vOutletLatitude, lon: +o.vOutletLongitude };
        const coordinates2 = { lat: lat, lon: lng };
        const dist = haversine(coordinates1, coordinates2);
        return dist < +offerInfo.iFencingRange;
      });

      setInRangeOutlet(outletInRange);

      console.log('Outlet In Range', outletInRange);
      if (!outletInRange) {
        setNoOutletInRange(true);
        //Alert.error('No outlet found at you current location');
        return;
      }

      if (outletId && outletInRange.iOutletID && +outletId !== outletInRange.iOutletID) {
        setNotInSelectedOutlet(true);
      }
    }

    const params = new URLSearchParams({
      ...(prive && { isPrive: 'Yes', isCheckout: 'Yes' }),
    });

    history.push(
      { pathname: '/checkout', search: params.toString() },
      {
        headerInfo: details.HeaderInfo,
        image: images[0],
        outlet: outlet,
        offerInfo: offerInfo,
      },
    );
  };
  const handleRedeemClick = () => {
    const { user } = store.getState().user;

    if (!user) {
      history.push('/login', {
        redirect: location,
      });
      Alert.error('Please login to redeem.');
      return;
    }
    if (!offerInfosVisible && !selectedOfferInfo) {
      setTimeout(() => {
        offerInfosRef.current?.scrollIntoView(true);
      }, 150);
      return;
    }

    redeem(selectedOfferInfo);
  };

  const handleGetDirections = (outlet) => {
    if (!outlet) return;
    window.open(
      `https://www.google.com/maps/search/?api=1&query=${outlet.vOutletLatitude},${outlet.vOutletLongitude}`,
      '_blank',
    );
  };

  useEffect(() => {
    const handleScroll = () => {
      if (faqRef.current) {
        const sectionTop = faqRef.current.offsetTop;
        const scrollPosition = window.scrollY + window.innerHeight;

        if (scrollPosition > sectionTop) {
          setShowDirectionButton(true);
        } else {
          setShowDirectionButton(false);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!brandId || !outletId || !dealId) {
      history.replace('/');
      return;
    }

    (async () => {
      try {
        window.scrollTo(0, 0);
        setShowLoader(true);

        const payload = {
          iCountryID: selectedCity.iCountryID,
          dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
          dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
          iBrandID: brandId,
          iOutletID: outletId,
          iDealID: dealId,
        };

        const { user } = store.getState().user;

        let apiCalls;
        if (prive) {
          if (user) {
            apiCalls = [
              priveApiClient.post('/offer/offerDetailNew', payload),
              priveApiClient.post('/offer/offerDetailNew2', payload),
            ];
          } else {
            apiCalls = [
              priveApiClient.post('/offer/offerDetailNew', payload),
              priveApiClient.post('/offer/offerDetailNew2', payload),
            ];
          }
        } else {
          if (user) {
            apiCalls = [
              apiClient.post('/offer/offerDetailNew', payload),
              apiClient.post('/offer/offerDetailNew2', payload),
            ];
          } else {
            apiCalls = [
              apiClient.post('/guestoffer/offerDetailNew', payload),
              apiClient.post('/guestoffer/offerDetailNew2', payload),
            ];
          }
        }

        const [response1, response2] = await Promise.all(apiCalls);

        if (response1.data.status === 1 && response2.data.status === 1) {
          setDetails(response1.data.DATA);
          setDetails2(response2.data.DATA);

          if (response1.data.DATA.HeaderInfo.deal_images_list.length === 0) {
            setImages([response1.data.DATA.HeaderInfo.deal_images]);
          } else if (
            response1.data.DATA.HeaderInfo.deal_images_list[0] !==
            response1.data.DATA.HeaderInfo.deal_images
          ) {
            setImages([
              response1.data.DATA.HeaderInfo.deal_images,
              ...response1.data.DATA.HeaderInfo.deal_images_list,
            ]);
          } else {
            setImages(response1.data.DATA.HeaderInfo.deal_images_list);
          }
          if (response1.data.DATA.OfferInfo.length > 0) {
            const currentOutlet = response1.data.DATA.OfferInfo[0].Outlet_List.find(
              (o) => o.iOutletID === outletId,
            );
            setOutlet(currentOutlet || response1.data.DATA.OfferInfo[0].Outlet_List[0]);
            setOtherOutlets(
              response1.data.DATA.OfferInfo[0].Outlet_List.filter((o) => o.iOutletID !== outletId),
            );
            setDesc(response1.data.DATA.OfferInfo[0].tDescription);
            setOfferInfos(response1.data.DATA.OfferInfo);
          }
          if (response1.merchantInfo) {
            setIsFavourite(response1.merchantInfo.isFavouriteOffer === 1);
          }
        }
      } catch (e) {
        console.error(
          `Failed to load deal details prive: ${prive}, ${{
            isPrive: prive,
            iBrandID: brandId,
            iOutletID: outletId,
            iDealID: dealId,
          }}`,
          e,
        );
        setDetails(undefined);
        setDetails2(undefined);
        setImages([]);
        setOutlet(undefined);
        setOtherOutlets([]);
        setDesc(undefined);
        setOtherOutlets([]);
        setIsFavourite(false);
      } finally {
        setShowLoader(false);
      }
    })();
  }, [brandId, outletId, dealId, prive]);

  useEffect(() => {
    if (!offerInfosVisible && !selectedOfferInfo) {
      setRedeemEnable(true);
      return;
    }
    if (selectedOfferInfo) {
      setRedeemEnable(true);
    } else {
      setRedeemEnable(false);
    }
  }, [selectedOfferInfo, offerInfosVisible]);
  const toggleFav = () => {
    setIsFavourite((value) => !value);

    dispatch(
      toggleFavourite({
        iBrandID: brandId,
        iOutletID: outletId,
        iDealID: dealId,
        isFavouriteOffer: !isFavourite,
        ...(prive && { isPrive: 'Yes' }),
      }),
    );
  };

  useEffect(() => {
    logEvent('view_promotion', { name: 'Deal Detail Page for the Offers' });
  }, []);

  if (!details) {
    return <div style={{ height: '100vh' }}></div>;
  }

  return (
    <>
      <div className="twl-details-wrapper">
        <MediaQuery maxWidth={767}>
          <div className="details-mobile-header">
            <Link
              to="#"
              className="back-arrow"
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
            </Link>
            <div className="right-action">
              <Button onClick={toggleFav}>
                {isFavourite ? (
                  <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" />
                ) : (
                  <SvgIcon name="heart" viewbox="0 0 10.238 9.131" />
                )}
              </Button>
            </div>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={767}>
          <div className="mobile-redeem-action">
            <div className="left-actions">
              <Button
                type="link"
                onClick={() => {
                  if (navigator.clipboard) {
                    navigator.clipboard.writeText(window.location.href);
                    Alert.success('Deal URL copied to the clipboard!');
                  }
                }}
              >
                <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
              </Button>
              <Button
                type="link"
                onClick={() => {
                  if (outlet?.tPhoneNo) {
                    const nums = outlet.tPhoneNo.split(',');
                    if (nums.length > 0) {
                      window.open(`tel:${nums[0]}`, '_blank');
                    }
                  }
                }}
              >
                <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
              </Button>
            </div>
            {!showDirectionButton && (
              <Button disabled={!redeemEnable} type="primary" onClick={handleRedeemClick}>
                Redeem
              </Button>
            )}

            {showDirectionButton && (
              <Button type="primary" onClick={() => handleGetDirections(outlet)}>
                Get Directions
              </Button>
            )}
          </div>
        </MediaQuery>
        <section>
          <Container className="container-upper">
            {images.length > 1 && (
              <MediaQuery minWidth={768}>
                <div className="details-images">
                  <div className="left-image">
                    <Zoom>
                      <div className="square-image-container">
                        <img src={images[0]} alt="Experience London skyline" />
                      </div>
                    </Zoom>
                  </div>
                  <div className="right-image">
                    {images.slice(1, 5).map((image, index) => {
                      return (
                        <Zoom key={index}>
                          <div className="square-image-container">
                            <img key={index} src={image} alt={details.HeaderInfo.vBrandName} />
                          </div>
                        </Zoom>
                      );
                    })}
                  </div>
                </div>
              </MediaQuery>
            )}
            <MediaQuery maxWidth={767}>
              <div className="mobile-details-slider">
                <Slider {...detailSlider}>
                  {images.map((image, index) => {
                    return (
                      <div>
                        <img key={index} src={image} alt={details.HeaderInfo.vBrandName} />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </MediaQuery>
            <div className="details-row">
              <div className="details-left">
                {images.length === 1 && (
                  <MediaQuery minWidth={767}>
                    <div className="square-image-container">
                      <img src={images[0]} alt={`Tour Image`} className="detail-img-sub" />
                    </div>
                  </MediaQuery>
                )}
                <div className="dtl-upper">
                  <div className="dtl-upperleft">
                    <div className="rating">
                      <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                      <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                      <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                      <SvgIcon name="star-filled" viewbox="0 0 15 15" />
                      <SvgIcon name="star-outline" viewbox="0 0 15.999 16" />
                    </div>
                    <div className="dlt-logo">
                      <img src={details.HeaderInfo.vBrandImage} alt="Details Logo" />
                    </div>
                    <h1>{details.HeaderInfo.vBrandName}</h1>
                    <div className="location">
                      <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                      {outlet.vAreaName}
                    </div>
                  </div>
                  <MediaQuery minWidth={768}>
                    <div className="dtl-upperright">
                      <Button onClick={toggleFav}>
                        Favorite{' '}
                        {isFavourite ? (
                          <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" />
                        ) : (
                          <SvgIcon name="heart" viewbox="0 0 10.238 9.131" />
                        )}
                      </Button>
                      <Button
                        type="link"
                        onClick={() => {
                          if (navigator.clipboard) {
                            navigator.clipboard.writeText(window.location.href);
                            Alert.success('Deal URL copied to the clipboard!');
                          }
                        }}
                      >
                        Share <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                      </Button>
                    </div>
                  </MediaQuery>
                </div>
                <div className="overview-row">
                  <h2>Overview</h2>
                  <p>{desc}</p>
                </div>
                {details2.OUTLET &&
                  (details2.OUTLET.averagePrice ||
                    details2.OUTLET.weekDayOperationHours ||
                    details2.OUTLET.weekEndOperationHours) && (
                    <div className="highlights-row">
                      <h2>Highlights</h2>
                      <ul>
                        {/* <li>
                        <div className="left-icon">
                          <SvgIcon name="italian-icon" viewbox="0 0 32.352 19.676" />
                        </div>
                        <div>Italian</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="wheelchair-icon" viewbox="0 0 32.714 29.209" />
                        </div>
                        <div>Wheelchair Access</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="takeaway-icon" viewbox="0 0 21.193 28.901" />
                        </div>
                        <div>Takeaway</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="covid-icon" viewbox="0 0 28.349 28.35" />
                        </div>
                        <div>Covid-19 precautions</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="dinein-icon" viewbox="0 0 27.073 27.137" />
                        </div>
                        <div>Dine-in</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="delivery-icon" viewbox="0 0 33.41 25.336" />
                        </div>
                        <div>Delivery</div>
                      </li>*/}
                        {details2.OUTLET.averagePrice && (
                          <li>
                            <div className="left-icon">
                              <SvgIcon name="payment-icon" viewbox="0 0 29.354 29.096" />
                            </div>
                            <div>{details2.OUTLET.averagePrice}</div>
                          </li>
                        )}
                        {details2.OUTLET.weekDayOperationHours &&
                          details2.OUTLET.weekEndOperationHours && (
                            <li>
                              <div className="left-icon">
                                <SvgIcon name="date-icon" viewbox="0 0 22.241 23.293" />
                              </div>
                              <div>
                                {details2.OUTLET.weekDayOperationHours}
                                <br />
                                {details2.OUTLET.weekEndOperationHours}
                              </div>
                            </li>
                          )}
                      </ul>
                    </div>
                  )}
              </div>
              <div className="details-right">
                <div ref={offerInfosRef} className="details-right-inner">
                  <div className="head">
                    <h4>Offers</h4>
                    <p>APPLICABLE AT VENUE</p>
                  </div>
                  <List
                    itemLayout="horizontal"
                    dataSource={offerInfos}
                    renderItem={(item, index) => (
                      <List.Item
                        onClick={() => handleOfferInfoClick(item)}
                        actions={
                          item.selected && [<SvgIcon name="check" viewbox="0 0 10.289 9.742" />]
                        }
                      >
                        <div className="offer-item">
                          <div className="circleTop" />
                          <div className="circleBottom" />

                          <div className="offer-item-inner">
                            <div className="offer-item-content">
                              <div className="left">
                                {prive && <PriveLogo className="prive-logo" />}
                                <h4>{getOfferInfoTitle(item)}</h4>
                                <p>{getOfferInfoDesc(item)}</p>
                              </div>
                              <div className="right">
                                <div className="btn-available">
                                  {item.total_coupons > 0
                                    ? `${+item.total_coupons - +item.redeem_count} out of ${item.total_coupons}`
                                    : 'Unlimited'}
                                </div>
                                <div className="btn-use">Use Now</div>
                              </div>
                            </div>
                            <div className="offer-item-logo">
                              <TravellerLogoVertical className="logo" />
                              {/* <img className="logo" src={logoImage} alt="logo" />*/}
                            </div>
                          </div>
                        </div>
                        {/*<List.Item.Meta
                          avatar={<img src={getOfferInfoIcon(item)} alt={item.title} />}
                          title={getOfferInfoTitle(item)}
                          description={getOfferInfoDesc(item)}
                        />*/}
                      </List.Item>
                    )}
                  />
                  <div className="bottom-action">
                    <MediaQuery minWidth={767}>
                      <Button
                        disabled={!redeemEnable}
                        type="primary"
                        onClick={handleRedeemClick}
                        block
                      >
                        Redeem
                      </Button>
                      <Button
                        type="primary"
                        ghost
                        block
                        onClick={() => handleGetDirections(outlet)}
                      >
                        Get The Directions
                      </Button>
                    </MediaQuery>
                    <Button
                      type="link"
                      icon={<SvgIcon name="play" viewbox="0 0 41.93 41.965" />}
                      onClick={() => setShowHowToRedeem(true)}
                      block
                    >
                      Learn how to redeem an offer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            {otherOutlets.length > 0 && (
              <div className="other-vanues">
                <h2>Other Venues</h2>
                <Slider {...settingsOtherVanues}>
                  {otherOutlets.map((o) => (
                    <div>
                      <div className="vanues-card">
                        <div className="card-left">
                          <div className="vanues-image">
                            <img src={details.HeaderInfo.vBrandImage} alt="vanues" />
                          </div>
                          <div className="vanues-info">
                            <h4>{o.vOutletTitle}</h4>
                            <p>{o.vAreaName}</p>
                          </div>
                        </div>
                        <div className="card-action">
                          <Button
                            onClick={() => {
                              window.open(`tel:${o.tPhoneNo}`, '_blank');
                            }}
                          >
                            <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
                          </Button>
                          <Button
                            onClick={() => {
                              window.open(
                                `https://www.google.com/maps/search/?api=1&query=${o.vOutletLatitude},${o.vOutletLongitude}`,
                                '_blank',
                              );
                            }}
                          >
                            <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </Container>
        </section>
        {details2.similarOffer.length > 0 && (
          <section className="similervanues-section">
            <Container>
              <Row>
                <Col>
                  <h2>Similar Venues</h2>
                  <Slider {...settingsSimilerVanues}>
                    {details2.similarOffer.map((item) => (
                      <DealCard
                        key={`${item.iBrandID}_${item.iOutletID}_${item.iDealID}`}
                        item={{ ...item, isPrive: prive ? 'Yes' : 'No' }}
                      />
                    ))}
                  </Slider>
                </Col>
              </Row>
              <Row>
                <Col>
                  <hr />
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {details2.FAQ && details2.FAQ.length > 0 && (
          <section ref={faqRef} className="faq-section">
            <Container>
              <Row>
                <Col>
                  <h2>FAQ</h2>
                </Col>
              </Row>
              <Row>
                <Col sm="12" className="faq-right">
                  <Collapse
                    accordion
                    defaultActiveKey={['0']}
                    expandIcon={({ isActive }) =>
                      isActive ? (
                        <SvgIcon
                          name="chevron-right"
                          className="downArrow"
                          viewbox="0 0 16.638 17.53"
                        />
                      ) : (
                        <SvgIcon name="chevron-right" viewbox="0 0 16.638 17.53" />
                      )
                    }
                  >
                    {details2.FAQ.map((faq) => {
                      return (
                        <Panel header={faq.iFaqQuestion} key={faq.iFaqID}>
                          {htmlToList(faq.iFaqAnswer).map((text, index) => (
                            <p key={index}>{text}</p>
                          ))}
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {details.HeaderInfo.tTearmsConditions && (
          <section className="terms-section">
            <Container>
              <Row>
                <Col>
                  <h2>Terms & Conditions</h2>
                </Col>
              </Row>
              <Row>
                <ul>
                  {details.HeaderInfo.tTearmsConditions.split('|').map((s, i) => (
                    <li key={i}>{s.trim()}</li>
                  ))}
                </ul>
              </Row>
            </Container>
          </section>
        )}

        <section className="social-section">
          <div className="travellerPass-icon">
            <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
          </div>
          <Container>
            <Row>
              <Col>
                <img className="logo-icon" src={LogoIcon} alt="TravellerPass" />
                <h1>
                  Discover the world like never before <br /> with TravellerPass
                </h1>
                <ul>
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://www.tiktok.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://medium.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {locationModalType && locationModalType === 'permission_denied' && (
        <LocationModal
          handleCancel={() => {
            setLocationModalType(undefined);
          }}
        />
      )}
      {isNoOutletInRange && <NotAtVenueModal handleCancel={() => setNoOutletInRange(false)} />}
      {isNotInSelectedOutlet && inRangeOutlet && (
        <NotAtSelectedVenueModal
          handleConfirm={() => {
            const params = new URLSearchParams({
              iBrandID: brandId,
              iOutletID: inRangeOutlet.iOutletID,
              iDealID: dealId,
              ...(prive && { isPrive: '' }),
            });
            history.push({ pathname: '/details', search: params.toString() });
            setNoOutletInRange(false);
          }}
          handleCancel={() => setNoOutletInRange(false)}
        />
      )}
      <ModalVideo
        isOpen={showHowToRedeem}
        channel="custom"
        url={prive ? HowToRedeemPrive : HowToRedeem}
        onClose={() => setShowHowToRedeem(false)}
      />
    </>
  );
};

export default DetailsPage;
