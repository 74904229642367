import { SvgIcon } from '../index';
import { Select } from 'antd';
import React from 'react';
import './index.scss';
import classNames from 'classnames';
import { useCityCountrySelector } from '../../../store/selectors';
import { useDispatch } from 'react-redux';
import { setSelectedCity } from '../../../slice/countryCitySlice';
import { useMediaQuery } from 'react-responsive';

const SearchCountryDrop = ({ inNavBar }) => {
  const dispatch = useDispatch();
  const { cities, selectedCity } = useCityCountrySelector();

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  return (
    <Select
      value={selectedCity.iCityID}
      suffixIcon={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
      popupClassName={classNames('search-city-dropdown', {
        'in-navbar': inNavBar === true,
      })}
      listHeight={300}
      dropdownRender={(menu) => (
        <>
          <div className="drop-title">Choose City</div>
          {menu}
        </>
      )}
      onSelect={(value) => {
        const city = cities.find((city) => city.iCityID === value);
        if (city) {
          dispatch(setSelectedCity(city));
          window.location.reload();
        }
      }}
      dropdownStyle={{
        ...(inNavBar === true && { top: window.scrollY + (isMobile ? 80 : 142) }),
      }}
      options={[
        {
          label: (
            <>
              <div className="city-img">
                <SvgIcon name="dubai-flag" viewbox="0 0 42 42" />
              </div>
              United Arab Emirates
            </>
          ),
          options: [
            ...cities
              .filter((city) => city.vCountryName === 'UAE')
              .map((city) => {
                return {
                  value: city.iCityID,
                  label: (
                    <>
                      <div className="city-img">
                        <img src={city.city_banner} alt={city.vCityName} />
                      </div>
                      {city.vCityName}
                    </>
                  ),
                };
              }),
          ],
        },
        /* {
          label: (
            <>
              <div className="city-img">
                <SvgIcon name="dubai-flag" viewbox="0 0 42 42" />
              </div>
              Oman
            </>
          ),
          options: [
            {
              value: 'v4',
              label: (
                <>
                  <div className="city-img">
                    <img src={CityImage4} alt={CityImage4} />
                  </div>
                  Muscat
                </>
              ),
            },
            {
              value: 'v5',
              label: (
                <>
                  <div className="city-img">
                    <img src={CityImage5} alt={CityImage5} />
                  </div>
                  Sur
                </>
              ),
            },
            {
              value: 'v6',
              label: (
                <>
                  <div className="city-img">
                    <img src={CityImage6} alt={CityImage6} />
                  </div>
                  Southern Oman
                </>
              ),
            },
          ],
        },*/
      ]}
    />
  );
};

export default SearchCountryDrop;
