import React, { useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button, Radio } from 'antd';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Tilt from 'react-parallax-tilt';
import { useMediaQuery } from 'react-responsive';
import { Link } from 'react-router-dom';
import './index.scss';

import BannerImg from '../../../assets/images/buy-membership-banner.png';
import InfoImg from '../../../assets/images/buy-membership-info.png';
import { openChatSupport } from '../../../utility/chat';

const MyMembershipHome = () => {
  const [value, setValue] = useState(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
  return (
    <div className="buymembershiphome-wrapper">
      <section className="banner-section">
        <Container>
          <ParallaxProvider>
            <Row className="banner-row">
              <Col md="6" className="banner-left">
                <Parallax speed={10} disabled={isMobile}>
                  <h1 data-aos="fade-up" data-aos-duration="800">
                    Start your <br /> savings journey!
                  </h1>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000">
                      <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Unlimited Offers
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1200">
                      <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Access to 3000+ venues
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1400">
                      <SvgIcon name="check-circle" viewbox="0 0 30 30" /> 100+ Prive luxury offers
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1400">
                      <SvgIcon name="check-circle" viewbox="0 0 30 30" /> UAE and Oman venues
                      included
                    </li>
                  </ul>
                  <div className="bottom-info" data-aos="fade-up" data-aos-duration="1600">
                    <img src={InfoImg} alt="Buy Membership" />
                    <p>
                      Make up your membership cost by saving big from just two offer redemptions!
                    </p>
                  </div>
                </Parallax>
              </Col>
              <Col md="6">
                <Tilt
                  className="parallax-effect"
                  perspective={1500}
                  tiltMaxAngleX="5"
                  tiltMaxAngleY="5"
                >
                  <div className="banner-right">
                    <SvgIcon className="arrow" name="banner-arrow" viewbox="0 0 170.622 166.519" />
                    <img
                      className="right-img"
                      src={BannerImg}
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      alt={'Buy Membership'}
                    />
                    <div className="subscriptions-card">
                      <Parallax speed={15} disabled={isMobile}>
                        <div
                          className="subscriptions-card-inner"
                          data-aos="flip-left"
                          data-aos-duration="1500"
                        >
                          <div className="upper-header">
                            <Radio.Group
                              defaultValue="1"
                              buttonStyle="solid"
                              onChange={onChange}
                              value={value}
                            >
                              <Radio.Button value={1}>UAE</Radio.Button>
                              <Radio.Button value={2}>OMAN</Radio.Button>
                            </Radio.Group>
                            <Link
                              to="#"
                              className="need-help-icon"
                              onClick={(e) => {
                                e.preventDefault();
                                openChatSupport();
                              }}
                            >
                              <SvgIcon name="mice" viewbox="0 0 13.753 13.407" />
                              Need Help?
                            </Link>
                          </div>
                          <div className="header">Subscriptions</div>
                          <ul>
                            <li>
                              <div className="list-left">
                                <h3>
                                  {value == 1 ? 'UAE ' : value == 2 ? 'OMR ' : 'UAE '} 49.99 / Month{' '}
                                  <span className="save-badge secondary-badge">
                                    Save {value == 1 ? 'UAE ' : value == 2 ? 'OMR ' : 'UAE '} 100
                                  </span>
                                </h3>
                                <p>Pay monthly, cancel anytime</p>
                              </div>
                            </li>
                            <li>
                              <div className="list-left">
                                <h3>
                                  {value == 1 ? 'UAE ' : value == 2 ? 'OMR ' : 'UAE '} 499.99 /
                                  Yearly{' '}
                                  <span className="save-badge">
                                    Save {value == 1 ? 'UAE ' : value == 2 ? 'OMR ' : 'UAE '} 100
                                  </span>
                                </h3>
                                <p>Pay ahead for a full year</p>
                              </div>
                            </li>
                          </ul>
                          <Link to="purchase-membership">
                            <Button type="secondary" block>
                              Upgrade
                            </Button>
                          </Link>
                          <div>
                            <Button type="link" className="mx-auto">
                              <SvgIcon name="promo-code" viewbox="0 0 14.611 10.163" /> Do you have
                              Promo code?
                            </Button>
                          </div>
                          <div className="text-center">
                            <Button type="link" className="text-decoration mx-auto mt-1">
                              Cancel Subscription
                            </Button>
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
                </Tilt>
              </Col>
            </Row>
          </ParallaxProvider>
        </Container>
      </section>
      <section className="statics-section">
        <Container>
          <ParallaxProvider>
            <Row>
              <Col sm="12">
                <div className="statics-row">
                  <div className="card-list">
                    <div className="list-item" data-aos="fade-up" data-aos-duration="1200">
                      <div className="card-icon">
                        <SvgIcon name="categories-icon" viewbox="0 0 18.988 18.672" />
                      </div>
                      <div>
                        <h2>05</h2>
                        <p>Categories</p>
                        <hr />
                      </div>
                    </div>
                    <div className="list-item" data-aos="fade-down" data-aos-duration="1400">
                      <div className="card-icon">
                        <SvgIcon name="venues-icon" viewbox="0 0 19.144 18.234" />
                      </div>
                      <div>
                        <h2>3000+</h2>
                        <p>Venues</p>
                        <hr />
                      </div>
                    </div>
                    <div className="list-item" data-aos="fade-up" data-aos-duration="1600">
                      <div className="card-icon">
                        <SvgIcon name="unlimited-offers" viewbox="0 0 24.588 11.354" />
                      </div>
                      <div>
                        <h2>98%</h2>
                        <p>Unlimited offers</p>
                        <hr />
                      </div>
                    </div>
                  </div>
                  <h3 data-aos="fade-left" data-aos-duration="1600">
                    Unlimited savings with 2 for 1 & flat discounts up to 50% OFF
                  </h3>
                </div>
              </Col>
            </Row>
          </ParallaxProvider>
        </Container>
      </section>
    </div>
  );
};

export default MyMembershipHome;
