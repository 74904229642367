import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button, Input, Tabs } from 'antd';
import PayWithCard from './PayWithCard';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import PayVanue from './PayVanue';
import OtpInput from 'react-otp-input';
import { useLoaderContext } from '../../components/common/Loader';
import { SHA256 } from 'crypto-js';
import { apiClient, priveApiClient } from '../../apiConfig';
import { Alert } from '../../common';
import PaymentComponent from '../BuyMembership/BuyMembershipCheckout/PaymentComponent';
import { logEvent } from '../../utility/analytics';

const PaywithCardTitle = (
  <div className="options-card" data-aos="zoom-in" data-aos-duration="800">
    <div className="optionscard-inner">
      <SvgIcon name="card" viewbox="0 0 28 19.087" />
    </div>
    <p>Pay with Card</p>
  </div>
);

const ApplePayTitle = (
  <div className="options-card" data-aos="zoom-in" data-aos-duration="1000">
    <div className="optionscard-inner">
      <SvgIcon name="apple-pay" viewbox="0 0 38.937 16.118" />
    </div>
    <p>Apple Pay</p>
  </div>
);

const GooglePayTitle = (
  <div className="options-card" data-aos="zoom-in" data-aos-duration="1200">
    <div className="optionscard-inner">
      <SvgIcon name="gpay" viewbox="0 0 71.72 28.49" />
    </div>
    <p>Google Pay</p>
  </div>
);

const PayVanueTitle = (
  <div className="options-card" data-aos="zoom-in" data-aos-duration="1200">
    <div className="optionscard-inner">
      <SvgIcon name="pay-vanue" viewbox="0 0 14.083 24.047" />
    </div>
    <p>Pay at the venue</p>
  </div>
);

const Checkout = () => {
  const history = useHistory();
  const { search, state } = useLocation();
  const { headerInfo, image, outlet, offerInfo } = state || {};

  const searchParams = new URLSearchParams(search);
  const prive = searchParams.has('isPrive');

  const { setShowLoader } = useLoaderContext();

  const [paymentMode, setPaymentMode] = useState('outlet');
  const [totalAmount, setTotalAmount] = useState();
  const [discountAmount, setDiscountAmount] = useState();
  const [merchantCode, setMerchantCode] = useState();

  const [finalDiscountAmount, setFinalDiscountAmount] = useState(0);
  const [finalPayableAmount, setFinalPayableAmount] = useState(0);

  const [isValid, setIsValid] = useState(false);

  const [showStripeModal, setShowStripeModal] = useState(false);
  const [stripePaymentData, setStripePaymentData] = useState();
  const [tempRedeemId, setTempRedeemId] = useState();

  const offerType = useMemo(() => {
    if (offerInfo) {
      let discount = offerInfo.iPercentageDiscount;
      if (discount) {
        discount = discount.replace(/\s+/g, '');
      }
      if (discount === 'SpecialOffer') {
        return 'special';
      } else if (discount === '2for1') {
        return 'b1g1';
      } else if (isFinite(+discount)) {
        return `discount`;
      }
    }
    return undefined;
  }, [offerInfo]);

  useEffect(() => {
    const tAmount = !isNaN(parseInt(totalAmount, 10)) ? parseInt(totalAmount, 10) : undefined;
    const dAmount = !isNaN(parseInt(discountAmount, 10)) ? parseInt(discountAmount, 10) : undefined;

    if (offerType === 'b1g1') {
      if (!tAmount || !dAmount) {
        setFinalDiscountAmount(0);
        setFinalPayableAmount(0);
        setIsValid(false);
        return;
      }

      setIsValid(tAmount > 0 && dAmount > 0 && dAmount <= tAmount);
      setFinalDiscountAmount(dAmount);
      let payable = tAmount - dAmount;
      if (payable < 0) {
        payable = 0;
      }
      setFinalPayableAmount(payable);
    } else if (offerType === 'discount') {
      if (!tAmount) {
        setFinalDiscountAmount(0);
        setFinalPayableAmount(0);
        setIsValid(false);
        return;
      }
      const discount = tAmount * (offerInfo.iPercentageDiscount / 100.0);
      setIsValid(tAmount > 0);

      let payable = tAmount - discount;
      if (payable < 0) {
        payable = 0;
      }
      if (payable > 0) {
        setFinalDiscountAmount(discount);
      } else {
        setFinalDiscountAmount(0);
      }
      setFinalPayableAmount(payable);
    } else if (offerType === 'special') {
      const saveAmount = !isNaN(parseInt(offerInfo.iSavingRedem, 10))
        ? parseInt(offerInfo.iSavingRedem, 10)
        : undefined;

      if (offerInfo.isAllowAmount && offerInfo.isAllowAmount === 'No') {
        setFinalPayableAmount(0);
        setIsValid(true);
        return;
      }

      if (!tAmount || !saveAmount) {
        setFinalDiscountAmount(0);
        setFinalPayableAmount(0);
        setIsValid(false);
        return;
      }

      setIsValid(tAmount > 0);

      let payable = tAmount - saveAmount;
      if (payable < 0) {
        payable = 0;
      }
      if (payable > 0) {
        setFinalDiscountAmount(saveAmount);
      } else {
        setFinalDiscountAmount(0);
      }

      setFinalPayableAmount(payable);
    } else {
      setFinalDiscountAmount(0);
      setFinalPayableAmount(0);
      setIsValid(false);
    }
  }, [offerInfo, offerType, totalAmount, discountAmount]);

  const redeem = async (mode, extraPayload) => {
    try {
      setShowLoader(true);

      let code = offerInfo.vVerificationCode;
      if (offerInfo.isSkipCode && offerInfo.isSkipCode === 'No') {
        code = SHA256(merchantCode + 'Imc@$01tma$sa1@').toString();
      }

      let payload = {
        iDealID: offerInfo.iDealID,
        iBrandID: offerInfo.iBrandID,
        iOutletID: outlet.iOutletID,
        vVerificationCode: code,
        iTransactionID: offerInfo.iTransactionID,
        vTotalAmount: finalPayableAmount > 0 ? finalPayableAmount : null,
        vDiscountAmount: finalDiscountAmount > 0 ? finalDiscountAmount : null,
        ...(mode === 'outlet' ? { isTravellerPay: 'No' } : {}),
        ...(extraPayload && { ...extraPayload }),
      };

      if (offerInfo.isAllowAmount && offerInfo.isAllowAmount === 'No') {
        payload = {
          ...payload,
          vTotalAmount: 0,
        };
      }

      if (mode === 'outlet') {
        const response = await (prive
          ? priveApiClient.post('offer/redeem', payload)
          : apiClient.post('offer/redeem', payload));

        if (response.data.status === 1) {
          logEvent('purchase', { name: 'Successful Redemption Screen' });
          Alert.success(response.data.MESSAGE);

          const params = new URLSearchParams({
            ...(prive && { isPrive: 'Yes' }),
          });
          history.push(
            { pathname: 'payment-success', search: params.toString() },
            {
              data: {
                iTransactionID: offerInfo.iTransactionID,
                discountAmount: response.data.DiscountAmount,
              },
            },
          );
        } else {
          Alert.error(response.data.MESSAGE || 'Something went wrong, please try again/');
        }
      } else if (mode === 'card') {
        const response = await (prive
          ? priveApiClient.post('offer/transactionPrepare', payload)
          : apiClient.post('offer/transactionPrepare', payload));

        const data = response.data.DATA;
        if (response.data.status === 1 && data) {
          setShowStripeModal(true);
          setTempRedeemId(data.iTempRedeemID);
          setStripePaymentData(data.stripeinterceptor);
        }
      }
    } catch (e) {
    } finally {
      setShowLoader(false);
    }
  };

  const handlePaymentSuccess = () => {
    setPaymentMode('outlet');
    redeem('outlet', { iTempRedeemID: tempRedeemId, isTravellerPay: 'Yes' });
  };

  useEffect(() => {
    if (offerInfo) {
      logEvent('view_item', { name: 'Total Bill Amount Page' });
    }
    if (offerType === 'b1g1') {
      logEvent('view_item', { name: 'Discounted Amount Page' });
    }
  }, [offerType]);

  useEffect(() => {
    if (showStripeModal && stripePaymentData) {
      logEvent('begin_checkout', { name: 'Payment Option Screen' });
    }
  }, [showStripeModal, stripePaymentData]);

  if (!offerInfo || !headerInfo || !image || !outlet) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <div className="tp-checkout-wrapper">
        <Container>
          <Row>
            <Col>
              <div className="twl-row">
                <div className="twl-card checkout-left">
                  <div className="twl-header" data-aos="fade-in" data-aos-duration="800">
                    Checkout
                  </div>
                  <div className="twl-content">
                    <div className="twlcontent-inner">
                      <div className="checkout-upper-detl">
                        {offerInfo.isSkipCode && offerInfo.isSkipCode === 'No' && (
                          <>
                            <div className="pb-4">
                              <h3>Please request to the cashier to enter the code</h3>
                              <OtpInput
                                className="otp-input"
                                containerStyle="otp-input-container"
                                value={merchantCode}
                                onChange={(value) => setMerchantCode(value)}
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                                secure
                              />
                            </div>
                          </>
                        )}
                        <h3>Enter the Total Bill Amount</h3>
                        <p>Please ask the staff for the total bill amount</p>
                        <div className="bill-amount">
                          <div className="currency-icon">AED</div>
                          <Input
                            readOnly={
                              offerType === 'special' &&
                              offerInfo.isAllowAmount &&
                              offerInfo.isAllowAmount === 'No'
                            }
                            placeholder="0.00"
                            value={totalAmount}
                            onChange={(e) => setTotalAmount(e.target.value)}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </div>

                        {offerType === 'b1g1' && (
                          <>
                            <div className="checkout-upper-detl pt-2">
                              <h3>Enter the Discount Amount</h3>
                              <p>Request the staff for the discount amount</p>
                              <div className="bill-amount">
                                <div className="currency-icon">AED</div>
                                <Input
                                  placeholder="0.00"
                                  value={discountAmount}
                                  onChange={(e) => setDiscountAmount(e.target.value)}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      {/* <div className="checkout-upper-detl">
                      <h3>Enter the Total Bill Amount</h3>
                      <p>Please ask the staff for the total bill amount</p>
                      <div className="bill-amount">
                        <div className="currency-icon">AED</div>
                        <Input placeholder="0.00" />
                      </div>
                    </div>*/}
                      {/* <div className="checkout-upper-detl">
                      <h3>Enter the Total Bill Amount</h3>
                      <p>Please ask the staff for the total bill amount</p>
                      <div className="bill-amount">
                        <div className="currency-icon">AED</div>
                        <Input placeholder="0.00" />
                      </div>
                    </div>
                    <div className="checkout-upper-detl pt-2">
                      <h3>Enter the Discount Amount</h3>
                      <p>Request the staff for the discount amount</p>
                      <div className="bill-amount">
                        <div className="currency-icon">AED</div>
                        <Input placeholder="0.00" />
                      </div>
                    </div>*/}
                      {/*<div className="checkout-upper-detl">
                      <div className="pb-4">
                        <h3>Please request to the cashier to enter the code</h3>
                        <OtpInput
                          className="otp-input"
                          containerStyle="otp-input-container"
                          value={'1234'}
                          onChange={() => {}}
                          autoFocus
                          OTPLength={4}
                          otpType="number"
                          disabled={false}
                          secure
                        />
                      </div>
                      <h3>Enter the Total Bill Amount</h3>
                      <p>Please ask the staff for the total bill amount</p>
                      <div className="bill-amount">
                        <div className="currency-icon">AED</div>
                        <Input placeholder="0.00" />
                      </div>
                    </div>*/}
                      {offerInfo.isTravellerPay === 'Yes' && (
                        <>
                          <h3 className="inner-title">Payment options</h3>
                          <Tabs
                            activeKey={paymentMode}
                            className="payment-tabs"
                            onChange={(key) => setPaymentMode(key)}
                          >
                            <Tabs.TabPane tab={PayVanueTitle} key="outlet">
                              <PayVanue />
                            </Tabs.TabPane>
                            <Tabs.TabPane tab={PaywithCardTitle} key="card">
                              <PayWithCard />
                            </Tabs.TabPane>
                            {/*<Tabs.TabPane tab={ApplePayTitle} key="3">*/}
                            {/*  <ApplePay />*/}
                            {/*</Tabs.TabPane>*/}
                            {/*<Tabs.TabPane tab={GooglePayTitle} key="gpay">
                              <GooglePay />
                            </Tabs.TabPane>*/}
                          </Tabs>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="twl-card checkout-right">
                  <div className="twl-header" data-aos="fade-in" data-aos-duration="800">
                    Summary
                  </div>
                  <div className="twl-content">
                    <div className="twlcontent-inner">
                      <ul className="summary-list" data-aos="fade-left" data-aos-duration="800">
                        <li>
                          <div className="image-col">
                            <img src={image} alt={headerInfo.vBrandName} />
                          </div>
                          <div className="list-left">
                            <h3>{headerInfo.vBrandName}</h3>
                            <p>
                              {(Math.floor(outlet.distance * 100) / 100).toFixed(2)}Km /{' '}
                              {outlet.vAreaName}
                            </p>
                          </div>
                        </li>
                      </ul>
                      <div
                        className="payment-details"
                        data-aos="fade-left"
                        data-aos-duration="1200"
                      >
                        {/*<Row className="discount-row">
                        <Col>Service Charge</Col>
                        <Col className="text-right">AED 23</Col>
                      </Row>
                      <Row className="discount-row">
                        <Col>Promo Discount</Col>
                        <Col className="text-right">30.50!</Col>
                      </Row>*/}
                        {finalDiscountAmount > 0 && (
                          <Row className="willpay-row">
                            <Col>Discount</Col>
                            <Col className="text-right">AED {finalDiscountAmount}</Col>
                          </Row>
                        )}
                        <Row className="willpay-row">
                          <Col>You Will Pay</Col>
                          <Col className="text-right">AED {finalPayableAmount}</Col>
                        </Row>
                      </div>

                      <Button
                        className="pay-btn"
                        data-aos="fade-left"
                        data-aos-duration="1250"
                        type="primary"
                        disabled={!isValid}
                        block
                        icon={<SvgIcon name="arrow-right-circle" viewbox="0 0 30 30" />}
                        onClick={() => redeem(paymentMode)}
                      >
                        {offerInfo.isAllowAmount && offerInfo.isAllowAmount === 'No'
                          ? `Continue`
                          : `Pay`}
                      </Button>

                      <Button
                        className="mt-3"
                        data-aos-duration="1250"
                        data-aos="fade-left"
                        type="primary"
                        ghost
                        block
                      >
                        Get The Directions
                      </Button>
                      <Button
                        className="learn-btn"
                        data-aos="fade-left"
                        data-aos-duration="1300"
                        type="link"
                        icon={<SvgIcon name="play" viewbox="0 0 41.93 41.965" />}
                        block
                      >
                        Learn how to redeem an offer
                      </Button>
                      <div className="getit-touch" data-aos="fade-in" data-aos-duration="1400">
                        <div className="upper-row">
                          <h3>Get in touch</h3>
                        </div>
                        <p>
                          If you have any doubts, please reach out to us at{' '}
                          <Link to="/">concierge@travellerpass.com</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showStripeModal && stripePaymentData && (
        <PaymentComponent
          setShowStripeModal={setShowStripeModal}
          paymentIntent={stripePaymentData.payment_intent}
          handlePaymentSuccess={handlePaymentSuccess}
        />
      )}
    </>
  );
};

export default Checkout;
