import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const BRTermsandConditions = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Beyond Rewards
                  </h1>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Terms and Conditions
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/beyondrewards-privacypolicy">
                    Privacy Policy{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  Beyond Rewards mobile application provides Discount offers and ‘2 for 1’ offers at
                  participating retailers. The Offers are managed by a third party, known as
                  TravellerPass LLC. Beyond Rewards can only be availed for offer redemption by Bank
                  cardholders. Bank card customers must use the Beyond Rewards app and a valid Bank
                  card to avail the offer. Bank assumes no liability or responsibility with respect
                  to the products or services provided by any of the partners on the program.
                  <br />
                  <br />
                  Beyond Rewards lists the catalogue of Preferred Partners and their Offers which
                  are available to the Card Customers. Beyond Rewards, terms and conditions will
                  apply to all Beyond Rewards users. Customers will register by entering their name,
                  mobile number, e-mail ID and membership number. Once the registration process is
                  completed on Beyond Rewards, the customer will have access to all offers.
                  <br />
                  Beyond Rewards, privileges may be revoked at any time at the sole discretion of
                  Bank at any time with or without notification.
                  <br />
                  Preferred Partners - Preferred Partners are those Retailers/Partners in the UAE
                  where the Offers can be redeemed using an applicable Bank card.
                  <br />
                  <br />
                  The Preferred Partners and their respective offers are listed in Beyond Rewards.
                  Offers and the countries where the offers are allowed are subject to change from
                  time to time. Offers may be modified or discontinued at any time without notice.
                  <br />
                  The 2 for 1 offer, the partner merchant may apply either as a deduction of the
                  cost of one least expensive main course or main menu item (when two or more main
                  courses or main menu items are ordered) or may apply as a discount on the bill for
                  the offers which come with a discount.
                  <br />
                  Offer Redemption - The Offers can be redeemed at Preferred Partners only when the
                  purchase is made using an applicable Bank card. To avail the Offer, the Card
                  Customer will have to: i) Present Beyond Rewards mobile voucher to the Preferred
                  Partner prior to requesting for the bill, and ii) pay the remaining balance for
                  the service/meal using Bank cards.
                  <br />
                  All Bank Cards Terms and Conditions apply.
                </p>
                <ol>
                  <li>A valid Bank card must be presented for payment of the offer.</li>
                  <li>
                    Card Customers will receive the Offers at Preferred Partners on the total amount
                    of the bill minus any tax or service charges.
                  </li>
                  <li>
                    The Offers can only be claimed against purchases made at Preferred Partners but
                    cannot be paid in cash or using any other payment or loyalty card or by any such
                    combination with any other promotion or offer.
                  </li>
                  <li>
                    The Beyond Rewards Mobile App must be presented at the point of purchase to
                    redeem the Offer. A Preferred Partner has the right to deny the discount if the
                    Mobile App is not presented while requesting for the bill.
                  </li>
                  <li>
                    The use of Offers within the Mobile App in conjunction with any other officially
                    announced promotions or reductions offered is at the discretion of Preferred
                    Partners. The Offers will not be valid on festival days or as per the terms and
                    conditions offered by the preferred partner. The terms and conditions of each
                    offer are updated on the mobile App against each preferred partner.
                  </li>
                  <li>
                    Card Customers need to take note of Offer validity dates and special offer terms
                    and conditions which will be mentioned along with the Offer. Beyond Rewards
                    Validity
                  </li>
                </ol>
                <p>
                  Bank has the right to terminate the Beyond Rewards at any time without reason or
                  notice and Bank will not be liable for any loss or damage that may arise from any
                  suspension or termination.
                  <br />
                  Bank reserves the right to withdraw the privileges of the Beyond Rewards if any
                  Card Customer is in breach of the terms and conditions of Beyond Rewards
                  <br />
                  Disputes on Discount
                  <br />
                  If a Preferred Partner does not honour the valid Offer, the instance can be
                  reported by the customer within 2 working days by contacting support through
                  Beyond Rewards (10 am – 10 pm, Sun - Thu) or email concierge@travellerpass.com.
                  <br />
                  The Customer must provide complete details to TravellerPass about the merchant’s
                  name and location, date of purchase, discount denied and purchase information. If
                  the Customer has a dispute on the discount, TravellerPass will take reasonable
                  efforts to resolving the dispute.
                </p>
                <h4>Additional Terms and Conditions</h4>
                <ol type="a">
                  <li>
                    The Offers are subject to the prescribed Bank terms and conditions and the terms
                    and conditions of TravellerPass. Bank reserves the right to amend these terms
                    and conditions at any time or withdraw this offer at any time without any prior
                    notice. By availing the Offer, the Customer accepts and agrees to be bound by
                    the terms and conditions.
                  </li>
                  <li>
                    Bank assumes no responsibility with respect to the services provided by the
                    service partner (TravellerPass).
                  </li>
                  <li>
                    The Customer understands and acknowledges that the Beyond Rewards is a service
                    offered by Bank and it will be performed by TravellerPass. TravellerPass and
                    Bank are independent contractors to each other, and Bank will have no liability
                    to the Customer for any claims which the Customer may have against TravellerPass
                    for services rendered by it to the Customer.
                  </li>
                  <li>
                    Bank will not be liable for any errors, omissions, delays, or disruptions in the
                    operation of the Beyond Rewards.
                  </li>
                  <li>
                    Bank or TravellerPass may amend the Offers or add/remove Preferred Partners from
                    the program at any time.
                  </li>
                  <li>
                    Preferred Partners may exclude products or services from being eligible for the
                    Offers without prior consent or notice. At certain Preferred Partners, Offers
                    may be subject to availability and may be altered or withdrawn without notice.
                  </li>
                  <li>
                    Bank is not responsible if a Preferred Partner changes its discount due to new
                    ownership or acquisition.
                  </li>
                  <li>
                    Bank will not be liable in any way if any participating Preferred Partner fails
                    to provide the Offer on any product. Bank does not accept any liability in
                    connection with the failure or refusal of any participating Preferred Partner to
                    provide products. Bank does not accept any liability or responsibility for the
                    quality or suitability of products offered by the Preferred Partners.
                  </li>
                  <li>
                    No Preferred Partner has any authority, express or implied to make a
                    representation, warranty or statement on behalf of Bank or in relation to Beyond
                    Rewards and Bank accepts no liability in respect of any representation, warranty
                    or statement made by any Preferred Partner.
                  </li>
                  <li>
                    Bank does not give any guarantee undertaking or warranty concerning the products
                    or services supplied or the performance of any participating Preferred Partner.
                    All conditions and warranties whether express or implied and whether arising
                    under statute or otherwise as to the condition, suitability, quality, fitness or
                    safety of any goods or services supplied by any Preferred Partner are expressly
                    excluded.
                  </li>
                  <li>
                    The Beyond Rewards may be amended, withdrawn, or replaced at any time without
                    notice.
                  </li>
                  <li>
                    Bank is not liable in case of any dissatisfaction about delivery, service or
                    quality of the Beyond Rewards
                  </li>
                  <li>
                    Bank and TravellerPass’s interpretation of these Terms and Conditions is final
                    and binding.
                  </li>
                </ol>
                <h4>Beyond Rewards Mobile App Terms and Conditions – Beyond Rewards</h4>
                <ol>
                  <li>
                    The Mobile App will only work on compatible Devices and operating systems and
                    certain functionalities will only work if your device is connected to the
                    internet. Some features may also only be available with certain operating
                    systems.
                  </li>
                  <li>
                    You may be charged by your service provider for downloading and/or accessing the
                    Mobile App on your mobile phone (the Device). These may include data roaming
                    charges if you do this outside your home territory. All these charges are solely
                    your responsibility.
                  </li>
                  <li>
                    Certain functionalities of the Mobile App may need to access your Device and
                    information stored on it so that it works properly. By using the Mobile App, you
                    consent to this.
                  </li>
                  <li>TravellerPass reserves the right to make changes to the app.</li>
                  <li>
                    ou must not use the Mobile App for any of the following purposes:(a) anything
                    unlawful or illegal or which is fraudulent or malicious or which promotes any
                    criminal activity or provides information about the same;(b) anything which is
                    defamatory, harassing or threatening or which otherwise infringes or violates
                    the rights of others. This includes any information that you may add or upload
                    to the Mobile App;(c) interfering in any way with any other user of the Mobile
                    App; and/or(d) knowingly introducing viruses or other malicious or harmful
                    material or using it in connection with unsolicited communications. The Mobile
                    App and all copyright, database rights, trademarks and other intellectual
                    property rights related to it belong to us or our licensors.
                  </li>
                  <li>
                    Access to the Mobile App may be suspended temporarily from time to time and
                    without notice in the case of system failure, maintenance or repair or for any
                    reason beyond our control or if we deem it necessary.
                    <br />
                    For the avoidance of doubt, we shall not be liable for any loss or liability
                    which may be suffered or incurred by you as a result of any suspension of, or
                    interruption to, the operation of the Mobile App.
                  </li>
                  <li>
                    The Mobile App, including all content on or available through the Mobile App, is
                    provided on an ‘as is’ basis and we do not make any representation or give any
                    warranty in respect of the Mobile App or any of its content. In particular, but
                    without limitation, we do not give any warranty as to the accuracy, suitability,
                    reliability, completeness, performance, fitness, freedom from viruses or
                    timeliness of the content contained on the App.
                  </li>
                  <li>
                    Bank/TravellerPass does not accept any responsibility for:
                    <ol type="a">
                      <li>
                        malfunctions in communication facilities which cannot reasonably be
                        considered to be under our control and that may affect the accuracy or
                        timeliness of messages you send or the material you access via the Mobile
                        App
                      </li>
                      <li>
                        any losses or delays in transmission of messages or material you access
                        arising out of the use of any Internet access service provider or mobile
                        network service provider or caused by any browser or other software which is
                        not under our control
                      </li>
                      <li>
                        Viruses that may infect your computer equipment or other property on account
                        of your access to or use of the Mobile App or you are accessing any
                        materials on the Mobile App
                      </li>
                      <li>
                        any unauthorized use or interception of any message or information before it
                        reaches the Mobile App or our servers from the Mobile App
                      </li>
                      <li>
                        any unauthorized use of or access to data relating to you or your
                        transactions which is held by us (unless such use or access is caused by our
                        negligence, fraud or failure to comply with laws relating to the protection
                        of your data), to the extent permitted by local law
                      </li>
                      <li>any content provided by third parties</li>
                    </ol>
                  </li>
                </ol>
                <p>
                  In addition, Bank/TravellerPass and its officers, directors, employees,
                  shareholders or agents, exclude all liability and responsibility for any amount or
                  kind of loss or damage that may result to you or a third party (including without
                  limitation, any direct, indirect, punitive or consequential loss or damages, or
                  any loss of income, profits, goodwill, data, contracts, use of money, or loss or
                  damages arising from or connected in any way to business interruption, and whether
                  in tort (including without limitation negligence), contract or otherwise) in any
                  way or in connection with:
                </p>
                <ol type="a">
                  <li>
                    the Mobile App and your use, delay in use or inability to use the App or if it
                    does not work as you expect or would like or if it contains any defects or
                    errors and whether or not we correct any of those defects or errors
                  </li>
                  <li>
                    your access to and use of any material on the Mobile App or material linked to
                    or referred to on the Mobile App; and/or
                  </li>
                  <li>
                    any loss or damage to your Device or any other hardware or software you use in
                    connection with the Mobile App, including in connection with any viruses that
                    may affect the same on account of your downloading and use of the Mobile App
                  </li>
                </ol>
                <p>
                  You acknowledge that use of the Mobile App is dependent on third parties,
                  including your own network providers, and that we are not liable for any acts or
                  omissions of those third parties. These Mobile App T&Cs and any dispute or claim
                  arising out of or in connection with them or their subject matter (including any
                  non-contractual disputes or claims) will be governed by the law of United Arab
                  Emirates (“UAE”) and the parties agree that the courts of UAE will have exclusive
                  jurisdiction to settle any such disputes or claims.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default BRTermsandConditions;
