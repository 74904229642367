import React from 'react';
import DealCard from '../DealCard';
import './index.scss';

export default function DealList({ items, onFavouriteClick }) {
  return (
    <div className="tp-item-card-list">
      {items.map((item) => (
        <DealCard
          key={`${item.iBrandID}_${item.iOutletID}_${item.iDealID}${item.isPrive === 'Yes' ? '_prive' : ''}`}
          item={item}
          onFavouriteClick={onFavouriteClick}
        />
      ))}
    </div>
  );
}
