import React from 'react';
import PropTypes from 'prop-types';

import { NavbarDefault } from '../components/layout';

const DefaultMapLayout = ({ children, navbar }) => (
  <React.Fragment>
    <div className="map-listing-wrapper">
      {!navbar && <NavbarDefault logoOnly />}
      <main>{children}</main>
    </div>
  </React.Fragment>
);

DefaultMapLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool,
};

DefaultMapLayout.defaultProps = {
  navbar: false,
  footer: false,
};

export default DefaultMapLayout;
