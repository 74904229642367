import React, { useEffect, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button, Card, Collapse } from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Tilt from 'react-parallax-tilt';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import '../../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';

import BannerImg from '../../../assets/images/buy-membership-banner.png';
import InfoImg from '../../../assets/images/buy-membership-info.png';
import DownloadImg from '../../../assets/images/download.png';
import testimonialImg from '../../../assets/images/testimonial.png';
import testimonialImg2 from '../../../assets/images/testimonial2.png';
import testimonialImg3 from '../../../assets/images/testimonial3.png';
import testimonialImg4 from '../../../assets/images/testimonial4.png';
import testimonialImg5 from '../../../assets/images/testimonial5.png';
import LogoIcon from '../../../assets/images/logo-icon.svg';
import { useLoaderContext } from '../../../components/common/Loader';
import { useCategorySelector, useMembershipSelector } from '../../../store/selectors';
import { useDispatch } from 'react-redux';
import { getMembershipPlans, setSelectedPlanId } from '../../../slice/membershipSlice';
import { LoadState } from '../../../constants';
import PromoModal from '../PromoModal';
import MembershipPlanList from '../../../components/common/MembershipPlanList';
import ExpImg from '../../../assets/images/exp-img.png';
import DiscoverImageSmall from '../../../assets/images/discover-small-img.jpg';
import ModalVideo from 'react-modal-video';
import HowItWorks from '../../../assets/video/how_it_works.mp4';
import CancelSubscriptionModal from '../../ProfileSetting/CancelSubscriptionModal';
import { openChatSupport } from '../../../utility/chat';
import { logEvent } from '../../../utility/analytics';

const { Panel } = Collapse;

function NextArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const BuyMembershipHome = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { categories } = useCategorySelector();
  const { state, selectedPlanId } = useMembershipSelector();
  const { setShowLoader } = useLoaderContext();
  const [isPromoModalVisible, setIsPromoModalVisible] = useState(false);
  const [showHowItWorks, setShowHowItWorks] = useState(false);

  const settingsClients = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrowClients />,
    prevArrow: <PrevArrowClients />,
  };

  const [value, setValue] = useState(1);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const onPlanClick = (plan) => {
    dispatch(setSelectedPlanId(plan.iMembershipPlanID));
  };

  const handleCategoryClick = (category) => {
    if (category) {
      if (category.CategoryWebLink && category.CategoryWebLink.startsWith('https://')) {
        window.open(category.CategoryWebLink, '_blank');
      } else if (category.isPrive === 'Yes') {
        const params = new URLSearchParams({
          isPrive: 'Yes',
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      } else {
        const params = new URLSearchParams({
          categoryId: category.iCategoryID,
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      }
    }
  };

  useEffect(() => {
    logEvent('begin_checkout', { name: 'Buy Membership Page' });
  }, []);

  useEffect(() => {
    dispatch(getMembershipPlans());
  }, []);

  useEffect(() => {
    if (state === LoadState.SUCCESS) {
      setShowLoader(false);
    } else {
      setShowLoader(true);
    }
  }, [state]);
  return (
    <>
      <div className="buymembershiphome-wrapper">
        <section className="banner-section">
          <Container>
            <ParallaxProvider>
              <Row className="banner-row">
                <Col md="6" className="banner-left" data-aos="fade-in" data-aos-duration="1000">
                  <Parallax speed={10} data-depth="0.2" disabled={isMobile}>
                    <h1 data-aos="fade-up" data-aos-duration="800">
                      Start your <br /> savings journey!
                    </h1>
                    <ul>
                      <li data-aos="fade-up" data-aos-duration="1000">
                        <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Unlimited Offers
                      </li>
                      <li data-aos="fade-up" data-aos-duration="1200">
                        <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Access to 3000+ venues
                      </li>
                      <li data-aos="fade-up" data-aos-duration="1400">
                        <SvgIcon name="check-circle" viewbox="0 0 30 30" /> 100+ Prive luxury offers
                      </li>
                      <li data-aos="fade-up" data-aos-duration="1400">
                        <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Multiple Categories
                      </li>
                    </ul>
                    <div className="bottom-info" data-aos="fade-up" data-aos-duration="1600">
                      <img src={InfoImg} alt="Buy Membership" />
                      <p>
                        Make up your membership cost by saving big from just two offer redemptions!
                      </p>
                    </div>
                  </Parallax>
                </Col>
                <Col md="6">
                  <Tilt
                    className="parallax-effect"
                    perspective={1500}
                    tiltMaxAngleX="5"
                    tiltMaxAngleY="5"
                  >
                    <div className="banner-right">
                      <SvgIcon
                        className="arrow"
                        name="banner-arrow"
                        viewbox="0 0 170.622 166.519"
                      />
                      <img
                        className="right-img"
                        src={BannerImg}
                        data-aos="zoom-in-up"
                        data-aos-duration="1000"
                        alt={'Buy Membership'}
                      />
                      <div className="subscriptions-card">
                        <Parallax speed={15} disabled={isMobile}>
                          <div
                            className="subscriptions-card-inner"
                            data-aos="flip-left"
                            data-aos-duration="1500"
                          >
                            <div className="upper-header">
                              {/*<Radio.Group
                                defaultValue="1"
                                buttonStyle="solid"
                                onChange={onChange}
                                value={value}
                              >
                                <Radio.Button value={1}>UAE</Radio.Button>
                                <Radio.Button value={2}>OMAN</Radio.Button>
                              </Radio.Group>*/}
                              <div className="header">Subscriptions</div>
                              <Link
                                to="#"
                                className="need-help-icon"
                                onClick={(e) => {
                                  e.preventDefault();
                                  openChatSupport();
                                }}
                              >
                                <SvgIcon name="mice" viewbox="0 0 13.753 13.407" />
                                Need Help?
                              </Link>
                            </div>
                            {/* <div className="header">Subscriptions</div>*/}
                            {/* <ul>
                              {plans.map((plan) => {
                                return (
                                  <li
                                    key={`${plan.iMembershipPlanID}`}
                                    onClick={() => onPlanClick(plan)}
                                  >
                                    <div className="list-left">
                                      <h3>
                                        {plan.mPriceDenomination} {formatPrice(plan.mPlanPrice)}{' '}
                                        {plan.mPlanOriginal && (
                                          <span>{formatPrice(plan.mPlanOriginal)}</span>
                                        )}{' '}
                                        / {plan.mPlanDuration.replace(/^(.*)ly$/g, '$1')}
                                      </h3>
                                      <p>Pay {plan.mPlanDuration.toLowerCase()}, cancel anytime</p>
                                    </div>
                                    {plan.isActive && (
                                      <span className="save-badge active-badge">Active</span>
                                    )}
                                    {plan.mPriceSaving && +plan.mPriceSaving !== 0 && (
                                      <span className="save-badge">
                                        Save {plan.mPriceDenomination} {plan.mPriceSaving}
                                      </span>
                                    )}
                                    {selectedPlanId &&
                                      selectedPlanId === plan.iMembershipPlanID && (
                                        <div className="right-check">
                                          <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                                        </div>
                                      )}
                                  </li>
                                );
                              })}
                              <li>
                              <div className="list-left">
                                <h3>
                                  {value == 1 ? 'UAE ' : value == 2 ? 'OMR ' : 'UAE '} 49.99 / Month
                                </h3>
                                <p>Pay monthly, cancel anytime</p>
                              </div>
                              <div className="right-check">
                                <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                              </div>
                            </li>
                            <li>
                              <div className="list-left">
                                <h3>
                                  {value == 1 ? 'UAE ' : value == 2 ? 'OMR ' : 'UAE '} 499.99 /
                                  Yearly{' '}
                                  <span className="save-badge">
                                    Save {value == 1 ? 'UAE ' : value == 2 ? 'OMR ' : 'UAE '} 100
                                  </span>
                                </h3>
                                <p>Pay ahead for a full year</p>
                              </div>
                            </li>
                            </ul>*/}
                            <MembershipPlanList />

                            <Button
                              type="secondary"
                              block
                              disabled={!selectedPlanId}
                              onClick={() => {
                                history.push('/membership/checkout', {
                                  from: location,
                                });
                              }}
                            >
                              Purchase
                            </Button>
                            <div className="text-center">
                              <CancelSubscriptionModal />
                            </div>
                            <div>
                              <Button
                                type="link"
                                block
                                onClick={() => {
                                  setIsPromoModalVisible(true);
                                }}
                              >
                                <SvgIcon name="promo-code" viewbox="0 0 14.611 10.163" /> Do you
                                have Promo code?
                              </Button>
                            </div>
                          </div>
                        </Parallax>
                      </div>
                    </div>
                  </Tilt>
                </Col>
              </Row>
            </ParallaxProvider>
          </Container>
        </section>
        <section className="statics-section">
          <Container>
            <ParallaxProvider>
              <Row>
                <Col sm="12">
                  <div className="statics-row">
                    <div className="card-list">
                      <div className="list-item" data-aos="fade-up" data-aos-duration="1200">
                        <div className="card-icon">
                          <SvgIcon name="categories-icon" viewbox="0 0 18.988 18.672" />
                        </div>
                        <div>
                          <h2>06</h2>
                          <p>Categories</p>
                          <hr />
                        </div>
                      </div>
                      <div className="list-item" data-aos="fade-down" data-aos-duration="1400">
                        <div className="card-icon">
                          <SvgIcon name="venues-icon" viewbox="0 0 19.144 18.234" />
                        </div>
                        <div>
                          <h2>3000+</h2>
                          <p>Venues</p>
                          <hr />
                        </div>
                      </div>
                      <div className="list-item" data-aos="fade-up" data-aos-duration="1600">
                        <div className="card-icon">
                          <SvgIcon name="unlimited-offers" viewbox="0 0 24.588 11.354" />
                        </div>
                        <div>
                          <h2>98%</h2>
                          <p>Unlimited offers</p>
                          <hr />
                        </div>
                      </div>
                    </div>
                    <h3 data-aos="fade-left" data-aos-duration="1600">
                      Unlimited savings with 2 for 1 & flat discounts up to 50% OFF
                    </h3>
                  </div>
                </Col>
              </Row>
            </ParallaxProvider>
          </Container>
        </section>
        <section className="download-section">
          <ParallaxProvider>
            <Container>
              <Row>
                <Col lg="6" className="download-left">
                  <Parallax speed={5} disabled={isMobile}>
                    <h1 data-aos="zoom-out" data-aos-duration="800">
                      Download the app and start today!
                    </h1>
                  </Parallax>
                  <Parallax speed={6} disabled={isMobile}>
                    <p data-aos="zoom-out" data-aos-duration="1600">
                      Get access to endless 2 for 1 deals and flat discounts at over 3000+ venues
                      across multiple categories and cities.
                    </p>
                  </Parallax>
                  <Parallax speed={4} disabled={isMobile}>
                    <div className="download-btns" data-aos="fade-in" data-aos-duration="1200">
                      <Button
                        onClick={() =>
                          window.open(
                            'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(
                            'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
                      </Button>
                      {/*<Button
                        onClick={() =>
                          window.open('https://appgallery.huawei.com/#/app/C100681983', '_blank')
                        }
                      >
                        <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
                      </Button>*/}
                    </div>
                  </Parallax>
                </Col>
                <Col lg="6" className="download-right">
                  <div className="right-inner">
                    <Parallax speed={20} disabled={isMobile}>
                      <div className="download-circle spinner"></div>
                    </Parallax>
                    <Parallax className="download-img-div web" scale={[0.3, 0.85]} speed={20}>
                      <img className="download-img" src={DownloadImg} alt={'Download'} />
                    </Parallax>
                    <div
                      className="download-img-div mobile"
                      data-aos="zoom-out"
                      data-aos-duration="1000"
                    >
                      <img className="download-img" src={DownloadImg} alt={'Download'} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ParallaxProvider>
        </section>
        {categories && categories.length > 0 && (
          <section className="explore-section">
            <Container>
              <Row>
                <Col sm="12">
                  <div>
                    <h2 className="heading text-left" data-aos="fade-in" data-aos-duration="1000">
                      Explore our <br /> amazing categories
                    </h2>
                    <ul className="explore-list">
                      {categories.map((item, index) => (
                        <li
                          key={item.iCategoryID}
                          data-aos="fade-up"
                          data-aos-duration={600 + index * 200}
                        >
                          <div className="explore-list-card">
                            <div className="explore-list-card-image">
                              <img
                                className="icon"
                                src={item.vCategoryMainImage}
                                alt={item.vCategoryName}
                              />
                            </div>
                            <div className="explore-caption">
                              <h3>{item.vCategoryName}</h3>
                              <p>{item.vCategoryDescription}</p>
                              <Button onClick={() => handleCategoryClick(item)}>
                                Explore <SvgIcon name="arrow-right" viewbox="0 0 24.603 7.838" />
                              </Button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        <section className="faq-section">
          <Container>
            <ParallaxProvider>
              <Row>
                <Col sm="5" className="faq-left">
                  <Parallax speed={16} disabled={isMobile}>
                    <h1>
                      Frequently <br /> Asked Questions
                    </h1>
                  </Parallax>
                  <Parallax speed={15} disabled={isMobile}>
                    <Card cover={<img alt="Explore" src={ExpImg} />}>
                      <div className="meta-row">
                        <p>
                          Discover a world <br /> of offers
                        </p>
                        <div className="venues-row">
                          <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
                          <h3>3,000+</h3>
                          <small>Venues</small>
                        </div>
                      </div>
                    </Card>
                  </Parallax>
                </Col>
                <Col sm="7" className="faq-right">
                  <Parallax speed={15} disabled={isMobile}>
                    <Collapse
                      accordion
                      defaultActiveKey={['1']}
                      expandIcon={({ isActive }) =>
                        isActive ? (
                          <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
                        ) : (
                          <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                        )
                      }
                    >
                      <Panel header="What is TravellerPass?" key="1">
                        <p>
                          TravellerPass is a mobile application that gives you access to over 3000+
                          venues in the UAE and Oman through unlimited 2 for 1 offers or flat
                          discounts across categories such as Dining, Experiences, Lifestyle,
                          Wellness and our luxury segement, Prive.
                        </p>
                      </Panel>
                      <Panel
                        header="Is there a limit to the number of redemption per offer?"
                        key="2"
                      >
                        <p>
                          95% of all our offers are unlimited, which means you get your discount as
                          many times as you want.
                        </p>
                      </Panel>
                      <Panel header="Where can I download TravellerPass?" key="3">
                        <p>
                          TravellerPass can be downloaded from the below stores:
                          <div className="download-btns">
                            <Button
                              onClick={() =>
                                window.open(
                                  'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                                  '_blank',
                                )
                              }
                            >
                              <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
                            </Button>
                            <Button
                              onClick={() =>
                                window.open(
                                  'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                                  '_blank',
                                )
                              }
                            >
                              <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
                            </Button>
                            {/*<Button
                              onClick={() =>
                                window.open(
                                  'https://appgallery.huawei.com/#/app/C100681983',
                                  '_blank',
                                )
                              }
                            >
                              <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
                            </Button>*/}
                          </div>
                        </p>
                      </Panel>
                      <Panel header="When can I start my membership with TravellerPass? " key="4">
                        <p>
                          You can purchase our Annual Bundle membership plan to get started right
                          away! What's more, you get all the days as promised based on the
                          membership you choose from the date of activation, so you can keep
                          availing our offers without any interruption.
                        </p>
                      </Panel>
                      <Panel header="How do I redeem my TravellerPass offer?" key="5">
                        <p>
                          Upon activating your membership, you can immediately begin enjoying
                          savings by exploring and selecting your preferred venue from the
                          comprehensive list available on the app.
                        </p>
                      </Panel>
                    </Collapse>
                  </Parallax>
                </Col>
              </Row>
            </ParallaxProvider>
          </Container>
        </section>
        <section className="letsjoin-section">
          <Container>
            <Row>
              <Col>
                <div className="letsjoin-row" data-aos="zoom-in" data-aos-duration="1200">
                  <div className="letsjoin-card">
                    <h3>
                      Join the <br /> party{' '}
                    </h3>
                    <SvgIcon name="themepark" viewbox="0 0 28.218 56.499" />
                    <p className="mb-0 upto-text">
                      <small>Up to</small>
                    </p>
                    <h2>50% OFF</h2>
                    <p>On Drinks</p>
                    <Button
                      onClick={() => {
                        const params = new URLSearchParams({
                          categoryId: 1,
                          subCategoryId: 4,
                        });
                        history.push({ pathname: '/listing', search: params.toString() });
                      }}
                    >
                      Explore
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="testomonial-section">
          <Container>
            <ParallaxProvider>
              <Row>
                <Col sm="12" className="mb-4">
                  <Parallax disabled={isMobile}>
                    <h1 className="heading mb-0">What they say</h1>
                    <p className="heading-p-tag">*App Store Reviews</p>
                  </Parallax>
                </Col>
                <Col sm="12">
                  <Slider className="testomonial-slider" {...settingsClients}>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Great App lots of choices I’ve been using this app for about 1 year
                              and I will give thumbs up and 10/10 points to this app because I have
                              lot of choices and variety of options and the best thing is unlimited
                              use of the vouchers which no other app gives …. So, download this app
                              and enjoy
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Pavlina</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg2}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Worth it! <br />
                              Highly recommend this app over most available in the market today. I
                              have been using it for 3 years now and am super happy with my savings.
                              The new interface of the app makes it easy to navigate and find the
                              best options close to you!
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Karthik</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg3}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Excellent customer service <br />
                              Had an issue with one of the restaurants while using TravellerPass.
                              TravellerPass resolved this through their persistent follow-up with
                              the restaurant. The restaurant called and even offered a free dish.
                              Five-star customer service indeed from TravellerPass.
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Marvina</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg4}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Great Experience <br />
                              Have been using this app over last year and a half; amazing
                              experience, covers all the major outlet in Dubai with amazing deals in
                              all. Strongly recommend everyone to download the app and open up the
                              great offers….
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Jamie</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg5}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Awesome! <br />
                              It’s easy to use and very handy. It helps a lot to save more. Worth
                              every penny😍
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Nikol</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </Col>
              </Row>
            </ParallaxProvider>
          </Container>
        </section>
        <section className="social-section">
          <div className="travellerPass-icon" data-aos="fade-right" data-aos-duration="900">
            <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
          </div>
          <Container>
            <Row>
              <Col>
                <img
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className="logo-icon"
                  src={LogoIcon}
                  alt="TravellerPass"
                />
                <h1 data-aos="fade-up" data-aos-duration="1100">
                  Discover the world like never before <br /> with TravellerPass
                </h1>
                <div className="small-card" data-aos="fade-up" data-aos-duration="1200">
                  <div className="left-img" onClick={() => setShowHowItWorks(true)}>
                    <div className="overlay-play">
                      <SvgIcon name="play-alt" viewbox="0 0 30.001 30" />
                    </div>
                    <img src={DiscoverImageSmall} alt="TravellerPass" />
                  </div>
                  <div>
                    <h3>Welcome</h3>
                    <p>A whole new experience to TravellerPass</p>
                  </div>
                </div>
                <ul data-aos="fade-up" data-aos-duration="1400">
                  {/*<li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="dribbble" viewbox="0 0 17.206 17.206" />
                  </Button>
                </li>
                  <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                  </Button>
                </li>*/}
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://www.tiktok.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://medium.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <ModalVideo
        isOpen={showHowItWorks}
        channel="custom"
        url={HowItWorks}
        onClose={() => setShowHowItWorks(false)}
      />
      <PromoModal
        isOpen={isPromoModalVisible}
        onClose={() => {
          setIsPromoModalVisible(false);
        }}
        onApply={() => {
          history.push('/membership/checkout', {
            from: location,
          });
          setIsPromoModalVisible(false);
        }}
      />
    </>
  );
};

export default BuyMembershipHome;
