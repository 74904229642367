import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import './index.scss';

import successBg from '../../assets/images/success-bg.svg';

const PrivePaymentSuccessful = () => {
  return (
    <div className="checkout-wrapper prive-mode">
      <Container>
        <Row>
          <Col>
            <div className="twl-checkout-success">
              <img src={successBg} alt="Success" data-aos="fade-in" data-aos-duration="1000" />
              <div className="success-check" data-aos="zoom-in" data-aos-duration="1000">
                <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
              </div>
              <h2 data-aos="fade-up" data-aos-duration="800">
                Congratulations
              </h2>
              <p data-aos="fade-up" data-aos-duration="1000">
                Your redemption is successful!
              </p>
              <div className="discount">
                <p>🤗 Discount</p>
                <h3>AED 30.00! </h3>
              </div>
              <p data-aos="fade-up" data-aos-duration="1200">
                Transaction ID
              </p>
              <h2 data-aos="fade-up" data-aos-duration="1400">
                43645272556
              </h2>
              <div className="mt-4 pt-2" data-aos="fade-up" data-aos-duration="1600">
                <Link to="/">
                  <Button type="secondary">I’m Done</Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivePaymentSuccessful;
