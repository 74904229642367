// checkedItemsSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../apiConfig';
import { LoadState } from '../constants';

const fetchData = async (state, firstPart) => {
  const { selectedCity } = state.countryCity;
  const { user, latLng } = state.user;
  if (!selectedCity) {
    throw Error('City not selected.');
  }

  let url1 = user ? '/offer/featuredOfferList' : '/guestoffer/featuredOfferList';
  let url2 = user ? '/offer/featuredOfferList2' : '/guestoffer/featuredOfferList2';

  console.log(latLng);

  const payload = {
    iCountryID: selectedCity.iCountryID,
    dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
    dCurrentLong: latLng?.lan || selectedCity.vCityLongitude,
    vCityName: selectedCity.vCityName,
    iCityID: selectedCity.iCityID,
    Language: 'en',
  };
  const response = firstPart
    ? await apiClient.post(url1, payload)
    : await apiClient.post(url2, payload);
  if (response.data.status !== 1) {
    throw Error(`${url1} received non zero status.`);
  }

  return response.data;
};

export const getHomePageData = createAsyncThunk('fetch/home', async (arg, thunkAPI) => {
  return fetchData(thunkAPI.getState(), true);
});

export const getHomePageDataNext = createAsyncThunk('fetch/home2', async (arg, thunkAPI) => {
  return fetchData(thunkAPI.getState(), false);
});

export const homeSlice = createSlice({
  name: 'home',
  initialState: {
    headerImage: [],
    categories: [],
    handpicked: {},
    trending: {},
    monthly: {},
    topBrandLogos: [],
    state: LoadState.IDLE,
    state2: LoadState.IDLE,
  },
  reducers: {
    toggleFav: (state, action) => {
      const { iBrandID, iOutletID, iDealID, isPrive, isFavouriteOffer } = action.payload;

      if (state.trending.items) {
        for (let i = 0; i < state.trending.items.length; i++) {
          if (
            state.trending.items[i].iBrandID === iBrandID &&
            state.trending.items[i].iDealID === iDealID &&
            state.trending.items[i].iOutletID === iOutletID
          ) {
            state.trending.items[i] = {
              ...state.trending.items[i],
              isFavouriteOffer: isFavouriteOffer,
            };
          }
        }
      }

      if (state.monthly.items) {
        for (let i = 0; i < state.monthly.items.length; i++) {
          if (
            state.monthly.items[i].iBrandID === iBrandID &&
            state.monthly.items[i].iDealID === iDealID &&
            state.monthly.items[i].iOutletID === iOutletID
          ) {
            state.monthly.items[i] = {
              ...state.monthly.items[i],
              isFavouriteOffer: isFavouriteOffer,
            };
          }
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHomePageData.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getHomePageData.fulfilled, (state, action) => {
      const dataArr = action.payload.DATA;
      dataArr.forEach((item) => {
        if (item.type === 'Header Banner') {
          state.headerImage = item.DATA.hBannerImage || [];
        } else if (item.type === 'Category') {
          state.categories = item.DATA.categorylist || [];
        } else if (item.type === 'Handpicked') {
          state.handpicked = { title: item.Title, items: item.DATA.Handpicked || [] };
        }
      });
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getHomePageData.rejected, (state, action) => {
      console.error('getHomePageData failed', action.error);
      state.state = LoadState.ERROR;
    });

    builder.addCase(getHomePageDataNext.pending, (state, action) => {
      state.state2 = LoadState.LOADING;
    });
    builder.addCase(getHomePageDataNext.fulfilled, (state, action) => {
      const dataArr = action.payload.DATA;
      dataArr.forEach((item) => {
        if (item.type === 'Trending') {
          state.trending = { title: item.Title, items: item.DATA.trendingOffers || [] };
        } else if (item.type === 'Monthly') {
          state.monthly = { title: item.Title, items: item.DATA.monthlyOffers || [] };
        } else if (item.type === 'Top Brands') {
          state.topBrandLogos = []; //item.topBrands;
        }
      });
      state.state2 = LoadState.SUCCESS;
    });

    builder.addCase(getHomePageDataNext.rejected, (state, action) => {
      console.error('getHomePageDataNext failed', action.error);
      state.state2 = LoadState.ERROR;
    });
  },
});

export const { toggleFav } = homeSlice.actions;
export default homeSlice.reducer;
