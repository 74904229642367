import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { SvgIcon } from '../../../components/common';
import './index.scss';

import logoImage from '../../../assets/images/logo.svg';
import PersonImg1 from '../../../assets/images/inquiries-person1.png';
import PersonImg2 from '../../../assets/images/inquiries-person2.png';
import PersonImg3 from '../../../assets/images/inquiries-person3.png';
import TwoForOne from '../../../assets/images/twoforone.png';

const { TextArea } = Input;

const StartGetListedOfferDetailsOne = () => {
  const history = useHistory();
  return (
    <div className="getlistedform-alt-wrapper">
      <div className="getlistedform-left">
        <div className="getlistedform-inner">
          <div>
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>What kind of offers will you be listing?</h1>
          </div>
          <div>
            <p>
              <small>WHY NEED IT?</small>
            </p>
            <p>So that we know what offers we can push to our audience.</p>
          </div>
        </div>
      </div>
      <div className="getlistedform-right">
        <Form name="basic" autoComplete="off" layout="vertical">
          <div className="getlistedform-inner">
            <div className="right-upper">
              <div className="upper-inner">
                <Button className="help-btn" type="text">
                  Help?
                </Button>
                <ul>
                  <li>
                    <img src={PersonImg1} alt={PersonImg1} />
                  </li>
                  <li>
                    <img src={PersonImg2} alt={PersonImg2} />
                  </li>
                  <li>
                    <img src={PersonImg3} alt={PersonImg3} />
                  </li>
                </ul>
                <Button
                  type="default"
                  className="auth-upper-btn"
                  onClick={() => history.push('/get-listed')}
                >
                  Save & Exit
                </Button>
              </div>
            </div>
            <div className="right-form">
              <div className="contact-form" data-aos="fade-in" data-aos-duration="800">
                <div className="offer-upper">
                  <div className="offerlist-img">
                    <img src={TwoForOne} alt={'2 for 1'} />
                  </div>
                  <h3>2 for 1 </h3>
                  <h1>Offer Details</h1>
                </div>
                <Form.Item label="Offer Details">
                  <Input placeholder="Example : 2 for 1" />
                </Form.Item>
                <Form.Item label="Terms & Conditions">
                  <TextArea rows={5} placeholder="Enter" />
                </Form.Item>
              </div>
            </div>
            <div className="getlistedform-right-footer">
              <div className="back-left">
                <Link to="/get-listed-onboarding-offer">Back</Link>
              </div>
              <Button
                type="secondary"
                htmlType="submit"
                className="with-icon"
                icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                onClick={() => history.push('/get-listed-onboarding-offer-details2')}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default StartGetListedOfferDetailsOne;
