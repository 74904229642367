import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, FloatLabel, Row, SvgIcon } from '../../../components/common';
import { Button, Input, Tabs } from 'antd';
import PayWithCard from './PayWithCard';
import '../../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import MembershipIdModal from './MembershipIdModal';
import ApplePay from './ApplePay';
import GooglePay from './GooglePay';

import EnqPeople1 from '../../../assets/images/inquiries-person1.png';
import EnqPeople2 from '../../../assets/images/inquiries-person2.png';
import EnqPeople3 from '../../../assets/images/inquiries-person3.png';
import { useDispatch } from 'react-redux';
import { useMembershipSelector, useUserSelector } from '../../../store/selectors';
import { useLoaderContext } from '../../../components/common/Loader';
import { formatPrice } from '../../../utility/formatter';
import MembershipPlanList from '../../../components/common/MembershipPlanList';
import { removePromoCode, setPromoCode } from '../../../slice/membershipSlice';
import { Alert } from '../../../common';
import { ReactComponent as Valid } from '../../../assets/images/valid.svg';
import { ReactComponent as Invalid } from '../../../assets/images/invalid.svg';
import { apiClient } from '../../../apiConfig';
import PaymentComponent from './PaymentComponent';
import { setUser } from '../../../slice/userSlice';
import { LoadState } from '../../../constants';
import { logEvent } from '../../../utility/analytics';

const PaywithCardTitle = (
  <div className="options-card" data-aos="zoom-in" data-aos-duration="800">
    <div className="optionscard-inner">
      <SvgIcon name="card" viewbox="0 0 28 19.087" />
    </div>
    <p>Pay with Card</p>
  </div>
);

const ApplePayTitle = (
  <div className="options-card" data-aos="zoom-in" data-aos-duration="1000">
    <div className="optionscard-inner">
      <SvgIcon name="apple-pay" viewbox="0 0 38.937 16.118" />
    </div>
    <p>Apple Pay</p>
  </div>
);

const GooglePayTitle = (
  <div className="options-card" data-aos="zoom-in" data-aos-duration="1200">
    <div className="optionscard-inner">
      <SvgIcon name="gpay" viewbox="0 0 71.72 28.49" />
    </div>
    <p>Google Pay</p>
  </div>
);

const BuyMembershipCheckout = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const { from } = location.state || {};

  const { user } = useUserSelector();
  const { plans, selectedPlanId, promo, state } = useMembershipSelector();
  const [isChecking, setIsChecking] = useState(false);
  const { setShowLoader } = useLoaderContext();

  const [promoState, setPromoState] = useState('');
  const [promoCodeValue, setPromoCodeValueValue] = useState(promo?.code || '');
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [stripePaymentData, setStripePaymentData] = useState();

  const selectedPlan = useMemo(() => {
    return plans.find((plan) => plan.iMembershipPlanID === selectedPlanId);
  }, [plans, selectedPlanId]);

  const checkPromoCode = async () => {
    try {
      setIsChecking(true);
      const response = await apiClient.post('/user/checkPromoCode', {
        vPromoCode: promoCodeValue,
      });
      const data = response.data.DATA;
      if (response.data.status === 1 && data && data.iMembershipPlanID.length > 0) {
        dispatch(setPromoCode({ ...data, code: promoCodeValue }));
        setPromoState('valid');
      } else {
        if (response.data.MESSAGE) {
          Alert.error(response.data.MESSAGE);
        }
        setPromoState('invalid');
      }
    } catch (error) {
      console.error('Check promo code error', error);
      Alert.error('Oops! Something went wrong.');
    } finally {
      setIsChecking(false);
    }
  };

  const handlePurchase = async () => {
    try {
      setShowLoader(true);
      const payload = {
        iMembershipPlanID: selectedPlan.iMembershipPlanID,
        vPromoCode: promoCodeValue,
        amount: '0',
        duration: '0',
      };
      const response = await apiClient.post('user/updatePaymentInfoPrepare', payload);
      const data = response.data.DATA;
      if (response.data.status === 1 && data) {
        setShowStripeModal(true);
        setStripePaymentData(data.stripeinterceptor);
      }
    } catch (error) {
      console.error(error);
      Alert.error('Oops! Something went wrong.');
    } finally {
      setShowLoader(false);
    }
  };

  const handlePaymentSuccess = async (id) => {
    console.log('handlePaymentSuccess', id);
    try {
      setShowLoader(true);
      const payload = {
        iMembershipPlanID: selectedPlan.iMembershipPlanID,
        vPromoCode: promoCodeValue,
        amount: '0',
        duration: '0',
        pay_key: id,
        payment_exec_status: 'completed',
      };
      const response = await apiClient.post('user/updateCKOUTPaymentInfo', payload);
      if (response.data.status === 1) {
        logEvent('purchase', { name: 'Successful Membership Purchase' });
        if (user) {
          dispatch(
            setUser({
              ...user,
              iMembershipCode: response.data.MembershipCode,
              iMembershipPlanID: selectedPlan.iMembershipPlanID,
              dExpiredDate: response.data.dExpiredDate,
            }),
          );
        }
        Alert.success(response.data.DATA);
        history.replace('/membership/checkout/success', {
          iMembershipCode: response.data.MembershipCode,
        });
      } else {
        Alert.error('Oops! Something went wrong.');
      }
    } catch (e) {
      console.error(e);
      Alert.error('Oops! Something went wrong.');
    } finally {
      setShowLoader(false);
    }
  };
  useEffect(() => {
    setShowLoader(isChecking);
  }, [isChecking]);

  useEffect(() => {
    logEvent('begin_checkout', { name: 'Start Buy Membership Purchase Screen' });
  }, []);

  useEffect(() => {
    if (showStripeModal && stripePaymentData) {
      logEvent('add_payment_info', { name: 'Buy Membership Checkout Screen' });
    }
  }, [showStripeModal && stripePaymentData]);

  if (!from || from.pathname !== '/membership/buy' || state !== LoadState.SUCCESS) {
    return <Redirect to="/membership/buy" />;
  }
  return (
    <>
      <div className="buymembershicheckout-wrapper">
        <Container>
          <Row>
            <Col>
              <div className="bmc-row">
                <div className="bmc-card checkout-left">
                  <div className="bmc-header" data-aos="fade-in" data-aos-duration="800">
                    Checkout
                  </div>
                  <div className="bmc-content">
                    <div className="bmccontent-inner">
                      <h3 className="inner-title">Payment options</h3>
                      <Tabs defaultActiveKey="1" className="payment-tabs">
                        <Tabs.TabPane tab={PaywithCardTitle} key="1">
                          <PayWithCard />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={ApplePayTitle} key="2">
                          <ApplePay />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab={GooglePayTitle} key="3">
                          <GooglePay />
                        </Tabs.TabPane>
                      </Tabs>
                    </div>
                  </div>
                </div>
                <div className="bmc-card checkout-right">
                  <div className="bmc-header" data-aos="fade-in" data-aos-duration="800">
                    Summary
                  </div>
                  <div className="bmc-content">
                    <div className="bmccontent-inner">
                      <MembershipPlanList />
                      <div className="promocode-row" data-aos="fade-left" data-aos-duration="1000">
                        <div className="promocode-colum">
                          <FloatLabel
                            label="Got a promo code?"
                            name="workemail"
                            value={promoCodeValue}
                          >
                            <Input
                              value={promoCodeValue}
                              onChange={(e) => {
                                setPromoCodeValueValue((e.target.value || '').trim().toUpperCase());
                                if (promo?.code) {
                                  dispatch(removePromoCode());
                                  setPromoState('');
                                }
                              }}
                              prefix={<SvgIcon name="promo-code" viewbox="0 0 14.611 10.163" />}
                            />
                          </FloatLabel>
                          <Button
                            type="primary"
                            disabled={promoCodeValue.length < 4 || isChecking}
                            onClick={checkPromoCode}
                          >
                            Check
                          </Button>
                        </div>
                        <div className="valid-invalid-row">
                          {promoState === 'valid' && (
                            <>
                              <Valid />
                              <span>Code is valid!</span>
                            </>
                          )}
                          {promoState === 'invalid' && (
                            <>
                              <Invalid />
                              <span>Code is invalid!</span>
                            </>
                          )}
                        </div>
                      </div>
                      {selectedPlan && (
                        <div className="payment-details">
                          {(selectedPlan.mPlanPromoDiscount || 0) > 0 && (
                            <Row className="discount-row">
                              <Col>Promo Discount</Col>
                              <Col className="text-right">
                                {formatPrice(selectedPlan.mPlanPromoDiscount)}!
                              </Col>
                            </Row>
                          )}
                          <Row className="willpay-row">
                            <Col>You Will Pay</Col>
                            <Col className="text-right">
                              {selectedPlan.mPriceDenomination}{' '}
                              {formatPrice(selectedPlan.mPlanPrice)}
                            </Col>
                          </Row>
                        </div>
                      )}
                      <Button
                        data-aos="fade-left"
                        data-aos-duration="1250"
                        type="primary"
                        block
                        disabled={!selectedPlan}
                        onClick={handlePurchase}
                      >
                        Purchase
                      </Button>
                      <MembershipIdModal />
                      <div className="getit-touch" data-aos="fade-in" data-aos-duration="1400">
                        <div className="upper-row">
                          <h3>Get in touch</h3>
                          <div className="enq-people-list">
                            <img src={EnqPeople1} alt="Get in touch" />
                            <img src={EnqPeople2} alt="Get in touch" />
                            <img src={EnqPeople3} alt="Get in touch" />
                          </div>
                        </div>
                        <p>
                          If you have any doubt please reach Out us{' '}
                          <Link to="/">@ info@imcholding.com</Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {showStripeModal && stripePaymentData && (
        <PaymentComponent
          setShowStripeModal={setShowStripeModal}
          paymentIntent={stripePaymentData.payment_intent}
          handlePaymentSuccess={handlePaymentSuccess}
        />
      )}
    </>
  );
};

export default BuyMembershipCheckout;
