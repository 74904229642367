import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const NBTermsandConditions = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    NBF Rewards
                  </h1>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Terms and Conditions
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/nbf-privacy-policy">
                    Privacy Policy{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  National Bank of Fujairah PJSC (“NBF”) provides discounted offers and ‘Buy one get
                  one free’ offers (“NBF Rewards” or the “Offers”) at participating retailers via
                  the NBF Rewards Mobile Application (the “Mobile App”). The Offers are managed by a
                  third party, known as TravellerPass LLC (“TravellerPass”) through a product called
                  TravellerPass.
                  <br />
                  <br />
                  NBF Rewards can only be availed for offer redemption by NBF cardholders and NBF
                  regular customers for exclusive offers. NBF card customers (NBF Preferred Banking
                  /NBF Ajyal) and regular customers must use the Mobile App and a valid NBF card to
                  avail the offer. NBF assumes no liability or responsibility with respect to the
                  products or services provided by any of the partners on the program.
                  <br />
                  <br />
                  The Mobile App lists the catalogue of Preferred Partners and their Offers (as
                  defined below) which are available to customers. The Mobile App’s terms and
                  conditions will apply to NBF Rewards. NBF customers will activate their account by
                  entering their name, mobile number, email address, the first four and last four
                  digits of their card number, all of which is registered with NBF. Once activated,
                  customers can set up a username and password for their account.
                  <br />
                  <br />
                  Once the registration process is completed on the Mobile App, customers will have
                  access to all offers. NBF Rewards and privileges may be revoked at the sole
                  discretion of NBF at any time with or without notice to customers.
                </p>
              </div>
              <div className="content-row">
                <h3>Preferred Partners</h3>
                <p>
                  Preferred Partners are those retailers/partners in the UAE where the NBF Rewards
                  can be redeemed using an NBF card. The Preferred Partners and their respective
                  offers are listed in the Mobile App. The Offers and the countries where the offers
                  are allowed are subject to change from time to time. Offers may be modified or
                  discontinued at any time without notice.
                  <br />
                  <br />
                  In regards to the buy one get one free offer, the partner merchant may apply
                  either as a deduction of the cost of one least expensive main course or main menu
                  item (when two or more main courses or main menu items are ordered) or may apply
                  as a discount on the bill for the offers which come with a discount.
                </p>
              </div>

              <div className="content-row">
                <h3>Offer Redemption</h3>
                <p>
                  The Offers can be redeemed at Preferred Partners only when the purchase is made
                  using an NBF card. In order to avail the Offer, the Card Customer will have to: i)
                  Present the Mobile App voucher to the Preferred Partner prior to requesting for
                  the bill; and ii) pay the remaining balance for the service/meal using an NBF
                  card.
                  <br />
                  All NBF Cards Terms and Conditions will apply.
                </p>
                <ol>
                  <li>A valid NBF card must be presented for payment of the offer.</li>
                  <li>
                    Card Customers will receive the Offers at Preferred Partners on the total amount
                    of the bill minus any tax or service charges.
                  </li>
                  <li>
                    The Offers can only be claimed against purchases made at Preferred Partners but
                    cannot be paid in cash or using any other payment or loyalty card or by any such
                    combination with any other promotion or offer.
                  </li>
                  <li>
                    The NBF Rewards Mobile App must be presented at the point of purchase to redeem
                    the Offer. A Preferred Partner has the right to deny the discount if the Mobile
                    App is not presented while requesting for the bill.
                  </li>
                  <li>
                    The use of Offers within the Mobile App in conjunction with any other publicly
                    announced promotions or reductions offered is at the discretion of the Preferred
                    Partners. The Offers will not be valid on festival days or as per the terms and
                    conditions offered by the Preferred Partner. The terms and conditions of each
                    offer is updated on the Mobile App against each Preferred Partner.
                  </li>
                  <li>
                    Card Customers need to take note of Offer validity dates and special offer terms
                    and conditions which will be mentioned along with the “Offer”.
                  </li>
                </ol>
              </div>
              <div className="content-row">
                <h3>NBF Rewards Validity</h3>
                <p>
                  NBF has the right to terminate NBF Rewards at any time without reason or notice
                  and will not be liable for any loss or damage that may arise from any suspension
                  or termination of the Offers. NBF reserves the right to withdraw the privileges of
                  the NBF Rewards if any Card Customer is in breach of the terms and conditions of
                  NBF Rewards.
                </p>
              </div>
              <div className="content-row">
                <h3>Disputes on Discount</h3>
                <p>
                  If a Preferred Partner does not honor the valid Offer, the instance can be
                  reported within two working days by connecting with TravellerPass through the
                  Mobile App in the “Need Help” section during office hours (from 12PM – 10PM, on
                  all days) or via email to{' '}
                  <a href="mailto:concierge@travellerpass.com">concierge@travellerpass.com</a>.
                  <br />
                  The Customer must provide complete details to TravellerPass about the merchant’s
                  name and location, date of purchase, discount denied and purchase information. If
                  the Customer has a dispute on the discount, TravellerPass will take reasonable
                  efforts to resolve the dispute.
                </p>
              </div>
              <div className="content-row">
                <h3>Additional Terms and Conditions</h3>
                <ol type="a">
                  <li>
                    The Offers are subject to the prescribed NBF Terms and Conditions and the Terms
                    and Conditions of TravellerPass. NBF reserves the right to amend these terms and
                    conditions at any time or withdraw this Offer at any time without any prior
                    notice. By availing the Offer, the customer accepts and agrees to be bound by
                    the terms and conditions.
                  </li>
                  <li>
                    NBF assumes no responsibility with respect to the services provided by the
                    service partner TravellerPass.
                  </li>
                  <li>
                    Customer understands and acknowledges that the NBF Rewards is a service offered
                    by NBF and it will be performed by TravellerPass. TravellerPass and NBF are
                    independent contractors to each other and NBF will have no liability to the
                    Customer for any claims which the Customer may have against TravellerPass for
                    services rendered by it to the Customer.
                  </li>
                  <li>
                    NBF will not be liable for any errors, omissions, delays, or disruptions in the
                    operation of the NBF Rewards.
                  </li>
                  <li>
                    NBF or TravellerPass may amend the Offers or add/remove Preferred Partners from
                    the program at any time.
                  </li>
                  <li>
                    Preferred Partners may exclude products or services from being eligible for the
                    Offers without prior consent or notice. At certain Preferred Partners, Offers
                    may be subject to availability and may be altered or withdrawn without notice.
                  </li>
                  <li>
                    NBF is not responsible if a Preferred Partner changes its discount due to new
                    ownership or acquisition.
                  </li>
                  <li>
                    NBF will not be liable in any way if any participating Preferred Partner fails
                    to provide the Offer on any product. NBF does not accept any liability in
                    connection with the failure or refusal of any participating Preferred Partner to
                    provide products. NBF does not accept any liability or responsibility for the
                    quality or suitability of products offered by the Preferred Partners.
                  </li>
                  <li>
                    No Preferred Partner has any authority, express or implied to make a
                    representation, warranty, or statement on behalf of NBF or in relation to NBF
                    Rewards and NBF accepts no liability in respect of any representation, warranty
                    or statement made by any Preferred Partner.
                  </li>
                  <li>
                    Customers acknowledge that Preferred Partners (not NBF) will supply products and
                    services treatments. Accordingly, NBF does not give any guarantee, undertaking
                    or warranty concerning the products or services supplied or the performance of
                    any participating Preferred Partner. All conditions and warranties whether
                    express or implied and whether arising under statute or otherwise as to the
                    condition, suitability, quality, fitness or safety of any goods or services
                    supplied by any Preferred Partner are expressly excluded.
                  </li>
                  <li>
                    The NBF Rewards may be amended, withdrawn, or replaced at any time without
                    notice.
                  </li>
                  <li>
                    NBF is not liable in case of any dissatisfaction about delivery, service, or
                    quality of the NBF Rewards.
                  </li>
                  <li>
                    NBF and TravellerPass’s interpretation of these Terms and Conditions is final
                    and binding.
                  </li>
                </ol>
              </div>
              <div className="content-row">
                <h3>NBF Rewards Mobile App Terms and Conditions:</h3>
                <ol>
                  <li>
                    Customer hereby consent, understand and acknowledge that National Bank of
                    Fujairah PJSC (“NBF”) will and hereby authorized to disclose his/her data to an
                    external vendor for providing this service and that NBF will not be liable or
                    responsible for any issues, claims, or actions that may arise therefrom.
                  </li>
                  <li>
                    You may be charged by your service provider for downloading and/or accessing the
                    Mobile App on your mobile phone. These may include data roaming charges if you
                    do this outside your home territory. All these charges are solely your
                    responsibility.
                  </li>
                  <li>
                    Certain functionalities of the Mobile App may need to access your device and
                    information stored on it so that it works properly. By using the Mobile App, you
                    consent to allow the Mobile App access to your information requested to proving
                    this Service.
                  </li>
                  <li>TravellerPass reserves the right to make changes to the Mobile App.</li>
                  <li>
                    You must not use the Mobile App for any of the following purposes:(a) anything
                    unlawful or illegal or which is fraudulent or malicious or which promotes any
                    criminal activity or provides information about the same;(b) anything which is
                    defamatory, harassing or threatening or which otherwise infringes or violates
                    the rights of others. This includes any information that you may add or upload
                    to the Mobile App;(c) interfering in any way with any other user of the Mobile
                    App; and/or(d) knowingly introducing viruses or other malicious or harmful
                    material or using it in connection with unsolicited communications. The Mobile
                    App and all copyright, database rights, trademarks and other intellectual
                    property rights related to it belong to us or our licensors.
                  </li>
                  <li>
                    Access to the Mobile App may be suspended temporarily from time to time and
                    without notice in the case of system failure, maintenance, or repair or for any
                    reason beyond our control or if we deem it necessary. For the avoidance of
                    doubt, we shall not be liable for any loss or liability which may be suffered or
                    incurred by you because of any suspension of, or interruption to, the operation
                    of the Mobile App.
                  </li>
                  <li>
                    The Mobile App, including all content on or available through the Mobile App, is
                    provided on an ‘as is’ basis and we do not make any representation or give any
                    warranty in respect of the Mobile App or any of its contents. But without
                    limitation, we do not give any warranty as to the accuracy, suitability,
                    reliability, completeness, performance, fitness, freedom from viruses or
                    timeliness of the content contained on the App.
                  </li>
                  <li>
                    The Mobile App will only work on compatible devices and operating systems and
                    certain functionalities will only work if your device is connected to the
                    internet. Some features may also only be available with certain operating
                    systems.
                  </li>
                </ol>
              </div>
              <div className="content-row">
                <h3>NBF and TravellerPass do not accept any responsibility for:</h3>
                <ol type="a">
                  <li>
                    malfunctions in communication facilities which cannot reasonably be considered
                    to be under our control and that may affect the accuracy or timeliness of
                    messages you send or the material you access via the Mobile App
                  </li>
                  <li>
                    any losses or delays in transmission of messages or material you access arising
                    out of the use of any internet access service provider or mobile network service
                    provider or caused by any browser or other software which is not under our
                    control.
                  </li>
                  <li>
                    Viruses that may infect your computer equipment or other property on account of
                    your access to or use of the Mobile App or your accessing any materials on the
                    Mobile App
                  </li>
                  <li>
                    any unauthorized use or interception of any message or information before it
                    reaches the Mobile App or our servers from the Mobile App.
                  </li>
                  <li>
                    any unauthorized use of or access to data relating to you or your transactions
                    which is held by us (unless such use or access is caused by our negligence,
                    fraud or failure to comply with laws relating to the protection of your data),
                    to the extent permitted by local law
                  </li>
                  <li>any content provided by third parties</li>
                </ol>
              </div>
              <div className="content-row">
                <p>
                  In addition, both NBF and TravellerPass’s officers, directors, employees,
                  shareholders or agents, exclude all liability and responsibility for any amount or
                  kind of loss or damage that may result to you or a third party (including without
                  limitation, any direct, indirect, punitive or consequential loss or damages, or
                  any loss of income, profits, goodwill, data, contracts, use of money, or loss or
                  damages arising from or connected in any way to business interruption, and whether
                  in tort (including without limitation negligence), contract or otherwise) in any
                  way or in connection with:
                </p>
                <ol type="a">
                  <li>
                    the Mobile App and your use, delay in use or inability to use the App or if it
                    does not work as you expect or would like or if it contains any defects or
                    errors and whether or not we correct any of those defects or errors
                  </li>
                  <li>
                    your access to and use of any material on the Mobile App or material linked to
                    or referred to on the Mobile App; and/or
                  </li>
                  <li>
                    any loss or damage to your device or any other hardware or software you use in
                    connection with the Mobile App, including in connection with any viruses that
                    may affect the same on account of your downloading and use of the Mobile App.
                  </li>
                </ol>
                <p>
                  You acknowledge that the use of the Mobile App is dependent on third parties,
                  including your own network providers, and that we are not liable for any acts or
                  omissions of those third parties. These Mobile App Terms and Conditions and any
                  dispute or claim arising out of or in connection with them or their subject matter
                  (including any non-contractual disputes or claims) will be governed by the law of
                  the United Arab Emirates and the parties agree that the courts of the United Arab
                  Emirates will have exclusive jurisdiction to settle any such disputes or claims.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default NBTermsandConditions;
