import { Button, Card } from 'antd';
import { SvgIcon } from '../index';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { isArray } from 'lodash';
import './index.scss';
import { useDispatch } from 'react-redux';
import { apiClient, priveApiClient } from '../../../apiConfig';
import { Alert } from '../../../common';
import { toggleFavourite } from '../../../slice/globalActions';
import store from '../../../store';

export default function DealCard({ item, onFavouriteClick }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const imageUrl = useMemo(() => {
    if (
      item.vFeaturedImage &&
      item.vFeaturedImage.startsWith('https://') &&
      !item.vFeaturedImage.endsWith('/image.jpg')
    ) {
      return item.vFeaturedImage;
    }
    if (item.vDealImage) {
      return item.vDealImage;
    }
    return item.deal_images;
  }, [item]);

  const distance = useMemo(() => {
    if (item.min_dist) {
      return item.min_dist;
    }
    if (item.distance) {
      return item.distance;
    }
    if (isArray(item.Distance) && item.Distance.length > 0) {
      return item.Distance[0].distance;
    }
  }, [item]);

  const areaName = useMemo(() => {
    if (item.vAreaName) {
      return item.vAreaName;
    }
    if (isArray(item.Distance) && item.Distance.length > 0) {
      return item.Distance[0].vAreaName;
    }
  }, [item]);

  const offerTag = useMemo(() => {
    let discount = item.iPercentageDiscount;
    if (discount) {
      discount = discount.replace(/\s+/g, '');
    }

    if (discount === 'SpecialOffer') {
      return 'Special Offer';
    } else if (discount === '2for1') {
      return '2 For 1';
    } else if (isFinite(+discount)) {
      return `${discount}% Off`;
    }
    return '';
  }, [item]);

  const handleFavouriteToggle = async () => {
    const { user } = store.getState().user;

    if (!user) {
      history.push('/login', {
        redirect: location,
      });
      Alert.error('Please login to add deal to your favourites.');
      return;
    }
    try {
      let outletId = item.iOutletID;
      if (!outletId && isArray(item.Distance) && item.Distance.length > 0) {
        outletId = item.Distance[0].iOutletID;
      }

      const payload = {
        iDealID: item.iDealID,
        iMerchantId: item.iBrandID,
        vOutletID: outletId,
        favourite: !item.isFavouriteOffer,
      };

      if (onFavouriteClick) {
        onFavouriteClick({
          iBrandID: item.iBrandID,
          iOutletID: item.iOutletID,
          iDealID: item.iDealID,
          isFavouriteOffer: !item.isFavouriteOffer,
          ...(item.isPrive === 'Yes' && { isPrive: 'Yes' }),
        });
      }

      dispatch(
        toggleFavourite({
          iBrandID: item.iBrandID,
          iOutletID: item.iOutletID,
          iDealID: item.iDealID,
          isFavouriteOffer: !item.isFavouriteOffer,
          ...(item.isPrive === 'Yes' && { isPrive: 'Yes' }),
        }),
      );

      if (item.isPrive === 'Yes') {
        const response = await priveApiClient.post('offer/fevouriteOffer', payload);
        Alert.success(response.data.MESSAGE);
      } else {
        const response = await apiClient.post('offer/fevouriteOffer', payload);
        Alert.success(response.data.MESSAGE);
      }
    } catch (e) {
      console.log('Failed to update deal favourite state.');
    } finally {
    }
  };

  return (
    <div className="tp-item-card-wrapper">
      <Button onClick={handleFavouriteToggle} className={{ 'fev-selected': item.isFavouriteOffer }}>
        <SvgIcon name="heart" viewbox="0 0 10.238 9.131" />
      </Button>

      <Card
        className="tp-item-card"
        cover={
          <div className="deal-image-container">
            <img alt={item.tOffer || item.deal_title} src={imageUrl} />
          </div>
        }
        onClick={() => {
          const params = new URLSearchParams({
            iBrandID: item.iBrandID,
            iOutletID: item.iOutletID,
            iDealID: item.iDealID,
            ...(item.isPrive === 'Yes' && { isPrive: '' }),
          });
          if (location.pathname === '/details') {
            history.push('/reload', {
              from: {
                ...location,
                search: params.toString(),
              },
            });
          } else {
            history.push({ pathname: '/details', search: params.toString() });
          }
        }}
      >
        <div className="offer-tag">{offerTag}</div>
        <div className="bottom-row">
          <div className="left-col">
            <h3>{item.tOffer || item.deal_title}</h3>
            <p>
              <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
              {(Math.floor(distance * 100) / 100).toFixed(2)}Km / {areaName}
            </p>
          </div>
        </div>
      </Card>
    </div>
  );
}
