import React from 'react';
import PropTypes from 'prop-types';

import { Footer, NavbarDefault } from '../components/layout';

const DefaultDetailsLayout = ({ children, navbar, footer }) => {
  return (
    <React.Fragment>
      <div className="detailpage-wrapper">
        {!navbar && <NavbarDefault logoOnly />}
        <main>{children}</main>
        {!footer && <Footer />}
      </div>
    </React.Fragment>
  );
};

DefaultDetailsLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool,
};

DefaultDetailsLayout.defaultProps = {
  navbar: false,
  footer: false,
};

export default DefaultDetailsLayout;
