import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button } from 'antd';
import { Link, Redirect, useLocation } from 'react-router-dom';
import './index.scss';

import successBg from '../../assets/images/success-bg.svg';

const PaymentSuccessful = () => {
  const location = useLocation();
  const { data } = location.state || {};

  if (!data) {
    return <Redirect to="/" />;
  }

  return (
    <div className="checkout-wrapper">
      <Container>
        <Row>
          <Col>
            <div className="twl-checkout-success">
              <img src={successBg} alt="Success" data-aos="fade-in" data-aos-duration="1000" />
              <div className="success-check" data-aos="zoom-in" data-aos-duration="1000">
                <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
              </div>
              <h2 data-aos="fade-up" data-aos-duration="800">
                Congratulations
              </h2>
              <p data-aos="fade-up" data-aos-duration="1000">
                Your redemption is successful!
              </p>
              {data.discountAmount && (
                <>
                  <div className="discount">
                    <p>🤗 Discount</p>
                    <h3>AED {data.discountAmount}! </h3>
                  </div>
                </>
              )}
              <p data-aos="fade-up" data-aos-duration="1200">
                Transaction ID
              </p>
              <h2 data-aos="fade-up" data-aos-duration="1400">
                {data.iTransactionID}
              </h2>
              <div className="mt-4 pt-2" data-aos="fade-up" data-aos-duration="1600">
                <Link to="/">
                  <Button type="secondary">Done</Button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PaymentSuccessful;
