import React, { useEffect } from 'react';
import { Col, Container, Row } from '../index';
import { useTopBrandsSelector } from '../../../store/selectors';
import { LoadState } from '../../../constants';
import { useDispatch } from 'react-redux';
import './index.scss';
import { getTopBrands } from '../../../slice/topBrandsSlice';

export default function TopBrandLogo() {
  const dispatch = useDispatch();
  const { logos, state } = useTopBrandsSelector();

  useEffect(() => {
    console.log('TOP BRANDS' + state);
    if (state === LoadState.IDLE) {
      dispatch(getTopBrands());
    }
  }, [state]);

  if (logos.length < 1) {
    return null;
  }

  return (
    <section className="topbrand-logo-section">
      <Container>
        <Row>
          <Col>
            <h1 className="heading" data-aos="fade-in" data-aos-duration="1000">
              Top brands, <br /> all in one place
            </h1>
            <div className="topbrand-grid">
              {logos.map((s, i) => (
                <div className="square-image-container">
                  <img key={i} src={s} />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
