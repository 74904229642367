import SvgSprite from './utility/SvgSpriteLoader';
import { AppRoutes, rotues } from './routes';
import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'antd/dist/reset.css';
import 'aos/dist/aos.css';
import { Provider } from 'react-redux';

//Svg Sprite
import svgFile from './assets/images/svg/svg-sprite.svg';
import { CustomLoader, LoaderContextProvider } from './components/common/Loader';
import { UnexpectedError } from './components/common';
import store from './store';
import { setPriveCredential, setUser } from './slice/userSlice';
import { setCities, setInitialSelectedCity } from './slice/countryCitySlice';
import { setCategories } from './slice/categorySlice';
import { apiClient } from './apiConfig';

function App() {
  const [isError, setError] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [metaInfo, setMetaInfo] = useState({});

  const init = async () => {
    console.log(':::init():::');

    const xApiKey = JSON.parse(localStorage.getItem('xApiKey'));

    if (!xApiKey) {
      console.log('Api key not found fetching.');
      const keyResponse = await apiClient.post('/key');
      const data = keyResponse.data;
      localStorage.setItem('xApiKey', JSON.stringify(data.key));
      console.log('Api key fetched ', data.key);
    }

    const { user, accessToken, priveCredential } = store.getState().user;

    if (accessToken && user) {
      if (!priveCredential) {
        console.log('Prive credentials not found fetching.');
        const handShakeResponse = await apiClient.post('/offer/privehandshake');
        if (handShakeResponse.data.status === 1) {
          store.dispatch(setPriveCredential(handShakeResponse.data.DATA));
        } else {
          throw new Error(`prive handshake failed.`);
        }
      }

      console.log('User credentials found refreshing user data.');
      const userResponse = await apiClient.get('/user/setting');
      if (userResponse.data.status === 1) {
        store.dispatch(setUser(userResponse.data.DATA));
      }
    } else {
      console.log('User not logged-in');
      if (!priveCredential) {
        console.log('Prive guest credentials not found fetching.');
        const handShakeResponse = await apiClient.post('/guestoffer/privehandshake');
        if (handShakeResponse.data.status === 1) {
          store.dispatch(setPriveCredential(handShakeResponse.data.DATA));
        } else {
          throw new Error(`prive guest handshake failed.`);
        }
      }
    }

    const fetchCategory = async (city) => {
      const endPoint = user ? '/offer/categoryList' : '/guestoffer/categoryList';
      const categoryResponse = await apiClient.post(endPoint, {
        iCountryID: city.iCountryID,
        iCityID: city.iCityID,
      });

      if (categoryResponse.data.status === 1) {
        store.dispatch(setCategories(categoryResponse.data.DATA));
      } else {
        throw new Error(`Api call categoryList failed.`);
      }
    };

    const { selectedCity } = store.getState().countryCity;
    const fetchCity = async () => {
      const endPoint = user ? '/offer/countryCityList' : '/guestoffer/countryCityList';
      const cityResponse = await apiClient.post(endPoint);

      if (cityResponse.data.status === 1) {
        const countries = cityResponse.data.DATA;
        const cities = countries.flatMap((country) =>
          country.cityList.map((city) => ({
            ...city,
            countryImage: country.country_banner,
            iCountryID: country.iCountryID,
            vCountryName: country.vCountryName,
            vCityLatitude: 25.2048,
            vCityLongitude: 55.2708,
          })),
        );

        if (cities.length === 0) {
          throw new Error(`Received empty city list from api response`);
        }

        store.dispatch(setCities(cities));

        if (!selectedCity) {
          store.dispatch(setInitialSelectedCity(cities[0]));
          await fetchCategory(cities[0]);
        }
      } else {
        throw new Error(`Api call countryCityList failed.`);
      }
    };

    if (selectedCity) {
      await Promise.all([fetchCity(), fetchCategory(selectedCity)]);
    } else {
      // once city loaded it will fetch category
      await Promise.all([fetchCity()]);
    }
  };

  // AOS.init();
  useEffect(() => {
    AOS.init({});
    window.scrollTo(0, 0);

    (async () => {
      try {
        const response = await fetch(process.env.PUBLIC_URL + '/metadata.json');
        if (response.ok) {
          const data = JSON.parse(await response.text());
          if (data !== undefined) {
            setMetaInfo(data);
          }
        }
      } catch (e) {
        console.error('Failed to load metadata.json');
      }
    })();

    (async () => {
      try {
        setLoading(true);
        await init();
      } catch (e) {
        setError(true);
        console.error('Failed to initialize app', e);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <CustomLoader />;
  }

  if (isError) {
    return (
      <UnexpectedError
        onRefreshClick={() => {
          window.location.replace('/');
        }}
      />
    );
  }

  return (
    <Provider store={store}>
      <LoaderContextProvider>
        <SvgSprite url={svgFile} />
        <AppRoutes metaInfo={metaInfo} />
      </LoaderContextProvider>
    </Provider>
  );
}

export default App;
