import React, { useEffect, useRef, useState } from 'react';
import { Col, Container, DealCard, Row, SvgIcon } from '../../components/common';
import { Button, Card } from 'antd';
import { useHistory } from 'react-router-dom';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import LogoIcon from '../../assets/images/logo-icon.svg';
import ExpImg from '../../assets/images/exp-img.png';
import BlogImg2 from '../../assets/images/blog-2.jpg';
import BlogImg3 from '../../assets/images/blog-3.jpg';
import BlogImg4 from '../../assets/images/blog-4.jpg';
import DiscoverImageSmall from '../../assets/images/discover-small-img.jpg';
import HowItWorks from '../../assets/video/how_it_works.mp4';
import { useCategorySelector, useHomeSelector } from '../../store/selectors';
import { LoadState } from '../../constants';
import { getHomePageData, getHomePageDataNext } from '../../slice/homeSlice';
import { useDispatch } from 'react-redux';
import ModalVideo from 'react-modal-video';
import SearchCountryDrop from '../../components/common/SearchCountryDrop';
import SearchCategoryDrop from '../../components/common/SearchCategoryDrop';
import SearchResultsDrop from '../../components/common/SearchResultsDrop';
import { setSelectedCategory } from '../../slice/categorySlice';
import { logEvent } from '../../utility/analytics';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Next
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Prev
    </div>
  );
}

function NextArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const LandingPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { headerImage, handpicked, trending, monthly, topBrandLogos, state, state2 } =
    useHomeSelector();
  const { categories } = useCategorySelector();
  const { title: handpickedTitle, items: handpickedItems } = handpicked;
  const { title: trendingTitle, items: trendingItems } = trending;
  const { title: monthlyTitle, items: monthlyItems } = monthly;

  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const videoRef = useRef();

  const Blogdata = [
    {
      title: 'The Ultimate Children’s Playground',
      avatar: BlogImg2,
      date: 'NOV 29 - 5 MIN READ',
    },
    {
      title: 'Time to head to The Dubai Mall',
      avatar: BlogImg3,
      date: 'DEC 02 - 9 MIN READ',
    },
    {
      title: 'Time for something new',
      avatar: BlogImg4,
      date: 'JAN 08 - 10 MIN READ',
    },
  ];

  const settingsHandpickedCollections = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const settingsTourSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrowClients />,
    prevArrow: <PrevArrowClients />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const getHandPickedItemTitle = (title) => {
    const parts = title.split(' ').filter((s) => s.trim().length > 0);
    if (parts.length >= 2) {
      return (
        <>
          <p>{parts[0]}</p>
          <h2>{parts.slice(1).join(' ')}</h2>
        </>
      );
    }
    return <p>{title}</p>;
  };

  const handleCategoryClick = (category) => {
    if (category) {
      if (category.CategoryWebLink && category.CategoryWebLink.startsWith('https://')) {
        window.open(category.CategoryWebLink, '_blank');
      } else if (category.isPrive === 'Yes') {
        const params = new URLSearchParams({
          isPrive: 'Yes',
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      } else {
        const params = new URLSearchParams({
          categoryId: category.iCategoryID,
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      }
    }
  };
  const handleSearchButtonClick = () => {
    const params = new URLSearchParams({
      categoryId: 1,
    });
    history.push({
      pathname: '/listing',
      search: params.toString(),
    });
  };
  const handleHandPickedClick = (item) => {
    if (
      item.iDealID &&
      +item.iDealID !== 0 &&
      item.iBrandID &&
      +item.iBrandID !== 0 &&
      item.iOutletID &&
      +item.iOutletID !== 0
    ) {
      const params = new URLSearchParams({
        iBrandID: item.iBrandID,
        iOutletID: item.iOutletID,
        iDealID: item.iDealID,
      });

      history.push({
        pathname: '/details',
        search: params.toString(),
      });
    } else if (item.iCategoryID && +item.iCategoryID !== 0) {
      const params = new URLSearchParams({
        categoryId: item.iCategoryID,
        ...(item.iSubCategoryID &&
          +item.iSubCategoryID !== 0 && { subCategoryId: item.iSubCategoryID }),
      });

      history.push({
        pathname: '/listing',
        search: params.toString(),
      });
    } else {
      const params = new URLSearchParams({
        groupId: item.cardGroup_id,
      });
      history.push({
        pathname: '/groupListing',
        search: params.toString(),
      });
    }
  };

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }
    if (headerImage.length > 0 && headerImage[0].city_webimagevideo.includes('.mp4')) {
      videoRef.current.autoplay = true;
      videoRef.current.loop = true;
      videoRef.current.defaultMuted = true;
      videoRef.current.muted = true;
      videoRef.current.setAttribute('playsinline', 'true');
    }
  }, [headerImage]);

  useEffect(() => {
    if (state === LoadState.IDLE) {
      dispatch(getHomePageData());
    }
    if (state === LoadState.SUCCESS && state2 === LoadState.IDLE) {
      dispatch(getHomePageDataNext());
    }
  }, [state, state2]);

  useEffect(() => {
    dispatch(setSelectedCategory(undefined));

    logEvent('view_item', { name: 'Home Page' });
  }, []);

  return (
    <>
      <div className="twl-landing-wrapper">
        <section className="landing-banner">
          {headerImage.length > 0 && (
            <div
              className="background-image"
              style={{
                backgroundImage:
                  headerImage[0].city_webimagevideo &&
                  !headerImage[0].city_webimagevideo.includes('.mp4') &&
                  `url(${headerImage[0].city_webimagevideo})`,
              }}
            >
              {headerImage[0].city_webimagevideo &&
                headerImage[0].city_webimagevideo.includes('.mp4') && (
                  <video
                    ref={videoRef}
                    style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                  >
                    <source src={`${headerImage[0].city_webimagevideo}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
            </div>
          )}
          <Container>
            <div className="banner-row">
              <h1 data-aos="fade-right" data-aos-duration="900">
                Lifestyle <br /> Reimagined
              </h1>
              <p>
                Explore a world of offers like <br />
                no other with TravellerPass
              </p>
            </div>
          </Container>
        </section>
        <section className="globalsearch-section" data-aos="fade-up" data-aos-duration="1200">
          <Container>
            <MediaQuery minWidth={767}>
              <div className="globalsearch-row">
                <div className="globalsearch-country">
                  <label className="select-label">City</label>
                  <SearchCountryDrop />
                </div>
                <div className="globalsearch-category">
                  <label className="select-label">Category</label>
                  <SearchCategoryDrop />
                </div>
                <div className="globalsearch-search">
                  <SvgIcon className="search-icon" name="search" viewbox="0 0 12.901 12.905" />
                  <SearchResultsDrop />
                </div>
                <div className="lets-btn">
                  <Button
                    type="primary"
                    className="with-icon"
                    icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                    onClick={handleSearchButtonClick}
                  >
                    Let's Go
                  </Button>
                </div>
              </div>
            </MediaQuery>
            <MediaQuery maxWidth={767}>
              <div className="main-search">
                <div className="globalsearch-country">
                  <SearchCountryDrop />
                </div>
                <div className="globalsearch-ctg">
                  <SearchCategoryDrop />
                </div>
                <div className="globalsearch-search">
                  <SearchResultsDrop />
                  <Button
                    className="search-icon-btn"
                    type="secondary"
                    onClick={handleSearchButtonClick}
                  >
                    <SvgIcon className="search-icon" name="search" viewbox="0 0 12.901 12.905" />
                  </Button>
                </div>
              </div>
            </MediaQuery>
          </Container>
        </section>
        {handpickedTitle && handpickedItems && handpickedItems.length > 0 && (
          <section className="handpickscollection-section">
            <Container>
              <Row>
                <Col>
                  <h2 className="heading">
                    Handpicked <br /> Collections
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Slider
                    className="handpicks-collection-slider"
                    {...settingsHandpickedCollections}
                  >
                    {handpickedItems.map((item, index) => {
                      return (
                        <div key={index}>
                          <Card
                            cover={<img alt={item.group_title} src={item.group_image} />}
                            onClick={() => handleHandPickedClick(item)}
                          >
                            <div className="meta-row">
                              <div className="meta-left">
                                {getHandPickedItemTitle(item.group_title)}
                                <div className="venues-row">
                                  <ul>
                                    {item.deal_images_list.map((imgUrl) => (
                                      <li>
                                        <img src={imgUrl} alt="Venues" />
                                      </li>
                                    ))}
                                  </ul>
                                  {item.groupDealCount > 0 && `+ ${item.groupDealCount} Venues`}
                                </div>
                              </div>
                              <Button type="secondary">Explore</Button>
                            </div>
                          </Card>
                        </div>
                      );
                    })}
                  </Slider>
                  <SvgIcon className="bottom-arrow" name="slide-arrow" viewbox="0 0 51.092 32.96" />
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {trendingItems && trendingItems.length > 0 && (
          <section className="tour-section">
            <Container>
              <Row className="align-items-center mb-2">
                <Col>
                  <h1>{trendingTitle}</h1>
                </Col>
                <Col className="text-right">
                  <Button
                    className="more-btn"
                    size="small"
                    onClick={() => {
                      history.replace(`/offerListing`, {
                        type: 'trending',
                      });
                    }}
                  >
                    More
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Slider className="tour-slider" {...settingsTourSlider}>
                    {trendingItems.map((item) => (
                      <DealCard
                        key={`${item.iBrandID}_${item.iOutletID}_${item.iDealID}`}
                        item={item}
                      />
                    ))}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {monthlyItems && monthlyItems.length > 0 && (
          <section className="tour-section">
            <Container>
              <Row className="align-items-center mb-2">
                <Col>
                  <h1>{monthlyTitle}</h1>
                </Col>
                <Col className="text-right">
                  <Button
                    className="more-btn"
                    size="small"
                    onClick={() => {
                      history.replace(`/offerListing`, {
                        type: 'monthly',
                      });
                    }}
                  >
                    More
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Slider className="tour-slider" {...settingsTourSlider}>
                    {monthlyItems.map((item) => (
                      <DealCard
                        key={`${item.iBrandID}_${item.iOutletID}_${item.iDealID}`}
                        item={item}
                      />
                    ))}
                  </Slider>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {categories && categories.length > 0 && (
          <section className="explore-section">
            <Container>
              <Row>
                <Col sm="12">
                  <div>
                    <h2 className="heading text-left" data-aos="fade-in" data-aos-duration="1000">
                      Explore our <br /> amazing categories
                    </h2>
                    <ul className="explore-list">
                      {categories.map((item, index) => (
                        <li
                          key={item.iCategoryID}
                          data-aos="fade-up"
                          data-aos-duration={600 + index * 200}
                        >
                          <div className="explore-list-card">
                            <div className="explore-list-card-image">
                              <img
                                className="icon"
                                src={item.vCategoryMainImage}
                                alt={item.vCategoryName}
                              />
                            </div>
                            <div className="explore-caption">
                              <h3>{item.vCategoryName}</h3>
                              <p>{item.vCategoryDescription}</p>
                              <Button onClick={() => handleCategoryClick(item)}>
                                Explore <SvgIcon name="arrow-right" viewbox="0 0 24.603 7.838" />
                              </Button>
                            </div>
                          </div>
                        </li>
                      ))}
                      <li data-aos="fade-up" data-aos-duration="1600">
                        <Card cover={<img alt="Explore" src={ExpImg} />}>
                          <div className="meta-row">
                            <p>
                              Discover a world <br /> of offers
                            </p>
                            <div className="venues-row">
                              <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
                              <h3>3,000+</h3>
                              <small>Venues</small>
                            </div>
                          </div>
                        </Card>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}

        {/*  <section className="startyour-section">
        <Container>
          <ParallaxProvider>
            <Row className="startyour-row">
              <Col md="6" className="startyour-left" data-aos="fade-in" data-aos-duration="1000">
                <Parallax speed={10} data-depth="0.2" disabled={isMobile}>
                  <h1 data-aos="fade-up" data-aos-duration="800">
                    Start your <br /> savings journey!
                  </h1>
                  <ul>
                    <li data-aos="fade-up" data-aos-duration="1000">
                      <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Unlimited Redemptions
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1200">
                      <SvgIcon name="check-circle" viewbox="0 0 30 30" /> Access to 3000+ Venues
                    </li>
                    <li data-aos="fade-up" data-aos-duration="1400">
                      <SvgIcon name="check-circle" viewbox="0 0 30 30" /> 100+ luxury offers
                    </li>
                  </ul>
                  <div className="bottom-info" data-aos="fade-up" data-aos-duration="1600">
                    <img src={InfoImg} alt="Buy Membership" />
                    <p>
                      Make up your membership cost by saving big from just two offer redemptions!
                    </p>
                  </div>
                </Parallax>
              </Col>
              <Col md="6">
                <Tilt
                  className="parallax-effect"
                  perspective={1500}
                  tiltMaxAngleX="5"
                  tiltMaxAngleY="5"
                >
                  <div className="startyour-right">
                    <SvgIcon className="arrow" name="banner-arrow" viewbox="0 0 170.622 166.519" />
                    <img
                      className="right-img"
                      src={BannerImg}
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                      alt={'Buy Membership'}
                    />
                    <div className="subscriptions-card">
                      <Parallax speed={15} disabled={isMobile}>
                        <div
                          className="subscriptions-card-inner"
                          data-aos="flip-left"
                          data-aos-duration="1500"
                        >
                          <div className="header">Subscriptions</div>
                          <ul>
                            <li>
                              <div className="list-left">
                                <h3>AED 49.99 / Month</h3>
                                <p>Pay monthly, cancel anytime</p>
                              </div>
                              <div className="right-check">
                                <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
                              </div>
                            </li>
                            <li>
                              <div className="list-left">
                                <h3>
                                  AED 499.99 / Yearly{' '}
                                  <span className="save-badge">Save AED 100</span>
                                </h3>
                                <p>Pay ahead for a full year</p>
                              </div>
                            </li>
                          </ul>
                          <Link to="/purchase-membership">
                            <Button type="secondary" block>
                              Purchase
                            </Button>
                          </Link>
                          <div>
                            <Button type="link" block>
                              <SvgIcon name="promo-code" viewbox="0 0 14.611 10.163" /> Do you have
                              Promo code?
                            </Button>
                          </div>
                        </div>
                      </Parallax>
                    </div>
                  </div>
                </Tilt>
              </Col>
            </Row>
          </ParallaxProvider>
        </Container>
      </section>*/}

        {/*      <section className="blog-section">
        <Container>
          <Row>
            <Col>
              <div className="blog-upper">
                <Row>
                  <Col lg="6" data-aos="fade-right" data-aos-duration="800">
                    <img className="blog-img" src={BlogImg1} alt="Blog" />
                  </Col>
                  <Col lg="6" data-aos="fade-left" data-aos-duration="800">
                    <label>Blogs</label>
                    <h3>Your perfect plan for an unforgettable holidays</h3>
                    <p>
                      People of the UAE, Eid Holidays are approaching. So, planning the ultimate Eid
                      weekend is what is on most of our minds as we all want to make the most out of
                      this Eid weekend.
                    </p>
                    <Button
                      type="primary"
                      className="with-icon"
                      icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                    >
                      Read More
                    </Button>
                    <div className="bottom-text">Nov 29 - 3 min read</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <List
                data-aos="fade-up"
                data-aos-duration="800"
                itemLayout="horizontal"
                dataSource={Blogdata}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button>
                        {' '}
                        <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<img src={item.avatar} alt="" />}
                      title={item.title}
                      description={item.date}
                    />
                  </List.Item>
                )}
              />
              <div className="more-blog">
                <ul>
                  <li>
                    <img src={Tpimg1} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg2} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg3} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg4} alt="Blog More" />
                  </li>
                </ul>
                <Button type="link">+ 23 Blogs</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>*/}

        <section className="social-section">
          <div className="travellerPass-icon" data-aos="fade-right" data-aos-duration="900">
            <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
          </div>
          <Container>
            <Row>
              <Col>
                <img
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className="logo-icon"
                  src={LogoIcon}
                  alt="TravellerPass"
                />
                <h1 data-aos="fade-up" data-aos-duration="1100">
                  Discover the world like never before <br /> with TravellerPass
                </h1>
                <div className="small-card" data-aos="fade-up" data-aos-duration="1200">
                  <div className="left-img" onClick={() => setShowHowItWorks(true)}>
                    <div className="overlay-play">
                      <SvgIcon name="play-alt" viewbox="0 0 30.001 30" />
                    </div>
                    <img src={DiscoverImageSmall} alt="TravellerPass" />
                  </div>
                  <div>
                    <h3>Welcome</h3>
                    <p>A whole new experience to TravellerPass</p>
                  </div>
                </div>
                <ul data-aos="fade-up" data-aos-duration="1400">
                  {/*<li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="dribbble" viewbox="0 0 17.206 17.206" />
                  </Button>
                </li>
                  <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                  </Button>
                </li>*/}
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://www.tiktok.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://medium.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {/* <LocationModal handleCancel={() => {}} />*/}
      <ModalVideo
        isOpen={showHowItWorks}
        channel="custom"
        url={HowItWorks}
        onClose={() => setShowHowItWorks(false)}
      />
    </>
  );
};

export default LandingPage;
