import { logEvent as logEventFirebase, setUserId } from 'firebase/analytics';
import { analytics } from './firebase';
import store from '../store';

export function logEvent(eventName, eventParams) {
  const { user } = store.getState().user;
  const params = {
    ...(eventParams && { ...eventParams }),
    ...(user && { userId: user.iUserID }),
  };
  console.log(`Event: ${eventName}, params: ${JSON.stringify(params)}`);
  logEventFirebase(analytics, eventName, params);
}

export function setAnalyticsUserId(userId) {
  console.log(`Analytics userId: ${userId}`);
  setUserId(analytics, userId);
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    userId: userId,
  });
}
