import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../common';
import { Link } from 'react-router-dom';
import './Footer.scss';

import logoImage from '../../../assets/images/logo.svg';
import { openChatSupport } from '../../../utility/chat';
import { logEvent } from '../../../utility/analytics';

const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="upper-footer">
          <Col lg="5" className="footer-col1">
            <img src={logoImage} alt="logo" />
            <div className="download-btns">
              <a
                href="https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154"
                target="_blank"
              >
                <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US"
                target="_blank"
              >
                <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
              </a>
              {/*              <Link to="/">
                <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
              </Link>*/}
            </div>
          </Col>
          <Col lg="7">
            <Row>
              <Col sm="4" xs="6">
                <h4>ABOUT</h4>
                <ul>
                  <li>
                    <Link to="/about">About Us</Link>
                  </li>

                  <li>
                    <Link
                      to="#"
                      onClick={() => {
                        logEvent('view_item_list', { name: 'Blog Section' });
                        window.open('https://medium.com/@travellerpass', '_blank');
                      }}
                    >
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/prive">Prive Exclusive</Link>
                  </li>
                  <li>
                    <Link to="/knowmore">Know More</Link>
                  </li>
                  {/*<li>
                    <Link to="/">FAQ</Link>
                  </li>*/}
                </ul>
              </Col>
              <Col sm="4" xs="6">
                <h4>COMPANY</h4>
                <ul>
                  <li>
                    <Link to="/get-listed">Get listed</Link>
                  </li>
                  <li>
                    <Link to="/membership/buy">Buy membership</Link>
                  </li>
                  <li>
                    <Link to="/terms-conditions">Terms {'&'} Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
              <Col sm="4">
                <h4>SUPPORT</h4>
                <ul>
                  <li>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openChatSupport();
                      }}
                    >
                      Chat With Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/user#refer_an_outlet">Refer an Outlet</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <div className="bottom-footer">
              <p>© 2025 TravellerPass LLC. All rights reserved.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
