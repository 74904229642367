import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient, priveApiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getMapListingPageData = createAsyncThunk(
  'fetch/map/offerList',
  async (arg, thunkAPI) => {
    const { countryCity: countryCityReducer, user: userReducer } = thunkAPI.getState();
    const { user } = userReducer;
    const { selectedCity } = countryCityReducer;
    const { cacheKey, categoryId, subCategoryId, radius, priveSubCat, dCurrentLat, dCurrentLong } =
      arg;
    if (!selectedCity) {
      throw Error('City not selected.');
    }
    if (!cacheKey) {
      throw Error('arg cacheKey not found.');
    }

    thunkAPI.dispatch(setCacheKey(cacheKey));

    console.log('ARG', arg);

    if (priveSubCat) {
      console.log('FFFFFF');
      const payload = {
        dCurrentLat: dCurrentLat,
        dCurrentLong: dCurrentLong,
        iCountryID: selectedCity.iCountryID,
        iCategoryID: subCategoryId,
        radius: radius,
      };

      const response = await priveApiClient.post('offer/offerByLocation', payload);

      return (response.data.DATA || []).map((item) => ({ ...item, isPrive: 'Yes' }));
    }

    const payload = {
      dCurrentLat: dCurrentLat,
      dCurrentLong: dCurrentLong,
      iCountryID: selectedCity.iCountryID,
      // vCityName: selectedCity.vCityName,
      // iCityID: selectedCity.iCityID,
      iCategoryID: categoryId,
      iSubCategoryID: subCategoryId,
      radius: radius,
    };

    const url = user ? '/offer/offerByLocation' : '/guestoffer/offerByLocation';

    const response = await apiClient.post(url, payload);

    return response.data.DATA || [];
  },
);

const initialState = {
  state: LoadState.IDLE,
  loadMoreState: LoadState.IDLE,
  cacheKey: undefined,
  initialArg: {},
  deals: [],
};
export const mapListingSlice = createSlice({
  name: 'mapListing',
  initialState,
  reducers: {
    toggleFav: (state, action) => {
      const { iBrandID, iOutletID, iDealID, isPrive, isFavouriteOffer } = action.payload;

      if (state.deals) {
        for (let i = 0; i < state.deals.length; i++) {
          if (
            state.deals[i].iBrandID === iBrandID &&
            state.deals[i].iDealID === iDealID &&
            state.deals[i].iOutletID === iOutletID
          ) {
            state.deals[i] = {
              ...state.deals[i],
              isFavouriteOffer: isFavouriteOffer,
            };
          }
        }
      }
    },
    setCacheKey: (state, action) => {
      state.cacheKey = action.payload;
    },
    resetMapListing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getMapListingPageData.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getMapListingPageData.fulfilled, (state, action) => {
      state.initialArg = action.meta.arg;
      console.log('Deals Length 1', action.payload.length);
      state.deals = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getMapListingPageData.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });
  },
});

export const { toggleFav, resetMapListing, setCacheKey } = mapListingSlice.actions;
export default mapListingSlice.reducer;
