import axios from 'axios';
import store from './store';
import { removeUser } from './slice/userSlice';

const apiClient = axios.create({
  baseURL: 'https://app.travellerpassllc.com/webApp1.0',
  headers: {
    'Content-Type': 'application/json',
    'X-API-KEY': '6d9f729b765aae27f45e5ef9150fa073f8a61b94',
    uCurrency: 'AED',
  },
});

const priveApiClient = axios.create({
  baseURL: 'https://prive.travellerpassapi.com/ws2024',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use((config) => {
  const xApiKey = JSON.parse(localStorage.getItem('xApiKey'));
  const { user, accessToken } = store.getState().user;
  const currency = store.getState().currency.selectedCurrency;

  config.headers = {
    ...config.headers,
    ...(xApiKey && { 'X-API-KEY': xApiKey }),
    ...(accessToken && { accesstoken: accessToken }),
    ...(user && { iUserID: user.iUserID }),
    ...(currency && { uCurrency: currency.uCurrency }),
  };
  if (typeof config.data === 'object') {
    config.data = { ...config.data, Language: 'en' };
  }

  if (!user) {
    console.log('URL', config.url);
    config.url = config.url.replace(/^offer\//, 'guestoffer/');
  }

  return config;
});

priveApiClient.interceptors.request.use((config) => {
  const { user, priveCredential } = store.getState().user;
  const currency = store.getState().currency.selectedCurrency;

  config.headers = {
    ...config.headers,
    ...(priveCredential['X-API-KEY'] && { 'X-API-KEY': priveCredential['X-API-KEY'] }),
    ...(priveCredential.accesstoken && { accesstoken: priveCredential.accesstoken }),
    ...(priveCredential.iUserID && { iUserID: priveCredential.iUserID }),
    ...(priveCredential.projectID && { projectID: priveCredential.projectID }),
    ...(currency && { uCurrency: currency.uCurrency }),
  };
  if (typeof config.data === 'object') {
    config.data = { ...config.data, Language: 'en', platform: 'web' };
  }

  if (!user) {
    config.baseURL = config.baseURL.replace(/^(.*)\/ws2024/, '$1/wsguest2024');
  }

  return config;
});

/*const OLD_IMAGE_BASE_URL_REGEX = /https:\/\/travellerpassapp\.com\/+images/g;
const NEW_IMAGE_BASE_URL = 'https://ik.imagekit.io/traveller/';

const updateImageUrls = (data) => {
  if (Array.isArray(data)) {
    return data.map(updateImageUrls);
  } else if (typeof data === 'object' && data !== null) {
    return Object.keys(data).reduce((acc, key) => {
      let value = data[key];

      if (typeof value === 'string' && OLD_IMAGE_BASE_URL_REGEX.test(value)) {
        console.log(`Updating key "${key}":`, value); // Debugging log
        value = value.replace(OLD_IMAGE_BASE_URL_REGEX, NEW_IMAGE_BASE_URL) + '?tr=w-400';
      }

      acc[key] = updateImageUrls(value);
      return acc;
    }, {});
  }
  return data;
};*/

apiClient.interceptors.response.use(
  (response) => {
    /*    if (response.data) {
      response.data = updateImageUrls(response.data);
    }*/
    return response;
  },
  (error) => {
    console.error('AxiosInterceptorError', error);
    if (error.response.status === 401 || error.response.status === 403) {
      store.dispatch(removeUser());
      window.location.href = '/';
    }
    return Promise.reject(error);
  },
);

export { apiClient, priveApiClient };
