import React, { useMemo, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { Modal } from 'antd';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import './index.scss';

/*
const stripePromise = loadStripe(
  'pk_test_51L5k11Ige6jHU45t9At0DFGCHueTidcYty0Sv8xmx5dUkVPgDfQVSqJ3OoiuNS5NzEpQ8w1BeBQlDSh4h3DDkWTU00Uu23f3lr',
);
*/

const stripePromise = loadStripe(
  'pk_live_51L5k11Ige6jHU45t7uZfxb1nc3eVjUEsqvvglO71b6XlUUpLGujhxbp9xYT5ieU4zqNNU0BRSYfG3JGr2jsElyr200UHMS2jI8',
);

function CheckoutForm({ paymentIntent, setShowStripeModal, handlePaymentSuccess }) {
  const { search } = useLocation();
  const [isPaymentLoading, setPaymentLoading] = useState(false);

  const [cardFields, setCardFields] = useState({});
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();

  const disabled = useMemo(() => {
    // card number, card cvv & card expiry 3 elements
    if (Object.keys(cardFields).length < 3) {
      return true;
    }

    for (const key in cardFields) {
      if (cardFields.hasOwnProperty(key) && cardFields[key] !== true) {
        return true;
      }
    }
    return false;
  }, [cardFields]);

  const handleCardNumberChange = async (event) => {
    setCardFields({
      ...cardFields,
      [event.elementType]: event.complete,
    });

    setError(event.error ? event.error.message : '');
  };

  const payMoney = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const paymentResult = await stripe.confirmCardPayment(paymentIntent, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });
    if (paymentResult.error) {
      alert(paymentResult.error.message);
    } else {
      setShowStripeModal(false);
      handlePaymentSuccess(paymentResult.paymentIntent.id);
    }
    setPaymentLoading(false);
  };

  const cardStyle = {
    base: {
      color: '#242424',
      fontFamily: 'Hanken Grotesk, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#9b9ca3',
      },
    },
    invalid: {
      fontFamily: 'Hanken Grotesk, sans-serif',
      color: '#ff0050',
      iconColor: '#ff0050',
    },
  };

  return (
    <Modal
      title=""
      open={true}
      onCancel={() => {
        if (!isPaymentLoading) {
          setShowStripeModal(false);
        }
      }}
      closeIcon={false}
      closable={false}
      footer={false}
    >
      <form className="payment-form" onSubmit={payMoney}>
        <label htmlFor="card-number-element">Card Number</label>

        <CardNumberElement
          id="card-number-element"
          options={{
            showIcon: true,
            iconStyle: 'solid',
            style: { ...cardStyle },
          }}
          onChange={handleCardNumberChange}
        />
        <div style={{ display: 'flex', marginTop: 16, marginBottom: 16 }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginRight: 8,
            }}
          >
            <label htmlFor="card-cvv-element">CVC</label>

            <CardCvcElement
              id="card-cvv-element"
              options={{
                style: { ...cardStyle },
              }}
              onChange={handleCardNumberChange}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginLeft: 8,
            }}
          >
            <label htmlFor="card-expiry-element">Expiration</label>

            <CardExpiryElement
              id="card-expiry-element"
              options={{
                style: { ...cardStyle },
              }}
              onChange={handleCardNumberChange}
            />
          </div>
        </div>
        <button className="pay-button" disabled={isPaymentLoading || disabled}>
          {isPaymentLoading ? 'Processing...' : 'Pay'}
        </button>
        {error && (
          <div className="card-error" role="alert">
            {error}
          </div>
        )}
      </form>
    </Modal>
  );
}

const PaymentComponent = (props) => {
  return (
    <>
      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm {...props} />
        </Elements>
      )}
    </>
  );
};

export default PaymentComponent;
