import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import { Col, Row, SvgIcon } from '../../../components/common';
import Lottie from 'react-lottie';
import './index.scss';

import AnimationOne from '../../../assets/json/thankyou.json';
import { useMembershipSelector } from '../../../store/selectors';
import dayjs from 'dayjs';

const CancelMembershipDoneModal = ({ handleCancel }) => {
  const { plans } = useMembershipSelector();
  const activePlan = useMemo(() => (plans || []).find((item) => item.isActive), [plans]);

  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };
  return (
    <>
      <Modal
        className="thankyou-modal"
        centered
        maskClosable={false}
        footer={null}
        title={null}
        width={550}
        open={true}
        onOk={handleCancel}
        onCancel={handleCancel}
        closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
      >
        <div>
          <Row>
            <Col md="12">
              <Lottie options={animationLottie} height={280} width={280} />
            </Col>
            <Col md="12">
              <h1 data-aos="fade-up" data-aos-duration="800">
                Done!
              </h1>
              <p data-aos="fade-up" data-aos-duration="900">
                We have cancelled your auto-renewal, your membership will expire on:{' '}
                {activePlan && (
                  <strong>{dayjs(activePlan.dExpiredDate).format('DD MMMM, YYYY')}</strong>
                )}
              </p>
              <div className="pt-3 pb-5" data-aos="fade-up" data-aos-duration="1000">
                <Button type="secondary" htmlType="submit" className="px-5" onClick={handleCancel}>
                  Ok
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default CancelMembershipDoneModal;
