import { createSlice } from '@reduxjs/toolkit';

const loadUserFromLocalStorage = () => {
  try {
    const userData = JSON.parse(localStorage.getItem('user'));
    if (userData) {
      return userData.DATA;
    }
  } catch (e) {}
  return undefined;
};

const loadLatLngFromLocalStorage = () => {
  try {
    const latLng = JSON.parse(localStorage.getItem('userLatLng'));
    if (latLng) {
      return latLng;
    }
  } catch (e) {}
  return undefined;
};

const loadAccessTokenFromLocalStorage = () => {
  try {
    const token = JSON.parse(localStorage.getItem('accesstoken'));
    if (token) {
      return token;
    }
  } catch (e) {}
  return undefined;
};

const loadPriveCredentialFromLocalStorage = () => {
  try {
    const credential = JSON.parse(localStorage.getItem('priveCredential'));
    if (credential) {
      return credential;
    }
  } catch (e) {}
  return undefined;
};

const userSlice = createSlice({
  name: 'user',
  initialState: {
    user: loadUserFromLocalStorage(),
    accessToken: loadAccessTokenFromLocalStorage(),
    latLng: loadLatLngFromLocalStorage(),
    locationPermission: 'pending',
    priveCredential: loadPriveCredentialFromLocalStorage(),
  },
  reducers: {
    setUser(state, action) {
      if (
        action.payload &&
        action.payload.uProfilePicture &&
        action.payload.uProfilePicture.match(/(\.[a-zA-Z0-9]+(\?|#|$))/g)
      ) {
        state.user = action.payload;
      } else {
        state.user = { ...action.payload, uProfilePicture: '' };
      }
    },
    setLocationPermission(state, action) {
      state.locationPermission = action.payload;
    },
    setLatLng(state, action) {
      state.latLng = action.payload;
    },
    setAccessToken(state, action) {
      state.accessToken = action.payload;
    },
    setPriveCredential(state, action) {
      state.priveCredential = action.payload;
    },
    removeUser(state, action) {
      state.user = undefined;
      state.accessToken = undefined;
      state.priveCredential = undefined;
    },
  },
});

export const {
  setUser,
  setLocationPermission,
  setLatLng,
  setAccessToken,
  setPriveCredential,
  removeUser,
} = userSlice.actions;

export default userSlice.reducer;
