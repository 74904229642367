export default function initGtm() {
  if (typeof window === 'undefined') return;

  const elScript = document.createElement('script');
  elScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-M7XNWH7S');`;

  const elNoscript = document.createElement('noscript');
  elNoscript.innerHTML = `<iframe src='https://www.googletagmanager.com/ns.html?id=GTM-M7XNWH7S'
  height='0' width='0' style='display:none;visibility:hidden'></iframe>`;

  document.head.insertBefore(elScript, document.head.childNodes[0]);
  document.body.insertBefore(elNoscript, document.body.childNodes[0]);
}
