import { SvgIcon } from '../index';
import { Select } from 'antd';
import React, { useMemo } from 'react';
import './index.scss';
import { useCategorySelector } from '../../../store/selectors';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedCategory } from '../../../slice/categorySlice';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';

const SearchCategoryDrop = ({ inNavBar }) => {
  const dispatch = useDispatch();
  const { categories } = useCategorySelector();
  const history = useHistory();

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const selectedCategory = useMemo(() => {
    return categories.find((item) => item.isSelected);
  }, [categories]);

  const handleCategoryClick = (category) => {
    if (category) {
      if (category.CategoryWebLink && category.CategoryWebLink.startsWith('https://')) {
        window.open(category.CategoryWebLink, '_blank');
      } else if (category.isPrive === 'Yes') {
        const params = new URLSearchParams({
          isPrive: 'Yes',
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      } else {
        const params = new URLSearchParams({
          categoryId: category.iCategoryID,
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      }
    }
  };

  return (
    <Select
      suffixIcon={<SvgIcon name="down-arrow" viewbox="0 0 18 9" />}
      popupClassName={classNames('search-category-dropdown', {
        'in-navbar': inNavBar === true,
      })}
      listHeight={300}
      dropdownRender={(menu) => (
        <>
          <div className="drop-title">Choose Category</div>
          {menu}
        </>
      )}
      dropdownStyle={{
        ...(inNavBar === true && { top: window.scrollY + (isMobile ? 80 : 142) }),
      }}
      options={categories.map((item) => {
        return {
          value: item.iCategoryID,
          label: (
            <>
              <div className="category-img">
                <img className="icon" src={item.vCategoryMainImage} alt={item.vCategoryName} />
              </div>
              {item.vCategoryName}
            </>
          ),
        };
      })}
      onSelect={(value) => {
        const category = categories.find((item) => item.iCategoryID === value);
        handleCategoryClick(category);
        dispatch(setSelectedCategory(value));
      }}
      {...(selectedCategory && { value: selectedCategory.iCategoryID })}
    />
  );
};

export default SearchCategoryDrop;
