import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button } from 'antd';
import MediaQuery from 'react-responsive';
import FilterDrawer from '../../../containers/FilterDrawer';
import { Container, SvgIcon } from '../../common';
import './index.scss';

import logoImage from '../../../assets/images/logo-icon.svg';
import LoginBtn from '../LoginBtn';
import SearchCountryDrop from '../../common/SearchCountryDrop';
import SearchCategoryDrop from '../../common/SearchCategoryDrop';
import SearchResultsDrop from '../../common/SearchResultsDrop';
import { useCategorySelector } from '../../../store/selectors';

const NavbarInner = () => {
  const history = useHistory();
  const { categories } = useCategorySelector();
  const [isActive, setIsActive] = useState(false);
  const SearchExPand = (event) => {
    setIsActive((current) => !current);
  };
  useEffect(() => {
    document.body.classList.toggle('scroll-off', isActive);
    return () => {
      document.body.classList.toggle('scroll-off', false);
    };
  }, [isActive]);

  useEffect(() => {
    setIsActive(false);
  }, [history.location.search]);

  const handleSearchButtonClick = () => {
    setIsActive((current) => false);
    const category = categories.find((item) => item.isSelected);
    handleCategoryClick(category);
  };

  const handleCategoryClick = (category) => {
    if (category) {
      if (category.CategoryWebLink && category.CategoryWebLink.startsWith('https://')) {
        window.open(category.CategoryWebLink, '_blank');
      } else if (category.isPrive === 'Yes') {
        const params = new URLSearchParams({
          isPrive: 'Yes',
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      } else {
        const params = new URLSearchParams({
          categoryId: category.iCategoryID,
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      }
    }
  };

  return (
    <header
      className={
        isActive ? 'main-header main-header-search expand-search' : 'main-header main-header-search'
      }
    >
      {isActive ? <div onClick={SearchExPand} className="overlay-mask"></div> : null}
      <Container>
        <div className="hader-inner">
          <div className="logo-left">
            <Link to="/">
              <img className="logo-icon" src={logoImage} alt={logoImage} />{' '}
              <SvgIcon className="logo-name" name="logoname" viewbox="0 0 122.877 12.012" />{' '}
            </Link>
          </div>
          <div className="main-search">
            {/*{isActive ? (
              <div className="tabssmall-header">
                <label className="active">Lifestyle</label>
                <label>Experiences</label>
              </div>
            ) : (
              ''
            )}*/}
            <div className="globalsearch-country" onClick={() => setIsActive(SearchExPand + 1)}>
              <div className="globalsearch-country-inner">
                <MediaQuery minWidth={767}>
                  {isActive ? <label className="select-label">City</label> : ''}
                </MediaQuery>
                <SearchCountryDrop inNavBar />
              </div>
            </div>
            <div className="globalsearch-ctg" onClick={() => setIsActive(SearchExPand + 1)}>
              <div className="globalsearch-ctg-inner">
                <MediaQuery minWidth={767}>
                  {isActive ? <label className="select-label">Category</label> : ''}
                </MediaQuery>
                <SearchCategoryDrop inNavBar />
              </div>
            </div>
            <div className="globalsearch-search">
              <div className="globalsearch-search-inner">
                <MediaQuery minWidth={767}>
                  {isActive ? (
                    <label className="select-label">Search by Venue and location</label>
                  ) : (
                    ''
                  )}
                </MediaQuery>
                <SearchResultsDrop inNavBar onClick={() => setIsActive(SearchExPand + 1)} />
              </div>
              {isActive ? (
                <Button className="search-btn" type="secondary" onClick={handleSearchButtonClick}>
                  <SvgIcon className="search-icon" name="search" viewbox="0 0 12.901 12.905" />{' '}
                  Search
                </Button>
              ) : (
                <Button
                  className="search-icon-btn"
                  type="secondary"
                  onClick={() => setIsActive(SearchExPand + 1)}
                >
                  <SvgIcon className="search-icon" name="search" viewbox="0 0 12.901 12.905" />
                </Button>
              )}
              <MediaQuery maxWidth={767}>
                <FilterDrawer />
              </MediaQuery>
            </div>
          </div>
          <div className="hedaer-right">
            <LoginBtn />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default NavbarInner;
