import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Radio } from 'antd';
import { SvgIcon } from '../../../components/common';
import './index.scss';

import logoImage from '../../../assets/images/logo.svg';
import PersonImg1 from '../../../assets/images/inquiries-person1.png';
import PersonImg2 from '../../../assets/images/inquiries-person2.png';
import PersonImg3 from '../../../assets/images/inquiries-person3.png';
import CtgListimg1 from '../../../assets/images/ctg-1.png';
import CtgListimg2 from '../../../assets/images/ctg-2.png';
import CtgListimg3 from '../../../assets/images/ctg-3.png';
import CtgListimg4 from '../../../assets/images/ctg-4.png';
import CtgListimg5 from '../../../assets/images/ctg-5.png';

const StartGetListedForm = () => {
  const history = useHistory();
  return (
    <div className="getlistedform-alt-wrapper">
      <div className="getlistedform-left">
        <div className="getlistedform-inner">
          <div data-aos="fade-right" data-aos-duration="800">
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>Which of the following categories does your business fall under?</h1>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000">
            <p>
              <small>WHY DO WE NEED IT?</small>
            </p>
            <p>
              It will help us to better segment our TP Audience inorder to help you get your ideal
              customers.
            </p>
          </div>
        </div>
      </div>
      <div className="getlistedform-right">
        <Form name="basic" autoComplete="off" layout="vertical">
          <div className="getlistedform-inner">
            <div className="right-upper">
              <div className="upper-inner">
                <Button className="help-btn" type="text">
                  Help?
                </Button>
                <ul>
                  <li>
                    <img src={PersonImg1} alt={PersonImg1} />
                  </li>
                  <li>
                    <img src={PersonImg2} alt={PersonImg2} />
                  </li>
                  <li>
                    <img src={PersonImg3} alt={PersonImg3} />
                  </li>
                </ul>
                <Button
                  type="default"
                  className="auth-upper-btn"
                  onClick={() => history.push('/get-listed')}
                >
                  Save & Exit
                </Button>
              </div>
            </div>
            <div className="right-form">
              <div className="category-list" data-aos="fade-left" data-aos-duration="800">
                <Radio.Group defaultValue="a" size="large">
                  <Radio.Button value="a">
                    <div className="ctg-list">
                      <h4>Dining</h4>
                      <div className="ctg-img">
                        <img src={CtgListimg1} alt={'Dining'} />
                      </div>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="b">
                    <div className="ctg-list">
                      <h4>Liesure</h4>
                      <div className="ctg-img">
                        <img src={CtgListimg2} alt={'Liesure'} />
                      </div>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="c">
                    <div className="ctg-list">
                      <h4>Prive</h4>
                      <div className="ctg-img">
                        <img src={CtgListimg3} alt={'Prive'} />
                      </div>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="d">
                    <div className="ctg-list">
                      <h4>Shopping</h4>
                      <div className="ctg-img">
                        <img src={CtgListimg4} alt={'Shopping'} />
                      </div>
                    </div>
                  </Radio.Button>
                  <Radio.Button value="e">
                    <div className="ctg-list">
                      <h4>Wellness</h4>
                      <div className="ctg-img">
                        <img src={CtgListimg5} alt={'Wellness'} />
                      </div>
                    </div>
                  </Radio.Button>
                </Radio.Group>
              </div>
            </div>
            <div className="getlistedform-right-footer">
              <div className="back-left">
                <Link to="/start-get-listed">Back</Link>
              </div>
              <Button
                type="secondary"
                htmlType="submit"
                className="with-icon"
                icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                onClick={() => history.push('/get-listed-onboarding-offer')}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default StartGetListedForm;
