import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyANBm1aOXMbL5AkGQzsAe3uW5hCVn2iNcM',
  authDomain: 'auth.travellerpass.com',
  databaseURL: 'https://travellerpass-2019.firebaseio.com',
  projectId: 'travellerpass-2019',
  storageBucket: 'travellerpass-2019.firebasestorage.app',
  messagingSenderId: '422890840522',
  appId: '1:422890840522:web:9425183de3d184733faf33',
  measurementId: 'G-2HZF7Z1ZQG',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth = getAuth();
