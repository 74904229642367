import React, { useEffect, useRef, useState } from 'react';
import { setLatLng, setLocationPermission } from './slice/userSlice';
import { Alert } from './common';
import LocationModalV2 from './components/modal/LocationModalV2';
import { useDispatch } from 'react-redux';
import { UAParser } from 'ua-parser-js';
import { useUserSelector } from './store/selectors';

const RequireLocationAccess = ({ children }) => {
  const dispatch = useDispatch();
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const { device } = UAParser(window.navigator.userAgent);
  const { locationPermission } = useUserSelector();
  const locationDeniedTimer = useRef();

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  useEffect(() => {
    if (locationPermission === 'pending') {
      if (!navigator.geolocation) {
        alert(
          'Geolocation is not supported by your browser, you will not be able to redeem some offers.',
        );
      } else {
        (async () => {
          const result = await navigator.permissions.query({ name: 'geolocation' });
          console.log('Location Permission State: ' + result.state);
          if (result.state === 'granted') {
            dispatch(setLocationPermission('granted'));
          } else {
            navigator.geolocation.getCurrentPosition(
              () => {
                console.log('Success - get location, new state: granted');
                dispatch(setLocationPermission('granted'));
              },
              () => {
                console.log('Failed - get location, new state: denied');
                dispatch(setLocationPermission('denied'));

                locationDeniedTimer.current && clearTimeout(locationDeniedTimer.current);
                locationDeniedTimer.current = setTimeout(() => {
                  if (device.is('mobile')) {
                    setShowLocationPopup(true);
                  } else {
                    Alert.error('Please grant Location Permission.');
                  }
                }, 2000);
              },
              options,
            );
          }
        })();
      }
    }
    return () => {
      locationDeniedTimer.current && clearTimeout(locationDeniedTimer.current);
    };
  }, [locationPermission]);

  useEffect(() => {
    let timerNextRequest = undefined;
    if (locationPermission === 'granted') {
      const error = (positionError) => {
        Alert.error('Please enable location permission from site settings and reload the page.');
        dispatch(setLocationPermission('denied'));
      };

      const success = (position) => {
        dispatch(setLocationPermission('granted'));
        dispatch(
          setLatLng({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }),
        );
        timerNextRequest = setTimeout(() => {
          console.log('Requesting current location...' + new Date());
          navigator.geolocation.getCurrentPosition(success, error, options);
        }, 5000);
      };

      navigator.geolocation.getCurrentPosition(success, error, options);
    }

    return () => {
      timerNextRequest && clearTimeout(timerNextRequest);
      timerNextRequest = undefined;
    };
  }, [locationPermission]);

  return (
    <>
      {children}
      {showLocationPopup && (
        <LocationModalV2
          handleAllow={() => {
            setShowLocationPopup(false);
            dispatch(setLocationPermission('prompt'));
          }}
          handleCancel={() => {
            setShowLocationPopup(false);
            dispatch(setLocationPermission('denied'));
          }}
        />
      )}
    </>
  );
};

export default RequireLocationAccess;
