import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../apiConfig';
import { LoadState } from '../constants';
import { formatPrice } from '../utility/formatter';

export const getMembershipPlans = createAsyncThunk(
  'fetch/membershipPlans',
  async (arg, thunkAPI) => {
    const { countryCity: countryCityReducer, user: userReducer } = thunkAPI.getState();
    const { user } = userReducer;
    const { selectedCity } = countryCityReducer;

    if (!selectedCity) {
      throw Error('City not selected.');
    }

    const response = await apiClient.post('user/buyMembership');

    if (response.data.status !== 1) {
      throw new Error(`buyMembership received non zero status.`);
    }
    let plans = response.data?.DATA;
    if (!plans) {
      throw new Error(`membership plans not found.`);
    }

    const existingPlan = response.data?.existingPlan;

    plans = plans.map((plan) => {
      const isActive = existingPlan && existingPlan.iMembershipPlanID === plan.iMembershipPlanID;
      return {
        ...plan,
        title: plan.membershipPlan,
        desc: `${plan.mPriceDenomination} ${plan.mPlanPrice} / ${plan.mPlanDuration.replace(/^(.*)ly$/g, '$1')}, cancel anytime`,
        mPlanPrice: +plan.mPlanPrice,
        mPlanPriceOriginal: +plan.mPlanPrice,
        mPlanPromoDiscount: 0,
        isActive: isActive,
        isAutoRenewal: isActive && existingPlan.paymentAutoRenewal === 'Yes',
        dExpiredDate: isActive ? existingPlan.dExpiredDate : undefined,
        isSelected: false,
      };
    });

    return plans;
  },
);

const initialState = {
  state: LoadState.IDLE,
  plans: [],
  selectedPlanId: undefined,
  promo: undefined,
};
export const subCategorySlice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    setSelectedPlanId: (state, action) => {
      const planId = action.payload;
      const selectedPlan = state.plans.find((plan) => plan.iMembershipPlanID === planId);
      if (!selectedPlan) {
        return;
      }
      const activePlan = state.plans.find((item) => item.isActive);
      // cannot select current active plan
      if (activePlan && activePlan.iMembershipPlanID === selectedPlan.iMembershipPlanID) {
        return;
      }
      if (activePlan && selectedPlan.mUpgradablePlans && +activePlan.mUpgradablePlans === 0) {
        return;
      }

      state.selectedPlanId = selectedPlan.iMembershipPlanID;
      state.plans = state.plans.map((plan) => ({
        ...plan,
        isSelected: plan.iMembershipPlanID === selectedPlan.iMembershipPlanID,
      }));
    },
    setPromoCode: (state, action) => {
      const { discountPercentage, iMembershipPlanID, freeDescription, promoDescription } =
        action.payload;
      const ids = iMembershipPlanID.map((plan) => plan.iMembershipPlanID);

      state.plans = state.plans.map((plan) => {
        if (ids.findIndex((planId) => planId === plan.iMembershipPlanID) >= 0) {
          const promoDiscount = plan.mPlanPriceOriginal * (+discountPercentage / 100.0);
          const finalPrice = plan.mPlanPriceOriginal - promoDiscount;

          let title = plan.title;
          let desc = `${plan.mPriceDenomination} ${formatPrice(finalPrice)} then, ${plan.mPriceDenomination} ${plan.mPlanPriceOriginal}/${plan.mPlanDuration.replace(/^(.*)ly$/g, '$1')}, cancel anytime`;
          if (ids.length === 1 && ids[0] === plan.iMembershipPlanID) {
            if (promoDescription) {
              desc = `${promoDescription}, cancel anytime`;
            }
            title = freeDescription ? freeDescription : plan.membershipPlan;
          }

          return {
            ...plan,
            title: title,
            desc: desc,
            mPlanPrice: finalPrice,
            mPlanPromoDiscount: promoDiscount,
          };
        }
        return plan;
      });
      state.promo = action.payload;
    },

    removePromoCode: (state, action) => {
      state.plans = state.plans.map((plan) => {
        return {
          ...plan,
          mPlanPrice: plan.mPlanPriceOriginal,
          mPlanPromoDiscount: 0,
          title: plan.membershipPlan,
          desc: `${plan.mPriceDenomination} ${plan.mPlanPriceOriginal} / ${plan.mPlanDuration.replace(/^(.*)ly$/g, '$1')}, cancel anytime`,
        };
      });
      state.promo = undefined;
    },

    autoRenewalCancelled: (state, action) => {
      state.plans = state.plans.map((plan) => {
        return {
          ...plan,
          isAutoRenewal: false,
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMembershipPlans.pending, (state, action) => {
      state.promo = {};
      state.state = LoadState.LOADING;
    });
    builder.addCase(getMembershipPlans.fulfilled, (state, action) => {
      state.plans = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getMembershipPlans.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });
  },
});

export const { setSelectedPlanId, setPromoCode, removePromoCode, autoRenewalCancelled } =
  subCategorySlice.actions;

export default subCategorySlice.reducer;
