import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const BRPrivacyPolicy = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Beyond Rewards
                  </h1>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Privacy Policy
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/beyondreward-tandc">
                    Terms and Conditions{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  This Privacy Policy applies to all information collected by TravellerPass and/or
                  its related entities, affiliates, and subsidiaries (individually and collectively,
                  "Beyond Rewards") under Clause 1 below.
                  <br />
                  To this Policy, “we” and “our” shall refer to Beyond Rewards.
                  <ol>
                    <li>
                      <h4>.When is information collected from you?</h4>
                      <p>
                        1.1 When you register for or use any of our services on Website/Mobile app
                        apps owned and/or operated by us.
                        <br />
                        1.2 When you take part in any contest, survey or promotion conducted by us
                        and/or our business partners.
                        <br />
                        1.3 When you register as a member of Website/Mobile app owned and/or
                        operated by us, or hosted by us on behalf of our business partners or use
                        services on such Website/ Mobile app.
                      </p>
                    </li>
                    <li>
                      <h4>What is the information used for?</h4>
                      <p>
                        2.1 Information which you provide to us may be used and disclosed by us and
                        our affiliates, location, business partners and contractors for the
                        following purposes:
                        <br />
                        2.1.0 We use your location to list out nearby offers and deals as well as
                        location data to send you nearby deal alerts even when the app is not in use
                        so that you never miss out on an offer. This can be controlled in the
                        notification settings section of the app.
                        <br />
                        2.1.1 to respond to your requests and queries;
                        <br />
                        2.1.2 to provide goods and services to you;
                        <br />
                        2.1.3 to verify and process your personal particulars;
                        <br />
                        2.1.4 to communicate with you;
                        <br />
                        2.1.5 to enforce contractual and legal rights and obligations;
                        <br />
                        2.1.6 for marketing research, user profile and statistical analysis;
                        <br />
                        2.1.7 to send you information, promotions, updates, and marketing and
                        advertising materials in relation to our goods and services and those of
                        third-party organisations;
                        <br />
                        2.1.8 complying with law, the requests of law enforcement and regulatory
                        officials, or orders of court; and
                        <br />
                        2.1.9 for any other purposes for which we have obtained your consent,
                        including during the registration process under our Member Terms and
                        Conditions.
                        <br />
                        2.2 In the event that you participate in contests or other promotional
                        programs on our Website/Mobile app sponsored or organized by third parties
                        or purchase goods and services offered by third parties or offer goods and
                        services to third parties on our Website/Mobile app , your information may
                        be disclosed to and used by such third parties.
                      </p>

                      <li>
                        <h4> Use of cookies</h4>
                        <p>
                          <br />
                          3.1 Beyond Rewards uses "cookies", where a small data file is sent to your
                          browser to store and track information about you when you enter our
                          Website/Mobile app. The cookie is used to track information such as the
                          number of users and their frequency of use, profiles of users and their
                          preferred Website/Mobile app.
                          <br />
                          3.2 Like many website/mobile app operators, Beyond Rewards may use an
                          independent company (the “Third Party Market Research Company”) to measure
                          and analyse the Internet usage across Beyond Rewards Website/Mobile app.
                          Beyond Rewards uses the Third Party Market Research Company’s services to
                          collect the following core information on the usage of our Website/Mobile
                          app, including:
                          <br />
                          3.2.1 The number of page views (or page impressions) that occur on Beyond
                          Rewards Website/Mobile app;
                          <br />
                          3.2.2 The number of unique visitors to Beyond Rewards Website/Mobile app;
                          <br />
                          3.2.3 How long these unique visitors (on average) spend on Beyond Rewards
                          Website/Mobile app when they do visit; and
                          <br />
                          3.2.4 Common entry and exit points into Beyond Rewards Website/Mobile app.
                          <br />
                          3.3 This information is collated by the Third-Party Market Research
                          Company and provided to Beyond Rewards to assist in analysing the usage of
                          Beyond Rewards Website/Mobile app. Such data is also accessible by media
                          organizations and research companies, for the purpose of conducting
                          industry comparisons with other Internet portals.
                          <br />
                          3.4 Pages on Beyond Rewards Website/Mobile app may be coded with software
                          which enables the Third Party Market Research Company and Beyond Rewards
                          to track visitors for analysis.
                        </p>
                        <b>Member Terms and Conditions</b>
                        <p>
                          By clicking on the “I Accept”, “Submit”, “Login” or similar button or
                          checking the “I Accept” or similar box at the end of the Account
                          registration process or when logging in to your Account, you hereby agree
                          to be bound by these Member Terms and Conditions (“Member Conditions”) as
                          well as the Website/Mobile app Terms And Conditions (“Website/Mobile app
                          Conditions”) which are hereby incorporated by reference into these Member
                          Conditions, including without limitation, the representations set out in
                          Clause 1.1 of the Website/Mobile app Conditions. In the event of any
                          conflict, inconsistency or ambiguity between these Member Conditions and
                          the Website/Mobile app Conditions, these Member Conditions shall govern
                          and take precedence over the Website/Mobile app Conditions:
                        </p>
                        <ol>
                          <li>
                            <b>Definitions</b>
                            <p>
                              1.1 All defined terms on the Website/Mobile app Conditions, apply to
                              these Member Conditions unless specifically defined in these Member
                              Conditions.
                            </p>
                          </li>
                          <li>
                            <b>Account Information</b>
                            <p>
                              2.1 You agree that your Account is for your sole, personal use (or
                              that of the entity on whose behalf you are entering into these Member
                              Conditions). You hereby agree not to:
                              <br />
                              2.1.1 share with or permit others to use your Account (defined in
                              Clause 2.3); or
                              <br />
                              2.1.2 assign or otherwise transfer your Account to any other person or
                              entity.
                              <br />
                              You acknowledge and agree that any unauthorised sharing or disclosure
                              of your account information will cause wrongful loss to Beyond
                              Rewards.
                              <br />
                              2.2 You shall provide Beyond Rewards with accurate, complete, and
                              up-to-date Account information and further ensure that the information
                              is kept updated and remains current, accurate and complete. Failure to
                              do so shall constitute a breach of these Member Conditions, which may
                              result in the restriction, suspension or immediate termination of your
                              Account.
                              <br />
                              2.3 As part of the registration process for the Account, customers add
                              Name, Mobile Number, Email and membership number issued to you by
                              bank.
                              <br />
                              2.3.1 select or use a User Name of another person with the intent to
                              impersonate that person;
                              <br />
                              2.3.2 use a name subject to the rights of any other person without
                              authorisation; or
                              <br />
                              2.3.3 use a Name that Beyond Rewards, in its sole discretion, deems
                              inappropriate or offensive.
                              <br />
                              2.4 You shall promptly notify Beyond Rewards of any known or suspected
                              unauthorised use(s) of your Account, or any known or suspected breach
                              of security, including loss, theft, or unauthorised disclosure of your
                              membership number. You shall be solely responsible for safeguarding
                              and maintaining the confidentiality of your Account Information.
                              <br />
                              2.5 You shall be bound by and responsible for, and Beyond Rewards
                              shall be entitled to rely on, all communications transmitted through
                              the use of your account information and all such communications shall
                              be deemed to be communications made and issued by you.
                              <br />
                              2.6 You shall be responsible for all User Content, messages, and all
                              online activity transmitted or conducted using your account.
                              <br />
                              2.7 Beyond Rewards shall have no responsibility or liability for any
                              loss, damage, cost, expenses, or liabilities arising as a result of or
                              in connection with the wrongful or fraudulent use of your Account
                              information.
                              <br />
                              2.8 You agree that Beyond Rewards reserves the right to change or
                              re-assign membership number at its sole discretion by giving you
                              notice. Beyond Rewards shall not be liable for any loss, damage, cost
                              or expense incurred by you as a result of such change or
                              re-assignment.
                              <br />
                              2.9 Unless prior written notice of no less than thirty (30) days (or
                              such other period specified by Beyond Rewards) is given to Beyond
                              Rewards, you agree that Beyond Rewards may automatically renew your
                              membership or subscription account upon expiration.
                            </p>
                          </li>
                          <li>
                            <b>Use of Account Information </b>
                            <p>
                              3.1 In addition to and without limiting the terms of our Privacy
                              Policy, by providing the information requested for your Account, you
                              hereby consent to Beyond Rewards’ use and disclosure of all such
                              information and information relating to your use of the site/mobile
                              app or Services for the following purposes:
                              <br />
                              3.1.1 to respond to your requests and queries;
                              <br />
                              3.1.2 to provide the Services to you;
                              <br />
                              3.1.3 to verify and process your personal particulars and payments;
                              <br />
                              3.1.4 to communicate with you;
                              <br />
                              3.1.5 to enforce these Member Conditions and the Website/Mobile app
                              Conditions and our legal rights and remedies;
                              <br />
                              3.1.6 for marketing research, user profile and statistical analysis;
                              <br />
                              3.1.7 to send you information, promotions, updates, and marketing and
                              advertising materials in relation to our goods and services and those
                              of third party organisations;
                              <br />
                              3.1.8 complying with law, the requests of law enforcement and
                              regulatory officials, or orders of court; and
                              <br />
                              3.1.9 for any other purpose including the disclosure of such
                              information to third parties for commercial / business reasons,
                              provided that Beyond Rewards shall not disclose credit card account
                              information except for the purposes set out in Clauses 3.1.2 and 3.1.3
                              above.
                            </p>
                          </li>
                        </ol>
                      </li>
                    </li>
                    <li>
                      <h4>Suspension and Termination of Account </h4>
                      <p>
                        4.1 You agree that Beyond Rewards has the right in its sole and absolute
                        discretion and without notice to:
                        <br />
                        4.1.1 restrict, suspend, or terminate your access to all or any part of the
                        Services; and/or
                        <br />
                        4.1.2 terminate, deactivate or suspend your Account, delete your Account and
                        all related information and files in your Account, without assigning any
                        reason. Without prejudice to the generality of the above, Beyond Rewards
                        reserves the right to deactivate your Account if it has been inactive for a
                        period of six (6) months or more, or if you are in breach of any of our
                        Website/Mobile app Conditions or these Member Conditions or if Beyond
                        Rewards believes that you have been using the Account for unlawful and/or
                        undesirable activities.
                        <br />
                        4.2 You agree not to hold Beyond Rewards liable or responsible for any loss
                        or damage incurred by you arising out of or in connection with the
                        suspension and/or termination of your Account.
                      </p>
                    </li>
                    <li>
                      <h4>Member Obligations </h4>
                      <p>
                        5.1 You represent and warrant that you have read and agree to be bound by
                        our Website/Mobile app Conditions and these Member Conditions.
                        <br />
                        5.2 You acknowledge that the Site and Services are used by a community of
                        users, and you hereby agree and undertake:
                        <br />
                        5.2.1 to treat all users with respect and civility;
                        <br />
                        5.2.2 not to annoy, pester, solicit, harass, threaten, offend, insult,
                        abuse, defame, or injure any other users, or commit any unlawful or tortious
                        acts against other users;
                        <br />
                        5.2.3 not to spam, mail bomb, send viruses, spyware, malware, worms or other
                        damaging material to other users, or act in any manner that adversely
                        affects the use of the Services by other users;
                        <br />
                        5.2.4 not to harvest or otherwise collect information about users, including
                        email addresses, without Beyond Rewards prior written consent.
                        <br />
                        5.3 You agree that when using the Services:
                        <br />
                        5.3.1 you will only submit, upload or publish User Content in strict
                        compliance with Clause 6 below;
                        <br />
                        5.3.2 you will not offer for sale items and/or services which are illegal,
                        banned, unlicensed, controlled, or socially or morally reprehensible items;
                        <br />
                        5.3.3 you will not offer for sale items and/or services, or submit, upload
                        or publish User Content, in an incorrect or inappropriate category or area
                        on the Website/Mobile app, including without limitation posting unauthorised
                        advertisements in any forum, discussion or message boards that is open and
                        accessible to users of the Services; and
                        <br />
                        5.3.4 you will not alter, delete, manipulate, undermine or interfere with
                        the listings or postings of any other Member.
                        <br />
                        5.4 Any infringing, fraudulent, abusive, or otherwise illegal activity, or
                        any breach of the Website/Mobile app Conditions or these Member Conditions,
                        shall be grounds for termination of your use of the Website/Mobile app and
                        Services and/or your Account, at Beyond Rewards sole and absolute
                        discretion, and you may be reported to appropriate law-enforcement agencies.
                        <br />
                        5.5 Without prejudice to Beyond Rewards rights of restriction, suspension
                        and termination under Clause 4 above, Beyond Rewards reserves the right to
                        terminate your Account and/or your access to all or any part of the
                        Site/Mobile app or Services if you are in breach of any of these Member
                        Conditions or the Website/Mobile app Conditions or if Beyond Rewards
                        believes that you have been using the Website/Mobile app or any of the
                        Services for unlawful and/or undesirable activities.
                        <br />
                        5.6 You agree to indemnify and hold Beyond Rewards, and its subsidiaries,
                        affiliates, officers, agents, partners, and employees, harmless from all
                        claims, demands, actions, proceedings, liabilities (including statutory
                        liability and liability to third parties), penalties, and costs (including
                        without limitation, legal costs on a full indemnity basis), awards, losses
                        and/or expenses, due to or arising out of:
                        <br />
                        5.6.1 any advertisements, listings, postings or User Content placed by you;
                        <br />
                        5.6.2 any products and services offered, sold, provided, or advertised by
                        you;
                        <br />
                        5.6.3 your use of any Services;
                        <br />
                        5.6.4 your breach of warranty, or breach of any terms and conditions of
                        these Member Conditions; or
                        <br />
                        5.6.5 your misrepresentation, fraudulent acts, tortious acts, breach of
                        contract, or violation of any rights of another person or entity.
                      </p>
                    </li>
                    <li>
                      <h4>User Content</h4>
                      <p>
                        <br />
                        6.1 Please exercise respect when participating in any community feature on
                        the Website/Mobile app or the Services which permits you to upload or submit
                        User Content.
                        <br />
                        6.2 You may not submit, upload or publish through the Website/Mobile app or
                        the Services any User Content that is inaccurate, misleading, libellous,
                        defamatory, threatening, pornographic, obscene, indecent, lewd, abusive,
                        illegal, political, racist, religious, blasphemous, false, an infringement
                        of any intellectual property rights of a third party, or would otherwise
                        violate or encourage the violation of any law (including the Official
                        Secrets Act (Cap.213)) or the proprietary or other rights of any third party
                        (individually and collectively “Improper Works”). Furthermore, you may not
                        submit or publish User Content that solicits funds, or includes programs
                        that contain viruses, Trojan horses, worms, time bombs or any other programs
                        designed to impair the operation and functionality of the Website/Mobile
                        app, Services, Servers, or any computer.
                        <br />
                        6.3 If, at any time you contribute, submit, upload or post User Content to
                        Beyond Rewards or the Website/Mobile app or using the Services, you
                        automatically:
                        <br /> 6.3.1 and irrevocably grant and assign to Beyond Rewards an equal
                        share of all intellectual property rights, title and interests (including
                        copyright) in the User Content, in all forms and media, and in all countries
                        of the world, to be co-owned as tenants-in-common, with full rights to use,
                        license, exploit and enforce the said intellectual property rights and to
                        assign the above share of co-ownership, and without any obligation for
                        Beyond Rewards to account to you for any proceeds thereof, and you also
                        agree and undertake to execute at the request of Beyond Rewards, such other
                        instruments, assurances or documents, as may be required to vest in Beyond
                        Rewards, or required for Beyond Rewards to register in any jurisdiction, all
                        the title or rights as referred to herein. For the avoidance of doubt, and
                        without derogation from the above, Beyond Rewards and its affiliates,
                        subsidiaries and subcontractors (including its Internet content hosting
                        servers and delivery networks) shall have a non-exclusive, royalty-free,
                        irrevocable, perpetual and worldwide right to use (including without
                        limitation, to store, reproduce, modify, distribute, publish, display,
                        communicate, transmit, broadcast, podcast, webcast, or broadcast) and to
                        sub-licence the User Content whether or not in connection with the provision
                        of the Services and/or on or via the Website/Mobile app, other
                        Website/Mobile app/Mobile apps, and other printed and online publications
                        and newspapers;
                        <br />
                        6.3.2 represent and warrant that:
                        <ol type="i">
                          <li>
                            all such User Content are your own original works and creations and do
                            not and will not infringe the copyright or any other intellectual
                            property or other rights of any third party
                          </li>
                          <li>none of the User Content are proprietary or confidential</li>
                          <li>
                            none of the User Content are Improper Works, nor will they expose Beyond
                            Rewards to any civil or criminal proceedings in any part of the world;
                            and
                          </li>
                          <li>
                            the use by Beyond Rewards and other users for the purposes and in the
                            manner set out in this Clause 6.3, and the hosting of such User Content
                            on the Servers by Beyond Rewards will not require any further licences
                            or rights from, or infringe any intellectual property or other rights
                            of, any third party; and
                          </li>
                        </ol>
                        <br />
                        6.4 Beyond Rewards at all times retains the sole discretion to remove or
                        decline to accept any User Content from the Website/Mobile app without
                        assigning any reason whatsoever. Without limiting the foregoing right,
                        Beyond Rewards may monitor the Website/Mobile app for Improper Works (but is
                        not obliged to do so) and reserves the right to remove any User Content
                        which Beyond Rewards believes are Improper Works, or which is the subject of
                        any dispute.
                        <br />
                        6.5 Beyond Rewards may in its sole and absolute discretion lift the
                        restriction or suspension of your access to the Website/Mobile app, Services
                        or Account, if Beyond Rewards is satisfied that the User Content no longer
                        constitutes Improper Works or is the subject of any dispute. If full payment
                        was received by Beyond Rewards for the display of such removed User Content,
                        Beyond Rewards will restore such paid User Content (provided always that
                        such User Content is no longer deemed as Improper Works or is the subject of
                        any dispute) on to the Site until the remaining term for its display
                        expires. For the avoidance of doubt, Beyond Rewards shall not be obliged to
                        extend the term for the display of paid User Content, for the time period
                        that such User Content was removed from the Site. In the event that Beyond
                        Rewards fails to restore the paid User Content for whatsoever reason, you
                        agree that Beyond Rewards shall only be liable to refund the amounts paid
                        for the display and publication of such User Content, on a pro-rated basis
                        for the remaining and unexpired term for its display.
                        <br />
                        6.6 You agree to indemnify and hold Beyond Rewards, and its subsidiaries,
                        affiliates, officers, agents, co-branders or other partners, and employees,
                        harmless from all claims, demands, actions, proceedings, liabilities
                        (including statutory liability and liability to third parties), penalties,
                        and costs (including without limitation, legal costs on a full indemnity
                        basis), awards, losses and/or expenses, due to or arising out of User
                        Content you submit, post to or transmit through the Website/Mobile app or
                        Services.
                      </p>
                    </li>
                    <li>
                      <h4>Use of Services </h4>
                      <p>
                        7.1 General
                        <br />
                        You acknowledge and agree that:
                        <br />
                        7.1.1 Some Services are subject to further terms and conditions or the
                        execution of a separate contract between Beyond Rewards and yourself.
                        <br />
                        7.1.2 Beyond Rewards shall be entitled at any time, at its sole discretion
                        and without prior notice, to:
                        <br />
                        7.1.2.1 add to, vary, terminate, withdraw or suspend the whole or any part
                        or feature of any Service; and/or
                        <br />
                        7.1.2.2 add new Services.
                        <br />
                        7.1.3 Where new Services are added, these Member Conditions and the
                        Website/Mobile app Conditions will also apply to such new Services, in
                        addition to any further terms and conditions notified to you.
                        <br />
                        7.2 App Services by Subscription
                        <br />
                        7.2.1 The terms and conditions governing the subscription on or via the
                        Website/Mobile app to an App or the content or services of an App (“App
                        Services”), can be found here, and are hereby incorporated by reference into
                        these Member Conditions.
                        <br />
                        7.3 Online Contests & Lucky Draws
                        <br />
                        7.3.1 The terms and conditions governing Beyond Rewards online contests and
                        lucky draws can be found here and are hereby incorporated by reference into
                        these Member Conditions.
                      </p>
                    </li>
                    <li>
                      <h4>Fees</h4>
                      <p>
                        8.1 Beyond Rewards may offer the use of any Service, subject to payment of a
                        fee or other charges to Beyond Rewards. If you wish to use such Service, you
                        shall make full and prompt payment to Beyond Rewards of the applicable fees
                        in accordance with the payment terms specified by Beyond Rewards at the
                        point of transaction.
                        <br />
                        8.2 You acknowledge and agree that Beyond Rewards in its sole and absolute
                        discretion, may now or in the future impose a fee or vary any fee for any
                        Service by notifying you.
                        <br />
                        8.3 In the event that any fee for any Service is stated erroneously on the
                        Website/Mobile app or otherwise, as determined in the sole discretion of
                        Beyond Rewards, Beyond Rewards:
                        <br />
                        8.3.1 is not obliged to provide the Service to you at the erroneous fee; and
                        <br />
                        8.3.2 shall be entitled to rectify such error by giving you written notice
                        of the error and of the correct fee.
                        <br />
                        If you do not agree to make payment of the correct fee after being notified,
                        you may terminate the Service and Beyond Rewards shall refund any fees paid
                        by you to Beyond Rewards on a pro-rated basis for the remaining and
                        unexpired portion of the term for such Service, as your sole remedy.
                        <br />
                        8.4 In the event that Beyond Rewards terminates or withdraws the operation
                        of any Service, Beyond Rewards may (but is not obliged to) refund any fees
                        paid by you to Beyond Rewards, on a pro-rated basis for the remaining and
                        unexpired portion of the term for such specific and relevant Service.
                        <br />
                        8.5 If your Account is terminated at any time by Beyond Rewards for breach
                        of the Agreement, or if you cancel any payable Service, you shall not be
                        entitled to any refund of any fees that have been paid to Beyond Rewards and
                        shall be liable to pay Beyond Rewards the unpaid balance of the full amount
                        of the agreed fees for the Service.
                        <br />
                        8.6 Your use or access of certain Services or Beyond Rewards Content may
                        require payment of additional charges to other third-party service
                        providers, including your telecommunications service provider or mobile
                        service provider. You shall be solely responsible for the payment of any
                        applicable telecommunications charges, data charges or other charges
                        incurred in connection with your use or access of the Services or Beyond
                        Rewards Content. You should check with your relevant third-party service
                        provider(s) if any such additional charges are applicable, prior to using,
                        accessing, or purchasing any Service or Beyond Rewards Content.
                      </p>
                    </li>
                    <li>
                      <h4>Beyond Rewards’s Legal & Regulatory Compliance Obligations </h4>
                      <p>
                        9.1 You acknowledge that Beyond Rewards may be required under applicable law
                        or upon the receipt of legitimate instructions from government authorities,
                        to carry out acts in breach of the terms of this Agreement, and you hereby
                        agree not to hold Beyond Rewards liable or responsible for any such breach
                        of the Agreement.
                      </p>
                    </li>
                    <li>
                      <h4>Evidence</h4>
                      <p>
                        10.1 You hereby agree that:
                        <br />
                        10.1.1 All records of Beyond Rewards relating to the Services, your use or
                        access of the Services or the Website/Mobile app, your Account, your
                        particulars, any Content, or this Agreement (collectively “Records”), though
                        in electronic form, are written documents, and you shall not dispute or
                        challenge the validity or enforceability of any Record on the grounds that
                        it is not a written document, is in electronic form, or was produced by or
                        is the output of a computer system, and you hereby waive any such right you
                        may have at law; and
                        <br />
                        10.1.2 the Records, though in electronic form, are original documents, and
                        you will not challenge the admissibility of any Record on the grounds that
                        it is made in electronic form.
                      </p>
                    </li>
                    <h4>App Services by Subscription</h4>
                    <p>
                      TERMS AND CONDITIONS <br />
                      For the avoidance of doubt, these Terms and Conditions are an integral part of
                      the Terms of Use for Apps (iOS) / Terms of Use for Apps (Non-iOS) (“App Terms
                      of Use”), the Member Terms and Conditions (“Member Conditions”) and the
                      Website/Mobile app Terms And Conditions (“Website/Mobile app Conditions”),
                      which should all be read as a whole.
                      <br />
                      In the event of any conflict, inconsistency or ambiguity between these Terms
                      and Conditions, the App Terms of Use (iOS) / App Terms of Use (Non-iOS), the
                      Member Conditions and/or the Website/Mobile app Conditions, the following
                      descending order of precedence will apply: (i) these Terms and Conditions,
                      (ii) the App Terms of Use (iOS) / App Terms of Use (Non-iOS), (iii) the Member
                      Conditions, (iv) the Website/Mobile app Conditions.
                      <br />
                      1. <b>Definitions</b>
                      <br />
                      1.1 All defined terms in the App Terms of Use (iOS) / App Terms of Use
                      (Non-iOS), Website/Mobile app Conditions and Member Conditions, apply to the
                      terms and conditions below unless specifically defined.
                      <br />
                      2. <b>App Services by Subscription</b>
                      <br />
                      2.1 The following provisions shall apply where access to the App Services is
                      available via a subscription service subscribed on or via the Website/Mobile
                      app (“App Subscription Service”):
                      <br />
                      2.1.1 Beyond Rewards does not guarantee that the said App Services will remain
                      available as an App Subscription Service. Beyond Rewards reserves the right to
                      alter the App Subscription Service, the types of content or services offered
                      on or via the App, the subscription period, and the fees for such App
                      Subscription Service.
                      <br />
                      2.1.2 Any gifts offered for subscribing to the App Subscription Service are
                      subject to availability of stocks. Where available, the colour and model of
                      the gift may vary from the sample shown. If the selected gift is unavailable
                      for any reason, Beyond Rewards reserves the right to replace it with another
                      product of similar value at its discretion. Beyond Rewards is also entitled to
                      withdraw any gift offered if Beyond Rewards, in its sole and absolute opinion,
                      determines that you have (i) abused the benefit through incidences that
                      include but are not limited to frequent changes of subscription plans or (ii)
                      declared false or misleading information in the subscription form.
                      <br />
                      2.1.3 In order to subscribe to an App Subscription Service, you must provide
                      us with complete and accurate payment information.
                      <br />
                      2.1.4 Beyond Rewards reserves the right to restrict, suspend or terminate your
                      subscription to the App Subscription Service if you breach any applicable
                      terms and conditions, with or without notice and without further obligation or
                      liability to you.
                      <br />
                      2.1.5 You have agreed and consented to allow Beyond Rewards to use the
                      personal details and information collected during your Account registration
                      and/or use of the Website/Mobile app or Services as set out in Clause 3 of the
                      Member Conditions and the Privacy Policy. Should you wish to withdraw such
                      consent, please notify Beyond Rewards via the “need help” section in the app.
                      ​
                    </p>
                  </ol>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default BRPrivacyPolicy;
