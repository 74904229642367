// checkedItemsSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { priveApiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getPriveCategories = createAsyncThunk(
  'fetch/prive/categories',
  async (arg, thunkAPI) => {
    const { countryCity, user } = thunkAPI.getState();
    const { selectedCity } = countryCity;

    if (!selectedCity) {
      throw Error('City not selected.');
    }

    const payload = {
      iCountryID: selectedCity.iCountryID,
      dCurrentLat: user?.latLng?.lat || selectedCity.vCityLatitude,
      dCurrentLong: user?.latLng?.lng || selectedCity.vCityLongitude,
      vCityName: selectedCity.vCityName,
      iCityID: selectedCity.iCityID,
      isPrivedeal: 'Yes',
      Language: 'en',
    };

    const response = await priveApiClient.post('/offer/categoryList', payload);

    if (response.data.status !== 1) {
      throw new Error(`prive categoryList received non zero status.`);
    }
    const categories = response.data?.DATA;
    if (!categories) {
      throw new Error(`categories not found for prive`);
    }

    return categories;
  },
);

const initialState = {
  cacheKey: undefined,
  state: LoadState.IDLE,
  categories: [],
};
export const priveCategorySlice = createSlice({
  name: 'priveCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPriveCategories.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getPriveCategories.fulfilled, (state, action) => {
      state.categories = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getPriveCategories.rejected, (state, action) => {
      console.error('getPriveCategories failed', action.error);
      state.state = LoadState.ERROR;
    });
  },
});

export const {} = priveCategorySlice.actions;

export default priveCategorySlice.reducer;
