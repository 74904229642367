import { Button, Dropdown } from 'antd';
import userImage from '../../../assets/images/user.png';
import { SvgIcon } from '../../common';
import React, { useMemo } from 'react';
import { useUserSelector } from '../../../store/selectors';
import { Link, useHistory } from 'react-router-dom';
import LogoutModal from '../../../containers/ProfileSetting/LogoutModal';
import DeleteAccountModal from '../../../containers/ProfileSetting/DeleteAccountModal';
import { openChatSupport } from '../../../utility/chat';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
dayjs.extend(advancedFormat);

const LoginBtn = () => {
  const history = useHistory();
  const { user } = useUserSelector();

  const generateNameFromEmail = (email) => {
    const parts = email?.split('@');
    if (!parts || parts.length < 2) {
      return ''; // or any default value you prefer
    }
    const username = parts[0];
    // Capitalize the first letter of each word
    return username
      .split('.')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  };

  const items = useMemo(() => {
    if (!user) {
      return [];
    } else {
      return [
        {
          key: '1',
          label: (
            <div className="user-upper">
              <h4>Hi, {user.vFirstName}👋</h4>
              <p className="light-text">Your Membership id</p>
              <p>{user.iMembershipCode}</p>
              {user.dExpiredDate && (
                <div className="exp-date">
                  Expiry Date : <span>{dayjs(user.dExpiredDate).format('Do MMM YYYY')}</span>
                </div>
              )}
            </div>
          ),
        },
        {
          key: '2',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="profile-setting-icon" viewbox="0 0 9.022 9.736" />
            </span>
          ),
          label: <Link to="/user#profile">Profile Setting</Link>,
        },
        /*{
          key: '3',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="mybooking" viewbox="0 0 11.026 9.836" />
            </span>
          ),
          label: <Link to="/user">My Bookings</Link>,
        },*/
        {
          key: '4',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="favourite-icon" viewbox="0 0 10.055 8.961" />
            </span>
          ),
          label: <Link to="/user#my_favourite">My Favourite</Link>,
        },
        {
          key: '5',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="mymembership-icon" viewbox="0 0 10.738 6.859" />
            </span>
          ),
          label: <Link to="/user#my_membership">My Membership</Link>,
        },
        /*{
          key: '6',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="mycards-icon" viewbox="0 0 10.575 7.931" />
            </span>
          ),
          label: <Link to="/user">My Cards</Link>,
        },*/
        {
          key: '7',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="gift-icon" viewbox="0 0 11.651 11.119" />
            </span>
          ),
          label: <Link to="/user#redemptions">Redemptions</Link>,
        },
        {
          key: '8',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="myoffers-icon" viewbox="0 0 10.083 10.096" />
            </span>
          ),
          label: <Link to="/user#my_offers">My Offers</Link>,
        },
        /*{
          key: '9',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="notification-icon" viewbox="0 0 8.315 9.262" />
            </span>
          ),
          label: <Link to="/user">Notification Setting</Link>,
        },*/
        {
          key: '10',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="outlet-icon" viewbox="0 0 9.169 8.724" />
            </span>
          ),
          label: <Link to="/user#refer_an_outlet">Refer an Outlet</Link>,
        },
        /*{
          key: '11',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="terms" viewbox="0 0 7.55 9.38" />
            </span>
          ),
          label: <Link to="/terms-conditions">Terms & Conditions</Link>,
        },*/
        {
          key: '12',
          icon: (
            <span className="menu-icons">
              <SvgIcon name="mice" viewbox="0 0 13.753 13.407" />
            </span>
          ),
          label: (
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                openChatSupport();
              }}
            >
              Need Help?
            </Link>
          ),
        },
        {
          key: '13',
          label: <LogoutModal />,
        },
        /*{
          key: '14',
          label: <DeleteAccountModal />,
        },*/
      ];
    }
  }, [user]);

  return user ? (
    <Dropdown overlayClassName="user-drop-menu" menu={{ items }} placement="bottomRight">
      <Button className="user-right-btn">
        {user.vFirstName || generateNameFromEmail(user.vEmail)}
        <div className="user-rightbtn-img">
          <img src={userImage} alt="Amelia" />
        </div>
      </Button>
    </Dropdown>
  ) : (
    <Button
      onClick={() => {
        history.push('/login');
      }}
      className="login-btn"
      size="small"
    >
      Login <SvgIcon name="user" viewbox="0 0 15.381 15.381" />
    </Button>
  );
};

export default LoginBtn;
