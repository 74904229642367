import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const NBFPrivacyPolicy = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    NBF Rewards
                  </h1>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Privacy Policy
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/nbf-terms-and-conditions">
                    Terms and Conditions{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  National Bank of Fujairah PJSC (“NBF”) provides ‘discounted offers’ and ‘Buy one
                  get one free’ offers at participating retailers via the “NBF Rewards” ('Mobile
                  App") Mobile Application. The Offers are managed by a third party, known as
                  TravellerPass LLC (“TravellerPass”). National Bank of Fujairah PJSC (“the Bank”)
                  considers your privacy as its top priority the safety and protection of your
                  personal data. Your personal data includes any information that may lead, directly
                  or combined with other information, to your identification or tracing as a natural
                  person. Personal data may include details such as name and surname, national
                  identity number , passport number, natural/electronic addresses, phone/mobile
                  phone numbers, credit/debit/prepaid card numbers, e-mails, transactions’ data,
                  telephone and electronic communications data, payments data, identification
                  details of equipment or terminal appliances, as POS, PC, smartphone, tablet,
                  browser history (log files, cookies etc.), as well as any other piece of
                  information that may allow your identification, in accordance with the provisions
                  of the applicable laws in the UAE.
                  <br />
                  By accessing the Mobile App provided by the Bank, and by pressing I Agree,
                  whenever this is required, The Customer unreservedly accept the practices
                  described in this policy, whose terms will govern our contractual relationship and
                  form part of the terms of use of each of the Bank’s services.
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h4>Objective:</h4>
                      <p>
                        This Privacy Policy aims to inform you on the terms of collection,
                        processing and transfer of your personal data collected by the Bank.
                      </p>
                    </li>
                    <li>
                      <h4>Personal Data Collection:</h4>
                      <p>
                        National Bank of Fujairah, will always only ask for the minimum personal
                        data, so that you are able to use and benefit from the Mobile App. Such
                        personal data includes,your name/user code, password, number, and validity
                        and expiration date of debit/credit/prepaid card, telephone number, e-mail,
                        postal address and device location services, if enabled on the mobile app.
                        The Bank retains your personal data only for as long as required by the
                        contractual terms of each service, in combination with the applicable
                        financial, banking, tax, telecommunication and other laws in the UAE,
                        related to the respective processing purpose, while afterwards it anonymises
                        or destroys them.
                      </p>
                    </li>

                    <li>
                      <h4>What is the information used for with regards to the Mobile App?</h4>
                      <p>
                        2.1 Information which you provide may be used and disclosed to affiliates,
                        business partners and contractors for the following purposes:
                        <br />
                        2.1.1 to respond to your requests and queries;
                        <br />
                        2.1.2 to provide goods and services to you;
                        <br />
                        2.1.3 to verify and process your personal particulars;
                        <br />
                        2.1.4 to communicate with you;
                        <br />
                        2.1.5 to enforce contractual and legal rights and obligations;
                        <br />
                        2.1.6 for marketing research, user profile and statistical analysis;
                        <br />
                        2.1.7 to send you information, promotions, updates, and marketing and
                        advertising materials in relation to our goods and services and those of
                        third party organizations;
                        <br />
                        2.1.8 complying with law, the requests of law enforcement and regulatory
                        officials, or orders of court; and
                        <br />
                        2.1.9 for any other purposes for which we have obtained your consent,
                        including during the registration process under our Member Terms and
                        Conditions.
                        <br />
                        2.2.0 Location Services
                        <br />- One of the key features of the mobile app is its ability to locate
                        physical real-world outlets featuring offers listed within the app that are
                        nearest to the user. In order to provide this functionality, the app
                        requires access to device's location services.
                        <br />- Enabling location services is essential for users to redeem the
                        offers listed on the app when physically present at an outlet.
                        <br />- Additionally, enabling location services all the time facilitates
                        the operation of background location functionalities, thereby enabling users
                        to receive app notifications upon passing by designated areas where specific
                        offers listed on the mobile application are accessible at physical outlets.
                        Users retain full autonomy over notifications and may opt out via the app's
                        notification settings or deactivate location services all together through
                        their device settings.
                        <br />- We understand the importance of privacy and assure you that your
                        location data is used solely for the purpose of providing these features
                        within the app. We do not share your location data with third parties
                        without your explicit consent. - By using our app and enabling location
                        services, you consent to the collection and use of your location data as
                        described in this privacy policy.
                      </p>
                    </li>
                    <li>
                      <h4>Instances of Personal Data Collection</h4>
                      <p>
                        he Bank collects your personal data in the following instances:
                        <br />
                        <br />
                        When you register for the Mobile App.
                        <br />
                        <br />
                        Upon your registration, and in the event your contact data is formatted to
                        hard-copied or electronic lists so that you receive informative material or
                        other marketing material in the form of prospectuses, electronically or by
                        SMS or so that you renew your preferences or upon your participation in
                        competitions, questionnaires and surveys, if you explicitly agree to such
                        material being forwarded to you.
                        <br />
                        <br />
                        Upon your communication with your Bank’s HelpDesk, with your enquiries
                        and/or claims. Upon the submission to us of documents, that provide
                        information for your protection against fraud or money laundering or combat
                        against financial and electronic crime.
                      </p>
                    </li>
                    <li>
                      <h4> Processing Principles</h4>
                      <p>
                        The Bank will use your information for the following lawful processing
                        purposes, namely:
                        <br />
                        <br />
                        For the completion of your request to register in and use of your Mobile
                        App.
                        <br />
                        <br />
                        To answer on your applications and questions, indicatively for the provision
                        of information on transactions, wallet status, and service queries.
                        <br />
                        <br />
                        For internal operations and analysis, such as internal management,
                        prevention of fraud, AML under UAE laws, and for use by information
                        technology management, invoicing, accounting, billing and auditing systems.
                      </p>
                    </li>
                    <li>
                      <h4>Third parties</h4>
                      <p>
                        When you use your Mobile App and you provide us with personal information,
                        the Bank operates as intermediary and it does not transfer nor share your
                        information, nor even among the Participants in Mobile App or third parties,
                        but only to the extent it is necessary for the completion of your
                        instructions.
                        <br />
                        <br />
                        We choose reliable providers and we try to set contractual restrictions to
                        third parties who receive your personal data to ensure that they use them in
                        accordance with this policy and the applicable in the UAE. Except that, we
                        advise you to carefully read the personal data protection practices of any
                        third-party providers/suppliers, whose products you buy via our Mobile App.
                        In addition, such third parties may contact you, if necessary, to receive
                        additional information about a potential service, or payment of yours.
                        <br />
                        <br />
                        The Bank takes the appropriate technical and organizational measures to
                        ensure that your personal information is transferred, stored and processed
                        in accordance with the appropriate security standards and with the
                        provisions of this policy and the applicable data protection laws of the
                        United Arab Emirates.
                        <br />
                        <br />
                        Finally, the Bank may transfer or reveal your personal data to official or
                        national state and supervisory bodies (e.g. police, Central Bank of the UAE,
                        etc.) when we are asked to comply with the law and to prevent any unlawful
                        actions (e.g. fraud, money laundering etc.) to our or our customers’
                        detriment.
                      </p>
                    </li>
                    <li>
                      <h4>Data Security</h4>
                      <p>
                        The Bank has in place proper security policies and it uses the appropriate
                        technical and operational tools, as anonymization, data encryption, use of
                        firewalls, setting of access rights, authorizations, training of personnel,
                        periodic audits, compliance with international ISO security and business
                        continuity standards, PCI for protection of data and payment cards etc.
                        <br />
                        <br />
                        Any authorised partner of the Bank who has access to the above information
                        uses them to exclusively serve the above purposes. The Bank shares the
                        information you provide to it exclusively in the manners described in this
                        Policy and in accordance with your explicit and special consent per type of
                        processing, which you may at any time and freely withdraw by communicating
                        with the Bank.
                      </p>
                    </li>
                    <li>
                      <h4>In compliance with the Privacy Policy for Android Apps </h4>
                      <p>
                        The Bank is the owner of the application that you use to access the Services
                        <br />
                        <br />
                        The data we collect is used for the provision of the Services; completion of
                        your registration, identification and verification, Transaction processing,
                        and fraud monitoring and prevention.
                        <br />
                        <br />
                        The data is collected in compliance with privacy and data protection laws in
                        the UAE.
                        <br />
                        <br />
                        When third parties access your information, it is solely for the purpose of
                        Transaction processing, and no data is retained by these third parties
                        beyond what is mandated by the applicable law of the UAE. These parties will
                        provide the same or equal protection of your data as required by the
                        applicable law in the UAE.
                        <br />
                        <br />
                        You may request a change in the status of your data if permitted under the
                        applicable law of the UAE.
                        <br />
                        <br />
                        You will be notified by the Bank through standard channels of communication
                        of any change in this Privacy Policy.
                      </p>
                    </li>
                    <li>
                      <h4> In compliance with the Privacy Policy for iOS Apps</h4>
                      <p>
                        The Bank is the owner of the application that you use to access the Services
                        <br />
                        <br />
                        The data we collect is used for the provision of the Services; completion of
                        your registration, identification and verification, Transaction processing,
                        and fraud monitoring and prevention.
                        <br />
                        <br />
                        The data is collected in compliance with privacy and data protection laws in
                        the UAE.
                        <br />
                        <br />
                        When third parties access your information, it is solely for the purpose of
                        Transaction processing, and no data is retained by these third parties
                        beyond what is mandated by the applicable law of the UAE. These parties will
                        provide the same or equal protection of your data as required by the
                        applicable law in the UAE.
                        <br />
                        <br />
                        You may request a change in the status of your data if permitted under the
                        applicable law of the UAE.
                      </p>
                    </li>
                    <p>
                      You will be notified by the Bank through standard channels of communication of
                      any change in this Privacy Policy.
                    </p>
                  </ol>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default NBFPrivacyPolicy;
