import React, { useEffect, useMemo, useState } from 'react';
import { SvgIcon } from '../../components/common';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Button, Checkbox, Divider, Form, Input } from 'antd';
import './index.scss';

import logoImage from '../../assets/images/logo-light.svg';
import logoImage1 from '../../assets/images/logo.svg';
import AwesomeImg from '../../assets/images/tp-left.png';
import VideoOne from '../../assets/video/bg_auth.mp4';
import { useDispatch } from 'react-redux';
import { useLoaderContext } from '../../components/common/Loader';
import { logEvent } from '../../utility/analytics';
import { apiClient } from '../../apiConfig';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { Alert } from '../../common';
import { setAccessToken, setPriveCredential, setUser } from '../../slice/userSlice';
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { auth } from '../../utility/firebase';

const Signup = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setShowLoader } = useLoaderContext();
  const { redirect } = location.state || {};

  const [formData, setFormData] = useState({
    firstName: '',
    email: '',
    mobile: '',
    mobileCountryCode: '',
    agree: false,
  });
  const [mobileNumberValid, setMobileNumberValid] = useState(false);

  const isValid = useMemo(() => {
    return (
      mobileNumberValid &&
      formData.email &&
      /\S+@\S+\.\S+/.test(formData.email) &&
      formData.firstName &&
      /^[A-Za-z\s]+[.]?[A-Za-z\s]*$/.test(formData.firstName) &&
      formData.mobile &&
      formData.mobileCountryCode &&
      formData.agree
    );
  }, [mobileNumberValid, formData]);

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;
    let inputValue = value;

    // Handle select input separately
    if (type === 'checkbox') {
      inputValue = checked;
    } else if (type === 'select-one') {
      inputValue = e;
    }

    setFormData({ ...formData, [name]: inputValue });
  };

  const handleCheckEmail = async () => {
    try {
      const { email } = formData;
      if (!email) {
        console.error('Please provide an email address.');
        return;
      }

      logEvent('email_register_initiated');

      setShowLoader(true);
      // Send checkEmail request
      const response = await apiClient.post(
        '/admin/checkEmail',
        { vEmail: email },
        // { headers: headers }
      );

      // Handle the response and proceed accordingly
      if (response.data) {
        // Check the MESSAGE and status conditions
        if (
          response.data.MESSAGE ===
            'Looks like you have registered with us already, please login with your email.' ||
          response.data.status === 0
        ) {
          Alert.error(response.data.MESSAGE);
          logEvent('signup_check_email_error', { message: response.data.MESSAGE });
          // Navigate to login screen
          history.push('/login', {
            redirect: redirect,
          });
        } else {
          Alert.success(response.data.MESSAGE);
          logEvent('signup_check_email_success');
          // Navigate to enter-otp screen
          history.push(`/verifyOtp`, { from: location, redirect: redirect, formData: formData });
        }
      } else {
        console.error('Check email failed: DATA not found in response');
      }
    } catch (error) {
      console.error('Error checking email:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const authUser = async (token) => {
    try {
      setShowLoader(true);
      const response = await apiClient.post('/admin/doLoginWithIdToken', {
        idToken: token,
      });
      if (response.data) {
        if (response.data.status === 1) {
          dispatch(setPriveCredential(undefined));
          dispatch(setAccessToken(response.data.DATA.accesstoken));
          dispatch(setUser(response.data.DATA));

          let redirectUrl = window.location.origin;
          if (redirect) {
            if (redirect.pathname.startsWith('/')) {
              redirectUrl += redirect.pathname + redirect.search;
            } else {
              redirectUrl += '/' + redirect.pathname + redirect.search;
            }
          }
          window.location.href = redirectUrl;
        } else {
          if (response.data?.MESSAGE) {
            Alert.error(response.data.MESSAGE);
          }
          console.error('Unknown status:', response.data.status);
        }
      }
    } catch (error) {
      console.error('Error login with idToken:', error);
    } finally {
      setShowLoader(false);
    }
  };

  const handleGoogle = () => {
    signInWithPopup(auth, new GoogleAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            //console.log(token);
            authUser(token);
          })
          .catch((error) => {});
        // ...
        //console.log(token);
      })
      .catch((error) => {
        console.error(error);
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          Alert.error('You have used another provider before to login/signup please use the same.');
        }
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleFacebook = () => {
    signInWithPopup(auth, new FacebookAuthProvider())
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            //console.log(token);
            authUser(token);
          })
          .catch((error) => {});
        // ...
        //console.log(token);
      })
      .catch((error) => {
        console.error(error);
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          Alert.error('You have used another provider before to login/signup please use the same.');
        }
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = FacebookAuthProvider.credentialFromError(error);
        // ...
      });
  };

  const handleApple = () => {
    signInWithPopup(auth, new OAuthProvider('apple.com'))
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = OAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        user
          .getIdToken()
          .then((token) => {
            console.log(token);
            authUser(token);
          })
          .catch((error) => {
            console.error(error);
          });
        // ...
        //console.log(token);
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        if (errorCode === 'auth/account-exists-with-different-credential') {
          Alert.error('You have used another provider before to login/signup please use the same.');
        }
        const errorMessage = error.message;
        console.log(errorMessage);
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = OAuthProvider.credentialFromError(error);
        // ...
      });
  };

  useEffect(() => {
    logEvent('register_screen');
  }, []);

  return (
    <div className="auth-wrapper">
      <div className="auth-left">
        <div className="authleft-inner">
          <div>
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>
              Discover <br />
              Your City
            </h1>
          </div>
          <div>
            <div className="awse-img">
              <img src={AwesomeImg} alt="Awesome" />
            </div>
            <h3>Awesome!</h3>
            <p>It's easy to use and very handy. It helps a lot to save more. Worth every penny</p>
            <div className="left-name">Nikol</div>
          </div>
        </div>
        <video playsInline autoPlay muted loop className="banner-video">
          <source src={VideoOne} />
        </video>
      </div>
      <div className="auth-right">
        <div className="authright-inner">
          <div className="right-upper">
            <div className="mobile-logo">
              <Link to="/">
                <img src={logoImage1} alt={logoImage1} />
              </Link>
            </div>
            <div className="upper-inner">
              <h2>Register</h2>
              <div className="right-head">
                <span>Already have an account?</span>
                <Link to="/login" className="auth-upper-btn ant-btn" replace>
                  Log In
                </Link>
              </div>
            </div>
          </div>
          <div className="right-form">
            <Form name="basic" autoComplete="off" layout="vertical">
              <Form.Item label="First Name">
                <Input
                  name="firstName"
                  prefix={<SvgIcon name="user" viewbox="0 0 15.381 15.381" />}
                  onChange={handleInputChange}
                  placeholder="Enter"
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  prefix={<SvgIcon name="email-icon" viewbox="0 0 30 30" />}
                  placeholder="Enter"
                />
              </Form.Item>
              <Form.Item label="Mobile">
                <PhoneInput
                  country={'ae'}
                  countryCodeEditable={false}
                  preferredCountries={['ae', 'in', 'om']}
                  autoFormat
                  enableSearch
                  onChange={(value, country) => {
                    const countryCode = country.dialCode;
                    const phoneNumber = value.slice(country.dialCode.length);
                    setFormData({
                      ...formData,
                      mobile: phoneNumber,
                      mobileCountryCode: countryCode,
                    });
                  }}
                  isValid={(value, country) => {
                    const countryCode = country.dialCode;
                    const phoneNumber = value.slice(countryCode.length);

                    const length = phoneNumber.replace(/\s+/, '').length;

                    if (
                      (countryCode === '971' && length === 9) ||
                      (countryCode === '968' && length === 8) ||
                      (countryCode !== '971' && countryCode !== '968' && length === 10)
                    ) {
                      setMobileNumberValid(true);
                      return true;
                    }

                    if (
                      (countryCode === '971' && length > 9) ||
                      (countryCode === '968' && length > 8) ||
                      (countryCode !== '971' && countryCode !== '968' && length > 10)
                    ) {
                      setMobileNumberValid(false);
                      return 'Invalid phone number.';
                    }

                    setMobileNumberValid(false);
                    return true;
                  }}
                />
              </Form.Item>
              <Form.Item className="agree-check">
                <Checkbox name="agree" checked={formData.agree} onChange={handleInputChange}>
                  I agree to the{' '}
                  <Link to="/terms-conditions">
                    <span className="terms-link">Terms and Conditions</span>
                  </Link>
                </Checkbox>
              </Form.Item>
              <Form.Item className="m-0 py-3">
                <Button
                  type="secondary"
                  htmlType="submit"
                  block
                  onClick={handleCheckEmail}
                  disabled={!isValid}
                >
                  Sign Up
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="auth-right-footer">
            <Divider>Or Sign up with your details</Divider>
            <ul>
              <li>
                <Button onClick={handleApple}>
                  <SvgIcon name="apple" viewbox="0 0 22.982 28.234" />
                </Button>
              </li>
              <li>
                <Button onClick={handleGoogle}>
                  <SvgIcon name="google" viewbox="0 0 28.688 29.243" />
                </Button>
              </li>
              <li>
                <Button onClick={handleFacebook}>
                  <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
