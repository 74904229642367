import React, { useEffect, useState } from 'react';
import { FloatLabel } from '../../components/common';
import { Button, DatePicker, Form, Input, Select } from 'antd';
import dayjs from 'dayjs';
import './index.scss';
import { useDispatch } from 'react-redux';
import { useUserSelector } from '../../store/selectors';
import { Alert } from '../../common';
import { useLoaderContext } from '../../components/common/Loader';
import { apiClient } from '../../apiConfig';
import { setUser } from '../../slice/userSlice';

const dateFormat = 'YYYY/MM/DD';

const ProfileSettingTab = () => {
  const dispatch = useDispatch();
  const { user } = useUserSelector();
  const { setShowLoader } = useLoaderContext();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [dob, setDob] = useState(null);
  const [gender, setGender] = useState('');
  const [maritalStatus, setmaritalStatus] = useState('');
  const [minimumSpend, setminimumSpend] = useState('');

  const generateNameFromEmail = (email) => {
    const parts = email?.split('@');
    if (!parts || parts.length < 2) {
      return ''; // or any default value you prefer
    }
    const username = parts[0];
    // Capitalize the first letter of each word
    return username
      .split('.')
      .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
      .join(' ');
  };

  const handleSubmit = async () => {
    if (name && name.trim().length === 0) {
      Alert.error('Invalid name');
      return;
    }
    const countryCode = user?.vMobileCountryCode;
    if (
      countryCode &&
      mobile &&
      mobile.match(/[0-9]+/) &&
      ((countryCode === '+971' && mobile.length !== 9) ||
        (countryCode === '+968' && mobile.length !== 8) ||
        (countryCode !== '+971' && countryCode !== '+968' && mobile.length !== 10))
    ) {
      Alert.error('Invalid phone number.');
      return;
    }

    setShowLoader(true);
    try {
      let response = await apiClient.post('/user/editProfile', {
        vEmail: email,
        dBirthdate: dob,
        vMobileNo: mobile,
        eGender: gender,
        eMaritalStatus: maritalStatus,
        vUserName: name,
        iSpend: minimumSpend,
      });
      if (response.data.status === 1) {
        try {
          const res = await apiClient.get('/user/setting');
          if (res.data.status === 1) {
            dispatch(setUser(res.data.DATA));
          }
        } catch (error) {
          if (error.response.status) {
            // localStorage.removeItem("userData");
            // localStorage.removeItem("selectedCity");
          }
        }
      }
    } catch (error) {
      // console.log((error));
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (user) {
      if (user.vUserName) {
        setName(user.vUserName);
      } else {
        setName(generateNameFromEmail(user.vEmail));
      }
      if (user.vMobileNo) {
        setMobile(user.vMobileNo);
      }
      if (user.eGender) {
        setGender(user.eGender);
      }
      if (user.iSpend) {
        setminimumSpend(user.iSpend);
      }
      setEmail(user.vEmail);
      if (user.dBirthdate && user.dBirthdate !== '') {
        const date = dayjs(user.dBirthdate, 'YYYY-MM-DD');
        if (date.isValid()) {
          setDob(date);
        }
      }
      setGender(user.eGender);
      setmaritalStatus(user.eMaritalStatus);
    }
  }, [user]);

  return (
    <div className="right-tab-inner">
      <h1 className="tab-heading">Profile Setting</h1>
      <div className="tab-content profile-setting" data-aos="fade-in" data-aos-duration="800">
        <Form name="basic" autoComplete="off" layout="vertical">
          <Form.Item className="icon-less">
            <FloatLabel label="Name" name="name" value={name}>
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Email" name="email" value={email}>
              <Input value={email} readOnly />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Mobile" name="mobile" value={mobile}>
              <Input
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Date of Birth" name="dob" value={dob}>
              <DatePicker value={dob} onChange={(e) => setDob(e)} format="YYYY-MM-DD" />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Gender" name="gender" value={gender}>
              <Select
                value={gender}
                suffixIcon={false}
                onChange={(value) => {
                  setGender(value);
                }}
                options={[
                  { value: 'male', label: 'Male' },
                  { value: 'female', label: 'Female' },
                ]}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Marital Status" name="maritalStatus" value={maritalStatus}>
              <Select
                value={maritalStatus}
                onChange={(val) => setmaritalStatus(val)}
                suffixIcon={false}
                options={[
                  { value: 'single', label: 'Single' },
                  { value: 'married', label: 'Married' },
                ]}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="icon-less">
            <FloatLabel label="Minimum Spend" name="minimumSpend" value={minimumSpend}>
              <Input
                value={minimumSpend}
                onChange={(e) => setminimumSpend(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item className="m-0 py-3">
            <Button type="secondary" htmlType="submit" onClick={handleSubmit}>
              Update
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ProfileSettingTab;
