import { withRouter } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

const MetaInfo = withRouter((props) => {
  const { location, metaInfo } = props;
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const url = window?.location.href || 'unknown';

  const setDefaultInfo = () => {
    setTitle(
      'TravellerPass | Unlock 2-for-1 & up to 50% Off Deals with TravellerPass – UAE’s Best Savings App',
    );
    setDescription(
      'Save big with TravellerPass! Enjoy unlimited 2-for-1 and up to 50% off discounts at 3000+ venues across the UAE. Dining, shopping, leisure & more—start exploring today!',
    );
  };

  useEffect(() => {
    const { pathname, search } = location;
    console.log('Search', search + ' ' + typeof metaInfo);
    if (metaInfo) {
      const key = Object.keys(metaInfo).find((s) => {
        if (s.toLowerCase().includes(pathname.toLowerCase())) {
          const url = new URL(s, 'https://travellerpass.com');

          const param1 = new URLSearchParams(search);
          const param2 = new URLSearchParams(url.search);

          if (pathname === '/') {
            let c1 = param1.get('vCityName');
            let c2 = param1.get('vCityName');

            console.log(c1 + ' ' + c2);
            if (c1 && c2) {
              c1 = decodeURIComponent(c1).toLowerCase().replace(/\s+/, '');
              c2 = decodeURIComponent(c2).toLowerCase().replace(/\s+/, '');
              return c1 === c2;
            }
          }
          if (pathname === '/listing') {
            return param1.get('categoryId') === param2.get('categoryId');
          }
          /*if (pathname === '/global-details') {
            return param1.get('productId') === param2.get('productId');
          }
          if (pathname === '/group-listing-page') {
            if (param1.has('vCityName') && !param2.has('vCityName')) {
              return false;
            }
            if (param1.has('vCityName') && param2.has('vCityName')) {
              return (
                param1.get('groupId') === param2.get('groupId') &&
                param1.get('vCityName') === param2.get('vCityName')
              );
            }
          }*/
          return s.toLowerCase() === pathname.toLowerCase();
        }

        return false;
      });
      if (key) {
        setTitle(metaInfo[key].title);
        setDescription(metaInfo[key].desc);
        return;
      }
    }
    setDefaultInfo();
  }, [metaInfo, location]);

  return (
    <Helmet
      defer={false}
      title={title}
      htmlAttributes={{ lang: 'en' }}
      link={[
        {
          rel: 'canonical',
          href: url,
        },
      ]}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:site_name',
          content: 'Discover Your City',
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: url,
        },
        {
          property: 'og:locale',
          content: 'en_US',
        },
        {
          property: 'og:image',
          content: 'https://discoveryourcity.io/logo1200.png',
        },
      ]}
    />
  );
});

export default MetaInfo;
