import * as React from 'react';
const TravellerLogoVertical = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 12.56 71.822"
    width={12.56}
    height={71.822}
    {...props}
  >
    <defs>
      <clipPath id="b">
        <path
          fill="url(#a)"
          stroke="rgba(0,0,0,0)"
          d="M.425.425a1.461 1.461 0 0 0 0 2.06l.1.1L3.738 5.8l1.01-1.011A1.481 1.481 0 0 0 4.865 2.8L2.485.425a1.461 1.461 0 0 0-2.06 0"
          data-name="Path 177454"
        />
      </clipPath>
      <clipPath id="c">
        <path
          fill="url(#a)"
          stroke="rgba(0,0,0,0)"
          d="M5.254 1.56 3.573 3.242.427 6.387a1.456 1.456 0 0 0 2.06 2.06L7.334 3.6a1.456 1.456 0 0 0 0-2.06c-.029-.029-.06-.056-.091-.082s-.053-.061-.082-.09L5.8 0a1.863 1.863 0 0 1-.546 1.56"
          data-name="Path 177455"
        />
      </clipPath>
      <linearGradient id="a" x1={0.5} x2={1} y2={0.738} gradientUnits="objectBoundingBox">
        <stop offset={0} stopColor="#ffa741" />
        <stop offset={1} stopColor="#ff7541" />
      </linearGradient>
    </defs>
    <g data-name="Group 32087">
      <g data-name="Group 29747">
        <g data-name="Group 29741">
          <g clipPath="url(#b)" data-name="Group 29740" transform="rotate(90 6.256 6.305)">
            <path
              fill="url(#a)"
              d="M0 0h7.975v7.975H0z"
              data-name="Rectangle 7264"
              transform="rotate(-45 1.979 5.12)"
            />
            <path
              fill="rgba(0,0,0,0)"
              d="m-3.319 2.899 5.918-5.918L8.517 2.9 2.599 8.817Zm5.918-5.36-5.36 5.36 5.36 5.36 5.36-5.36Z"
              data-name="Rectangle 7264 - Outline"
            />
          </g>
        </g>
        <g data-name="Group 29743">
          <g clipPath="url(#c)" data-name="Group 29742" transform="rotate(90 4.437 4.437)">
            <path
              fill="url(#a)"
              d="M0 0h8.045v9.015H0z"
              data-name="Rectangle 7265"
              transform="translate(-.142)"
            />
            <path
              fill="rgba(0,0,0,0)"
              d="M-.339-.197H8.1v9.409H-.339Zm8.045.394H.055v8.621h7.651Z"
              data-name="Rectangle 7265 - Outline"
            />
          </g>
        </g>
      </g>
      <g fill="#132c4a" data-name="Group 30857">
        <path
          d="M9.153 17.567v1a1.269 1.269 0 0 1-.294.919 1.586 1.586 0 0 1-1.166.381 2 2 0 0 1-.968-.2.864.864 0 0 1-.452-.576l-2.728 1.026v-.767l2.746-1.027H3.545v-.756Zm-2.484.756a.8.8 0 0 0 .2.621 1.027 1.027 0 0 0 .686.182 2.25 2.25 0 0 0 .447-.038.8.8 0 0 0 .3-.12.482.482 0 0 0 .175-.211.787.787 0 0 0 .055-.307v-.127Z"
          data-name="Path 177456"
        />
        <path
          d="M9.153 23.434v.589l-5.608 1.2v-.746l1.093-.211v-1.111l-1.093-.215v-.726Zm-3.93.723.893-.167q.912-.167 1.947-.276-.5-.051-1.022-.131t-1.1-.189l-.719-.138Z"
          data-name="Path 177457"
        />
        <path
          d="M9.153 27.029v.744l-2.07.338-.374.058-.814.12-.378.052-.309.042-.276.036.149.018c.044.005.08.01.109.015l.65.1.854.127.215.035.174.031 2.07.361v.7l-5.608-1.1v-.6Z"
          data-name="Path 177458"
        />
        <path
          d="M9.153 32.159v2.009h-.639v-1.253H6.737v1.111h-.639v-1.111H4.184v1.253h-.639v-2.009Z"
          data-name="Path 177459"
        />
        <path d="M9.153 36.796v.756H4.184v1.235h-.639v-1.991Z" data-name="Path 177460" />
        <path d="M9.153 41.192v.756H4.184v1.235h-.639v-1.991Z" data-name="Path 177461" />
        <path
          d="M9.153 45.589v2.009h-.639v-1.253H6.737v1.111h-.639v-1.111H4.184v1.253h-.639v-2.009Z"
          data-name="Path 177462"
        />
        <path
          d="M9.153 50.226v1a1.269 1.269 0 0 1-.294.919 1.586 1.586 0 0 1-1.166.381 2 2 0 0 1-.968-.2.866.866 0 0 1-.452-.576l-2.728 1.026v-.766l2.746-1.028H3.545v-.756Zm-2.484.756a.8.8 0 0 0 .2.621 1.027 1.027 0 0 0 .686.181 2.228 2.228 0 0 0 .447-.038.8.8 0 0 0 .3-.12.483.483 0 0 0 .175-.21.788.788 0 0 0 .055-.307v-.127Z"
          data-name="Path 177463"
        />
        <path
          d="M9.153 55.294v1a1.342 1.342 0 0 1-.363 1.041 1.718 1.718 0 0 1-1.162.327q-1.119 0-1.445-.657a2.4 2.4 0 0 1-.149-.955H3.545v-.756Zm-2.474.756a.873.873 0 0 0 .189.644.994.994 0 0 0 .685.183 1.232 1.232 0 0 0 .732-.173.642.642 0 0 0 .232-.549v-.105Z"
          data-name="Path 177464"
        />
        <path
          d="M9.153 60.807v.588l-5.608 1.2v-.746l1.093-.211v-1.111l-1.093-.215v-.726Zm-3.93.723.893-.167q.912-.168 1.947-.277-.5-.05-1.022-.13t-1.1-.189l-.719-.138Z"
          data-name="Path 177465"
        />
        <path
          d="M9.037 66.845H8.3a1.109 1.109 0 0 0 .247-.622.624.624 0 0 0-.211-.486.744.744 0 0 0-.534-.189.92.92 0 0 0-.5.134 2.2 2.2 0 0 0-.291.227q-.179.157-.429.409a3.368 3.368 0 0 1-.81.628 1.781 1.781 0 0 1-.81.167 1.572 1.572 0 0 1-1.071-.367 1.2 1.2 0 0 1-.41-.94 1.305 1.305 0 0 1 .265-.825h.743a1.286 1.286 0 0 0-.294.734.6.6 0 0 0 .205.483.833.833 0 0 0 .557.174 1.117 1.117 0 0 0 .549-.134 2.287 2.287 0 0 0 .267-.193q.156-.123.336-.308.283-.287.5-.476a2.338 2.338 0 0 1 .382-.28 1.676 1.676 0 0 1 .807-.174 1.515 1.515 0 0 1 1.043.341 1.178 1.178 0 0 1 .388.927 1.641 1.641 0 0 1-.192.77"
          data-name="Path 177466"
        />
        <path
          d="M9.037 71.554H8.3a1.109 1.109 0 0 0 .247-.621.623.623 0 0 0-.211-.487.744.744 0 0 0-.534-.189.918.918 0 0 0-.5.134 2.148 2.148 0 0 0-.291.227q-.179.158-.429.409a3.35 3.35 0 0 1-.81.628 1.777 1.777 0 0 1-.81.167 1.572 1.572 0 0 1-1.076-.366 1.2 1.2 0 0 1-.41-.94 1.3 1.3 0 0 1 .265-.825h.748a1.285 1.285 0 0 0-.294.733.6.6 0 0 0 .205.483.833.833 0 0 0 .557.174 1.12 1.12 0 0 0 .549-.134 2.236 2.236 0 0 0 .267-.193c.1-.082.212-.185.336-.308q.283-.287.5-.476a2.348 2.348 0 0 1 .382-.28 1.671 1.671 0 0 1 .807-.174 1.515 1.515 0 0 1 1.043.342 1.178 1.178 0 0 1 .388.926 1.64 1.64 0 0 1-.192.77"
          data-name="Path 177467"
        />
        <path d="M3.545 14.922v-.755h4.969v-1.235h.639v1.99Z" data-name="Path 177468" />
      </g>
    </g>
  </svg>
);
export default TravellerLogoVertical;
