import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { Button, Dropdown, Menu } from 'antd';
import { Col, Container, Row, SvgIcon } from '../../common';
import classNames from 'classnames';
import './index.scss';

import logoImage from '../../../assets/images/logo-icon.svg';
import DownloadImg from '../../../assets/images/download-small-dark.png';
import LoginBtn from '../LoginBtn';
import { useUserSelector } from '../../../store/selectors';

const getAppItems = [
  {
    key: 1,
    label: (
      <div>
        <Row>
          <Col md="7" className="download-left">
            <h1 data-aos="fade-up" data-aos-duration="800">
              Download the app <br /> and start today!
            </h1>
            <p data-aos="fade-up" data-aos-duration="1000">
              Get access to endless 2 for 1 deals and flat discounts at over 3000+ venues across
              multiple categories and cities.
            </p>
            <div className="download-btns" data-aos="fade-up" data-aos-duration="1200">
              <Button
                onClick={() =>
                  window.open(
                    'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                    '_blank',
                  )
                }
              >
                <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
              </Button>
              <Button
                onClick={() =>
                  window.open(
                    'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                    '_blank',
                  )
                }
              >
                <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
              </Button>
              {/*<Button
                onClick={() =>
                  window.open('https://appgallery.huawei.com/#/app/C100681983', '_blank')
                }
              >
                <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
              </Button>*/}
            </div>
          </Col>
          <Col md="5" className="download-right">
            <div className="right-inner">
              <img
                data-aos="zoom-in"
                data-aos-duration="1800"
                className="download-img"
                src={DownloadImg}
                alt={'Download'}
              />
              <div
                className="download-circle spinner"
                data-aos="zoom-in"
                data-aos-duration="1000"
              ></div>
            </div>
          </Col>
        </Row>
      </div>
    ),
  },
];

const NavbarDefault = ({ lightText, logoOnly, hasSearch }) => {
  const location = useLocation();
  return (
    <header
      className={classNames('landing-main-header', {
        'dark-text': typeof lightText === 'undefined',
      })}
      data-aos="fade-down"
      data-aos-delay="800"
    >
      <Container>
        <div className="hader-inner">
          <div className="logo-left">
            <Link to="/">
              <img className="logo-icon" src={logoImage} alt={logoImage} />{' '}
              <SvgIcon className="logo-name" name="logoname" viewbox="0 0 122.877 12.012" />{' '}
            </Link>
          </div>
          <div className="center-menu">
            {/*{!logoOnly && (
              <Menu
                mode="horizontal"
                overflowedIndicator={<SvgIcon name="menu" viewbox="0 0 24 17.42" />}
              >
                <Menu.Item key="1">
                  <NavLink to="/home" className={location.pathname === '/' ? 'active' : ''}>
                    Home
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="2">
                  <NavLink to="/prive" className={location.pathname === '/prive' ? 'active' : ''}>
                    Prive Exclusive
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="3">
                  <NavLink
                    to="/membership/buy"
                    className={location.pathname === '/membership/buy' ? 'active' : ''}
                  >
                    Buy Membership
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="4">
                  <NavLink to="/about" className={location.pathname === '/about' ? 'active' : ''}>
                    About Us
                  </NavLink>
                </Menu.Item>
                <Menu.Item key="5">
                   <NavLink to="/blog" className={location.pathname === '/blog' ? 'active' : ''}>
                    Blog
                  </NavLink>
                  <a href="https://travellerpass.medium.com/" target="_blank">
                    Blog
                  </a>
                </Menu.Item>
                <Menu.Item key="6">
                  <NavLink
                    to="/get-listed"
                    className={location.pathname === '/get-listed' ? 'active' : ''}
                  >
                    Get Listed
                  </NavLink>
                </Menu.Item>
              </Menu>
            )}*/}
          </div>
          <div className="hedaer-right">
            {/*{!logoOnly && (
              <Dropdown
                overlayClassName="tpdownload-drop"
                arrow={false}
                menu={{ items: getAppItems }}
              >
                <Button
                  className="download-link"
                  icon={<SvgIcon name="phone" viewbox="0 0 12.18 20.438" />}
                >
                  Download App
                </Button>
              </Dropdown>
            )}*/}
            <LoginBtn />
          </div>
        </div>
      </Container>
    </header>
  );
};

export default NavbarDefault;
