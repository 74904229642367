import React from 'react';
import { Col, Container, Row } from '../../components/common';
import './index.scss';
import Logo from '../../assets/images/rsa_logo.png';
import BannerImg from '../../assets/images/rsa_banner.png';
import StoreGoogle from '../../assets/images/app_store_google.png';
import StoreApple from '../../assets/images/app_store_apple.png';

const RsaStore = () => {
  return (
    <div className="rsa-wrapper">
      <section className="rsa-banner">
        <Container>
          <Row>
            <img
              data-aos="fade-in"
              data-aos-duration="1200"
              className="rsa-logo"
              src={Logo}
              alt="RSA LOGO"
            />
          </Row>
          <Row>
            <Col md="6" className="banner-left">
              <h1 data-aos="fade-right" data-aos-duration="800">
                Explore a world of offers like never before.
              </h1>
              <p data-aos="fade-right" data-aos-duration="900">
                TravellerPass is a mobile app that gives you access to endless 2 for 1 deals and
                flat discounts at over 3000+ venues in the UAE across{' '}
                <i>dining, leisure, shopping wellness and Prive, our luxury segment.</i>
              </p>
              <div className="download-btns" data-aos="fade-up" data-aos-duration="1200">
                <img
                  className="store-icon"
                  src={StoreGoogle}
                  alt="Play Store"
                  onClick={() =>
                    window.open(
                      'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                      '_blank',
                    )
                  }
                />
                <img
                  className="store-icon"
                  src={StoreApple}
                  alt="App Store"
                  onClick={() =>
                    window.open(
                      'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                      '_blank',
                    )
                  }
                />
              </div>
            </Col>
            <Col md="6" className="banner-right">
              <img
                data-aos="fade-in"
                data-aos-duration="1200"
                className="banner-img"
                src={BannerImg}
                alt={BannerImg}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default RsaStore;
