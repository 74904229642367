import { SvgIcon } from '../index';
import React from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import './index.scss';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const CategorySlider = ({
  subCategories,
  subCategoryId,
  handleSubCategoryClick,
  priveCategories,
  priveCategoryId,
  handlePriveCategoryClick,
}) => {
  const settingsListSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 14,
    slidesToScroll: 4,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1921,
        settings: {
          slidesToShow: 13,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 10,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 8,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 6,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  if (subCategories && subCategories.length > 0) {
    return (
      <Slider className="category-listing-slider" {...settingsListSlider}>
        {subCategories.map((subCat, index) => (
          <div
            key={subCat.iSubCategoryID}
            onClick={() => {
              if (handleSubCategoryClick) {
                handleSubCategoryClick(index === 0 ? null : subCat.iSubCategoryID);
              }
            }}
          >
            <div
              className={classNames('slider-col', {
                active: subCat.iSubCategoryID === subCategoryId || (!subCategoryId && index === 0),
              })}
            >
              <div className="list-slider-icon">
                <img src={subCat.vSubCategoryImage} className="sub-cat-icon" />
                {/*<SvgIcon name="all-icon" viewbox="0 0 33.03 33.023" />*/}
              </div>
              <p>{subCat.vSubCategoryName}</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  }

  if (priveCategories && priveCategories.length > 0) {
    return (
      <Slider className="category-listing-slider" {...settingsListSlider}>
        {priveCategories.map((cat, index) => (
          <div
            key={cat.iCategoryID}
            onClick={() => {
              if (handlePriveCategoryClick) {
                handlePriveCategoryClick(index === 0 ? null : cat.iCategoryID);
              }
            }}
          >
            <div
              className={classNames('slider-col', {
                active: cat.iCategoryID === priveCategoryId || (!priveCategoryId && index === 0),
              })}
            >
              <div className="list-slider-icon">
                <img src={cat.vCategoryImage} className="sub-cat-icon" />
                {/*<SvgIcon name="all-icon" viewbox="0 0 33.03 33.023" />*/}
              </div>
              <p>{cat.vCategoryName}</p>
            </div>
          </div>
        ))}
      </Slider>
    );
  }

  return null;
};
export default CategorySlider;
