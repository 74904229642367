// checkedItemsSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getGroupListing = createAsyncThunk('fetch/deals', async (arg, thunkAPI) => {
  const { countryCity, user: userReducer } = thunkAPI.getState();
  const { selectedCity } = countryCity;
  const { user } = userReducer;
  const { cacheKey, groupId } = arg;
  if (!selectedCity) {
    throw Error('City not selected.');
  }

  if (!cacheKey) {
    throw Error('arg cacheKey not found.');
  }

  thunkAPI.dispatch(setCacheKey(cacheKey));

  const payload = {
    iCountryID: selectedCity.iCountryID,
    cardGroup_id: +groupId,
    dCurrentLat: selectedCity.vCityLatitude,
    dCurrentLong: selectedCity.vCityLongitude,
    vCityName: selectedCity.vCityName,
  };
  const url = user ? '/offer/groupMainListing' : '/guestoffer/groupMainListing';

  const response = await apiClient.post(url, payload);

  if (response.data.status !== 1) {
    throw new Error(`categoryFeaturedOfferList received non zero status.`);
  }
  return response.data;
});

const initialState = {
  cacheKey: undefined,
  state: LoadState.IDLE,
  deals: [],
  title: '',
};
export const groupListingSlice = createSlice({
  name: 'groupListing',
  initialState,
  reducers: {
    setCacheKey: (state, action) => {
      state.cacheKey = action.payload;
    },
    resetGroupListing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupListing.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getGroupListing.fulfilled, (state, action) => {
      state.deals = action.payload.DATA;
      state.title = action.payload.Title;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getGroupListing.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });
  },
});

export const { resetGroupListing, setCacheKey } = groupListingSlice.actions;

export default groupListingSlice.reducer;
