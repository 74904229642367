import React, { createContext, useContext, useEffect, useState } from 'react';
import { CustomLoader } from './CustomLoader';
import history from '../../../common/history';

const LoaderContext = createContext();

export const LoaderContextProvider = ({ children }) => {
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    const removeListener = history.listen((location, action) => {
      if (action === 'POP') {
        setShowLoader(false);
      }
    });
    return () => {
      removeListener();
    };
  }, []);
  return (
    <LoaderContext.Provider value={{ setShowLoader }}>
      <>
        {children}
        <CustomLoader isOpen={showLoader} />
      </>
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = () => {
  const { setShowLoader } = useContext(LoaderContext);
  return {
    setShowLoader,
  };
};
