import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getSearchListingPageData = createAsyncThunk(
  'fetch/search/offerList',
  async (arg, thunkAPI) => {
    const { countryCity: countryCityReducer, user: userReducer } = thunkAPI.getState();
    const { user, latLng } = userReducer;
    const { selectedCity } = countryCityReducer;
    const { cacheKey, sType, tOffer, discountType, distance } = arg;
    if (!selectedCity) {
      throw Error('City not selected.');
    }
    if (!cacheKey) {
      throw Error('arg cacheKey not found.');
    }

    console.log('LatLng', latLng);

    thunkAPI.dispatch(setCacheKey(cacheKey));

    const payload = {
      dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
      dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
      iCountryID: selectedCity.iCountryID,
      vCityName: selectedCity.vCityName,
      iCityID: selectedCity.iCityID,
      sType: sType,
      vKeyword: tOffer,
      start: 0,
      limit: 20,
      fromAreaSearch: true,
      ...(discountType && { discountType: discountType }),
      ...(distance && { maxKM: distance }),
    };

    const url = user ? '/offer/offerList' : '/guestoffer/offerList';

    const response = await apiClient.post(url, payload);

    return response.data.DATA || [];
  },
);

export const loadMoreSearchListingPageData = createAsyncThunk(
  'fetch/search/offerList/loadMore',
  async (arg, thunkAPI) => {
    const { countryCity, user: userReducer, listing } = thunkAPI.getState();

    const { user, latLng } = userReducer;

    if (!listing.initialArg) {
      throw Error('Initial load arg not found.');
    }

    const { sType, tOffer, discountType, distance } = listing.initialArg;

    const { selectedCity } = countryCity;
    if (!selectedCity) {
      throw Error('City not selected.');
    }

    const payload = {
      dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
      dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
      iCountryID: selectedCity.iCountryID,
      vCityName: selectedCity.vCityName,
      iCityID: selectedCity.iCityID,
      start: listing.deals.length,
      limit: 20,
      sType: sType,
      vKeyword: tOffer,
      fromAreaSearch: true,
      ...(discountType && { discountType: discountType }),
      ...(distance && { maxKM: distance }),
    };

    const url = user ? '/offer/offerList' : '/guestoffer/offerList';

    const response = await apiClient.post(url, payload);

    return response.data.DATA || [];
  },
);

const initialState = {
  state: LoadState.IDLE,
  loadMoreState: LoadState.IDLE,
  cacheKey: undefined,
  initialArg: {},
  deals: [],
  hasMore: true,
};
export const searchListingSlice = createSlice({
  name: 'searchListing',
  initialState,
  reducers: {
    toggleFav: (state, action) => {
      const { iBrandID, iOutletID, iDealID, isPrive, isFavouriteOffer } = action.payload;

      if (state.deals) {
        for (let i = 0; i < state.deals.length; i++) {
          if (
            state.deals[i].iBrandID === iBrandID &&
            state.deals[i].iDealID === iDealID &&
            state.deals[i].iOutletID === iOutletID
          ) {
            state.deals[i] = {
              ...state.deals[i],
              isFavouriteOffer: isFavouriteOffer,
            };
          }
        }
      }
    },
    setCacheKey: (state, action) => {
      state.cacheKey = action.payload;
    },
    resetSearchListing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSearchListingPageData.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getSearchListingPageData.fulfilled, (state, action) => {
      state.initialArg = action.meta.arg;
      state.hasMore = action.payload.length >= 20;
      console.log('Search Listing Deals Length 1', action.payload.length);
      state.deals = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getSearchListingPageData.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });
    builder.addCase(loadMoreSearchListingPageData.pending, (state, action) => {
      state.loadMoreState = LoadState.LOADING;
    });
    builder.addCase(loadMoreSearchListingPageData.fulfilled, (state, action) => {
      state.hasMore = action.payload.length >= 20;
      state.deals = [...state.deals, ...action.payload];
      state.loadMoreState = LoadState.SUCCESS;
    });
    builder.addCase(loadMoreSearchListingPageData.rejected, (state, action) => {
      console.error('loadMoreSearchListingPageData error', action.error);
      state.loadMoreState = LoadState.ERROR;
    });
  },
});

export const { toggleFav, resetSearchListing, setCacheKey } = searchListingSlice.actions;
export default searchListingSlice.reducer;
