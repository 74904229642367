import React, { useEffect, useState } from 'react';
import { AutoComplete, Form, Input } from 'antd';
import { Col, FloatLabel, Row, SvgIcon } from '../../components/common';
import usePlacesAutocompleteService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import './index.scss';
import ThankYouModal from './ThankYouModal';
import Lottie from 'react-lottie';
import DotLoader from '../../assets/json/dot_loader.json';
import { logEvent } from '../../utility/analytics';

const ReferAnOutlet = () => {
  const [name, setName] = useState('');

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesAutocompleteService({
      debounce: 500,
    });
  const [placesAutoCompleteQuery, setPlacesAutoCompleteQuery] = useState('');

  console.log(placePredictions);
  console.log(isPlacePredictionsLoading);

  useEffect(() => {
    logEvent('refer_outlet', { name: ' Outlet Referral' });
  }, []);

  return (
    <div className="right-tab-inner">
      <h1 className="tab-heading" data-aos="fade-in" data-aos-duration="800">
        Refer an Outlet
      </h1>
      <div className="tab-content referanoutlet-tab" data-aos="fade-in" data-aos-duration="800">
        <Row>
          <Col>
            <div className="mb-5">
              <h3>Found a place you like?</h3>
              <p>Let us know and we will try to get you the best offer.</p>
            </div>
            <Form name="basic" autoComplete="off" layout="vertical">
              <Form.Item className="icon-less">
                <FloatLabel label="Outlet Name" name="outletname" value={name}>
                  <Input value={name} onChange={(e) => setName(e.target.value)} />
                </FloatLabel>
              </Form.Item>
              <Form.Item>
                <AutoComplete
                  value={placesAutoCompleteQuery}
                  onSearch={(value) => {
                    getPlacePredictions({
                      input: value,
                    });
                    setPlacesAutoCompleteQuery(value);
                  }}
                  popupClassName="refer-outlet-location-drop"
                  listHeight={300}
                  dropdownRender={(menu) => <>{menu}</>}
                  options={[
                    ...(isPlacePredictionsLoading
                      ? [
                          {
                            key: 'loader',
                            value: (
                              <div onClick={(e) => e.stopPropagation()}>
                                <Lottie
                                  options={{
                                    loop: true,
                                    autoplay: true,
                                    animationData: DotLoader,
                                    renderer: 'svg',
                                  }}
                                  height={160}
                                  width={160}
                                />
                              </div>
                            ),
                          },
                        ]
                      : placePredictions.map((item) => {
                          return {
                            key: item.place_id,
                            value: (
                              <div
                                className="location-list-inner"
                                onClick={() => {
                                  setPlacesAutoCompleteQuery(item.description);
                                }}
                              >
                                <div className="icons">
                                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                                </div>
                                {item.description}
                              </div>
                            ),
                          };
                        })),
                  ]}
                >
                  <Input
                    value={placesAutoCompleteQuery}
                    prefix={<SvgIcon name="search" viewbox="0 0 15.289 15.286" />}
                    placeholder="Enter Location"
                    onChange={(evt) => {
                      getPlacePredictions({
                        input: evt.target.value,
                      });
                      setPlacesAutoCompleteQuery(evt.target.value);
                    }}
                  />
                </AutoComplete>
              </Form.Item>
              {/* <div className="map-section">
                <img src={MapImage} alt="Map" />
              </div>*/}
              <Form.Item className="m-0 py-2">
                <ThankYouModal />
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ReferAnOutlet;
