import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Input } from 'antd';
import { SvgIcon } from '../../../components/common';
import './index.scss';

import logoImage from '../../../assets/images/logo.svg';
import PersonImg1 from '../../../assets/images/inquiries-person1.png';
import PersonImg2 from '../../../assets/images/inquiries-person2.png';
import PersonImg3 from '../../../assets/images/inquiries-person3.png';
import ThankYouModal from '../ThankYouModal';

const { TextArea } = Input;

const StartGetListedFormContact = () => {
  const history = useHistory();
  return (
    <div className="getlistedform-alt-wrapper">
      <div className="getlistedform-left">
        <div className="getlistedform-inner">
          <div>
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>Thank You! Please fill in your details so we can get in touch with you soon.</h1>
          </div>
        </div>
      </div>
      <div className="getlistedform-right">
        <Form name="basic" autoComplete="off" layout="vertical">
          <div className="getlistedform-inner">
            <div className="right-upper">
              <div className="upper-inner">
                <Button className="help-btn" type="text">
                  Help?
                </Button>
                <ul>
                  <li>
                    <img src={PersonImg1} alt={PersonImg1} />
                  </li>
                  <li>
                    <img src={PersonImg2} alt={PersonImg2} />
                  </li>
                  <li>
                    <img src={PersonImg3} alt={PersonImg3} />
                  </li>
                </ul>
                <Button
                  type="default"
                  className="auth-upper-btn"
                  onClick={() => history.push('/get-listed')}
                >
                  Save & Exit
                </Button>
              </div>
            </div>
            <div className="right-form">
              <div className="contact-form" data-aos="fade-in" data-aos-duration="800">
                <Form.Item label="Full Name">
                  <Input
                    prefix={<SvgIcon name="user" viewbox="0 0 15.381 15.381" />}
                    placeholder="Enter"
                  />
                </Form.Item>
                <Form.Item label="Email">
                  <Input
                    prefix={<SvgIcon name="email-icon" viewbox="0 0 30 30" />}
                    placeholder="Enter"
                  />
                </Form.Item>
                <Form.Item label="Mobile">
                  <Input
                    prefix={
                      <>
                        <SvgIcon name="dubai-flag" viewbox="0 0 42 42" />{' '}
                        <span className="ccode">+971</span>
                      </>
                    }
                  />
                </Form.Item>
                <Form.Item label="Any further details">
                  <TextArea rows={5} placeholder="Enter" />
                </Form.Item>
              </div>
            </div>
            <div className="getlistedform-right-footer">
              <div className="back-left">
                <Link to="/get-listed-onboarding-offer-details3">Back</Link>
              </div>
              <ThankYouModal />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default StartGetListedFormContact;
