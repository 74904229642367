import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form } from 'antd';
import { SvgIcon } from '../../../components/common';
import './index.scss';

import logoImage from '../../../assets/images/logo-light.svg';
import AwesomeImg from '../../../assets/images/tp-left.png';
import VideoOne from '../../../assets/video/bg_auth.mp4';

const StartGetListed = () => {
  const history = useHistory();
  return (
    <div className="getlistedform-wrapper">
      <div className="getlistedform-left">
        <div className="getlistedform-inner">
          <div data-aos="fade-right" data-aos-duration="800">
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>
              Become a partner <br />
              with us today
            </h1>
          </div>
          <div data-aos="fade-right" data-aos-duration="1000">
            <div className="awse-img">
              <img src={AwesomeImg} alt="Awesome" />
            </div>
            <h3>Worth it! </h3>
            <p>Highly recommend this app over most available in the market today.</p>
            <div className="left-name">Pavlina</div>
          </div>
        </div>
        <video playsInline autoPlay muted loop className="banner-video">
          <source src={VideoOne} />
        </video>
      </div>
      <div className="getlistedform-right">
        <div className="getlistedform-inner">
          <div className="right-upper"></div>
          <div className="right-form" data-aos="zoom-in" data-aos-duration="800">
            <Form name="basic" autoComplete="off" layout="vertical">
              <Form.Item className="m-0 py-3">
                <Button
                  type="secondary"
                  htmlType="submit"
                  className="with-icon"
                  icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                  onClick={() => history.push('/get-listed-onboarding')}
                >
                  Start Listing
                </Button>
              </Form.Item>
            </Form>
          </div>
          <div className="getlistedform-right-footer">Don’t worry, it will take 5 minutes only</div>
        </div>
      </div>
    </div>
  );
};

export default StartGetListed;
