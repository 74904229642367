import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const PartnerAppTermsandConditions = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Terms and <br /> Conditions
                  </h1>
                  <p data-aos="fade-right" data-aos-duration="1000">
                    Last Updated: October 1, 2024
                  </p>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/parnterappprivacypolicy">
                    Privacy Policy{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  Welcome to TravellerPass Partner App! These terms and conditions ("Agreement")
                  apply to your access and use of the TravellerPass Partner app, which is operated
                  by TravellerPass LLC (referred to as "us," "we," or "our").
                  <br />
                  <br />
                  By accessing or using the TravellerPass Partner app, you agree to be bound by this
                  Agreement. If you do not agree to all of the terms and conditions in this
                  Agreement, do not use the TravellerPass Partner app.
                </p>
                <ol>
                  <li>
                    Description of Service: The TravellerPass Partner app provides our business
                    partners with access to stats and helps to keep track of usage and redemptions.
                    As a partner, you can provide offers and discounts for our app users, which will
                    help increase footfall for your venue.
                  </li>
                  <li>
                    Account Registration: In order to use the TravellerPass Partner app, you must
                    create an account with us. You agree to provide accurate and complete
                    information when you register for an account, and you will keep your account
                    information up to date.
                  </li>
                  <li>
                    Partner Offers: As a partner, you may provide offers and discounts for our app
                    users. You are solely responsible for the offers and discounts you provide, and
                    you agree to honour any offers or discounts you provide through the
                    TravellerPass Partner app. We reserve the right to review and approve all offers
                    and discounts before they are made available to our app users.
                  </li>
                  <li>
                    App User Data: As a partner, you may have access to certain data about app users
                    who redeem your offers or discounts through the TravellerPass Partner app. You
                    agree to use this data only for the purpose of fulfilling the offer or discount
                    and to comply with all applicable laws and regulations regarding the use and
                    protection of personal data.
                  </li>
                  <li>
                    Termination: We may terminate this Agreement and your access to the
                    TravellerPass Partner app at any time, with or without cause or notice, for any
                    reason or no reason. You may also terminate this Agreement by providing our team
                    with 3 months written notice.
                  </li>
                  <li>
                    Intellectual Property: We own all rights, titles, and interests in and to the
                    TravellerPass Partner app, including all intellectual property rights. You may
                    not use our intellectual property without our prior written consent.
                  </li>
                  <li>
                    Disclaimer of Warranties: The TravellerPass Partner app is provided on an "as
                    is" and "as available" basis, without warranty of any kind, either express or
                    implied, including, without limitation, any warranty of merchantability, fitness
                    for a particular purpose, or non-infringement.
                  </li>
                  <li>
                    Limitation of Liability: In no event shall we be liable for any indirect,
                    special, incidental, consequential, or punitive damages, whether in an action in
                    contract, tort (including negligence), or otherwise, arising out of or in
                    connection with the use or inability to use the TravellerPass Partner app,
                    including, without limitation, any damages resulting from loss of use, data, or
                    profits, or business interruption.
                  </li>
                  <li>
                    Governing Law: This Agreement shall be governed by and construed in accordance
                    with UAE court of law in which TravellerPass is located, without giving effect
                    to any principles of conflicts of law.
                  </li>
                  <li>
                    Changes to this Agreement: We may modify this Agreement from time to time, and
                    any modifications will be effective immediately upon posting on the
                    TravellerPass Partner app. Your continued use of the TravellerPass Partner app
                    after any modification to this Agreement constitutes your acceptance of the
                    modified Agreement.
                  </li>
                </ol>
                <p>
                  If you have any questions about this Agreement, please contact us at{' '}
                  <a href="mailto:concierge@travellerpass.com">concierge@travellerpass.com</a>.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default PartnerAppTermsandConditions;
