import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const RPPrivacyPolicy = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Rewards Plus
                  </h1>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Privacy Policy
                  </h1>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/rewards-plus-terms">
                    Terms and Conditions{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  This Privacy Policy applies to all information collected by TravellerPass and/or
                  its related entities, affiliates, and subsidiaries (individually and collectively,
                  "Rewards Plus") under Clause 1 below. In this policy, “we” and “our” shall refer
                  to Rewards Plus.
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h4> When was the information collected from you?</h4>
                      <p>
                        1.1 When you register for or use any of our services on a website or mobile
                        app owned and/or operated by us,
                        <br />
                        1.2 When you take part in any contest, survey, or promotion conducted by us
                        and/or our business partners.
                        <br />
                        1.3 When you register as a member of a website or mobile app owned and/or
                        operated by us, hosted by us on behalf of our business partners, or use
                        services on such a website or mobile app.
                      </p>
                    </li>
                    <li>
                      <h4>What is the information used for?</h4>
                      <p>
                        2.1 Information that you provide to us may be used and disclosed by us and
                        our affiliates, location, business partners, and contractors for the
                        following purposes: <br />
                        2.1.0 We use your location to list out nearby offers and deals, as well as
                        location data to send you nearby deal alerts even when the app is not in
                        use, so that you never miss out on an offer. This can be controlled in the
                        notification settings section of the app. Additionally, Rewards Plus
                        collects location data to deliver timely push notifications when you are
                        near participating outlets with special offers, even when the app is closed
                        or not in use. This data is solely used for enhancing this feature and
                        improving your in-app experience, ensuring you never miss an offer or pay
                        full price. <br />
                        2.1.1 To respond to your requests and queries,
                        <br />
                        2.1.2 To provide goods and services to you; <br />
                        2.1.3 To verify and process your personal particulars; <br />
                        2.1.4 To communicate with you: <br />
                        2.1.5 To enforce contractual and legal rights and obligations; <br />
                        2.1.6 For marketing research, user profiles, and statistical analysis;{' '}
                        <br />
                        2.1.7 To send you information, promotions, updates, and marketing and
                        advertising materials in relation to our goods and services and those of
                        third-party organizations; <br />
                        2.1.8 To comply with the law, the requests of law enforcement and regulatory
                        officials, or orders of court; and <br />
                        2.1.9 For any other purposes for which we have obtained your consent,
                        including during the registration process under our Member Terms and
                        Conditions. <br />
                        2.2 In the event that you participate in contests or other promotional
                        programs on our website or mobile apps sponsored or organized by third
                        parties, purchase goods and services offered by third parties, or offer
                        goods and services to third parties on our website or mobile apps, your
                        information may be disclosed to and used by such third parties.
                      </p>
                    </li>

                    <li>
                      <h4>Use of cookies</h4>
                      <p>
                        3.1 Rewards Plus uses "cookies,” where a small data file is sent to your
                        browser to store and track information about you when you enter our website
                        or mobile apps. The cookie is used to track information such as the number
                        of users and their frequency of use, profiles of users, and their preferred
                        website or mobile apps. <br />
                        3.2 Like many website and mobile app operators, Rewards Plus may use an
                        independent company (the “Third Party Market Research Company”) to measure
                        and analyze the Internet usage across the Rewards Plus website and mobile
                        apps. Rewards Plus uses the Third Party Market Research Company’s services
                        to collect the following core information on the usage of our website and
                        mobile apps, including: <br />
                        3.2.1 The number of page views (or page impressions) that occur on Rewards
                        Plus website and mobile apps; <br />
                        3.2.2 The number of unique visitors to Rewards Plus website or mobile apps;
                        <br />
                        3.2.3 How long these unique visitors (on average) spend on Rewards Plus
                        website or mobile apps when they do visit; and <br />
                        3.2.4 Common entry and exit points into the Rewards Plus website and mobile
                        apps. <br />
                        3.3 This information is collated by the Third-Party Market Research Company
                        and provided to Rewards Plus to assist in analyzing the usage of Rewards
                        Plus websites and mobile apps. Such data is also accessible by media
                        organizations and research companies for the purpose of conducting industry
                        comparisons with other Internet portals. <br />
                        3.4 pages on the Rewards Plus website or mobile app may be coded with
                        software that enables the Third-Party Market Research Company and Rewards
                        Plus to track visitors for analysis.
                      </p>
                      <p>
                        By clicking on the “I Accept”, “Submit”, “Login” or similar button or
                        checking the “I Accept” or similar box at the end of the account
                        registration process or when logging in to your account, you hereby agree to
                        be bound by these Member Terms and Conditions (“Member Conditions”) as well
                        as the Website/Mobile app Terms and Conditions (“Website/Mobile app
                        Conditions"), which are hereby incorporated by reference into these Member
                        Conditions, including without limitation, the representations set out in
                        Clause 1.1 of the Website/Mobile app Conditions. In the event of any
                        conflict, inconsistency, or ambiguity between these Member Conditions and
                        the Website/Mobile app Conditions, these Member Conditions shall govern and
                        take precedence over the Website/Mobile app Conditions:
                        <ol>
                          <li>
                            <b>Definitions</b>
                            <p>
                              1.1 All defined terms on the website or mobile app conditions apply to
                              these member conditions unless specifically defined in these member
                              conditions.
                            </p>
                          </li>
                          <li>
                            <b>Account Information</b>
                            <p>
                              2.1 You agree that your account is for your sole, personal use (or
                              that of the entity on whose behalf you are entering into these Member
                              Conditions). You hereby agree not to:
                              <br />
                              2.1.1 Share with or permit others to use your account (defined in
                              Clause 2.3); or <br />
                              2.1.2 Assign or otherwise transfer your account to any other person or
                              entity. You acknowledge and agree that any unauthorized sharing or
                              disclosure of your account information will cause wrongful loss to
                              Rewards Plus. <br />
                              2.2 You shall provide Rewards Plus with accurate, complete, and
                              up-to-date account information and further ensure that the information
                              is kept updated and remains current, accurate, and complete. Failure
                              to do so shall constitute a breach of these Member Conditions, which
                              may result in the restriction, suspension, or immediate termination of
                              your account. <br />
                              2.3 As part of the registration process for the account, customers add
                              their name, mobile number, email, and membership number issued to them
                              by the bank. <br />
                              2.3.1 Select or use the username of another person with the intent to
                              impersonate that person; <br />
                              2.3.2 Use a name subject to the rights of any other person without
                              authorization; or
                              <br />
                              2.3.3 Use a name that Rewards Plus, in its sole discretion, deems
                              inappropriate or offensive. <br />
                              2.4 You shall promptly notify Rewards Plus of any known or suspected
                              unauthorized use(s) of your account or any known or suspected breach
                              of security, including loss, theft, or unauthorized disclosure of your
                              membership number. You shall be solely responsible for safeguarding
                              and maintaining the confidentiality of your account information.{' '}
                              <br />
                              2.5 You shall be bound by and responsible for, and Rewards Plus shall
                              be entitled to rely on, all communications transmitted using your
                              account information, and all such communications shall be deemed to be
                              communications made and issued by you. <br />
                              2.6 You shall be responsible for all user content, messages, and all
                              online activity transmitted or conducted using your account. <br />
                              2.7 Rewards Plus shall have no responsibility or liability for any
                              loss, damage, cost, expenses, or liabilities arising because of or in
                              connection with the wrongful or fraudulent use of your account
                              information. <br />
                              2.8 You agree that Rewards Plus reserves the right to change or
                              re-assign membership numbers at its sole discretion by giving you
                              notice. Rewards Plus shall not be liable for any loss, damage, cost,
                              or expense incurred by you because of such a change or re-assignment.{' '}
                              <br />
                              2.9 Unless prior written notice of no less than thirty (30) days (or
                              such other period specified by Rewards Plus) is given to Rewards Plus,
                              you agree that Rewards Plus may automatically renew your membership or
                              subscription account upon expiration.
                            </p>
                          </li>
                          <li>
                            <b>Use of Account Information </b>
                            <p>
                              3.1 In addition to and without limiting the terms of our Privacy
                              Policy, by providing the information requested for your account, you
                              hereby consent to Rewards Plus use and disclosure of all such
                              information and information relating to your use of the site, mobile
                              app, or services for the following purposes: <br />
                              3.1.1 To respond to your requests and queries; <br />
                              3.1.2 To provide the services to you; <br />
                              3.1.3 To verify and process your personal particulars and payments;{' '}
                              <br />
                              3.1.4 To communicate with you: 3 <br />
                              .1.5 To enforce these Member Conditions, the website and mobile app
                              conditions, and our legal rights and remedies;
                              <br />
                              3.1.6 For marketing research, user profiles, and statistical analysis;
                              <br />
                              3.1.7 To send you information, promotions, updates, and marketing and
                              advertising materials in relation to our goods and services and those
                              of third-party organizations; <br />
                              3.1.8 Complying with law, the requests of law enforcement and
                              regulatory officials, or orders of court; and
                              <br />
                              3.1.9 For any other purpose, including the disclosure of such
                              information to third parties for commercial/business reasons, provided
                              that Rewards Plus shall not disclose credit card account information
                              except for the purposes set out in Clauses 3.1.2 and 3.1.3 above.
                            </p>
                          </li>
                          <li>
                            <b>Suspension and Termination of Accounts</b>
                            <p>
                              4.1 You agree that Rewards Plus has the right, in its sole and
                              absolute discretion and without notice, to: <br />
                              4.1.1 Restrict, suspend, or terminate your access to all or any part
                              of the Services; and/or
                              <br />
                              4.1.2 Terminate, deactivate, or suspend your account; delete your
                              account and all related information and files in your account without
                              assigning any reason. <br />
                              4.2 Without prejudice to the generality of the above, Rewards Plus
                              reserves the right to deactivate your account if it has been inactive
                              for a period of six (6) months or more, if you are in breach of any of
                              our terms and conditions (including these Member Conditions and the
                              Website/Mobile App Conditions), or if Rewards Plus believes you have
                              been using the account for unlawful and/or undesirable activities.{' '}
                              <br />
                              4.3 You may terminate your account if you notify us in writing
                              (including via email at [email protected]) of your desire to do so.
                              Notwithstanding any such termination, you shall remain responsible and
                              liable for any fees or charges that may have been incurred by you
                              and/or any other party(ies) using the account.
                              <br />
                              4.4 In the event that your account is terminated or suspended for
                              whatever reason, your right to use the site, mobile app, and services
                              shall immediately cease. Rewards Plus shall not be liable to you or
                              any third party for any termination or suspension of your account or
                              any inability to access the site, mobile app, and/or use the services.
                            </p>
                          </li>
                          <li>
                            <b>Accountholder Obligations</b>
                            <p>
                              5.1 To use Rewards Plus, the site, mobile app, and/or the services,
                              you must: <br />
                              5.1.1 Comply at all times with these Member Conditions and the
                              Website/Mobile App Conditions; and <br />
                              5.1.2 Promptly notify Rewards Plus if you are aware of any
                              unauthorized use of the account or any other breach of security.
                            </p>
                          </li>
                          <li>
                            <b>Disclaimer and Exclusion of Liability </b>
                            <p>
                              6.1 The site/mobile app, the services, the information on the
                              site/mobile app, and the use of all related facilities are provided on
                              an "as is, as available" basis without any warranties, express or
                              implied. <br />
                              6.2 To the fullest extent permitted by applicable law, we disclaim all
                              representations and warranties relating to the site or mobile app and
                              its contents, including, in relation to any inaccuracies or omissions
                              in the site or mobile app, warranties of merchantability, quality, and
                              fitness for a particular purpose, accuracy, availability,
                              non-infringement, or implied warranties from the course of dealing or
                              usage of trade. <br />
                              6.3 We do not warrant that the site or mobile app will always be
                              accessible, uninterrupted, timely, secure, error-free, or free from
                              computer viruses or other invasive or damaging code, or that the site
                              or mobile app will not be affected by any acts of God or other force
                              majeure events, including the inability to obtain or shortage of
                              necessary materials, equipment, facilities, power, or
                              telecommunications, a lack of telecommunications equipment or
                              facilities, or a failure of information technology or
                              telecommunications equipment or facilities. <br />
                              6.4 While we may use reasonable efforts to include accurate and
                              up-to-date information on the site or mobile app, we make no
                              warranties or representations as to its accuracy, timeliness, or
                              completeness. <br />
                              6.5 WE SHALL NOT BE LIABLE FOR ANY ACTS OR OMISSIONS OF ANY THIRD
                              PARTIES HOWSOEVER CAUSED, AND FOR ANY DIRECT, INDIRECT, INCIDENTAL,
                              SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, HOWEVER, CAUSED,
                              RESULTING FROM OR IN CONNECTION WITH THE SITE/MOBILE APP AND THE
                              SERVICES OFFERED IN THE SITE/MOBILE APP, YOUR ACCESS TO, USE OF, OR
                              INABILITY TO USE THE SITE/MOBILE APP OR THE SERVICES OFFERED IN THE
                              SITE/MOBILE APP, RELIANCE ON OR DOWNLOADING FROM THE SITE/MOBILE APP
                              AND/OR SERVICES, OR ANY DELAYS, INACCURACIES IN THE INFORMATION OR IN
                              ITS TRANSMISSION INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOSS OF
                              BUSINESS OR PROFITS, USE, DATA OR OTHER INTANGIBLE, EVEN IF WE HAVE
                              BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. <br />
                              6.6 We shall not be liable to you for any indirect, special,
                              incidental, or consequential losses or damages of any kind (including
                              but not limited to loss of revenue, profits, business, contracts, or
                              anticipated savings), whether arising from tort (including
                              negligence), breach of contract, or otherwise, even if foreseeable or
                              if we have been advised of the possibility of such damages, suffered
                              or incurred by you arising out of or in connection with or due to any
                              act or omission by us. <br />
                              6.7 Without prejudice to the foregoing, no action may be brought by
                              you against us under these Member Conditions or related to the site,
                              mobile app, and/or the Services more than one year after the cause of
                              action arose.
                            </p>
                          </li>
                          <li>
                            <b>Indemnity</b>
                            <p>
                              7.1 You agree to indemnify and hold us, and our subsidiaries,
                              affiliates, officers, agents, co-branders, or other partners, and
                              employees, harmless from any claim or demand, including reasonable
                              legal fees, made by any third party due to or arising out of your use
                              of the site/mobile app and/or the Services, your connection to the
                              site/mobile app, your violation of these Member Conditions, or your
                              violation of any rights of another party.
                            </p>
                          </li>
                          <li>
                            <b>General</b>
                            <p>
                              8.1 Your access to the site or mobile app, as well as these Member
                              Conditions, are governed by and construed in accordance with the laws
                              of the Emirates of Dubai and the Federal laws of the United Arab
                              Emirates. <br />
                              8.2 You hereby consent to the exclusive jurisdiction and venue of
                              courts in Dubai, United Arab Emirates, in all disputes arising out of
                              or relating to the use of the site or mobile app. <br />
                              8.3 Rewards Plus’ failure to exercise or enforce any right or
                              provision of these Member Conditions shall not constitute a waiver of
                              such right or provision. <br />
                              8.4 If any provision of these Member Conditions is found by a court of
                              competent jurisdiction to be invalid, the parties nevertheless agree
                              that the court should endeavor to give effect to the parties'
                              intentions as reflected in the provision, and the other provisions of
                              these Member Conditions remain in full force and effect.
                            </p>
                          </li>
                        </ol>
                      </p>
                    </li>
                  </ol>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default RPPrivacyPolicy;
