import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';
import { Col, Row, SvgIcon } from '../../../../components/common';
import './index.scss';
import { apiClient } from '../../../../apiConfig';
import { Alert } from '../../../../common';
import { format } from 'date-fns';
import { useUserSelector } from '../../../../store/selectors';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../../slice/userSlice';
import { logEvent } from '../../../../utility/analytics';

const MembershipIdModal = (props) => {
  const dispatch = useDispatch();
  const { user } = useUserSelector();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [membershipCode, setMembershipCode] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const [membershipData, setMembershipData] = useState();

  const checkMembershipCode = async () => {
    try {
      setIsChecking(true);
      const response = await apiClient.post('/admin/newMembershipInfo', {
        iMembershipCode: membershipCode,
      });
      const data = response.data;
      if (response.data.status === 1 && data?.DATA) {
        setMembershipData(data.DATA);
      } else {
        if (data.MESSAGE) {
          Alert.error(data.MESSAGE);
        }
        setMembershipData(undefined);
      }
    } catch (error) {
      console.error(error);
      Alert.error('Oops! Something went wrong.');
    } finally {
      setIsChecking(false);
    }
  };

  const activateMembershipCode = async () => {
    if (!membershipData) return;
    try {
      setIsChecking(true);
      const response = await apiClient.post('/admin/postlogin', {
        iUserID: user.iUserID,
        vMobileCountryCode: user.vMobileCountryCode,
        iMembershipCode: membershipCode,
        iAge: 0,
      });
      const data = response.data;
      if (response.data.status === 1 && data?.DATA) {
        dispatch(
          setUser({
            ...user,
            iMembershipCode: membershipData.iMembershipCode,
            iMembershipPlanID: membershipData.iMembershipPlanID,
            dExpiredDate: membershipData.dExpiredDate,
          }),
        );
        handleCancel();
        window.location.href = '/';
      } else {
        if (data.MESSAGE) {
          Alert.error(data.MESSAGE);
        }
      }
    } catch (error) {
      console.error(error);
      Alert.error('Oops! Something went wrong.');
    } finally {
      setIsChecking(false);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    if (isModalVisible) {
      logEvent('enter_membership_id', { name: 'Have a Membership ID Screen' });
    }
  }, [isModalVisible]);

  return (
    <>
      <Button
        data-aos="fade-left"
        data-aos-duration="1300"
        onClick={showModal}
        type="link"
        className="havemembid-btn"
      >
        <SvgIcon name="card-light" viewbox="0 0 18.525 13.197" /> Have a membership ID?
      </Button>
      <Modal
        className="membershipid-modal"
        centered
        maskClosable={false}
        footer={null}
        title={null}
        width={500}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
      >
        <div className="text-center">
          <Row>
            <Col sm="12" className="upper-section">
              <SvgIcon
                name="membership-id"
                viewbox="0 0 173.477 111.802"
                data-aos="zfadoom-in"
                data-aos-duration="800"
              />
              <h3>Membership ID</h3>
              <p>Please enter the membership ID to get started</p>
            </Col>
          </Row>
          <Row>
            <Col sm="8" className="mx-auto id-input">
              <Input
                value={membershipCode}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  const sanitizedValue = inputValue.replace(/[^0-9]/g, '');
                  setMembershipCode(sanitizedValue);
                }}
                placeholder="Enter"
              />
            </Col>
          </Row>
          {membershipData ? (
            <div className="congratulations-row">
              <h4>🤗 Congratulations</h4>
              <h2>{membershipData.membershipPlan}</h2>
              <div className="eligible-text">
                <p>You are eligible for a {membershipData.mPlanDuration.toLowerCase()} pass</p>
              </div>
              <div className="activated-text">
                <p>The membership expiry if activated today</p>
                <b>{format(membershipData.dExpiredDate, 'do MMM yyyy')}</b>
              </div>
              <Row>
                <Col sm="8" className="mx-auto mt-4 pt-2">
                  <Button type="primary" onClick={activateMembershipCode} block>
                    Activate
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col sm="8" className="mx-auto mt-5">
                <Button
                  type="primary"
                  block
                  onClick={checkMembershipCode}
                  disabled={membershipCode.length <= 4}
                >
                  Check
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
};

export default MembershipIdModal;
