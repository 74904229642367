import React, { useEffect, useState } from 'react';
import { SvgIcon } from '../../components/common';
import { Button, Drawer, Radio, Slider } from 'antd';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import './index.scss';
import { useHistory, useLocation } from 'react-router-dom';
import { isFinite, isNumber } from 'lodash';

const FilterDrawer = (FiterButton, FiterIcon) => {
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [open, setOpen] = useState(false);
  const [discountType, setDiscountType] = useState('all');
  const [distance, setDistance] = useState(0);
  const [filterCount, setFilterCount] = useState(0);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const marks = isMobile
    ? {
        0: '0',
        5: ' ',
        10: '10 km',
        15: ' ',
        20: ' ',
        25: '25 km',
        30: ' ',
        35: ' ',
        40: '40 km',
        45: ' ',
        50: ' ',
        55: '55 km',
        60: ' ',
        65: ' ',
        70: '70 km',
        75: ' ',
        80: ' ',
        85: '85 km',
        90: ' ',
        95: ' ',
        100: '100 km',
      }
    : {
        0: '0',
        5: '5',
        10: '10 km',
        15: '15',
        20: '20',
        25: '25 km',
        30: '30',
        35: '35',
        40: '40 km',
        45: '45',
        50: '50',
        55: '55 km',
        60: '60',
        65: '65',
        70: '70 km',
        75: '75',
        80: '80',
        85: '85 km',
        90: '90',
        95: '95',
        100: '100 km',
      };

  useEffect(() => {
    const type = searchParams.get('type');
    const dist = searchParams.get('dist');
    let count = 0;
    if (['all', 'B1G1', 'discount'].indexOf(type) >= 0) {
      count++;
      setDiscountType(type);
    }
    if (isNumber(dist) && isFinite(dist)) {
      count++;
      setDistance(+dist);
    }
    setFilterCount(count);
  }, []);

  const applyFilter = () => {
    onClose();
    const searchParams = new URLSearchParams(search);
    if (discountType !== 'all') {
      searchParams.set('type', discountType);
    } else {
      searchParams.delete('type');
    }
    if (distance !== 0 || distance >= 100) {
      searchParams.set('dist', distance);
    } else {
      searchParams.delete('dist');
    }
    history.replace({ search: searchParams.toString() });
  };

  const clearFilter = () => {
    onClose();
    const searchParams = new URLSearchParams(search);
    searchParams.delete('type');
    searchParams.delete('dist');
    history.replace({ search: searchParams.toString() });
  };

  return (
    <>
      <MediaQuery maxWidth={767}>
        <Button onClick={showDrawer} className="filter-btn" type="primary" ghost>
          <SvgIcon name="filter" viewbox="0 0 15.839 11.88" />
        </Button>
      </MediaQuery>
      <MediaQuery minWidth={768}>
        <Button onClick={showDrawer} className="filter-btn" type="primary" ghost>
          Filter <SvgIcon name="filter" viewbox="0 0 15.839 11.88" />
        </Button>
      </MediaQuery>
      <Drawer
        height={'80%'}
        title={null}
        className="filter-drawer"
        onClose={onClose}
        closeIcon={
          <Button className="close-btn">
            <SvgIcon name="close" viewbox="0 0 7.481 7.521" />
          </Button>
        }
        placement="top"
        open={open}
        footer={
          <div className="footer-inner">
            <div>
              {filterCount > 0 && (
                <>
                  <h3>{filterCount} Filters Applied</h3>
                  <Button type="link" onClick={clearFilter}>
                    Clear Filter
                  </Button>
                </>
              )}
            </div>

            <div>
              <Button type="primary" onClick={applyFilter}>
                Apply
              </Button>
            </div>
          </div>
        }
      >
        <div className="filterdrawer-inner">
          <div className="title">Filters</div>
          <div className="filter-row">
            <div className="filter-head">
              <h4>Offer Type</h4>
              <p>Choose the deal type</p>
            </div>
            <div className="offertype">
              <Radio.Group
                value={discountType}
                onChange={(e) => {
                  setDiscountType(e.target.value);
                }}
              >
                <Radio.Button value="all">All</Radio.Button>
                <Radio.Button value="B1G1">2 For 1 Offer</Radio.Button>
                <Radio.Button value="discount">Discount Offer</Radio.Button>
                <Radio.Button value="special">Special Offer</Radio.Button>
              </Radio.Group>
            </div>
          </div>
          <div className="filter-row">
            <div className="filter-head">
              <h4>Distance Range</h4>
              <p>Choose the offer range</p>
            </div>
            <Slider
              defaultValue={distance}
              marks={marks}
              onChange={(e) => {
                setDistance(e);
              }}
            />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default FilterDrawer;
