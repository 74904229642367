import React, { useEffect, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button, Divider } from 'antd';
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';
import DiscoverImageSmall from '../../assets/images/discover-small-img.jpg';
import HowItWorks from '../../assets/video/how_it_works.mp4';
import ModalVideo from 'react-modal-video';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import Tilt from 'react-parallax-tilt';
import JourneyImg from '../../assets/images/journey-img.png';
import DiscoverImage from '../../assets/images/discover-img.jpg';
import DownloadImg from '../../assets/images/download.png';
import { useMediaQuery } from 'react-responsive';
import { useHomeSelector } from '../../store/selectors';
import { LoadState } from '../../constants';
import { getHomePageData, getHomePageDataNext } from '../../slice/homeSlice';
import Slider from 'react-slick';
import testimonialImg from '../../assets/images/testimonial.png';
import testimonialImg2 from '../../assets/images/testimonial2.png';
import testimonialImg3 from '../../assets/images/testimonial3.png';
import testimonialImg4 from '../../assets/images/testimonial4.png';
import testimonialImg5 from '../../assets/images/testimonial5.png';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

function NextArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}
const KnowMorePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });
  const { monthly, topBrandLogos, state, state2 } = useHomeSelector();
  const { title: monthlyTitle, items: monthlyItems } = monthly;

  const settingsClients = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrowClients />,
    prevArrow: <PrevArrowClients />,
  };

  useEffect(() => {
    if (state === LoadState.IDLE) {
      dispatch(getHomePageData());
    }
    if (state === LoadState.SUCCESS && state2 === LoadState.IDLE) {
      dispatch(getHomePageDataNext());
    }
  }, [state, state2]);

  return (
    <>
      <div className="twl-knowmore-wrapper">
        <section className="savingstep-section">
          <Container>
            <Row>
              <Col>
                <h1 className="heading" data-aos="fade-up" data-aos-duration="800">
                  Start your savings journey <br /> in three steps
                </h1>
              </Col>
            </Row>
          </Container>
          <div className="savingstep-section-bottom">
            <Container>
              <ParallaxProvider>
                <Row>
                  <Col lg="6">
                    <ul className="left-section">
                      <li data-aos="fade-right" data-aos-duration="800">
                        <div className="list-inner">
                          <div className="circle">01</div>
                          <Divider />
                          <div className="text-text">
                            <h3>Download & Register</h3>
                            <p>Download the app from the app store and register to start off</p>
                          </div>
                        </div>
                      </li>
                      <li data-aos="fade-right" data-aos-duration="1000">
                        <div className="list-inner">
                          <div className="circle">02</div>
                          <Divider />
                          <div className="text-text">
                            <h3>Get a Membership</h3>
                            <p>
                              Join our membership program to gain access to our exciting world of
                              offers & experiences
                            </p>
                          </div>
                        </div>
                      </li>
                      <li data-aos="fade-right" data-aos-duration="1200">
                        <div className="list-inner">
                          <div className="circle">03</div>
                          <Divider />
                          <div className="text-text">
                            <h3>Explore & Redeem</h3>
                            <p>
                              Explore our categories or easily find the best offers near you to
                              redeem and save
                            </p>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Col>
                  <Col lg="6">
                    <Tilt
                      className="parallax-effect"
                      perspective={1500}
                      tiltMaxAngleX="5"
                      tiltMaxAngleY="5"
                    >
                      <div className="right-section">
                        <div className="play-icon">
                          <div className="watch-row" onClick={() => setShowHowItWorks(true)}>
                            Watch Video <SvgIcon name="play" viewbox="0 0 41.93 41.965" />
                          </div>
                          <SvgIcon
                            className="watch-arrow"
                            name="watch-arrow"
                            viewbox="0 0 33.689 50.654"
                          />
                        </div>
                        <div className="journey-img">
                          <img
                            data-aos="flip-left"
                            data-aos-duration="1000"
                            src={JourneyImg}
                            alt="journey"
                          />
                        </div>
                        <div className="circles-div">
                          <div className="circle1" data-aos="zoom-in" data-aos-duration="800"></div>
                          <div className="circle2" data-aos="zoom-in" data-aos-duration="600"></div>
                          <div className="circle3" data-aos="zoom-in" data-aos-duration="400"></div>
                          <div className="circle4" data-aos="zoom-in" data-aos-duration="300"></div>
                        </div>
                      </div>
                    </Tilt>
                  </Col>
                </Row>
              </ParallaxProvider>
            </Container>
          </div>
        </section>
        <section className="discover-section">
          <Container>
            <Row>
              <Col>
                <div className="discover-card" data-aos="zoom-in" data-aos-duration="800">
                  <div
                    className="discover-card-left"
                    style={{ backgroundImage: `url(${DiscoverImage})` }}
                  >
                    <SvgIcon name="tp-logo2" viewbox="0 0 126.805 124.746" />
                  </div>
                  <div className="discover-card-right">
                    <label>ABOUT US</label>
                    <h2>
                      Discover exciting offers <br /> across your city
                    </h2>
                    <ul>
                      <li>
                        <div className="ds-icon">
                          <SvgIcon name="redemptions-icon" viewbox="0 0 18.401 18.425" />
                        </div>
                        <p>Unlimited Redemptions</p>
                      </li>
                      <li>
                        <div className="ds-icon">
                          <SvgIcon name="venues-icon" viewbox="0 0 16.027 15.27" />
                        </div>
                        <p>3000+ Venues</p>
                      </li>
                      <li>
                        <div className="ds-icon">
                          <SvgIcon name="luxury-icon" viewbox="0 0 19.482 18.912" />
                        </div>
                        <p>Exclusive Luxury Category</p>
                      </li>
                      <li>
                        <div className="ds-icon">
                          <SvgIcon name="savings-icon" viewbox="0 0 24.588 11.354" />
                        </div>
                        <p>Unlimited Savings </p>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="download-section">
          <ParallaxProvider>
            <Container>
              <Row>
                <Col lg="6" className="download-left">
                  <Parallax speed={5} disabled={isMobile}>
                    <h1 data-aos="zoom-out" data-aos-duration="800">
                      Download the app and start today!
                    </h1>
                  </Parallax>
                  <Parallax speed={6} disabled={isMobile}>
                    <p data-aos="zoom-out" data-aos-duration="1600">
                      Get access to endless 2 for 1 deals and flat discounts at over 3000+ venues
                      across multiple categories and cities.
                    </p>
                  </Parallax>
                  <Parallax speed={4} disabled={isMobile}>
                    <div className="download-btns" data-aos="fade-in" data-aos-duration="1200">
                      <Button
                        onClick={() =>
                          window.open(
                            'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(
                            'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
                      </Button>
                      {/*<Button
                        onClick={() =>
                          window.open('https://appgallery.huawei.com/#/app/C100681983', '_blank')
                        }
                      >
                        <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
                      </Button>*/}
                    </div>
                  </Parallax>
                </Col>
                <Col lg="6" className="download-right">
                  <div className="right-inner">
                    <Parallax speed={20} disabled={isMobile}>
                      <div className="download-circle spinner"></div>
                    </Parallax>
                    <Parallax className="download-img-div web" scale={[0.5, 1.3]} speed={20}>
                      <img className="download-img" src={DownloadImg} alt={'Download'} />
                    </Parallax>
                    <div
                      className="download-img-div mobile"
                      data-aos="zoom-out"
                      data-aos-duration="1000"
                    >
                      <img className="download-img" src={DownloadImg} alt={'Download'} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ParallaxProvider>
        </section>
        {monthlyItems && monthlyItems.length > 0 && (
          <section className="checkout-section">
            <Container>
              <Row>
                <Col>
                  <h1 data-aos="fade-in" data-aos-duration="800">
                    Check out some of our <br /> new offers for the month
                  </h1>
                  <Button
                    onClick={() => {
                      history.replace(`/offerListing`, {
                        type: 'monthly',
                      });
                    }}
                  >
                    Explore
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {topBrandLogos && topBrandLogos.length > 0 && (
          <section className="topbrand-section">
            <Container>
              <Row>
                <Col>
                  <h1 className="heading" data-aos="fade-in" data-aos-duration="1000">
                    Top brands, <br /> all in one place
                  </h1>
                  <div className="topbrand-grid">
                    {topBrandLogos.map((s, i) => (
                      <div className="square-image-container">
                        <img key={i} src={s} />
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        <section className="letsjoin-section">
          <Container>
            <Row>
              <Col>
                <div className="letsjoin-row" data-aos="zoom-in" data-aos-duration="1200">
                  <div className="letsjoin-card">
                    <h3>
                      Dubai is partying, <br /> join the fun
                    </h3>
                    <SvgIcon name="themepark" viewbox="0 0 28.218 56.499" />
                    <p className="mb-0 upto-text">
                      <small>Up to</small>
                    </p>
                    <h2>50% OFF</h2>
                    <p>On Drinks</p>
                    <Button
                      onClick={() => {
                        const params = new URLSearchParams({
                          categoryId: 1,
                          subCategoryId: 4,
                        });
                        history.push({ pathname: '/listing', search: params.toString() });
                      }}
                    >
                      Explore
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="testomonial-section">
          <Container>
            <ParallaxProvider>
              <Row>
                <Col sm="12" className="mb-4">
                  <Parallax disabled={isMobile}>
                    <h1 className="heading mb-0">What they say</h1>
                    <p className="heading-p-tag">*App Store Reviews</p>
                  </Parallax>
                </Col>
                <Col sm="12">
                  <Slider className="testomonial-slider" {...settingsClients}>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              I just wanted to share a quick note and let you know that you guys do
                              a really good job. I’m glad I decided to work with you. It’s really
                              great how easy your websites are to update and manage.
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Amelia Kimani</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg2}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Worth it! <br />
                              Highly recommend this app over most available in the market today. I
                              have been using it for 3 years now and am super happy with my savings.
                              The new interface of the app makes it easy to navigate and find the
                              best options close to you!
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Karthik</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg3}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Excellent customer service <br />
                              Had an issue with one of the restaurants while using TravellerPass.
                              TravellerPass resolved this through their persistent follow-up with
                              the restaurant. The restaurant called and even offered a free dish.
                              Five-star customer service indeed from TravellerPass.
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Marvina</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg4}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Great Experience <br />
                              Have been using this app over last year and a half; amazing
                              experience, covers all the major outlet in Dubai with amazing deals in
                              all. Strongly recommend everyone to download the app and open up the
                              great offers….
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Jamie</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg5}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Awesome! <br />
                              It’s easy to use and very handy. It helps a lot to save more. Worth
                              every penny😍
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Nikol</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </Col>
              </Row>
            </ParallaxProvider>
          </Container>
        </section>
        <section className="social-section">
          <div className="travellerPass-icon" data-aos="fade-right" data-aos-duration="900">
            <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
          </div>
          <Container>
            <Row>
              <Col>
                <img
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className="logo-icon"
                  src={LogoIcon}
                  alt="TravellerPass"
                />
                <h1 data-aos="fade-up" data-aos-duration="1100">
                  Discover the world like never before <br /> with TravellerPass
                </h1>
                <div className="small-card" data-aos="fade-up" data-aos-duration="1200">
                  <div className="left-img" onClick={() => setShowHowItWorks(true)}>
                    <div className="overlay-play">
                      <SvgIcon name="play-alt" viewbox="0 0 30.001 30" />
                    </div>
                    <img src={DiscoverImageSmall} alt="TravellerPass" />
                  </div>
                  <div>
                    <h3>Welcome</h3>
                    <p>A whole new experience to TravellerPass</p>
                  </div>
                </div>
                <ul data-aos="fade-up" data-aos-duration="1400">
                  {/*<li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="dribbble" viewbox="0 0 17.206 17.206" />
                  </Button>
                </li>
                  <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                  </Button>
                </li>*/}
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://www.tiktok.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://medium.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <ModalVideo
        isOpen={showHowItWorks}
        channel="custom"
        url={HowItWorks}
        onClose={() => setShowHowItWorks(false)}
      />
    </>
  );
};

export default KnowMorePage;
