import React, { memo } from 'react';

// Layout Types
import {
  authLayout,
  communityLayout,
  defaultDetailsLayout,
  defaultLayout,
  defaultListingLayout,
  defaultPagesLayout,
  defaultPriveLayout,
  landingLayout,
  landingPriveLayout,
  profileLayout,
} from './layouts';

// Route Views
import Login from './containers/Login';
import Signup from './containers/Signup';
import LandingPage from './containers/LandingPage';
import ListingPage from './containers/ListingPage';
import HomeOfferListing from './containers/HomeOfferListing';
import ListingPageMap from './containers/ListingPageMap';
import DetailsPage from './containers/DetailsPage';
import DetailsPageSize from './containers/DetailsPageSize';
import DetailsPrivePage from './containers/DetailsPrivePage';
import DetailsPrivePageSize from './containers/DetailsPrivePageSize';
import Checkout from './containers/Checkout';
import PaymentSuccessful from './containers/PaymentSuccessful';
import CheckoutMerchant from './containers/CheckoutMerchant';
import CheckoutOffers from './containers/CheckoutOffers';
import PriveCheckout from './containers/CheckoutPrive';
import PriveCheckoutMerchant from './containers/CheckoutMerchantPrive';
import PriveCheckoutOffers from './containers/CheckoutOffersPrive';
import PrivePaymentSuccessful from './containers/PrivePaymentSuccessful';
import ContactUs from './containers/ContactUs';
import AboutUsPage from './containers/AboutUsPage';
import BlogPage from './containers/BlogPage';
import BlogListCategory from './containers/BlogPage/BlogListCategory';
import BlogList from './containers/BlogPage/BlogList';
import BlogDetails from './containers/BlogPage/BlogDetails';
import BlogDetailsCategory from './containers/BlogPage/BlogDetailsCategory';
import GetListedPage from './containers/GetListed';
import StartGetListed from './containers/GetListed/StartGetListed';
import StartGetListedForm from './containers/GetListed/StartGetListedForm';
import StartGetListedFormOffer from './containers/GetListed/StartGetListedFormOffer';
import StartGetListedFormContact from './containers/GetListed/StartGetListedFormContact';
import ProfileSetting from './containers/ProfileSetting';
import TermsandConditions from './containers/TermsandConditions';
import PrivacyPolicy from './containers/PrivacyPolicy';
import LandingPagePrive from './containers/LandingPagePrive';
import CommunityPage from './containers/CommunityPage';
import StartGetListedOfferDetailsOne from './containers/GetListed/StartGetListedOfferDetailsOne';
import StartGetListedOfferDetailsTwo from './containers/GetListed/StartGetListedOfferDetailsTwo';
import StartGetListedOfferDetailsThree from './containers/GetListed/StartGetListedOfferDetailsThree';
import MyBookingDetails from './containers/ProfileSetting/MyBookingDetails';
import EnterOTP from './containers/EnterOTP';
import BuyMembershipHome from './containers/BuyMembership/BuyMembershipHome';
import BuyMembershipCheckout from './containers/BuyMembership/BuyMembershipCheckout';
import BuyMembershipCheckoutSuccess from './containers/BuyMembership/BuyMembershipCheckoutSuccess';
import MyMembershipHome from './containers/BuyMembership/MyMembershipHome';
import history from './common/history';
import { BrowserRouter as Router, Redirect, Route, useLocation } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import { useUserSelector } from './store/selectors';
import GroupListingPage from './containers/GroupListingPage';
import SetPriveMode from './SetPriveMode';
import PartnerAppTermsandConditions from './containers/PartnerAppTermsandConditions';
import BRTermsandConditions from './containers/BRTermsandConditions';
import NBTermsandConditions from './containers/NBFTermsandConditions';
import PartnerAppPrivacyPolicy from './containers/PartnerAppPrivacyPolicy';
import NBFPrivacyPolicy from './containers/NBFPrivacyPolicy';
import BRPrivacyPolicy from './containers/BRPrivacyPolicy';
import RPTermsandConditions from './containers/RPTermsandConditions';
import RPPrivacyPolicy from './containers/RPPrivacyPolicy';
import SearchOfferListing from './containers/SearchOfferListing';
import KnowMorePage from './containers/KnowMorePage';
import AddCityName from './AddCityName';
import RSAStore from './containers/RSAStore';
import RequireLocationAccess from './RequireLocationAccess';

const rotues = [
  {
    path: '/',
    public: true,
    exact: true,
    location: true,
    layout: landingLayout,
    component: () => <LandingPage />,
  },
  {
    path: '/home',
    public: true,
    exact: true,
    location: true,
    layout: landingLayout,
    component: () => <LandingPage />,
  },
  {
    path: '/login',
    public: true,
    layout: authLayout,
    component: () => <Login />,
  },
  {
    path: '/verifyOtp',
    public: true,
    layout: authLayout,
    component: () => <EnterOTP />,
  },
  {
    path: '/signup',
    public: true,
    layout: authLayout,
    component: () => <Signup />,
  },
  {
    path: '/listing',
    public: true,
    location: true,
    layout: defaultListingLayout,
    component: () => <ListingPage />,
  },
  {
    path: '/searchListing',
    public: true,
    location: true,
    layout: defaultListingLayout,
    navBarProps: {},
    component: () => <SearchOfferListing />,
  },
  {
    path: '/offerListing',
    public: true,
    layout: defaultListingLayout,
    component: () => <HomeOfferListing />,
  },
  {
    path: '/groupListing',
    public: true,
    location: true,
    layout: defaultListingLayout,
    component: () => <GroupListingPage />,
  },
  {
    path: '/listing-map',
    location: true,
    layout: defaultListingLayout,
    component: () => <ListingPageMap />,
  },
  {
    path: '/details',
    public: true,
    location: true,
    layout: defaultDetailsLayout,
    component: () => <DetailsPage />,
  },
  {
    path: '/details2',
    location: true,
    layout: defaultDetailsLayout,
    component: () => <DetailsPageSize />,
  },
  {
    path: '/details-prive',
    location: true,
    layout: defaultPriveLayout,
    component: () => <DetailsPrivePage />,
  },
  {
    path: '/details2-prive',
    location: true,
    layout: defaultPriveLayout,
    component: () => <DetailsPrivePageSize />,
  },
  {
    path: '/checkout',
    location: true,
    layout: defaultLayout,
    component: () => <Checkout />,
  },
  {
    path: '/payment-success',
    layout: defaultLayout,
    component: () => <PaymentSuccessful />,
  },
  {
    path: '/checkout-merchant',
    layout: defaultLayout,
    component: () => <CheckoutMerchant />,
  },
  {
    path: '/checkout-offers',
    layout: defaultLayout,
    component: () => <CheckoutOffers />,
  },
  {
    path: '/prive-checkout',
    layout: defaultLayout,
    component: () => <PriveCheckout />,
  },
  {
    path: '/prive-payment-success',
    layout: defaultLayout,
    component: () => <PrivePaymentSuccessful />,
  },
  {
    path: '/prive-checkout-merchant',
    layout: defaultLayout,
    component: () => <PriveCheckoutMerchant />,
  },
  {
    path: '/prive-checkout-offers',
    layout: defaultLayout,
    component: () => <PriveCheckoutOffers />,
  },
  {
    path: '/contact',
    layout: authLayout,
    component: () => <ContactUs />,
  },
  {
    path: '/about',
    public: true,
    layout: defaultPagesLayout,
    component: () => <AboutUsPage />,
  },
  {
    path: '/knowmore',
    public: true,
    layout: defaultPagesLayout,
    component: () => <KnowMorePage />,
  },
  {
    path: '/blog',
    layout: defaultPagesLayout,
    component: () => <BlogPage />,
  },
  {
    path: '/blog-category',
    layout: defaultPagesLayout,
    component: () => <BlogListCategory />,
  },
  {
    path: '/blog-list',
    layout: defaultPagesLayout,
    component: () => <BlogList />,
  },
  {
    path: '/blog-details',
    layout: defaultPagesLayout,
    component: () => <BlogDetails />,
  },
  {
    path: '/blog-details-category',
    layout: defaultPagesLayout,
    component: () => <BlogDetailsCategory />,
  },
  {
    path: '/get-listed',
    public: true,
    layout: defaultPagesLayout,
    component: () => <GetListedPage />,
  },
  {
    path: '/start-get-listed',
    public: true,
    layout: authLayout,
    component: () => <StartGetListed />,
  },
  {
    path: '/get-listed-onboarding',
    public: true,
    layout: authLayout,
    component: () => <StartGetListedForm />,
  },
  {
    path: '/get-listed-onboarding-offer',
    public: true,
    layout: authLayout,
    component: () => <StartGetListedFormOffer />,
  },
  {
    path: '/get-listed-onboarding-contact',
    public: true,
    layout: authLayout,
    component: () => <StartGetListedFormContact />,
  },
  {
    path: '/get-listed-onboarding-offer-details',
    public: true,
    layout: authLayout,
    component: () => <StartGetListedOfferDetailsOne />,
  },
  {
    path: '/get-listed-onboarding-offer-details2',
    public: true,
    layout: authLayout,
    component: () => <StartGetListedOfferDetailsTwo />,
  },
  {
    path: '/get-listed-onboarding-offer-details3',
    public: true,
    layout: authLayout,
    component: () => <StartGetListedOfferDetailsThree />,
  },
  {
    path: '/user',
    layout: profileLayout,
    component: () => <ProfileSetting />,
  },
  {
    path: '/discover/booking-details',
    layout: profileLayout,
    component: () => <MyBookingDetails />,
  },
  {
    path: '/terms-conditions',
    public: true,
    layout: authLayout,
    component: () => <TermsandConditions />,
  },
  {
    path: '/privacy-policy',
    public: true,
    layout: authLayout,
    component: () => <PrivacyPolicy />,
  },
  {
    path: '/termsandconditions',
    public: true,
    layout: authLayout,
    component: () => <TermsandConditions />,
  },
  {
    path: '/privacypolicy',
    public: true,
    layout: authLayout,
    component: () => <PrivacyPolicy />,
  },
  {
    path: '/partnerapptandc',
    public: true,
    layout: authLayout,
    component: () => <PartnerAppTermsandConditions />,
  },
  {
    path: '/parnterappprivacypolicy',
    public: true,
    layout: authLayout,
    component: () => <PartnerAppPrivacyPolicy />,
  },
  {
    path: '/beyondreward-tandc',
    public: true,
    layout: authLayout,
    component: () => <BRTermsandConditions />,
  },
  {
    path: '/beyondrewards-privacypolicy',
    public: true,
    layout: authLayout,
    component: () => <BRPrivacyPolicy />,
  },
  {
    path: '/nbf-terms-and-conditions',
    public: true,
    layout: authLayout,
    component: () => <NBTermsandConditions />,
  },
  {
    path: '/nbf-privacy-policy',
    public: true,
    layout: authLayout,
    component: () => <NBFPrivacyPolicy />,
  },
  {
    path: '/rewards-plus-terms',
    public: true,
    layout: authLayout,
    component: () => <RPTermsandConditions />,
  },
  {
    path: '/rewards-privacy-policy',
    public: true,
    layout: authLayout,
    component: () => <RPPrivacyPolicy />,
  },
  {
    path: '/rsa-store-links',
    public: true,
    layout: defaultLayout,
    component: () => <RSAStore />,
  },
  {
    path: '/prive',
    public: true,
    location: true,
    layout: landingPriveLayout,
    component: () => <LandingPagePrive />,
  },
  {
    path: '/community',
    layout: communityLayout,
    component: () => <CommunityPage />,
  },
  {
    path: '/membership/buy',
    exact: true,
    layout: defaultPagesLayout,
    component: () => <BuyMembershipHome />,
  },
  {
    path: '/membership/checkout',
    exact: true,
    layout: defaultLayout,
    component: () => <BuyMembershipCheckout />,
  },
  {
    path: '/membership/checkout/success',
    exact: true,
    layout: defaultLayout,
    component: () => <BuyMembershipCheckoutSuccess />,
  },
  {
    path: '/my-membership',
    layout: defaultLayout,
    component: () => <MyMembershipHome />,
  },
];

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { user } = useUserSelector();

  if (!user) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { redirect: location },
        }}
      />
    );
  }

  return children;
};

const PrivateRouteWrap = ({ isPrivate, children }) =>
  isPrivate ? <PrivateRoute>{children}</PrivateRoute> : children;

const ReloadRoute = () => {
  const location = useLocation();
  const { from } = location.state || {};

  if (!from) {
    return <Redirect to="/" />;
  }

  return (
    <Redirect
      to={{
        pathname: from.pathname,
        search: from.search,
        state: from.state,
      }}
    />
  );
};

export const AppRoutes = memo(({ metaInfo }) => {
  return (
    <Router history={history} basename={process.env.REACT_APP_BASENAME || ''}>
      {rotues.map((route, index) => {
        return (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={(props) => {
              return (
                <PrivateRouteWrap isPrivate={!route.public}>
                  <route.layout {...props}>
                    <SetPriveMode>
                      <ScrollToTop>
                        <AddCityName metaInfo={metaInfo}>
                          {route.location ? (
                            <RequireLocationAccess>
                              <route.component {...props} />
                            </RequireLocationAccess>
                          ) : (
                            <route.component {...props} />
                          )}
                        </AddCityName>
                      </ScrollToTop>
                    </SetPriveMode>
                  </route.layout>
                </PrivateRouteWrap>
              );
            }}
          />
        );
      })}
      <Route path="/reload" component={() => <ReloadRoute />} />
    </Router>
  );
});
