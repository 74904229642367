import React from 'react';
import PropTypes from 'prop-types';

import { NavbarDefault } from '../components/layout';

const CommunityLayout = ({ children, navbar }) => (
  <React.Fragment>
    <div className="landing-wrapper profile-wrapper">
      {!navbar && <NavbarDefault logoOnly />}
      <main>{children}</main>
    </div>
  </React.Fragment>
);

CommunityLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool,
};

CommunityLayout.defaultProps = {
  navbar: false,
  footer: false,
};

export default CommunityLayout;
