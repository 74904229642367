import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Link } from 'react-router-dom';
import '../TermsandConditions/index.scss';

const PartnerAppPrivacyPolicy = () => {
  return (
    <div className="twl-terms-wrapper">
      <section className="terms-banner">
        <Container>
          <Row className="banner-row">
            <Col>
              <div className="banner-left">
                <div>
                  <h1 data-aos="fade-right" data-aos-duration="900">
                    Privacy Policy
                  </h1>
                  <p data-aos="fade-right" data-aos-duration="1000">
                    Last Updated: October 1, 2024
                  </p>
                </div>
                <div className="bottom-link">
                  <SvgIcon
                    className="left-arrow"
                    name="privacy-arrow"
                    viewbox="0 0 55.468 17.558"
                  />
                  <Link to="/partnerapptandc">
                    Terms and Conditions{' '}
                    <SvgIcon
                      className="right-arrow"
                      name="chevron-right"
                      viewbox="0 0 4.029 6.932"
                    />
                  </Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="terms-section">
        <Container>
          <Row>
            <Col>
              <div className="content-row">
                <p>
                  Welcome to TravellerPass Partner App! This Privacy Policy describes how we
                  collect, use, and disclose information about our users through the TravellerPass
                  Partner app.
                  <br />
                  <br />
                  <ol>
                    <li>
                      <h4>Information We Collect:</h4>
                      <p>
                        We collect information you provide to us, such as your name, email address,
                        and other contact information. We also collect information about your use of
                        the TravellerPass Partner app, including statistics and usage data. We may
                        also collect information about your device, such as device type, operating
                        system, and mobile network information.
                      </p>
                    </li>
                    <li>
                      <h4>Use of Information:</h4>
                      <p>
                        We use the information we collect to provide and improve the TravellerPass
                        Partner app, including providing you with access to stats, helping you keep
                        track of usage and redemptions, and activating offers for app users. We may
                        also use the information we collect to communicate with you, respond to your
                        inquiries, and provide customer support. We may also use the information for
                        other purposes, such as to comply with legal obligations or to protect our
                        rights or interests.
                      </p>
                    </li>

                    <li>
                      <h4>Disclosure of Information:</h4>
                      <p>
                        We may share your information with third-party service providers who perform
                        services on our behalf, such as hosting, data analysis, and customer
                        service. We may also disclose your information if required by law or if we
                        believe that disclosure is necessary to protect our rights or interests or
                        the rights or interests of others.
                      </p>
                    </li>
                    <li>
                      <h4>Data Security:</h4>
                      <p>
                        We take reasonable measures to protect the information we collect from
                        unauthorized access, disclosure, or modification. However, no method of
                        transmission over the Internet or electronic storage is 100% secure, and we
                        cannot guarantee the absolute security.
                      </p>
                    </li>
                    <li>
                      <h4>Children's Privacy:</h4>
                      <p>
                        The TravellerPass Partner app is not intended for use by children under the
                        age of 18. We do not knowingly collect personal information from children
                        under the age of 18.
                      </p>
                    </li>
                    <li>
                      <h4>Changes to this Privacy Policy:</h4>
                      We may modify this Privacy Policy from time to time. If we make material
                      changes to this Privacy Policy, we will notify you by email or through the
                      TravellerPass Partner app. Your continued use of the TravellerPass Partner app
                      after any modification to this Privacy Policy constitutes your acceptance of
                      the modified Privacy Policy.
                    </li>
                    <li>
                      <h4>Contact Us:</h4>
                      <p>
                        If you have any questions about this Privacy Policy or our privacy
                        practices, please contact us at
                        <a href="mailto:concierge@travellerpass.com">concierge@travellerpass.com</a>
                      </p>
                    </li>
                  </ol>
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default PartnerAppPrivacyPolicy;
