import React, { useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import FavouriteTab from './FavouriteTab';
import MyMembershipTab from './MyMembershipTab';
import ProfileSettingTab from './ProfileSettingTab';
import RedemptionsTab from './RedemptionsTab';
import MyOffersTab from './MyOffersTab';
import ReferAnOutlet from './ReferAnOutlet';
import { Button, Drawer } from 'antd';
import MediaQuery from 'react-responsive';
import './index.scss';
import { useHistory, useLocation } from 'react-router-dom';

const ProfileSetting = () => {
  const history = useHistory();
  const location = useLocation();
  const { hash } = location;

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const tabList = [
    {
      name: '#profile',
      icon: <SvgIcon name="profile-setting-icon" viewbox="0 0 9.022 9.736" />,
      label: 'Profile Setting',
      content: <ProfileSettingTab />,
    },
    /* {
      name: 'My_Bookings',
      icon: <SvgIcon name="mybooking" viewbox="0 0 11.026 9.836" />,
      label: 'My Bookings',
      content: <MyBookingsTab />,
    },*/
    {
      name: '#my_favourite',
      icon: <SvgIcon name="favourite-icon" viewbox="0 0 10.055 8.961" />,
      label: 'Favourite',
      content: <FavouriteTab />,
    },
    {
      name: '#my_membership',
      icon: <SvgIcon name="mymembership-icon" viewbox="0 0 10.738 6.859" />,
      label: 'My Membership',
      content: <MyMembershipTab />,
    },
    /*    {
      name: 'My_Cards',
      icon: <SvgIcon name="mycards-icon" viewbox="0 0 10.575 7.931" />,
      label: 'My Cards',
      content: <MyCardsTab />,
    },*/
    {
      name: '#redemptions',
      icon: <SvgIcon name="gift-icon" viewbox="0 0 11.651 11.119" />,
      label: 'Redemptions',
      content: <RedemptionsTab />,
    },
    {
      name: '#my_offers',
      icon: <SvgIcon name="myoffers-icon" viewbox="0 0 10.083 10.096" />,
      label: 'My Offers',
      content: <MyOffersTab />,
    },
    /*{
      name: 'Notification_Setting',
      icon: <SvgIcon name="notification-icon" viewbox="0 0 8.315 9.262" />,
      label: 'Notification Setting',
      content: <NotificationSettingTab />,
    },*/
    {
      name: '#refer_an_outlet',
      icon: <SvgIcon name="outlet-icon" viewbox="0 0 9.169 8.724" />,
      label: 'Refer an Outlet',
      content: <ReferAnOutlet />,
    },
  ];
  return (
    <div className="profilesetting-wrapper">
      <Container>
        <Row>
          <Col sm="12">
            <div className="profilesetting-main">
              <MediaQuery minWidth={991}>
                <div className="profilesetting-left">
                  <ul>
                    {tabList.map((tab, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          history.replace({ hash: tab.name });
                        }}
                        className={tab.name === hash ? 'active' : ''}
                      >
                        <button>
                          <div className="tab-icon">{tab.icon}</div>
                          {tab.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </MediaQuery>
              <MediaQuery maxWidth={991}>
                <Button className="profilemenu-btn" type="primary" onClick={showDrawer}>
                  <SvgIcon name="menu" viewbox="0 0 24 17.42" />
                </Button>
              </MediaQuery>
              <Drawer
                className="profilesetting-drawer"
                width={290}
                placement="right"
                onClose={onClose}
                open={open}
              >
                <div className="profilesetting-left-small" onClick={onClose}>
                  <ul>
                    {tabList.map((tab, i) => (
                      <li
                        key={i}
                        onClick={() => {
                          history.replace({ hash: tab.name });
                        }}
                        className={tab.name === hash ? 'active' : ''}
                      >
                        <button>
                          <div className="tab-icon">{tab.icon}</div>
                          {tab.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </Drawer>

              <div className="profilesetting-right">
                {tabList.map((tab, i) => {
                  if (tab.name === hash) {
                    return <div key={i}>{tab.content}</div>;
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ProfileSetting;
