import { useMembershipSelector } from '../../../store/selectors';
import { SvgIcon } from '../index';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { setSelectedPlanId } from '../../../slice/membershipSlice';
import './index.scss';

const MembershipPlanList = () => {
  const dispatch = useDispatch();
  const { plans, selectedPlanId } = useMembershipSelector();

  const onPlanClick = (plan) => {
    dispatch(setSelectedPlanId(plan.iMembershipPlanID));
  };

  const selectedPlan = useMemo(() => {
    return plans.find((plan) => plan.iMembershipPlanID === selectedPlanId);
  }, [plans, selectedPlanId]);

  if (!plans || plans.length <= 0) {
    return null;
  }
  return (
    <ul className="membership-plan-list" data-aos="fade-left" data-aos-duration="800">
      {plans.map((plan) => {
        return (
          <li key={`${plan.iMembershipPlanID}`} onClick={() => onPlanClick(plan)}>
            <div className="list-left">
              <div className="title-row">
                <h3>{plan.title}</h3>
                {plan.isActive && <span className="badge active">Active</span>}
                {plan.mPriceSaving &&
                  +plan.mPriceSaving !== 0 &&
                  plan.mPlanPromoDiscount === 0 &&
                  !plan.isActive && (
                    <span className="badge">
                      Save {plan.mPriceDenomination} {plan.mPriceSaving}
                    </span>
                  )}
              </div>
              <p>{plan.desc}</p>
            </div>

            {selectedPlan && selectedPlan.iMembershipPlanID === plan.iMembershipPlanID && (
              <div className="right-check">
                <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};

export default MembershipPlanList;
