import { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';

class SetPriveMode extends PureComponent {
  componentDidMount = () => {
    const searchParams = new URLSearchParams(this.props.location.search);
    const isPrive = searchParams.has('isPrive');
    const isCheckoutFlow = searchParams.has('isCheckout');
    document.body.classList.toggle('prive-mode', isPrive);
    document.body.classList.toggle('prive-checkout', isPrive && isCheckoutFlow);
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.location.search !== prevProps.location.search) {
      const searchParams = new URLSearchParams(this.props.location.search);
      const isPrive = searchParams.has('isPrive');
      const isCheckoutFlow = searchParams.has('isCheckout');
      document.body.classList.toggle('prive-mode', isPrive);
      document.body.classList.toggle('prive-checkout', isPrive && isCheckoutFlow);
    }
  };

  render = () => this.props.children;
}

export default withRouter(SetPriveMode);
