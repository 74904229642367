import { AutoComplete, Radio } from 'antd';
import React, { useCallback, useRef, useState } from 'react';
import './index.scss';
import { debounce } from 'lodash';
import DotLoader from '../../../assets/json/dot_loader.json';
import Lottie from 'react-lottie';
import { useCityCountrySelector, useUserSelector } from '../../../store/selectors';
import { apiClient, priveApiClient } from '../../../apiConfig';
import { SvgIcon } from '../index';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { logEvent } from '../../../utility/analytics';

const SearchResultsDrop = ({ inNavBar, onClick }) => {
  const history = useHistory();
  const inputRef = useRef(null);
  const [sType, setSType] = useState('All');
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const { latLng } = useUserSelector();
  const { selectedCity } = useCityCountrySelector();

  const debounceSearch = useCallback(
    debounce((query, type) => handleSearch(query, type), 700),
    [],
  );

  const handleSearch = async (query, type) => {
    try {
      const payload = {
        iCountryID: selectedCity.iCountryID,
        dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
        dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
        tOffer: query,
        sType: type,
      };

      logEvent('search', { search_term: query });

      const [response, priveResponse] = await Promise.all([
        apiClient.post('offer/offerListKeySearch', payload),
        priveApiClient.post('offer/offerListKeySearch', payload),
      ]);

      let results = [];
      if (response.data.status === 1) {
        results = response.data.DATA;
      }
      if (priveResponse.data.status === 1) {
        results = [
          ...results,
          ...priveResponse.data.DATA.map((item) => ({ ...item, isPrive: 'Yes' })),
        ];
      }
      setSearchResults(results);
    } catch (e) {
      setSearchResults([]);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchInput = (query) => {
    setQuery(query);
    const s = query.trim();
    if (s.length > 2) {
      setLoading(true);
      debounceSearch(query, sType);
    } else {
      setLoading(false);
      setSearchResults([]);
    }
  };

  const handleSTypeChange = (event) => {
    setSType(event.target.value);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 150);
    setLoading(true);
    handleSearch(query, event.target.value);
  };

  const handleItemClick = (item) => {
    if (item.sType === 'Outlet' || (item.sType === 'tOffer' && item.isPrive === 'Yes')) {
      const params = new URLSearchParams({
        iBrandID: item.iBrandID,
        iOutletID: item.iOutletID,
        iDealID: item.iDealID,
        ...(item.isPrive === 'Yes' && { isPrive: '' }),
      });
      history.push({ pathname: '/details', search: params.toString() });
      return;
    }
    if (item.sType === 'Category') {
      const params = new URLSearchParams({
        categoryId: item.iCategoryID,
      });

      history.push({
        pathname: '/listing',
        search: params.toString(),
      });
      return;
    }

    if (item.sType === 'vAreaName' || item.sType === 'tOffer') {
      const params = new URLSearchParams({
        sType: item.sType,
        tOffer: item.tOffer,
      });

      history.push({
        pathname: '/searchListing',
        search: params.toString(),
      });
      return;
    }
  };
  return (
    <AutoComplete
      value={query}
      onSearch={(value) => handleSearchInput(value)}
      placeholder="Search"
      popupClassName={classNames('search-results-dropdown', {
        'in-navbar': inNavBar === true,
      })}
      listHeight={300}
      popupMatchSelectWidth={484}
      dropdownRender={(menu) => (
        <>
          <Radio.Group value={sType} onChange={handleSTypeChange}>
            <Radio.Button value="All">All</Radio.Button>
            <Radio.Button value="Brand">Brand</Radio.Button>
            <Radio.Button value="Area">Area</Radio.Button>
            {/*    <Radio.Button value="Category">Category</Radio.Button>*/}
          </Radio.Group>
          {menu}
        </>
      )}
      options={[
        ...(loading
          ? [
              {
                key: 'loader',
                value: (
                  <div onClick={(e) => e.stopPropagation()}>
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: DotLoader,
                        renderer: 'svg',
                      }}
                      height={160}
                      width={160}
                    />
                  </div>
                ),
              },
            ]
          : [
              ...searchResults
                .filter((item) => item.sType === 'Outlet' || item.sType === 'tOffer')
                .sort((a, b) => +a.distance - +b.distance)
                .map((item, index) => {
                  return {
                    key: `${item.sType}_${item.iBrandID}_${item.iOutletID}_${item.iDealID}${item.isPrive === 'Yes' ? '_prive' : ''}`,
                    value: (
                      <div className="search-list-inner" onClick={() => handleItemClick(item)}>
                        <div className="left-avatar">
                          <img src={item.vBrandImage} alt={item.tOffer} />
                        </div>
                        <div className="search-list-right">
                          <p>{item.tOffer}</p>
                          <label>
                            <SvgIcon name="map" viewbox="0 0 8.358 12.537" />{' '}
                            {(Math.floor(item.distance * 100) / 100).toFixed(2)}Km /{' '}
                            {item.vAreaName}
                          </label>
                        </div>
                      </div>
                    ),
                  };

                  /*return {
              key: index,
              value: (
                <div className="search-list-inner">
                  <div className="left-avatar">
                    <img src={item.vBrandImage} alt={item.tOffer} />
                  </div>
                  <div className="search-list-right">
                    <p>{item.tOffer}</p>
                    <label>
                      <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama
                      Center
                    </label>
                  </div>
                </div>
              ),
            };*/
                }),
              ...searchResults
                .filter((item) => item.sType === 'vAreaName')
                .map((item, index) => {
                  return {
                    key: `${item.sType}_${item.tOffer}${item.isPrive === 'Yes' ? '_prive' : ''}`,
                    value: (
                      <div className="search-list-inner" onClick={() => handleItemClick(item)}>
                        <div className="left-avatar">
                          <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        </div>
                        <div className="search-list-right">
                          <p>{item.tOffer}</p>
                          <label>{`${item.VenuesCount} Venues`}</label>
                        </div>
                      </div>
                    ),
                  };
                }),
            ]),
      ]}
      /* options={[
        {
          key: 's2',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <img src={SearchImg1} alt={SearchImg1} />
              </div>
              <div className="search-list-right">
                <p>Activities</p>
                <label>Experiences / 23 Venues</label>
              </div>
            </div>
          ),
        },
        {
          key: 's3',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <img src={SearchImg2} alt={SearchImg2} />
              </div>
              <div className="search-list-right">
                <p>Tamoka Cafe</p>
                <label>
                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama Center
                </label>
              </div>
            </div>
          ),
        },
        {
          key: 's4',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <img src={SearchImg3} alt={SearchImg3} />
              </div>
              <div className="search-list-right">
                <p>Theme Park</p>
                <label>Experiences / 23 Venues</label>
              </div>
            </div>
          ),
        },
        {
          key: 's5',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <img src={SearchImg4} alt={SearchImg4} />
              </div>
              <div className="search-list-right">
                <p>Grab Roll</p>
                <label>
                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama Center
                </label>
              </div>
            </div>
          ),
        },
        {
          key: 's6',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <img src={SearchImg5} alt={SearchImg5} />
              </div>
              <div className="search-list-right">
                <p>First Food</p>
                <label>
                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama Center
                </label>
              </div>
            </div>
          ),
        },
        {
          key: 's7',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
              </div>
              <div className="search-list-right">
                <p>Jumeirah Beach</p>
                <label>
                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama Center
                </label>
              </div>
            </div>
          ),
        },
        {
          key: 's8',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
              </div>
              <div className="search-list-right">
                <p>Jumeirah Beach</p>
                <label>
                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama Center
                </label>
              </div>
            </div>
          ),
        },
        {
          key: 's9',
          value: (
            <div className="search-list-inner">
              <div className="left-avatar">
                <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
              </div>
              <div className="search-list-right">
                <p>Jumeirah Beach</p>
                <label>
                  <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama Center
                </label>
              </div>
            </div>
          ),
        },
      ]}*/
      {...(onClick && { onClick: onClick })}
    />
  );
};

export default SearchResultsDrop;
