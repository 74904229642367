import React from 'react';
import { Button, Modal } from 'antd';
import { Col, Row, SvgIcon } from '../../common';
import Lottie from 'react-lottie';
import './index.scss';

import AnimationOne from '../../../assets/json/no_location.json';
import { useDispatch } from 'react-redux';
import store from '../../../store';
import { setLocationPermission } from '../../../slice/userSlice';
import { Alert } from '../../../common';

const LocationModal = ({ handleCancel }) => {
  const dispatch = useDispatch();
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  const handleAllow = () => {
    const options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    const success = () => {
      handleCancel();
      window.location.reload();
    };
    const error = (positionError) => {
      handleCancel();
      Alert.error('Oops! Something went wrong.');
    };

    navigator.permissions.query({ name: 'geolocation' }).then(
      (result) => {
        if (result.state === 'prompt') {
          console.log('Requesting current location...');
          navigator.geolocation.getCurrentPosition(success, error, options);
        } else if (result.state === 'denied') {
          Alert.error('Please enable location permission from site settings and reload the page.');
          handleCancel();
        }
      },
      () => {
        Alert.error('Please enable location permission from site settings and reload the page.');
        handleCancel();
      },
    );
  };
  return (
    <>
      <Modal
        className="location-modal"
        centered
        maskClosable={false}
        footer={null}
        title={null}
        width={550}
        open={true}
        onOk={handleCancel}
        onCancel={handleCancel}
        closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
      >
        <div>
          <Row>
            <Col md="12">
              <Lottie options={animationLottie} height={200} width={200} />
            </Col>
            <Col md="12">
              <h1 data-aos="fade-up" data-aos-duration="800">
                Precise Location
              </h1>
              <p data-aos="fade-up" data-aos-duration="900">
                In order for you to avail our offers,
                <br />
                We will need to ensure that you are at the venue
                <br />
                <br />
                Please enable precise location to continue
              </p>
              <div className="pt-3 sub-btns" data-aos="fade-up" data-aos-duration="1000">
                <Button type="secondary" className="px-5" onClick={handleAllow}>
                  Allow
                </Button>
                <Button
                  type="link"
                  className="px-5 cancel-text"
                  onClick={() => {
                    store.dispatch(setLocationPermission('denied'));
                    handleCancel();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default LocationModal;
