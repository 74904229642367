export function insertUrlParam(key, value, preserve_hash = false) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    if (preserve_hash) newurl = newurl + window.location.hash;

    window.history.replaceState({ path: newurl }, '', newurl);
  }
}

export function removeUrlParam(key, preserve_hash = false) {
  if (window.history.pushState) {
    let searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has(key)) {
      searchParams.delete(key);

      let newurl =
        window.location.protocol +
        '//' +
        window.location.host +
        window.location.pathname +
        (searchParams.toString().length > 0 ? '?' : '') +
        searchParams.toString();
      if (preserve_hash) newurl = newurl + window.location.hash;

      window.history.replaceState({ path: newurl }, '', newurl);
    }
  }
}
