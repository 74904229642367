import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import { Col, Row, SvgIcon } from '../../../components/common';
import Lottie from 'react-lottie';
import './index.scss';

import AnimationOne from '../../../assets/json/cancel-jump.json';
import { useDispatch } from 'react-redux';
import { removeUser } from '../../../slice/userSlice';

const LogoutModal = () => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };
  return (
    <>
      <div onClick={showModal}>Logout</div>
      <Modal
        className="thankyou-modal"
        centered
        maskClosable={false}
        footer={null}
        title={null}
        width={550}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
      >
        <div>
          <Row>
            <Col md="12">
              <Lottie options={animationLottie} height={280} width={280} />
            </Col>
            <Col md="12">
              <h1 data-aos="fade-up" data-aos-duration="800">
                Logout
              </h1>
              <p data-aos="fade-up" data-aos-duration="900">
                Are you sure, you want to logout?
              </p>
              <div className="pt-3 sub-btns" data-aos="fade-up" data-aos-duration="1000">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="px-5"
                  onClick={() => {
                    dispatch(removeUser());
                    window.location.href = '/';
                    handleCancel();
                  }}
                >
                  Yes, I would
                </Button>
                <Button type="link" className="px-5 cancel-text" onClick={handleCancel}>
                  No, Cancel
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
};

export default LogoutModal;
