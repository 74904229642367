import React from 'react';
import { Col, Container, DealCard, Row } from '../../components/common';
import MediaQuery from 'react-responsive';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import FilterDrawer from '../FilterDrawer';
import { useHomeSelector, usePriveHomeSelector } from '../../store/selectors';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { LoadState } from '../../constants';
import DealList from '../../components/common/DealList';

const HomeOfferListing = () => {
  const history = useHistory();
  const location = useLocation();
  const { type, groupId } = location.state || {};

  const { trending, monthly, state2: homeState } = useHomeSelector();
  const { collections, state: priveHomeState } = usePriveHomeSelector();
  const { title: trendingTitle, items: trendingItems } = trending;
  const { title: monthlyTitle, items: monthlyItems } = monthly;

  const searchParams = new URLSearchParams(location.search);
  const prive = searchParams.has('isPrive');

  if (
    (prive && (!groupId || priveHomeState !== LoadState.SUCCESS)) ||
    (!prive && (!type || homeState !== LoadState.SUCCESS))
  ) {
    return <Redirect to="/" />;
  }

  return (
    <div className="twl-listing-wrapper">
      <section className="listing-page-title">
        <Container>
          <h3>
            {type === 'monthly' && monthlyTitle}
            {type === 'trending' && trendingTitle}
            {prive && collections.find((i) => i.group_id === groupId)?.group_title}
            {/*What's <span>Trending</span>*/}
          </h3>
          <MediaQuery minWidth={768}>
            <FilterDrawer />
          </MediaQuery>
        </Container>
      </section>
      <section className="listing-bottom no-padding">
        <Container className="listing-container">
          <Row>
            <Col>
              {type === 'trending' && <DealList items={trendingItems} />}
              {type === 'monthly' && <DealList items={monthlyItems} />}
              <div className="tp-item-card-list">
                {prive &&
                  collections
                    .find((i) => i.group_id === groupId)
                    ?.dealList?.map((item) => (
                      <DealCard
                        key={`${item.iBrandID}_${item.iOutletID}_${item.iDealID}_prive`}
                        item={{ ...item, isPrive: 'Yes' }}
                      />
                    ))}
              </div>
            </Col>
          </Row>
          {/*<Row>
            <Col className="text-center">
              <Button
                type="primary"
                className="map-btn"
                onClick={() => window.open('/listing-map', '_self')}
              >
                Map <SvgIcon name="map-icon" viewbox="0 0 14.855 14.695" />
              </Button>
            </Col>
          </Row>*/}
        </Container>
      </section>
    </div>
  );
};

export default HomeOfferListing;
