// checkedItemsSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload.map((cat, index) => {
        return { ...cat, isSelected: index === 0 };
      });
    },
    setSelectedCategory: (state, action) => {
      state.categories = state.categories.map((cat, index) => {
        return {
          ...cat,
          isSelected: action.payload ? cat.iCategoryID === action.payload : index === 0,
        };
      });
    },
  },
});

export const { setCategories, setSelectedCategory } = categorySlice.actions;

export default categorySlice.reducer;
