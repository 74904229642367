// checkedItemsSlice.js

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getSubCategories = createAsyncThunk('fetch/subCategories', async (arg, thunkAPI) => {
  const { countryCity, user: userReducer } = thunkAPI.getState();
  const { selectedCity } = countryCity;
  const { latLng, user } = userReducer;
  const { cacheKey, categoryId } = arg;
  if (!selectedCity) {
    throw Error('City not selected.');
  }

  if (!cacheKey) {
    throw Error('arg cacheKey not found.');
  }

  thunkAPI.dispatch(setCacheKey(cacheKey));

  const payload = {
    iCountryID: selectedCity.iCountryID,
    iCategoryID: categoryId,
    dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
    dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
    vCityName: selectedCity.vCityName,
    iCityID: selectedCity.iCityID,
  };

  console.log('USER ----', user);
  console.log('LatLng ----', latLng);

  const url = user ? '/offer/categoryFeaturedOfferList' : '/guestoffer/categoryFeaturedOfferList';

  const response = await apiClient.post(url, payload);

  if (response.data.status !== 1) {
    throw new Error(`categoryFeaturedOfferList received non zero status.`);
  }
  const subCategories = response.data?.SubcategoryList;
  if (!subCategories) {
    throw new Error(`sub-categories not found for categoryId: ${categoryId}`);
  }

  return subCategories;
});

const initialState = {
  cacheKey: undefined,
  state: LoadState.IDLE,
  subCategories: [],
};
export const subCategorySlice = createSlice({
  name: 'subCategory',
  initialState,
  reducers: {
    setCacheKey: (state, action) => {
      state.cacheKey = action.payload;
    },
    resetSubCategories: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getSubCategories.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getSubCategories.fulfilled, (state, action) => {
      state.subCategories = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getSubCategories.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });
  },
});

export const { resetSubCategories, setCacheKey } = subCategorySlice.actions;

export default subCategorySlice.reducer;
