import React, { useMemo, useState } from 'react';
import { Button, Modal } from 'antd';
import { Col, Row, SvgIcon } from '../../../components/common';
import Lottie from 'react-lottie';
import './index.scss';

import AnimationOne from '../../../assets/json/cancel-jump.json';
import { useLoaderContext } from '../../../components/common/Loader';
import CancelMembershipDoneModal from '../CancelMembershipDoneModal';
import { useDispatch } from 'react-redux';
import { autoRenewalCancelled } from '../../../slice/membershipSlice';
import { useMembershipSelector } from '../../../store/selectors';

const CancelSubscriptionModal = () => {
  const dispatch = useDispatch();
  const { setShowLoader } = useLoaderContext();
  const { plans } = useMembershipSelector();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);

  const activePlan = useMemo(() => (plans || []).find((item) => item.isActive), [plans]);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const cancelSubscription = async () => {
    setShowLoader(true);
    await new Promise((resolve) => setTimeout(resolve, 2000));
    setShowLoader(false);
    dispatch(autoRenewalCancelled());
    setCancelSuccess(true);
  };
  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  return (
    <>
      {activePlan && activePlan.isAutoRenewal && (
        <>
          <Button
            type="link"
            className="text-decoration mx-auto mt-1 cancel-sub"
            htmlType="submit"
            onClick={showModal}
          >
            Cancel Subscription
          </Button>
          <Modal
            className="thankyou-modal"
            centered
            maskClosable={false}
            footer={null}
            title={null}
            width={550}
            open={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
          >
            <div>
              <Row>
                <Col md="12">
                  <Lottie options={animationLottie} height={280} width={280} />
                </Col>
                <Col md="12">
                  <h1 data-aos="fade-up" data-aos-duration="800">
                    Are You Sure?
                  </h1>
                  <p data-aos="fade-up" data-aos-duration="900">
                    Do you want to cancel the subscription?
                  </p>
                  <div className="pt-3 sub-btns" data-aos="fade-up" data-aos-duration="1000">
                    <Button
                      htmlType="submit"
                      className="px-5 yes-cancel-btn"
                      onClick={() => {
                        handleCancel();
                        cancelSubscription();
                      }}
                    >
                      Yes, cancel it
                    </Button>
                    <Button type="link" className="px-5 cancel-text" onClick={handleCancel}>
                      No, Cancel
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </Modal>
        </>
      )}
      {cancelSuccess && <CancelMembershipDoneModal handleCancel={() => setCancelSuccess(false)} />}
    </>
  );
};

export default CancelSubscriptionModal;
