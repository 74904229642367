import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button, Card, Collapse, List } from 'antd';
import { Link } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import Slider from 'react-slick';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';
import DetailsImg1 from '../../assets/images/details-img-6.jpg';
import dtlLogo from '../../assets/images/dtl-logo.jpg';
import VideoImage from '../../assets/images/video-image.png';
import Listimg1 from '../../assets/images/list-img-1.jpg';
import Listimg2 from '../../assets/images/list-img-2.jpg';
import Listimg3 from '../../assets/images/list-img-3.jpg';
import Listimg4 from '../../assets/images/list-img-4.jpg';
import Listimg5 from '../../assets/images/list-img-5.jpg';
import Listimg6 from '../../assets/images/list-img-6.jpg';
import Listimg7 from '../../assets/images/list-img-7.jpg';
import Listimg8 from '../../assets/images/list-img-8.jpg';
import Listimg9 from '../../assets/images/list-img-9.jpg';
import Listimg10 from '../../assets/images/list-img-10.jpg';
import offerTagimg1 from '../../assets/images/offer1.png';
import offerTagimg2 from '../../assets/images/offer1.png';
import offerTagimg3 from '../../assets/images/offer3.png';

const { Panel } = Collapse;

const offersList = [
  {
    key: 1,
    avatar: offerTagimg1,
    title: '30% off',
    description: 'Discount of 30% on total bill',
  },
  {
    key: 2,
    avatar: offerTagimg2,
    title: 'Buy 1 get 1 free',
    description: 'Discount of 30% on total bill Valid on dine-in only',
  },
  {
    key: 3,
    avatar: offerTagimg3,
    title: 'Special Offer',
    description: 'Complimentary main course when a main course is purchased.',
  },
];

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const DetailsPrivePageSize = () => {
  const settingsOtherVanues = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.6,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const settingsSimilerVanues = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const ListData = [
    { key: 1, image: Listimg1 },
    { key: 2, image: Listimg2 },
    { key: 3, image: Listimg3 },
    { key: 4, image: Listimg4 },
    { key: 5, image: Listimg5 },
    { key: 6, image: Listimg6 },
    { key: 7, image: Listimg7 },
    { key: 8, image: Listimg8 },
    { key: 9, image: Listimg9 },
    { key: 10, image: Listimg10 },
  ];
  return (
    <div className="twl-details-wrapper">
      <MediaQuery maxWidth={767}>
        <div className="details-mobile-header">
          <Link to="/listing" className="back-arrow">
            <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
          </Link>
          <div className="right-action">
            <Button type="link">
              <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" />
            </Button>
          </div>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={767}>
        <div className="mobile-redeem-action">
          <div className="left-actions">
            <Button type="link">
              <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
            </Button>
            <Button type="link">
              <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
            </Button>
          </div>
          <Button
            type="primary"
            onClick={() => {
              window.location.href = '/prive-checkout';
            }}
          >
            Redeem
          </Button>
        </div>
      </MediaQuery>
      <section>
        <Container className="container-upper">
          <Row>
            <Col>
              <Row className="size-details2">
                <Col md="7" className="left-image-main">
                  <div className="left-image">
                    <Link to="/details-prive">
                      <img src={DetailsImg1} alt="Experience London skyline" />
                    </Link>
                  </div>
                </Col>
                <Col md="5">
                  <div className="offer-details-right">
                    <div className="head">
                      <h4>Offers</h4>
                      <p>APPLICABLE AT VENUE</p>
                    </div>
                    <List
                      itemLayout="horizontal"
                      dataSource={offersList}
                      renderItem={(item) => (
                        <List.Item actions={[<SvgIcon name="check" viewbox="0 0 10.289 9.742" />]}>
                          <List.Item.Meta
                            avatar={<img src={item.avatar} alt={item.title} />}
                            title={item.title}
                            description={item.description}
                          />
                        </List.Item>
                      )}
                    />
                    <MediaQuery minWidth={768}>
                      <div className="bottom-action">
                        <Row>
                          <Col>
                            <Button
                              type="primary"
                              onClick={() => {
                                window.location.href = '/prive-checkout';
                              }}
                              block
                            >
                              Redeem
                            </Button>
                          </Col>
                          <Col>
                            <Button type="primary" ghost block>
                              Get The Directions
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </MediaQuery>
                  </div>
                </Col>
                <Col xs="12" className="learn-btn-row">
                  <Button type="link" icon={<SvgIcon name="play" viewbox="0 0 41.93 41.965" />}>
                    Learn how to redeem an offer
                  </Button>
                </Col>
              </Row>
              <div className="details-row pt-3">
                <div className="details-left pr-0">
                  <div className="dtl-upper">
                    <div className="dtl-upperleft">
                      <div className="dlt-logo">
                        <img src={dtlLogo} alt="Details Logo" />
                      </div>
                      <h1>Experience London skyline</h1>
                      <div className="location">
                        <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        London, United Kingdom
                      </div>
                    </div>
                    <MediaQuery minWidth={768}>
                      <div className="dtl-upperright">
                        <Button type="link">
                          Favorite <SvgIcon name="heart-outline" viewbox="0 0 13.269 12.168" />
                        </Button>
                        <Button type="link">
                          Share <SvgIcon name="share-icon" viewbox="0 0 10.314 11.942" />
                        </Button>
                      </div>
                    </MediaQuery>
                  </div>
                  <div className="overview-row">
                    <h2>Overview</h2>
                    <p>
                      War, fire, regeneration and technology in construction change the skyline of
                      the world’s capitals. Tall buildings are an inevitable result of those changes
                      Our accommodations are unusual ecological construction{' '}
                      <Link to="/">read more</Link>
                    </p>
                  </div>
                  <div className="highlights-row">
                    <h2>Highlights</h2>
                    <ul>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="italian-icon" viewbox="0 0 32.352 19.676" />
                        </div>
                        <div>Italian</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="wheelchair-icon" viewbox="0 0 32.714 29.209" />
                        </div>
                        <div>Wheelchair Access</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="takeaway-icon" viewbox="0 0 21.193 28.901" />
                        </div>
                        <div>Takeaway</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="covid-icon" viewbox="0 0 28.349 28.35" />
                        </div>
                        <div>Covid-19 precautions</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="dinein-icon" viewbox="0 0 27.073 27.137" />
                        </div>
                        <div>Dine-in</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="delivery-icon" viewbox="0 0 33.41 25.336" />
                        </div>
                        <div>Delivery</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="payment-icon" viewbox="0 0 29.354 29.096" />
                        </div>
                        <div>AED 250 for two People (approx.)</div>
                      </li>
                      <li>
                        <div className="left-icon">
                          <SvgIcon name="date-icon" viewbox="0 0 22.241 23.293" />
                        </div>
                        <div>
                          10:00AM - 11:30PM (Weekdays) <br /> 10:00AM - 11:30PM (Weekend)
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="other-vanues">
                <h2>Other Venues</h2>
                <Slider {...settingsOtherVanues}>
                  <div>
                    <div className="vanues-card">
                      <div className="card-left">
                        <div className="vanues-image">
                          <img src={dtlLogo} alt="vanues" />
                        </div>
                        <div className="vanues-info">
                          <h4>Jumeirah</h4>
                          <p>Dubai</p>
                        </div>
                      </div>
                      <div className="card-action">
                        <Button>
                          <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
                        </Button>
                        <Button>
                          <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="vanues-card">
                      <div className="card-left">
                        <div className="vanues-image">
                          <img src={dtlLogo} alt="vanues" />
                        </div>
                        <div className="vanues-info">
                          <h4>Jumeirah</h4>
                          <p>Dubai</p>
                        </div>
                      </div>
                      <div className="card-action">
                        <Button>
                          <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
                        </Button>
                        <Button>
                          <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="vanues-card">
                      <div className="card-left">
                        <div className="vanues-image">
                          <img src={dtlLogo} alt="vanues" />
                        </div>
                        <div className="vanues-info">
                          <h4>Jumeirah</h4>
                          <p>Dubai</p>
                        </div>
                      </div>
                      <div className="card-action">
                        <Button>
                          <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
                        </Button>
                        <Button>
                          <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="vanues-card">
                      <div className="card-left">
                        <div className="vanues-image">
                          <img src={dtlLogo} alt="vanues" />
                        </div>
                        <div className="vanues-info">
                          <h4>Jumeirah</h4>
                          <p>Dubai</p>
                        </div>
                      </div>
                      <div className="card-action">
                        <Button>
                          <SvgIcon name="call-icon" viewbox="0 0 14.993 14.993" />
                        </Button>
                        <Button>
                          <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="faq-section">
        <Container>
          <Row>
            <Col>
              <h2>FAQ</h2>
            </Col>
          </Row>
          <Row>
            <Col sm="5" className="faq-left">
              <h1>Some common questions you may have</h1>
              <div className="video-card">
                <div className="caption">
                  <Button href="#" type="link" className="play-icon">
                    <SvgIcon name="play" viewbox="0 0 41.93 41.965" />
                  </Button>
                  <p>
                    Learn how <br /> to use the app
                  </p>
                </div>
                <img src={VideoImage} alt="Learn how to use it" />
              </div>
            </Col>
            <Col sm="7" className="faq-right">
              <Collapse
                accordion
                defaultActiveKey={['1']}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <SvgIcon name="minus" viewbox="0 0 16.638 2.405" />
                  ) : (
                    <SvgIcon name="plus" viewbox="0 0 16.638 17.53" />
                  )
                }
              >
                <Panel header="What is TravellerPass?" key="1">
                  <p>
                    TravellerPass is a mobile application that gives you access to over 3000+ venues
                    in the UAE and Oman through unlimited 2 for 1 offers or flat discounts across
                    categories such as Dining, Experiences, Lifestyle, Wellness and our luxury
                    segement, Prive.
                  </p>
                </Panel>
                <Panel header="Is there a limit to the number of redemption per offer?" key="2">
                  <p>
                    95% of all our offers are unlimited, which means you get your discount as many
                    times as you want.
                  </p>
                </Panel>
                <Panel header="Where can I download TravellerPass?" key="3">
                  <p>
                    TravellerPass can be downloaded from the below stores:
                    <div className="download-btns">
                      <Button
                        onClick={() =>
                          window.open(
                            'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(
                            'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
                      </Button>
                      {/* <Button
                        onClick={() =>
                          window.open('https://appgallery.huawei.com/#/app/C100681983', '_blank')
                        }
                      >
                        <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
                      </Button>*/}
                    </div>
                  </p>
                </Panel>
                <Panel header="When can I start my membership with TravellerPass? " key="4">
                  <p>
                    You can purchase our Annual Bundle membership plan to get started right away!
                    What's more, you get all the days as promised based on the membership you choose
                    from the date of activation, so you can keep availing our offers without any
                    interruption.
                  </p>
                </Panel>
                <Panel header="How do I redeem my TravellerPass offer?" key="5">
                  <p>
                    Once you activate your membership, start saving by following the below steps:
                  </p>
                </Panel>
              </Collapse>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="similervanues-section">
        <Container>
          <Row>
            <Col>
              <hr />
            </Col>
          </Row>
          <Row>
            <Col>
              <h2>Similar Venues</h2>
              <Slider {...settingsSimilerVanues}>
                {ListData.map((item) => (
                  <div>
                    <Card
                      key={item.key}
                      className="tp-item-card"
                      cover={<img alt="TP List" src={item.image} />}
                      extra={
                        <Button>
                          <SvgIcon name="heart" viewbox="0 0 10.238 9.131" />
                        </Button>
                      }
                    >
                      <div className="offer-tag">50% OFF</div>
                      <div className="bottom-row">
                        <div className="left-col">
                          <h3>Yacht Junction</h3>
                          <p>
                            <SvgIcon name="map" viewbox="0 0 8.358 12.537" /> 12km / Al Karama
                            Center
                          </p>
                        </div>
                        <div className="right-col">
                          <h3>AED 340</h3>
                          <p>Starting Price</p>
                        </div>
                      </div>
                    </Card>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="social-section">
        <div className="travellerPass-icon">
          <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
        </div>
        <Container>
          <Row>
            <Col>
              <img className="logo-icon" src={LogoIcon} alt="TravellerPass" />
              <h1>
                Discover the world like never before <br /> with TravellerPass
              </h1>
              <ul>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="dribbble" viewbox="0 0 17.206 17.206" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="twitter" viewbox="0 0 18.304 14.986" />
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default DetailsPrivePageSize;
