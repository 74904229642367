import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import './index.scss';

import LogoIcon from '../../../assets/images/logo-icon.svg';
import BlogAvatar from '../../../assets/images/bog-avatar.png';
import BlogDetailsImg1 from '../../../assets/images/blog-details-1.jpg';
import BlogDetailsImg2 from '../../../assets/images/blog-details-2.jpg';
import BlogDetailsImg3 from '../../../assets/images/blog-details-3.jpg';
import BlogDetailsImg4 from '../../../assets/images/blog-details-4.jpg';
import DiscoverImageSmall from '../../../assets/images/discover-small-img.jpg';

const BlogDetailsCategory = () => {
  const BlogsData = [
    {
      id: 1,
      title: 'Circuit X',
      mainimage: BlogDetailsImg2,
      offer: '30',
      details:
        'Abu Dhabi’s many tourists and residents may not be aware that the city has so much more to offer than just these few ultra-popular hotspots. That’s why we at TravellerPass are here.',
      location: 'Al Hudayriat Island',
    },
    {
      id: 2,
      title: '321 Sports',
      mainimage: BlogDetailsImg3,
      offer: '30',
      details:
        'People of the UAE, Eid Holidays are approaching. So, planning the ultimate Eid weekend is what is on most of our minds as we all want to make the most out of this Eid weekend.',
      location: 'Al Hudayriat Island',
    },
    {
      id: 3,
      title: '321 Sports',
      mainimage: BlogDetailsImg4,
      offer: '30',
      details:
        'Abu Dhabi’s many tourists and residents may not be aware that the city has so much more to offer than just these few ultra-popular hotspots. That’s why we at TravellerPass are here.',
      location: 'Al Hudayriat Island',
    },
  ];

  return (
    <div className="twl-blog-wrapper">
      <section className="blog-banner-list"></section>
      <section className="blog-upper-list">
        <Container>
          <Row className="backbtn-row">
            <Col>
              <Link to="/blog-category">
                <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" /> Back
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <h1 data-aos="fade-up" data-aos-duration="700">
                Exploring the unknown Dubai edition
              </h1>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blog-upper">
        <Container>
          <Row>
            <Col>
              <div className="datetime-row" data-aos="fade-up" data-aos-duration="800">
                NOV 29 - 3 MIN READ - LIFESTYLE
              </div>
              <div className="user-row" data-aos="fade-up" data-aos-duration="800">
                <div className="user-left">
                  <div className="user-img">
                    <img src={BlogAvatar} alt={BlogAvatar} />
                  </div>
                  <div>
                    <h4>Aryaa Patel</h4>
                    <p>TravellerPass</p>
                  </div>
                </div>
                <div className="user-right btn-action">
                  <Button type="primary" ghost>
                    Wellness
                  </Button>
                  <Button type="primary" ghost>
                    Dining
                  </Button>
                </div>
              </div>
              <div className="blog-img-dtl" data-aos="fade-in" data-aos-duration="1000">
                <img src={BlogDetailsImg1} alt={BlogDetailsImg1} />
              </div>
              <div className="blog-detail-text mt-5">
                <p>
                  Welcome back to the wizard of offers. This week, we’re taking a trip to Abu Dhabi,
                  a city filled with exciting new experiences and incredible attractions for
                  everyone to enjoy, see and create unforgettable moments. However, with this many
                  iconic places, Abu Dhabi’s many tourists and residents may not be aware that the
                  city has so much more to offer than just these few ultra-popular hotspots. That’s
                  why we at TravellerPass are here. To shine some light on three of the many new
                  experiences that are not only as exciting but are ones you should visit for the
                  memory book.
                </p>
                <p>
                  Abu Dhabi’s many tourists and residents may not be aware that the city has so much
                  more to offer than just these few ultra-popular hotspots. That’s why we at
                  TravellerPass are here.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="blogdtl-bottom-section">
        <Container>
          <Row>
            <Col>
              <div className="blogdtl-header">
                <h3>Explore this week's</h3>
                <h2> Top Offers</h2>
              </div>
            </Col>
          </Row>
          {BlogsData.map((item) => (
            <Row key={item.key} data-aos="fade-up" data-aos-duration="700">
              <Col>
                <div className="blog-details-row">
                  <div className="blog-details-header">
                    <div className="header-left">
                      <h1 className="heading1">{item.title}</h1>
                      <h1 className="heading2">{item.offer}% OFF</h1>
                    </div>
                    <div className="header-right">
                      <div>
                        <SvgIcon name="map" viewbox="0 0 8.358 12.537" />
                        <p>LOCATION</p>
                        <h4>Al Hudayriat Island</h4>
                      </div>
                    </div>
                  </div>
                  <div className="blog-details-image">
                    <img src={item.mainimage} alt={item.title} />
                  </div>
                  <div className="blog-details-bottom">
                    <p>{item.details}</p>
                  </div>
                </div>
              </Col>
            </Row>
          ))}
        </Container>
      </section>
      <section className="social-section">
        <div className="travellerPass-icon" data-aos="fade-right" data-aos-duration="900">
          <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
        </div>
        <Container>
          <Row>
            <Col>
              <img
                data-aos="zoom-in"
                data-aos-duration="1000"
                className="logo-icon"
                src={LogoIcon}
                alt="TravellerPass"
              />
              <h1 data-aos="fade-up" data-aos-duration="1200">
                Discover the world like never before <br /> with TravellerPass
              </h1>
              <div className="small-card" data-aos="fade-up" data-aos-duration="1200">
                <div className="left-img">
                  <div className="overlay-play">
                    <SvgIcon name="play-alt" viewbox="0 0 30.001 30" />
                  </div>
                  <img src={DiscoverImageSmall} alt="TravellerPass" />
                </div>
                <div>
                  <h3>TravellerPass 2023 Release</h3>
                  <p>A whole new experience to Discover Your City</p>
                </div>
              </div>
              <ul data-aos="fade-up" data-aos-duration="1600">
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="dribbble" viewbox="0 0 17.206 17.206" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="twitter" viewbox="0 0 18.304 14.986" />
                  </Button>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default BlogDetailsCategory;
