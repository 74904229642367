import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import store from './store';
import { insertUrlParam, removeUrlParam } from './utility/util';
import MetaInfo from './MetaInfo';

class AddCityName extends PureComponent {
  componentDidMount = () => {
    if (
      this.props.location.pathname !== '/' &&
      this.props.location.pathname !== '/listing' &&
      this.props.location.pathname !== '/groupListing' &&
      this.props.location.pathname !== '/listing-map'
    ) {
      removeUrlParam('vCityName');
      return;
    }
    const { selectedCity } = store.getState().countryCity;
    if (selectedCity) {
      insertUrlParam('vCityName', selectedCity.vCityName);
    }
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.location.pathname !== '/' &&
      this.props.location.pathname !== '/listing' &&
      this.props.location.pathname !== '/groupListing' &&
      this.props.location.pathname !== '/listing-map'
    ) {
      removeUrlParam('vCityName');
      return;
    }

    const { selectedCity } = store.getState().countryCity;
    if (selectedCity) {
      insertUrlParam('vCityName', selectedCity.vCityName);
    }
  };

  render = () => (
    <>
      <MetaInfo metaInfo={this.props.metaInfo} />
      {this.props.children}
    </>
  );
}

export default withRouter(AddCityName);
