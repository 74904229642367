import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button } from 'antd';
import MediaQuery from 'react-responsive';
import Slider from 'react-slick';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';

import FilterDrawer from '../FilterDrawer';
import mapStyle from '../../mapStyle';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { default as debounce } from 'lodash/debounce';
import {
  useCityCountrySelector,
  useMapListingSelector,
  useSubCategorySelector,
} from '../../store/selectors';
import hash from 'object-hash';
import { getSubCategories, resetSubCategories } from '../../slice/subCategorySlice';
import { setSelectedCategory } from '../../slice/categorySlice';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import CategorySlider from '../../components/common/CategorySlider';
import { LoadState } from '../../constants';
import { getMapListingPageData, resetMapListing } from '../../slice/mapListingSlice';
import DealCardMap from '../../components/common/DealCardMap';
import { useDebouncedCallback } from 'use-debounce';

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const ListingPageMap = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const searchParams = new URLSearchParams(search);
  const categoryId = searchParams.get('categoryId');
  const subCategoryId = searchParams.get('subCategoryId');
  const isPrive = searchParams.has('isPrive');
  const isPriveSubCat = searchParams.has('priveSubCat');
  const { selectedCity } = useCityCountrySelector();

  const [mapDeals, setMapDeals] = useState([]);

  const { subCategories, cacheKey: subCatCacheKey, state: subCatState } = useSubCategorySelector();
  const { deals, cacheKey: listCacheKey, state: listState } = useMapListingSelector();

  const mapRef = React.useRef();
  const sliderRef = React.useRef(null);
  const settingsMapSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [prevCenter, setPrevCenter] = useState(undefined);

  const [center, setCenter] = useState({
    lat: Number(selectedCity.vCityLatitude),
    lng: Number(selectedCity.vCityLongitude),
  });

  const [markers, setMarkers] = useState([]);

  const debouncedCenterChange = useDebouncedCallback(() => {
    if (mapRef.current?.center) {
      setCenter({
        lat: mapRef.current.center.lat(),
        lng: mapRef.current.center.lng(),
      });
    }
  }, 1000);

  const handleCenterChanged = () => {
    debouncedCenterChange();
  };

  const handleMarkerClick = (m, i) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(i);
    }
  };

  const handleSubCategoryClick = (subCatId) => {
    /* if (+categoryId === 1 && subCatId && +subCatId === 155) {
      const params = new URLSearchParams({
        categoryId: 1,
        isPrive: 'Yes',
      });
      history.replace('/reload', {
        from: {
          pathname: location.pathname,
          search: params.toString(),
        },
      });
      return;
    }*/

    setMarkers([]);
    setMapDeals([]);

    const searchParams = new URLSearchParams(search);
    if (!subCatId) {
      searchParams.delete('subCategoryId');
    } else {
      searchParams.set('subCategoryId', subCatId);
      const sc = subCategories.find((sc) => +sc.iSubCategoryID === +subCatId);
      if (sc && sc.isPrive === 'Yes') {
        searchParams.set('priveSubCat', '');
      } else {
        searchParams.delete('priveSubCat');
      }
    }

    history.replace({ search: searchParams.toString() });
  };

  const handlePriveCategoryClick = (catId) => {
    const searchParams = new URLSearchParams(search);
    if (!catId) {
      searchParams.delete('categoryId');
    } else {
      searchParams.set('categoryId', catId);
    }

    history.replace({ search: searchParams.toString() });
  };

  useEffect(() => {
    if (!isPrive) {
      const categoryId = searchParams.get('categoryId');

      if (categoryId) {
        const cacheKey = hash({ categoryId });
        if (subCatCacheKey !== cacheKey) {
          dispatch(resetSubCategories());
          console.log(`[${Date.now()}] dispatch getSubCategories`);
          dispatch(setSelectedCategory(categoryId));
          dispatch(getSubCategories({ cacheKey, categoryId }));
        }
      } else {
        history.replace('/');
      }
    }
  }, [isPrive, searchParams, subCatCacheKey]);

  useEffect(() => {
    if (!isPrive) {
      if (subCatState === LoadState.SUCCESS && subCategories.length > 0) {
        const categoryId = searchParams.get('categoryId');
        const subCategoryId = searchParams.get('subCategoryId');
        const priveSubCat = searchParams.has('priveSubCat');

        const cacheKey = hash({
          categoryId,
          subCategoryId,
          dCurrentLat: center.lat,
          dCurrentLong: center.lng,
        });
        if (listCacheKey !== cacheKey) {
          console.log(`[${Date.now()}] dispatch getListingPageData`);
          dispatch(resetMapListing());
          dispatch(
            getMapListingPageData({
              cacheKey,
              categoryId,
              subCategoryId,
              radius: 10,
              priveSubCat,
              dCurrentLat: center.lat,
              dCurrentLong: center.lng,
            }),
          );
        }
      }
    }
  }, [isPrive, subCatState, subCategories, searchParams, center]);

  useEffect(() => {
    const marks = [];
    if (deals && deals.length > 0) {
      for (let i = 0; i < deals.length; i++) {
        const deal = deals[i];
        if (deal.Distance && deal.Distance[0]) {
          marks.push({
            lat: Number(deal.Distance[0].vOutletLatitude),
            lng: Number(deal.Distance[0].vOutletLongitude),
          });
        }
      }
    }

    if (marks.length > 0) {
      setMarkers(marks);
      setMapDeals(deals);

      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickGoTo(0);
        }
      }, 150);
    }
  }, [deals]);

  return (
    <div className="twl-listing-wrapper">
      <section className="category-section-main">
        <Container className="listing-container">
          <div className="category-section">
            {!isPrive && (
              <CategorySlider
                subCategories={subCategories}
                subCategoryId={subCategoryId}
                handleSubCategoryClick={handleSubCategoryClick}
              />
            )}
            {/* <MediaQuery minWidth={768}>
              <FilterDrawer />
            </MediaQuery>*/}
          </div>
        </Container>
      </section>
      <section className="map-section">
        <div className="map-row">
          <div className="map-area">
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '400px',
                maxHeight: 'calc(100vh - 205px)',
                minHeight: 'calc(100vh - 205px)',
              }}
              center={center}
              zoom={11}
              onLoad={(map) => (mapRef.current = map)}
              onCenterChanged={handleCenterChanged}
              options={{ styles: mapStyle, maxZoom: 15, minZoom: 13, disableDefaultUI: true }}
            >
              {markers.map((marker, index) => (
                <Marker
                  key={index}
                  position={marker}
                  onClick={(m) => handleMarkerClick(m, index)}
                />
              ))}
            </GoogleMap>
          </div>
          <div className="map-lists">
            <Container>
              <Row>
                <Container>
                  {mapDeals && mapDeals.length > 0 && (
                    <Slider ref={sliderRef} className="map-slider" {...settingsMapSlider}>
                      {mapDeals.map((item) => {
                        return (
                          <div
                            key={`${item.iBrandID}_${item.iOutletID}_${item.iDealID}${item.isPrive === 'Yes' ? '_prive' : ''}`}
                          >
                            <DealCardMap item={item} />
                          </div>
                        );
                      })}
                    </Slider>
                  )}
                </Container>
              </Row>
              <Row>
                <Col className="text-center">
                  <Button className="list-btn" onClick={() => window.open('/listing', '_self')}>
                    List <SvgIcon name="list" viewbox="0 0 18.156 12.109" />
                  </Button>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ListingPageMap;
