import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getTopBrands = createAsyncThunk('fetch/topBrands', async (arg, thunkAPI) => {
  const { countryCity, user: userReducer } = thunkAPI.getState();
  const { selectedCity } = countryCity;
  const { user } = userReducer;

  if (!selectedCity) {
    throw Error('City not selected.');
  }

  const payload = {
    iCountryID: selectedCity.iCountryID,
    vCityName: selectedCity.vCityName,
    iCityID: selectedCity.iCityID,
  };

  const url = user ? '/offer/topBrands' : '/guestoffer/topBrands';

  const response = await apiClient.post(url, payload);

  if (response.data.status !== 1) {
    throw new Error(`categoryFeaturedOfferList received non zero status.`);
  }
  const logos = response.data?.SubcategoryList;
  if (!logos) {
    throw new Error(`sub-categories not found for categoryId:`);
  }

  return logos;
});

const initialState = {
  state: LoadState.IDLE,
  logos: [],
};
export const topBrandsSlice = createSlice({
  name: 'topBrands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTopBrands.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getTopBrands.fulfilled, (state, action) => {
      state.logos = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getTopBrands.rejected, (state, action) => {
      state.state = LoadState.ERROR;
      console.log(action);
    });
  },
});

export const {} = topBrandsSlice.actions;

export default topBrandsSlice.reducer;
