import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiClient, priveApiClient } from '../apiConfig';
import { LoadState } from '../constants';

export const getListingPageData = createAsyncThunk('fetch/offerList', async (arg, thunkAPI) => {
  const { countryCity: countryCityReducer, user: userReducer } = thunkAPI.getState();
  const { user, latLng } = userReducer;
  const { selectedCity } = countryCityReducer;
  const { cacheKey, categoryId, subCategoryId, priveSubCat, discountType, distance } = arg;
  if (!selectedCity) {
    throw Error('City not selected.');
  }
  if (!cacheKey) {
    throw Error('arg cacheKey not found.');
  }

  thunkAPI.dispatch(setCacheKey(cacheKey));

  console.log('ARG', arg);

  if (priveSubCat) {
    console.log('FFFFFF');
    const payload = {
      dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
      dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
      iCountryID: selectedCity.iCountryID,
      start: 0,
      limit: 20,
      iCategoryID: subCategoryId,
      ...(discountType && { discountType: discountType }),
      ...(distance && { maxKM: distance }),
    };

    const response = await priveApiClient.post('offer/offerList', payload);

    return (response.data.DATA || []).map((item) => ({ ...item, isPrive: 'Yes' }));
  }

  const payload = {
    dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
    dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
    iCountryID: selectedCity.iCountryID,
    vCityName: selectedCity.vCityName,
    iCityID: selectedCity.iCityID,
    start: 0,
    limit: 20,
    iCategoryID: categoryId,
    iSubCategoryID: subCategoryId,
    ...(discountType && { discountType: discountType }),
    ...(distance && { maxKM: distance }),
  };

  const url = user ? '/offer/offerList' : '/guestoffer/offerList';

  const response = await apiClient.post(url, payload);

  return response.data.DATA || [];
});

export const loadMoreListingPageData = createAsyncThunk(
  'fetch/offerList/loadMore',
  async (arg, thunkAPI) => {
    const { countryCity, user: userReducer, listing } = thunkAPI.getState();

    const { user, latLng } = userReducer;

    if (!listing.initialArg) {
      throw Error('Initial load arg not found.');
    }

    const { categoryId, subCategoryId, priveSubCat, discountType, distance } = listing.initialArg;

    const { selectedCity } = countryCity;
    if (!selectedCity) {
      throw Error('City not selected.');
    }

    if (priveSubCat) {
      const payload = {
        dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
        dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
        iCountryID: selectedCity.iCountryID,
        start: listing.deals.length,
        limit: 20,
        iCategoryID: 2,
        ...(discountType && { discountType: discountType }),
        ...(distance && { maxKM: distance }),
      };

      const response = await priveApiClient.post('offer/offerList', payload);

      return (response.data.DATA || []).map((item) => ({ ...item, isPrive: 'Yes' }));
    }

    const payload = {
      dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
      dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
      iCountryID: selectedCity.iCountryID,
      vCityName: selectedCity.vCityName,
      iCityID: selectedCity.iCityID,
      start: listing.deals.length,
      limit: 20,
      iCategoryID: categoryId,
      iSubCategoryID: subCategoryId,
      ...(discountType && { discountType: discountType }),
      ...(distance && { maxKM: distance }),
    };

    const url = user ? '/offer/offerList' : '/guestoffer/offerList';

    const response = await apiClient.post(url, payload);

    return response.data.DATA || [];
  },
);

const initialState = {
  state: LoadState.IDLE,
  loadMoreState: LoadState.IDLE,
  cacheKey: undefined,
  initialArg: {},
  deals: [],
  hasMore: true,
};
export const listingSlice = createSlice({
  name: 'listing',
  initialState,
  reducers: {
    toggleFav: (state, action) => {
      const { iBrandID, iOutletID, iDealID, isPrive, isFavouriteOffer } = action.payload;

      if (state.deals) {
        for (let i = 0; i < state.deals.length; i++) {
          if (
            state.deals[i].iBrandID === iBrandID &&
            state.deals[i].iDealID === iDealID &&
            state.deals[i].iOutletID === iOutletID
          ) {
            state.deals[i] = {
              ...state.deals[i],
              isFavouriteOffer: isFavouriteOffer,
            };
          }
        }
      }
    },
    setCacheKey: (state, action) => {
      state.cacheKey = action.payload;
    },
    resetListing: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(getListingPageData.pending, (state, action) => {
      state.state = LoadState.LOADING;
    });
    builder.addCase(getListingPageData.fulfilled, (state, action) => {
      state.initialArg = action.meta.arg;
      state.hasMore = action.payload.length >= 20;
      console.log('Deals Length 1', action.payload.length);
      state.deals = action.payload;
      state.state = LoadState.SUCCESS;
    });
    builder.addCase(getListingPageData.rejected, (state, action) => {
      state.state = LoadState.ERROR;
    });
    builder.addCase(loadMoreListingPageData.pending, (state, action) => {
      state.loadMoreState = LoadState.LOADING;
    });
    builder.addCase(loadMoreListingPageData.fulfilled, (state, action) => {
      state.hasMore = action.payload.length >= 20;
      state.deals = [...state.deals, ...action.payload];
      state.loadMoreState = LoadState.SUCCESS;
    });
    builder.addCase(loadMoreListingPageData.rejected, (state, action) => {
      console.error('loadMoreListingPageData error', action.error);
      state.loadMoreState = LoadState.ERROR;
    });
  },
});

export const { toggleFav, resetListing, setCacheKey } = listingSlice.actions;
export default listingSlice.reducer;
