import React, { useEffect } from 'react';
import { Col, Container, DealCard, Row } from '../../components/common';
import MediaQuery from 'react-responsive';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import FilterDrawer from '../FilterDrawer';
import { useSearchListingSelector } from '../../store/selectors';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useLoaderContext } from '../../components/common/Loader';
import InfiniteScroll from 'react-infinite-scroll-component';
import Lottie from 'react-lottie';
import DotLoaderWhite from '../../assets/json/dot_loader_white.json';
import DotLoader from '../../assets/json/dot_loader.json';
import {
  getSearchListingPageData,
  loadMoreSearchListingPageData,
  resetSearchListing,
} from '../../slice/searchListingSlice';
import hash from 'object-hash';
import DealList from '../../components/common/DealList';

const SearchOfferListing = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const { search } = location;
  const { setShowLoader } = useLoaderContext();

  const searchParams = new URLSearchParams(search);
  const sType = searchParams.get('sType');
  const tOffer = searchParams.get('tOffer');
  const isPrive = searchParams.has('isPrive');

  const { deals, cacheKey: listCacheKey, state: listState, hasMore } = useSearchListingSelector();

  useEffect(() => {
    if (sType && tOffer) {
      const discountType = searchParams.get('type');
      const distance = searchParams.get('dist');

      const cacheKey = hash({ sType, tOffer, discountType, distance });
      if (listCacheKey !== cacheKey) {
        console.log(`[${Date.now()}] dispatch getSearchListingPageData`);
        dispatch(resetSearchListing());
        dispatch(getSearchListingPageData({ cacheKey, sType, tOffer, discountType, distance }));
      }
    }
  }, [sType, tOffer, searchParams]);

  if (!sType || !tOffer) {
    return <Redirect to="/" />;
  }

  return (
    <div className="twl-listing-wrapper">
      <section className="listing-page-title">
        <Container>
          <MediaQuery minWidth={768}>
            <FilterDrawer />
          </MediaQuery>
        </Container>
      </section>
      <section className="listing-bottom no-padding">
        <Container className="listing-container">
          <Row>
            <Col>
              <InfiniteScroll
                next={() => {
                  dispatch(loadMoreSearchListingPageData());
                }}
                hasMore={hasMore}
                loader={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      padding: 32,
                    }}
                  >
                    <Lottie
                      options={{
                        loop: true,
                        autoplay: true,
                        animationData: isPrive ? DotLoaderWhite : DotLoader,
                        renderer: 'svg',
                      }}
                      height={160}
                      width={160}
                    />
                  </div>
                }
                dataLength={deals.length}
              >
                <DealList items={deals} />
              </InfiniteScroll>
            </Col>
          </Row>
          {/*<Row>
            <Col className="text-center">
              <Button
                type="primary"
                className="map-btn"
                onClick={() => window.open('/listing-map', '_self')}
              >
                Map <SvgIcon name="map-icon" viewbox="0 0 14.855 14.695" />
              </Button>
            </Col>
          </Row>*/}
        </Container>
      </section>
    </div>
  );
};

export default SearchOfferListing;
