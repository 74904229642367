import React, { useState } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button } from 'antd';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import Slider from 'react-slick';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import { useHistory } from 'react-router-dom';
import './index.scss';

import BannerImg from '../../assets/images/get-listed.jpg';
import DashboardReport from '../../assets/images/dashboard-report.png';
import AboutImg from '../../assets/images/about.jpg';
import testimonialImg from '../../assets/images/testimonial.png';
import testimonialImg2 from '../../assets/images/testimonial2.png';
import testimonialImg3 from '../../assets/images/testimonial3.png';
import testimonialImg4 from '../../assets/images/testimonial4.png';
import testimonialImg5 from '../../assets/images/testimonial5.png';
import LogoIcon from '../../assets/images/logo-icon.svg';
import ListBusinessBg from '../../assets/images/list-business.jpg';
import BlogImg2 from '../../assets/images/blog-2.jpg';
import BlogImg3 from '../../assets/images/blog-3.jpg';
import BlogImg4 from '../../assets/images/blog-4.jpg';
import DiscoverImageSmall from '../../assets/images/discover-small-img.jpg';
import { useCategorySelector } from '../../store/selectors';
import ModalVideo from 'react-modal-video';
import HowItWorks from '../../assets/video/how_it_works.mp4';
import TopBrandLogo from '../../components/common/TopBrandLogo';

function NextArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

function PrevArrowClients(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      <SvgIcon name="chevron-left" viewbox="0 0 4.029 6.932" />
    </div>
  );
}

const GetListedPage = () => {
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const reachoutList = [
    {
      id: 1,
      image: <SvgIcon name="dining-icon" viewbox="0 0 71.125 48.658" />,
      name: 'Get featured',
      details:
        'TravellerPass will feature your brand at no cost on our apps and social media platforms',
      speed: 600,
    },
    {
      id: 2,
      image: <SvgIcon name="marketing-icon" viewbox="0 0 70.7 61.456" />,
      name: 'Mass Marketing',
      details: 'Market your venues through social media, email campaigns and much more.',
      speed: 800,
    },
    {
      id: 3,
      image: <SvgIcon name="redemptions-icon-alt" viewbox="0 0 63.433 61.727" />,
      name: 'Customer Redemptions',
      details:
        'All platforms will empower customers to find the partner venue and redeem your offers.',
      speed: 1000,
    },
    {
      id: 4,
      image: <SvgIcon name="dashboard-insights-icon" viewbox="0 0 65.61 52.071" />,
      name: 'Dashboard Insights',
      details:
        'Access to our real-time web and app dashboards with business insights to track and access data on usage metrics and more.',
      speed: 1200,
    },
  ];

  const exporeList = [
    {
      id: 1,
      image: <SvgIcon name="dining-icon" viewbox="0 0 71.125 48.658" />,
      name: 'Dining',
      details:
        'Set off on a gastronomical journey, savouring cuisines & drinks from around the globe',
      speed: 600,
    },
    {
      id: 2,
      image: <SvgIcon name="leisure-icon" viewbox="0 0 61.201 44.936" />,
      name: 'Leisure',
      details:
        'Create memorable moments with 2 FOR 1 deals and flat discounts up to 50% OFF across our activities',
      speed: 800,
    },
    {
      id: 3,
      image: <SvgIcon name="shopping-icon" viewbox="0 0 50.18 47.098" />,
      name: 'Shopping',
      details: 'Embrace that shopping fever with discounts up to 60% OFF across multiple brands',
      speed: 1000,
    },
    {
      id: 4,
      image: <SvgIcon name="wellness-icon" viewbox="0 0 53.338 53.341" />,
      name: 'Wellness',
      details:
        'Enjoy some "me time" with 2 FOR 1 deals and flat discounts up to 50% OFF across our wellness range',
      speed: 1200,
    },
    {
      id: 5,
      image: <SvgIcon name="prive-icon" viewbox="0 0 57.805 48.664" />,
      name: 'Prive',
      details: 'Luxury personified with hand-picked premium dining and spa offers across the city',
      speed: 1400,
    },
  ];

  const Blogdata = [
    {
      title: 'The Ultimate Children’s Playground',
      avatar: BlogImg2,
      date: 'NOV 29 - 5 MIN READ',
    },
    {
      title: 'Time to head to The Dubai Mall',
      avatar: BlogImg3,
      date: 'DEC 02 - 9 MIN READ',
    },
    {
      title: 'Time for something new',
      avatar: BlogImg4,
      date: 'JAN 08 - 10 MIN READ',
    },
  ];

  const settingsClients = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrowClients />,
    prevArrow: <PrevArrowClients />,
  };
  const { categories } = useCategorySelector();
  const history = useHistory();
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const handleCategoryClick = (category) => {
    if (category) {
      if (category.CategoryWebLink && category.CategoryWebLink.startsWith('https://')) {
        window.open(category.CategoryWebLink, '_blank');
      } else if (category.isPrive === 'Yes') {
        const params = new URLSearchParams({
          isPrive: 'Yes',
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      } else {
        const params = new URLSearchParams({
          categoryId: category.iCategoryID,
        });
        history.push({
          pathname: '/listing',
          search: params.toString(),
        });
      }
    }
  };

  return (
    <>
      <div className="twl-landing-wrapper get-listed-wrapper">
        <section className="get-listed-banner">
          <Container>
            <Row>
              <Col md="5" className="banner-left">
                <h1 data-aos="fade-right" data-aos-duration="800">
                  List your business today
                </h1>
                <p data-aos="fade-right" data-aos-duration="900">
                  Time to take your growth to the next level
                </p>
                <Button
                  data-aos="fade-right"
                  data-aos-duration="1000"
                  type="secondary"
                  className="with-icon"
                  icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                  onClick={() => window.open('mailto:concierge@travellerpass.com', '_blank')}
                >
                  Start Listing
                </Button>
              </Col>
              <Col md="7" className="banner-right">
                {/*<div className="card-right" data-aos="zoom-in" data-aos-duration="1000">
                  <div className="card-upper">
                    <Button>
                      <SvgIcon name="play" viewbox="0 0 41.93 41.965" />
                    </Button>
                    <SvgIcon className="arrow-icon" name="arrow2" viewbox="0 0 51.113 32.973" />
                  </div>
                  <div className="card-bottom">
                    <p>
                      How it <br />
                      works
                    </p>
                    <SvgIcon name="dots" viewbox="0 0 48.628 42.496" />
                  </div>
                </div>*/}
                <img
                  data-aos="fade-in"
                  data-aos-duration="1200"
                  className="banner-img"
                  src={BannerImg}
                  alt={BannerImg}
                />
              </Col>
            </Row>
          </Container>
        </section>
        <section className="reachout-section">
          <Container>
            <Row>
              <Col sm="12">
                <div>
                  <small className="small-title" data-aos="fade-in" data-aos-duration="800">
                    LET'S GET STARTED
                  </small>
                  <h2 className="heading text-left" data-aos="fade-in" data-aos-duration="1000">
                    How we <br /> reach out
                  </h2>
                  <ul className="reachout-list">
                    {reachoutList.map((item) => (
                      <li key={item.id} data-aos="fade-up" data-aos-duration={item.speed}>
                        <div className="reachout-list-card">
                          <div className="reachout-list-card-image">{item.image}</div>
                          <div className="reachout-caption">
                            <h3>{item.name}</h3>
                            <p>{item.details}</p>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="about-getlisted">
          <Container>
            <Row>
              <Col>
                <div className="aboutgetlisted-row">
                  <div className="aboutgetlisted-left">
                    <small className="small-title" data-aos="fade-right" data-aos-duration="600">
                      ABOUT US
                    </small>
                    <h2 data-aos="fade-right" data-aos-duration="800">
                      <b>TravellerPass LLC</b> is a part of <b>IMC Middle East</b> leveraging over{' '}
                      <b>20 years</b> of expertise in crafting cutting-edge loyalty solutions
                    </h2>
                    <p data-aos="fade-right" data-aos-duration="1000">
                      Our team comprises of a diverse set of experts, ranging from consultants,
                      engineers, social media experts and loyalty experts. We are committed to
                      helping businesses deploy strategic marketing campaigns and building loyalty
                      programs.
                    </p>
                  </div>
                  <div
                    className="aboutgetlisted-right"
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <img className="abuot-img" src={AboutImg} alt="about" />
                    <div className="aboutgetlisted-right-inner">
                      <img src={LogoIcon} alt={LogoIcon} />
                      <h3>We Grow Together With TravellerPass</h3>
                      <ul>
                        <li>
                          <div className="ds-icon">
                            <SvgIcon name="redemptions-icon" viewbox="0 0 18.401 18.425" />
                          </div>
                          <p>Years to Loyalty</p>
                        </li>
                        <li>
                          <div className="ds-icon">
                            <SvgIcon name="venues-icon" viewbox="0 0 16.027 15.27" />
                          </div>
                          <p>Expertise In MENA Region</p>
                        </li>
                        <li>
                          <div className="ds-icon">
                            <SvgIcon name="luxury-icon" viewbox="0 0 19.482 18.912" />
                          </div>
                          <p>Stronger Brand Affinity</p>
                        </li>
                        <li>
                          <div className="ds-icon">
                            <SvgIcon name="star-filled-alt" viewbox="0 0 15 15" />
                          </div>
                          <p>Driving Incremental Profits</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {categories && categories.length > 0 && (
          <section className="explore-section">
            <Container>
              <Row>
                <Col sm="12">
                  <div>
                    <h2 className="heading text-left" data-aos="fade-in" data-aos-duration="1000">
                      Explore our <br /> amazing categories
                    </h2>
                    <ul className="explore-list">
                      {categories.map((item, index) => (
                        <li
                          key={item.iCategoryID}
                          data-aos="fade-up"
                          data-aos-duration={600 + index * 200}
                        >
                          <div className="explore-list-card">
                            <div className="explore-list-card-image">
                              <img
                                className="icon"
                                src={item.vCategoryMainImage}
                                alt={item.vCategoryName}
                              />
                            </div>
                            <div className="explore-caption">
                              <h3>{item.vCategoryName}</h3>
                              <p>{item.vCategoryDescription}</p>
                              <Button onClick={() => handleCategoryClick(item)}>
                                Explore <SvgIcon name="arrow-right" viewbox="0 0 24.603 7.838" />
                              </Button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        )}
        {/*  <section className="explore-section explore-getlisted-section">
        <Container>
          <Row>
            <Col sm="12">
              <div>
                <small className="small-title">CATEGORY</small>
                <h2 className="heading text-left" data-aos="fade-in" data-aos-duration="1000">
                  Explore our <br /> Amazing Categories
                </h2>
                <ul className="explore-list">
                  {exporeList.map((item) => (
                    <li key={item.id} data-aos="fade-up" data-aos-duration={item.speed}>
                      <div className="explore-list-card">
                        <div className="explore-list-card-image">{item.image}</div>
                        <div className="explore-caption">
                          <h3>{item.name}</h3>
                          <p>{item.details}</p>
                          <Button>
                            Explore <SvgIcon name="arrow-right" viewbox="0 0 24.603 7.838" />
                          </Button>
                        </div>
                      </div>
                    </li>
                  ))}
                  <li data-aos="fade-up" data-aos-duration="1600">
                    <Card cover={<img alt="Explore" src={ExpImg} />}>
                      <div className="meta-row">
                        <p>
                          Discover a world <br /> of offers
                        </p>
                        <div className="venues-row">
                          <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
                          <h3>3,000+</h3>
                          <small>Venues</small>
                        </div>
                      </div>
                    </Card>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </section>*/}
        <TopBrandLogo />
        <section className="listbusiness-section">
          <Container>
            <Row>
              <Col>
                <div className="listbusiness-card" data-aos="zoom-in" data-aos-duration="800">
                  <img className="listbusiness-image" src={ListBusinessBg} alt={ListBusinessBg} />
                  <div className="listbusiness-overlay">
                    <div>
                      <h2>
                        List your business <br />
                        with us today
                      </h2>
                      <Button
                        type="secondary"
                        className="with-icon"
                        icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                        onClick={() => window.open('mailto:concierge@travellerpass.com', '_blank')}
                      >
                        Start Listing
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="dashboardr-section">
          <ParallaxProvider>
            <Container>
              <Row>
                <Col lg="6" className="dashboardr-left">
                  <Parallax speed={5} disabled={isMobile}>
                    <h1 data-aos="zoom-out" data-aos-duration="800">
                      Dashboard <br />
                      Reporting.
                    </h1>
                  </Parallax>
                  <Parallax speed={6} disabled={isMobile}>
                    <p data-aos="zoom-out" data-aos-duration="1600">
                      TravellerPass works in parallel with a Content Management System where
                      marketing teams can access reports and stats.
                    </p>
                  </Parallax>
                  <Parallax speed={4} disabled={isMobile}>
                    <div className="dashboardr-btns" data-aos="fade-in" data-aos-duration="1200">
                      <Button
                        onClick={() => window.open('https://partner.travellerpass.com/', '_blank')}
                        type="secondary"
                        className="with-icon"
                        icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                      >
                        Go to Console
                      </Button>
                    </div>
                  </Parallax>
                </Col>
                <Col lg="6" className="dashboardr-right">
                  <div className="right-inner">
                    <Parallax speed={20} disabled={isMobile}>
                      <div className="dashboardr-circle spinner"></div>
                    </Parallax>
                    <Parallax className="dashboardr-img-div web" scale={[0.5, 1.3]} speed={20}>
                      <img className="dashboardr-img" src={DashboardReport} alt={'Download'} />
                    </Parallax>
                    <div
                      className="dashboardr-img-div mobile"
                      data-aos="zoom-out"
                      data-aos-duration="1000"
                    >
                      <img className="dashboardr-img" src={DashboardReport} alt={'Download'} />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </ParallaxProvider>
        </section>
        <section className="testomonial-section testomonial-getlisted">
          <Container>
            <ParallaxProvider>
              <Row>
                <Col sm="12" className="mb-4">
                  <Parallax disabled={isMobile}>
                    <h1 className="heading mb-0">What they say</h1>
                    <p className="heading-p-tag">*App Store Reviews</p>
                  </Parallax>
                </Col>
                <Col sm="12">
                  <Slider className="testomonial-slider" {...settingsClients}>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              I just wanted to share a quick note and let you know that you guys do
                              a really good job. I’m glad I decided to work with you. It’s really
                              great how easy your websites are to update and manage.
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Amelia Kimani</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg2}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Worth it! <br />
                              Highly recommend this app over most available in the market today. I
                              have been using it for 3 years now and am super happy with my savings.
                              The new interface of the app makes it easy to navigate and find the
                              best options close to you!
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Karthik</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg3}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Excellent customer service <br />
                              Had an issue with one of the restaurants while using TravellerPass.
                              TravellerPass resolved this through their persistent follow-up with
                              the restaurant. The restaurant called and even offered a free dish.
                              Five-star customer service indeed from TravellerPass.
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Marvina</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg4}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Great Experience <br />
                              Have been using this app over last year and a half; amazing
                              experience, covers all the major outlet in Dubai with amazing deals in
                              all. Strongly recommend everyone to download the app and open up the
                              great offers….
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Jamie</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="testomonial-row">
                        <Parallax speed={10} disabled={isMobile}>
                          <img
                            className="testomonial-image"
                            src={testimonialImg5}
                            alt="Testimonial"
                          />
                        </Parallax>
                        <div className="right-text">
                          <Parallax speed={14} disabled={isMobile}>
                            <p>
                              Awesome! <br />
                              It’s easy to use and very handy. It helps a lot to save more. Worth
                              every penny😍
                            </p>
                          </Parallax>
                          <Parallax speed={10} disabled={isMobile}>
                            <h3>Nikol</h3>
                          </Parallax>
                          <Parallax speed={9} disabled={isMobile}>
                            <hr />
                          </Parallax>
                        </div>
                      </div>
                    </div>
                  </Slider>
                </Col>
              </Row>
            </ParallaxProvider>
          </Container>
        </section>
        {/* <section className="blog-section blog-section-getlisted">
        <Container>
          <Row>
            <Col>
              <div className="blog-upper">
                <Row>
                  <Col lg="6" data-aos="fade-right" data-aos-duration="800">
                    <img className="blog-img" src={BlogImg1} alt="Blog" />
                  </Col>
                  <Col lg="6" data-aos="fade-left" data-aos-duration="800">
                    <label>Blogs</label>
                    <h3>Your perfect plan for an unforgettable holidays</h3>
                    <p>
                      People of the UAE, Eid Holidays are approaching. So, planning the ultimate Eid
                      weekend is what is on most of our minds as we all want to make the most out of
                      this Eid weekend.
                    </p>
                    <Button
                      type="primary"
                      className="with-icon"
                      icon={<SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />}
                    >
                      Read Full
                    </Button>
                    <div className="bottom-text">Nov 29 - 3 min read</div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <List
                data-aos="fade-up"
                data-aos-duration="800"
                itemLayout="horizontal"
                dataSource={Blogdata}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button>
                        {' '}
                        <SvgIcon name="chevron-right" viewbox="0 0 4.029 6.932" />
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      avatar={<img src={item.avatar} alt="" />}
                      title={item.title}
                      description={item.date}
                    />
                  </List.Item>
                )}
              />
              <div className="more-blog">
                <ul>
                  <li>
                    <img src={Tpimg1} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg2} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg3} alt="Blog More" />
                  </li>
                  <li>
                    <img src={Tpimg4} alt="Blog More" />
                  </li>
                </ul>
                <Button type="link">+ 23 Blogs</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>*/}
        <section className="social-section">
          <div className="travellerPass-icon" data-aos="fade-right" data-aos-duration="900">
            <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
          </div>
          <Container>
            <Row>
              <Col>
                <img
                  data-aos="zoom-in"
                  data-aos-duration="1000"
                  className="logo-icon"
                  src={LogoIcon}
                  alt="TravellerPass"
                />
                <h1 data-aos="fade-up" data-aos-duration="1100">
                  Discover the world like never before <br /> with TravellerPass
                </h1>
                <div className="small-card" data-aos="fade-up" data-aos-duration="1200">
                  <div className="left-img" onClick={() => setShowHowItWorks(true)}>
                    <div className="overlay-play">
                      <SvgIcon name="play-alt" viewbox="0 0 30.001 30" />
                    </div>
                    <img src={DiscoverImageSmall} alt="TravellerPass" />
                  </div>
                  <div>
                    <h3>Welcome</h3>
                    <p>A whole new experience to TravellerPass</p>
                  </div>
                </div>
                <ul data-aos="fade-up" data-aos-duration="1400">
                  {/*<li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="dribbble" viewbox="0 0 17.206 17.206" />
                  </Button>
                </li>
                  <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="linkedin" viewbox="0 0 16.165 16.137" />
                  </Button>
                </li>*/}
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.instagram.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() =>
                        window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                      }
                    >
                      <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://www.tiktok.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="tiktok" viewbox="0 0 11 13" />
                    </Button>
                  </li>
                  <li>
                    <Button
                      type="link"
                      onClick={() => window.open('https://medium.com/@travellerpass', '_blank')}
                    >
                      <SvgIcon name="medium" viewbox="0 0 17.135 14.163" />
                    </Button>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      <ModalVideo
        isOpen={showHowItWorks}
        channel="custom"
        url={HowItWorks}
        onClose={() => setShowHowItWorks(false)}
      />
    </>
  );
};

export default GetListedPage;
