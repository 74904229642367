import React, { useEffect, useState } from 'react';
import { DealCard } from '../../components/common';
import Lottie from 'react-lottie';
import './index.scss';
import AnimationOne from '../../assets/json/empty-offer.json';
import { apiClient, priveApiClient } from '../../apiConfig';
import { useLoaderContext } from '../../components/common/Loader';
import { useCityCountrySelector, useUserSelector } from '../../store/selectors';

const FavouriteTab = () => {
  const { setShowLoader } = useLoaderContext();
  const { selectedCity } = useCityCountrySelector();
  const { latLng } = useUserSelector();
  const [results, setResults] = useState([]);

  useEffect(() => {
    (async () => {
      setShowLoader(true);
      const payload = {
        dCurrentLat: latLng?.lat || selectedCity.vCityLatitude,
        dCurrentLong: latLng?.lng || selectedCity.vCityLongitude,
      };
      try {
        const [response, priveResponse] = await Promise.all([
          apiClient.post('offer/myFavouriteOffers', payload),
          priveApiClient.post('offer/myFavouriteOffers', payload),
        ]);

        console.log(priveResponse);

        let results = [];
        if (response.data.status === 1) {
          results = response.data.DATA;
        }
        if (priveResponse.data.status === 1) {
          results = [
            ...results,
            ...priveResponse.data.DATA.map((item) => ({ ...item, isPrive: 'Yes' })),
          ];
        }
        setResults(results);
      } catch (e) {
      } finally {
        setShowLoader(false);
      }
    })();
  }, []);

  const handleRemoveFavourite = (payload) => {
    const { iBrandID, iOutletID, iDealID, isPrive } = payload;

    console.log(payload);
    setResults(
      results.filter((item) => {
        return !(
          item.iBrandID === iBrandID &&
          item.iDealID === iDealID &&
          item.iOutletID === iOutletID
        );
      }),
    );
  };

  const animationLottie = {
    loop: true,
    autoplay: true,
    animationData: AnimationOne,
    renderer: 'svg',
  };

  return (
    <div className="right-tab-inner">
      {results.length > 0 ? (
        <>
          <h1 className="tab-heading" data-aos="fade-in" data-aos-duration="800">
            Favorite
          </h1>
          <div className="tab-content favourite-tab" data-aos="fade-in" data-aos-duration="800">
            <div className="listing-row">
              {results.map((item) => (
                <DealCard
                  key={`${item.iBrandID}_${item.iOutletID}_${item.iDealID}${item.isPrive === 'Yes' ? '_prive' : ''}`}
                  item={item}
                  onFavouriteClick={handleRemoveFavourite}
                />
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className="empty-data-card empty-favourite" data-aos="fade-in" data-aos-duration="800">
          <Lottie options={animationLottie} height={280} width={280} />
          <h2>Sorry, its empty!</h2>
          <p>
            We will make sure to notify you when you have free vouchers, promotions and goodies to
            avail!
          </p>
        </div>
      )}
    </div>
  );
};

export default FavouriteTab;
