import React, { useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { Button, Form } from 'antd';
import OtpInput from 'react-otp-input';
import './index.scss';

import { SHA256 } from 'crypto-js';
import logoImage from '../../assets/images/logo-light.svg';
import logoImage1 from '../../assets/images/logo.svg';
import AwesomeImg from '../../assets/images/tp-left.png';
import VideoOne from '../../assets/video/bg_auth.mp4';
import { useDispatch } from 'react-redux';
import { useLoaderContext } from '../../components/common/Loader';
import { logEvent } from '../../utility/analytics';
import { Alert } from '../../common';
import { apiClient } from '../../apiConfig';
import { setAccessToken, setPriveCredential, setUser } from '../../slice/userSlice';

const EnterOTP = () => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setShowLoader } = useLoaderContext();
  const { from, redirect, formData } = location.state || {};
  const [OTP, setOTP] = useState('');

  const generateRandomID = () => {
    // Generate a random number between 100000 and 999999 (inclusive)
    return Math.floor(Math.random() * 900000) + 100000;
  };

  const handleDoneClick = async () => {
    const { firstName, email, mobile, mobileCountryCode } = formData;

    try {
      setShowLoader(true);

      const hashedAppOTP = SHA256(OTP + 'Imc@$01tma$sa1@').toString();
      const body = {
        vEmail: email,
        appOTP: hashedAppOTP,
        UDID: generateRandomID().toString(),
        eDeviceType: 'web',
        vDeviceToken: '',
        ...(from.pathname === '/signup' && {
          vUserName: firstName,
          vMobileCountryCode: `+${mobileCountryCode}`,
          vMobileNo: mobile,
        }),
      };
      const url = from.pathname === '/signup' ? '/admin/prelogin1' : '/admin/doLogin';
      const response = await apiClient.post(url, body);

      if (response.data && response.data.status === 0) {
        if (from.pathname === '/signup') {
          logEvent('register_error', { message: response.data.MESSAGE });
        } else {
          logEvent('login_error', { message: response.data.MESSAGE });
        }
        Alert.error(response.data.MESSAGE);
      } else if (response.data && response.data.status === 1) {
        if (from.pathname === '/signup') {
          logEvent('register_success', { user_id: response.data.DATA.iUserID });
        } else {
          logEvent('login_success', { user_id: response.data.DATA.iUserID });
        }
        dispatch(setPriveCredential(undefined));
        dispatch(setAccessToken(response.data.DATA.accesstoken));
        dispatch(setUser(response.data.DATA));

        let redirectUrl = window.location.origin;
        if (redirect) {
          if (redirect.pathname.startsWith('/')) {
            redirectUrl += redirect.pathname + redirect.search;
          } else {
            redirectUrl += '/' + redirect.pathname + redirect.search;
          }
        }
        window.location.href = redirectUrl;
      } else {
        console.error('Unknown status:', response.data.status);
      }
    } catch (e) {
    } finally {
      setShowLoader(false);
    }
  };

  const handleResend = async () => {
    try {
      setShowLoader(true);
      const { email } = formData;
      const url = from.pathname === '/signup' ? '/admin/checkEmail' : '/admin/checkEmailLogin';
      const response = await apiClient.post(url, { vEmail: email });

      if (response.data) {
        if (response.data.status === 0) {
          Alert.error(response.data.MESSAGE);
        } else {
          Alert.success(response.data.MESSAGE);
        }
      }
    } catch (e) {
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    logEvent('enter_otp', { name: 'OTP Screen' });
  }, []);

  if (!from || !formData) {
    return <Redirect to={'/'} />;
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-left">
        <div className="authleft-inner">
          <div>
            <div className="dextop-logo">
              <Link to="/">
                <img src={logoImage} alt={logoImage} />
              </Link>
            </div>
            <h1>
              Discover <br />
              Your City
            </h1>
          </div>
          <div>
            <div className="awse-img">
              <img src={AwesomeImg} alt="Awesome" />
            </div>
            <h3>Awesome!</h3>
            <p>
              Awesome! It’s easy to use and very handy. It helps a lot to save more. Worth every
              penny
            </p>
            <div className="left-name">Nikol</div>
          </div>
        </div>
        <video playsInline autoPlay muted loop className="banner-video">
          <source src={VideoOne} />
        </video>
      </div>
      <div className="auth-right">
        <div className="authright-inner">
          <div className="right-upper">
            <div className="mobile-logo">
              <Link to="/">
                <img src={logoImage1} alt={logoImage1} />
              </Link>
            </div>
          </div>
          <div className="right-form right-form-top">
            <Form name="basic" autoComplete="off" layout="vertical">
              <div className="otpform-inner">
                <div className="otpform-upper">
                  <h2>Enter OTP</h2>
                  <p>OTP has been sent to your email</p>
                  <Form.Item className="mt-5">
                    <OtpInput
                      className="otp-input"
                      containerStyle="otp-input-container"
                      value={OTP}
                      onChange={setOTP}
                      autoFocus
                      OTPLength={4}
                      otpType="number"
                      disabled={false}
                      secure
                    />
                  </Form.Item>
                  <div className="optsreend">
                    <span>Didn’t get it?</span>
                    <div
                      onClick={handleResend}
                      style={{ display: 'inline-block', cursor: 'pointer' }}
                      className="auth-upper-btn ant-btn resend-btn"
                    >
                      Resend
                    </div>
                  </div>
                </div>
                <div className="mt-auto">
                  <Form.Item className="m-0 py-3">
                    <Button
                      type="secondary"
                      block
                      onClick={handleDoneClick}
                      disabled={OTP.length !== 4}
                    >
                      Done
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterOTP;
