import React, { useEffect, useState } from 'react';
import { Button, Input, Modal, Spin } from 'antd';
import { Col, Row, SvgIcon } from '../../../components/common';
import { ReactComponent as Valid } from '../../../assets/images/valid.svg';
import { ReactComponent as Invalid } from '../../../assets/images/invalid.svg';
import Lottie from 'lottie-react';
import LogoutLottie from '../../../assets/json/no_remptions.json';
import './index.scss';
import { Alert } from '../../../common';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { setPromoCode } from '../../../slice/membershipSlice';
import { apiClient } from '../../../apiConfig';
import { logEvent } from '../../../utility/analytics';

const PromoModal = ({ isOpen, onClose, onApply }) => {
  const dispatch = useDispatch();
  const [promoState, setPromoState] = useState('');
  const [isChecking, setIsChecking] = useState(false);
  const [promoCodeValue, setPromoCodeValue] = useState('');
  const [descContent, setDescContentContent] = useState(<></>);
  const [promoData, setPromoData] = useState(undefined);

  const checkPromoCode = async () => {
    try {
      setIsChecking(true);
      const response = await apiClient.post('/user/checkPromoCode', {
        vPromoCode: promoCodeValue,
      });
      const data = response.data.DATA;
      if (response.data.status === 1 && data && data.iMembershipPlanID.length > 0) {
        if (data.freeDescription && data.promoDescription) {
          setDescContentContent(
            <>
              <h2>{data.freeDescription}</h2>
              <h3>{data.promoDescription}</h3>
            </>,
          );
        } else if (data.promoDescription && data.iMembershipPlanID.length === 1) {
          setDescContentContent(
            <>
              <h2>{data.iMembershipPlanID[0].membershipPlan}</h2>
              <h3>{data.promoDescription}</h3>
            </>,
          );
        } else if (data.promoDescription) {
          setDescContentContent(
            <>
              <h2>You unlocked</h2>
              <h3>{data.promoDescription}</h3>
            </>,
          );
        } else {
          setDescContentContent(<></>);
        }

        setPromoData({ ...data, code: promoCodeValue });
        setPromoState('valid');
      } else {
        if (response.data.MESSAGE) {
          Alert.error(response.data.MESSAGE);
        }
        setPromoData(undefined);
        setPromoState('invalid');
      }
    } catch (error) {
      console.error(error);
      Alert.error('Oops! Something went wrong.');
    } finally {
      setIsChecking(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      logEvent('apply_promo_code', { name: 'Promo Code Screen' });
    }
  }, [isOpen]);
  return (
    <>
      <Modal
        className="promo-modal"
        centered
        maskClosable={false}
        footer={null}
        title={null}
        width={500}
        open={isOpen}
        onOk={onClose}
        onCancel={onClose}
        closeIcon={<SvgIcon name="close" viewbox="0 0 7.481 7.521" />}
        destroyOnClose={true}
      >
        <div className="text-center">
          <Row>
            <Col sm="12" className="upper-section">
              <div style={{ width: '50%', margin: 'auto' }}>
                <Lottie animationData={LogoutLottie} loop={false} />
              </div>
              <h3>Promo Code</h3>
              <p>
                Apply a code to unlock a discount <br />
                across our plans
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="8" className="mx-auto id-input">
              <Input
                onChange={(e) => {
                  setPromoState('');
                  setPromoData(undefined);
                  setPromoCodeValue((e.target.value || '').trim().toUpperCase());
                }}
                value={promoCodeValue}
                placeholder="Enter"
              />

              <div className="valid-invalid-row">
                {promoState === 'valid' && (
                  <>
                    <Valid />
                    <span>Code is valid!</span>
                  </>
                )}
                {promoState === 'invalid' && (
                  <>
                    <Invalid />
                    <span>Code is invalid!</span>
                  </>
                )}
              </div>
            </Col>
          </Row>

          {promoState === 'valid' && promoData ? (
            <div className="congratulations-row">
              <h4>🤗 Congratulations</h4>
              <br></br>
              {/*<h3>Discount</h3>
              <h2>{discount}%</h2>*/}
              {descContent}

              <Row>
                <Col sm="8" className="mx-auto mt-4 pt-2">
                  <Button
                    type="primary"
                    onClick={() => {
                      dispatch(setPromoCode(promoData));
                      onApply();
                    }}
                    block
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
            </div>
          ) : (
            <Row>
              <Col sm="8" className="mx-auto mt-5" style={{ minHeight: 52 }}>
                {isChecking && <Spin indicator={<LoadingOutlined spin />} size="large" />}
                {!isChecking && (
                  <Button
                    type="primary"
                    onClick={checkPromoCode}
                    disabled={promoCodeValue.length < 4 || isChecking}
                    block
                  >
                    Check
                  </Button>
                )}
              </Col>
            </Row>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PromoModal;
