import React from 'react';
import PropTypes from 'prop-types';

import { Footer, NavbarDefault } from '../components/layout';

const DefaultPriveLayout = ({ children, navbar, footer }) => (
  <React.Fragment>
    <div className="prive-mode">
      {!navbar && <NavbarDefault logoOnly />}
      <main>{children}</main>
      {!footer && <Footer />}
    </div>
  </React.Fragment>
);

DefaultPriveLayout.propTypes = {
  navbar: PropTypes.bool,
  footer: PropTypes.bool,
};

DefaultPriveLayout.defaultProps = {
  navbar: false,
  footer: false,
};

export default DefaultPriveLayout;
