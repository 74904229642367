import { Modal } from 'antd';
import React from 'react';
import DotLoader from '../../../assets/json/dot_loader.json';
import Lottie from 'react-lottie';

export const CustomLoader = (props) => {
  const { isOpen = true } = props;
  return (
    <Modal
      open={isOpen}
      closeIcon={false}
      footer={false}
      centered
      maskClosable={false}
      closable={false}
      mask={true}
      styles={{
        mask: {
          backgroundColor: 'rgba(255,255,255, 0.5)',
          backdropFilter: 'blur(12px)',
        },
        content: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        body: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }}
      zIndex={1100}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: DotLoader,
          renderer: 'svg',
        }}
        height={160}
        width={160}
      />
    </Modal>
  );
};
