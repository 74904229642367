import React, { useEffect } from 'react';
import { Col, Container, DealCard, Row, SvgIcon } from '../../components/common';
import { Button, Card } from 'antd';
import MediaQuery from 'react-responsive';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';
import FilterDrawer from '../FilterDrawer';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useGroupListingSelector } from '../../store/selectors';
import hash from 'object-hash';
import { getGroupListing, resetGroupListing } from '../../slice/groupListingSlice';
import DealList from '../../components/common/DealList';

const GroupListingPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const { deals, title, cacheKey: prevCacheKey, state } = useGroupListingSelector();

  useEffect(() => {
    const groupId = searchParams.get('groupId');
    if (groupId) {
      const cacheKey = hash({ groupId });
      if (prevCacheKey !== cacheKey) {
        dispatch(resetGroupListing());
        console.log(`[${Date.now()}] dispatch getGroupListing`);
        dispatch(getGroupListing({ cacheKey, groupId }));
      }
    }
  }, [searchParams]);

  const getGroupListingTitle = (title) => {
    const parts = title.split(' ').filter((s) => s.trim().length > 0);
    if (parts.length >= 2) {
      return (
        <>
          <h3>
            {parts[0]}
            <span>{parts.slice(1).join(' ')}</span>
          </h3>
        </>
      );
    }
    return <h3>{title}</h3>;
  };

  return (
    <div className="twl-listing-wrapper">
      <section className="listing-page-title">
        <Container>
          {getGroupListingTitle(title)}
          <MediaQuery minWidth={768}>
            <FilterDrawer />
          </MediaQuery>
        </Container>
      </section>
      <section className="listing-bottom no-padding">
        <Container className="listing-container">
          <Row>
            <Col>
              <DealList items={deals} />
            </Col>
          </Row>
          {/* <Row>
            <Col className="text-center">
              <Button
                type="primary"
                className="map-btn"
                onClick={() => window.open('/listing-map', '_self')}
              >
                Map <SvgIcon name="map-icon" viewbox="0 0 14.855 14.695" />
              </Button>
            </Col>
          </Row>*/}
        </Container>
      </section>
    </div>
  );
};

export default GroupListingPage;
