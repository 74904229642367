import React, { useEffect } from 'react';
import { Col, Container, Row, SvgIcon } from '../../components/common';
import { Button, Card, Collapse } from 'antd';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import '../../assets/scss/plugins/slick-slider/slick.min.scss';
import '../../assets/scss/plugins/slick-slider/slick-theme.min.scss';
import './index.scss';

import LogoIcon from '../../assets/images/logo-icon.svg';
import PriveLogo from '../../assets/images/prive.svg';
import ExpImg from '../../assets/images/exp-img-prive.png';
import DiscoverImage from '../../assets/images/prive-img.jpg';
import PrivelandingBanner from '../../assets/images/privelanding-banner.jpg';
import PriveWhiteLogo from '../../assets/images/prive-white.svg';
import DownloadImg from '../../assets/images/prive-download.jpg';
import InqUserImg1 from '../../assets/images/inquiries-person1.png';
import InqUserImg2 from '../../assets/images/inquiries-person2.png';
import InqUserImg3 from '../../assets/images/inquiries-person3.png';
import { usePriveCategorySelector, usePriveHomeSelector } from '../../store/selectors';
import { useDispatch } from 'react-redux';
import { LoadState } from '../../constants';
import { getPriveHomePageData } from '../../slice/priveHomeSlice';
import { getPriveCategories } from '../../slice/priveCategorySlice';
import { openChatSupport } from '../../utility/chat';
import { logEvent } from '../../utility/analytics';

const { Panel } = Collapse;

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Next
    </div>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div className={className} onClick={onClick}>
      Prev
    </div>
  );
}

const LandingPagePrive = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { collections, state: homeState } = usePriveHomeSelector();
  const { categories, state: categoryState } = usePriveCategorySelector();

  const settingsHandpickedCollections = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerPadding: 10,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const isMobile = useMediaQuery({ query: `(max-width: 767px)` });

  const sliderBreaking = useMediaQuery({ query: `(max-width: 991px)` });
  const getCollectionItemTitle = (title) => {
    const parts = title.split(' ').filter((s) => s.trim().length > 0);
    if (parts.length >= 2) {
      return (
        <>
          <p>{parts[0]}</p>
          <h2>{parts.slice(1).join(' ')}</h2>
        </>
      );
    }
    return <p>{title}</p>;
  };

  useEffect(() => {
    logEvent('view_item', { name: 'Prive Exclusive Page' });
  }, []);

  useEffect(() => {
    if (homeState === LoadState.IDLE) {
      dispatch(getPriveHomePageData());
    }
  }, [homeState]);

  useEffect(() => {
    if (categoryState === LoadState.IDLE) {
      dispatch(getPriveCategories());
    }
  }, [categoryState]);

  return (
    <div className="twl-landing-prive">
      <section className="landing-banner">
        <div
          className="background-image"
          style={{ backgroundImage: `url(${PrivelandingBanner})` }}
        ></div>
        <Container>
          <Row className="banner-row">
            <Col sm="7">
              <h1 data-aos="fade-right" data-aos-duration="1000">
                Privé, your ultimate <br /> luxury experience
              </h1>
            </Col>
            <Col sm="5">
              {/*<div className="play-button">
                <div className="playbtn-inner" data-aos="zoom-in" data-aos-duration="800">
                  <SvgIcon name="play" viewbox="0 0 41.93 41.965" />
                </div>
              </div>*/}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="supercollection-section">
        <Container>
          <Row>
            <Col>
              <div className="supercollection-card">
                <img src={PriveLogo} alt="Luxurious offers. Unforgettable Moments" />
                <h3>Luxurious offers. Unforgettable Moments.</h3>
                <p>
                  Privé is an exclusive luxury segment that helps you discover privileges and
                  offers, on the go with ease. Experience luxury redefined, all with one membership.
                </p>
              </div>
            </Col>
          </Row>
          {collections && collections.length > 0 && (
            <div className="supercollection-bottom">
              <Row>
                <Col>
                  <h2 className="heading" data-aos="fade-in" data-aos-duration="1000">
                    Luxury Collections <br /> For You!
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Slider className="supercollection-slider" {...settingsHandpickedCollections}>
                    {collections.map((item, index) => {
                      return (
                        <div key={index}>
                          <Card
                            cover={<img alt={item.group_title} src={item.group_image} />}
                            data-aos="fade-in"
                            data-aos-duration="1000"
                          >
                            <div className="meta-row">
                              <div className="meta-left">
                                {getCollectionItemTitle(item.group_title)}
                                <div className="venues-row">
                                  <ul>
                                    {item.dealList
                                      .flatMap((deal) => deal.vDealImage)
                                      .slice(0, 4)
                                      .map((img) => {
                                        return (
                                          <li>
                                            <img src={img} alt="Venues" />
                                          </li>
                                        );
                                      })}
                                  </ul>
                                  {item.dealList.length > 0 && `+ ${item.dealList.length} Venues`}
                                </div>
                              </div>
                              <Button
                                type="secondary"
                                onClick={() => {
                                  const params = new URLSearchParams({
                                    isPrive: 'Yes',
                                  });
                                  history.replace(
                                    {
                                      pathname: `/offerListing`,
                                      search: params.toString(),
                                    },
                                    {
                                      groupId: item.group_id,
                                    },
                                  );
                                }}
                              >
                                Explore
                              </Button>
                            </div>
                          </Card>
                        </div>
                      );
                    })}
                  </Slider>
                  {sliderBreaking && collections.length <= 3 && (
                    <SvgIcon
                      className="bottom-arrow"
                      name="slide-arrow"
                      viewbox="0 0 51.092 32.96"
                    />
                  )}
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </section>
      {categories && (
        <section className="explore-section">
          <Container>
            <Row>
              <Col sm="12">
                <div>
                  <h2 className="heading text-left" data-aos="fade-in" data-aos-duration="1000">
                    Explore Our <br /> Amazing Categories
                  </h2>
                  <div className="explore-list-row">
                    <ul className="explore-list">
                      {categories.map((item, index) => (
                        <li
                          key={item.iCategoryID}
                          data-aos="fade-up"
                          data-aos-duration={600 + index * 200}
                        >
                          <div className="explore-list-card">
                            <div className="explore-list-card-image">
                              <img
                                className="icon"
                                src={item.vCategoryImage}
                                alt={item.vCategoryName}
                              />
                            </div>
                            <div className="explore-caption">
                              <h3>{item.vCategoryName}</h3>
                              <p>{item.vCategoryDescription}</p>
                              <Button
                                onClick={() => {
                                  const params = new URLSearchParams({
                                    categoryId: item.iCategoryID,
                                    isPrive: 'Yes',
                                  });
                                  history.push({
                                    pathname: '/listing',
                                    search: params.toString(),
                                  });
                                }}
                              >
                                Explore <SvgIcon name="arrow-right" viewbox="0 0 24.603 7.838" />
                              </Button>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <Card cover={<img alt="Explore" src={ExpImg} />}>
                      <div className="meta-row">
                        <p>
                          Discover a world <br /> of offers
                        </p>
                        <div className="venues-row">
                          <img src={PriveWhiteLogo} height="80" alt="Prive" />
                          <h3>100+</h3>
                          <small>Venues</small>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      )}
      <section className="discover-section">
        <Container>
          <Row>
            <Col>
              <div className="discover-card" data-aos="zoom-in" data-aos-duration="800">
                <div
                  className="discover-card-left"
                  style={{ backgroundImage: `url(${DiscoverImage})` }}
                >
                  <img src={PriveWhiteLogo} alt="Prive" />
                </div>
                <div className="discover-card-right">
                  <label>ABOUT US</label>
                  <h2>
                    Your Premium <br /> luxury experience
                  </h2>
                  <p>
                    Enjoy a curated list of the best luxury dining and spa venues across the UAE,
                  </p>
                  <ul>
                    <li>
                      <div className="ds-icon">
                        <SvgIcon name="redemptions-icon" viewbox="0 0 18.401 18.425" />
                      </div>
                      <p>Unlimited Redemptions</p>
                    </li>
                    <li>
                      <div className="ds-icon">
                        <SvgIcon name="venues-icon" viewbox="0 0 16.027 15.27" />
                      </div>
                      <p>100+ Venues</p>
                    </li>
                    <li>
                      <div className="ds-icon">
                        <SvgIcon name="luxury-icon" viewbox="0 0 19.482 18.912" />
                      </div>
                      <p>Exclusive Luxury Category</p>
                    </li>
                    <li>
                      <div className="ds-icon">
                        <SvgIcon name="savings-icon" viewbox="0 0 24.588 11.354" />
                      </div>
                      <p>Unlimited Savings </p>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="faq-section">
        <Container>
          <Row>
            <Col sm="12" className="faq-left">
              <h1>FAQ</h1>
            </Col>
            <Col sm="12" className="faq-right">
              <Collapse
                accordion
                defaultActiveKey={['0']}
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <SvgIcon
                      name="chevron-right"
                      className="downArrow"
                      viewbox="0 0 16.638 17.53"
                    />
                  ) : (
                    <SvgIcon
                      name="chevron-right"
                      className="downArrow"
                      viewbox="0 0 16.638 17.53"
                    />
                  )
                }
              >
                <Panel header="What is Prive?" key="1">
                  <p>
                    Prive is the TravellerPass app's luxury segment, helping you discover
                    extraordinary privileges and offers across high-end dining and wellness venues
                    across the UAE.
                  </p>
                </Panel>
                <Panel header="Do I need a membership to avail offers?" key="2">
                  <p>
                    Yes, you will need to activate one of the membership plans to avail of the
                    offers.You can purchase the 30-day or 365-day membership plans by clicking on
                    the 'My Membership' tab on the main menu of the app to get started right away!
                    What's more, you get all the days as promised based on the membership you choose
                    from the date of activation, so you can keep availing of our offers without any
                    interruption.
                  </p>
                </Panel>
                <Panel header="How do I use the app and start saving?" key="3">
                  <p>
                    Choose from nearby offers, select a category, or search for your favourite
                    brands. Once you have opened the brand page of choice, choose the voucher you
                    want to avail of and inform the staff before the bill is generated. Follow the
                    on-screen instructions from there and pay the discounted amount. It's that easy!
                  </p>
                </Panel>
                <Panel
                  header="Is there a limit to the number of time I can avail an offer?"
                  key="4"
                >
                  <p>
                    99% of Prive offers are unlimited, which means you get your discount as many
                    times as you want. Check the deal vouchers on specific brand pages to know more.
                  </p>
                </Panel>

                <Panel header="How do I get in touch for further assistance?" key="5">
                  <p>
                    Get in touch with our agents through the 'Need Help' tab on the main menu of the
                    app or email us at{' '}
                    <a href="mailto:concierge@travellerpass.com">concierge@travellerpass.com</a>. We
                    are happy to assist.
                  </p>
                </Panel>
                {/*<Panel header="Where can I download TravellerPass?" key="3">
                  <p>
                    TravellerPass can be downloaded from the below stores:
                    <div className="download-btns">
                      <Button
                        onClick={() =>
                          window.open(
                            'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
                      </Button>
                      <Button
                        onClick={() =>
                          window.open(
                            'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                            '_blank',
                          )
                        }
                      >
                        <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
                      </Button>
                      <Button
                        onClick={() =>
                          window.open('https://appgallery.huawei.com/#/app/C100681983', '_blank')
                        }
                      >
                        <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
                      </Button>
                    </div>
                  </p>
                </Panel>
                <Panel header="When can I start my membership with TravellerPass? " key="4">
                  <p>
                    You can purchase our Annual Bundle membership plan to get started right away!
                    What's more, you get all the days as promised based on the membership you choose
                    from the date of activation, so you can keep availing our offers without any
                    interruption.
                  </p>
                </Panel>
                <Panel header="How do I redeem my TravellerPass offer?" key="5">
                  <p>
                    Once you activate your membership, start saving by following the below steps:
                  </p>
                </Panel>*/}
              </Collapse>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="download-section">
        <ParallaxProvider>
          <Container>
            <Row>
              <Col lg="12">
                <div className="download-card">
                  <div className="bg-images-row">
                    <img className="bg-images" alt="" src={DownloadImg} />
                  </div>
                  <div className="download-card-iner">
                    <SvgIcon className="phone-icon" name="phone" viewbox="0 0 12.18 20.438" />
                    <Parallax speed={5} disabled={isMobile}>
                      <h1 data-aos="zoom-out" data-aos-duration="800">
                        Download the app <br /> and start today!
                      </h1>
                    </Parallax>
                    <Parallax speed={6} disabled={isMobile}>
                      <p data-aos="zoom-out" data-aos-duration="1600">
                        Get access to endless 2 for 1 deals and flat discounts at over 3000+ venues
                        across multiple categories and cities.
                      </p>
                    </Parallax>
                    <Parallax speed={4} disabled={isMobile}>
                      <div className="download-btns" data-aos="fade-in" data-aos-duration="1200">
                        <Button
                          onClick={() =>
                            window.open(
                              'https://apps.apple.com/ae/app/travellerpass-lifestyle-app/id955440154',
                              '_blank',
                            )
                          }
                        >
                          <SvgIcon name="apple" viewbox="0 0 22.982 28.234" /> App Store
                        </Button>
                        <Button
                          onClick={() =>
                            window.open(
                              'https://play.google.com/store/apps/details?id=com.traveller&hl=en_IN&gl=US',
                              '_blank',
                            )
                          }
                        >
                          <SvgIcon name="googleplay" viewbox="0 0 22.416 24.786" /> Google Play
                        </Button>
                        {/*<Button
                          onClick={() =>
                            window.open('https://appgallery.huawei.com/#/app/C100681983', '_blank')
                          }
                        >
                          <SvgIcon name="app-gallery" viewbox="0 0 22.132 21.799" /> App Gallery
                        </Button>*/}
                      </div>
                    </Parallax>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </ParallaxProvider>
      </section>
      <section className="social-section">
        <div className="travellerPass-icon" data-aos="fade-right" data-aos-duration="900">
          <SvgIcon name="travellerPass-icon" viewbox="0 0 115.729 187.284" />
        </div>
        <Container>
          <Row>
            <Col>
              <img className="logo-icon" src={LogoIcon} alt="TravellerPass" />
              <h1>
                Discover the world like never before <br /> with TravellerPass
              </h1>
              <p>
                For technical issues and general inquiries, <br /> please visit our{' '}
                <Link
                  to="#"
                  onClick={(e) => {
                    e.preventDefault();
                    openChatSupport();
                  }}
                >
                  Chat Center.
                </Link>
              </p>
              <div className="customer-care-list">
                <div className="item">
                  <img src={InqUserImg1} alt="Chat Center" />
                </div>
                <div className="item">
                  <img src={InqUserImg2} alt="Chat Center" />
                </div>
                <div className="item">
                  <img src={InqUserImg3} alt="Chat Center" />
                </div>
              </div>
              <ul>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="instagram" viewbox="0 0 17.137 17.137" />
                  </Button>
                </li>
                <li>
                  <Button
                    type="link"
                    onClick={() =>
                      window.open('https://www.facebook.com/travellerpassglobal/', '_blank')
                    }
                  >
                    <SvgIcon name="facebook" viewbox="0 0 8.106 17.344" />
                  </Button>
                </li>
              </ul>
              <div className="copyright">© 2025. All rights reserved.</div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default LandingPagePrive;
