import React from 'react';
import { Col, Container, Row, SvgIcon } from '../../../components/common';
import { Button } from 'antd';
import { Link, Redirect, useLocation } from 'react-router-dom';
import './index.scss';

import successBg from '../../../assets/images/success-bg.svg';

const BuyMembershipCheckoutSuccess = () => {
  const location = useLocation();
  const { iMembershipCode } = location.state || {};

  if (!iMembershipCode) {
    return <Redirect to="/" />;
  }

  return (
    <div className="buymembershicheckout-wrapper">
      <Container>
        <Row>
          <Col>
            <div className="bmc-checkout-success">
              <img src={successBg} alt="Success" data-aos="fade-in" data-aos-duration="1000" />
              <div className="success-check" data-aos="zoom-in" data-aos-duration="1000">
                <SvgIcon name="check" viewbox="0 0 10.289 9.742" />
              </div>
              <h2 data-aos="fade-up" data-aos-duration="800">
                Congratulations
              </h2>
              <p data-aos="fade-up" data-aos-duration="1000">
                Your purchase is successful!
              </p>
              <p data-aos="fade-up" data-aos-duration="1200">
                Your membership ID
              </p>
              <h2 data-aos="fade-up" data-aos-duration="1400">
                {iMembershipCode}
              </h2>
              <div className="mt-4 pt-3" data-aos="fade-up" data-aos-duration="1600">
                <Button
                  type="secondary"
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  I’m Done
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BuyMembershipCheckoutSuccess;
